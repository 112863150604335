
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

export class PeriodValidator{
    startDate:Date;
    endDate: Date;

    constructor(startDate:Date, endDate:Date){
        this.startDate = startDate;
        this.endDate = endDate;
    }

    isFormatRight(): Boolean{
        return this.startDate <= this.endDate;
    }

    isInPeriodDays(interval: number):Boolean{
        if( 0 <= this.differenceInDays() && 
        this.differenceInDays() <= interval ) return true;
        return false;
    }

    differenceInDays(): number{
        const diffInTime = Math.abs(this.startDate.getTime() - this.endDate.getTime());
        return Math.ceil(diffInTime /(1000 * 60 * 60 * 24)); 
    }

    alertSwall(titleMessage:String, textMessage:String){
        Swal
        .fire({
            title: `${titleMessage}`,
            text: `${textMessage}`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        });
    }


}
