import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss']
})
export class ResultDialogComponent {

  bacenBanco: number;
  invoicesNotFound: any[] = [];
  invoicesPaid: any[] = [];
  invoicesWasPaid: any[] = [];
  invoicesRegistered: any[] = [];
  invoicesRefused: any[] = [];
  invoicesError: any[] = [];
  registers: any[] = [];
  banks: any[] = [];
  size: number = 0;

  constructor(
    private router: Router,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageUtil: MessageUtil
  ) {
    if(this.config.data) {
      // invoiceError estava quebrando a tela por vir null e não ser tratado
      this.bacenBanco = this.config.data.bacenBanco ? this.config.data.bacenBanco : [];
      this.invoicesNotFound = this.config.data.invoicesNotFound ? this.config.data.invoicesNotFound : [];
      this.invoicesPaid = this.config.data.invoicesPaid ? this.config.data.invoicesPaid : [];
      this.invoicesWasPaid = this.config.data.invoicesWasPaid ? this.config.data.invoicesWasPaid : [];
      this.invoicesRegistered = this.config.data.invoicesRegistered ? this.config.data.invoicesRegistered : [];
      this.invoicesRefused = this.config.data.invoicesRefused ? this.config.data.invoicesRefused : [];
      this.invoicesError = this.config.data.invoicesError ? this.config.data.invoicesError : [];
      this.registers = this.config.data.otherRegistry ? this.config.data.otherRegistry : [];
      this.banks = this.config.data.banks;
    }
  
    // Mensagem de nada encontrado para exibir
    this.size += this.invoicesNotFound.length;
    this.size += this.invoicesPaid.length;
    this.size += this.invoicesWasPaid.length;
    this.size += this.invoicesRegistered.length;
    this.size += this.invoicesRefused.length;
    this.size += this.invoicesError.length;
    this.size += this.registers.length;

    if (this.size === 0) {
      this.messageUtil.generateMessage('info', 'Nenhum registro encontrado', 'Nenhum registro foi encontrado para ser exibido.');
      this.dialogRef.close();
    }
    console.log(this);
  }

  getValuePaid(invoice): number {
    switch (this.bacenBanco) {
      case 341:
        return invoice.valorRecebido + invoice.valorTarifa;
      default:
        return invoice.valorRecebido;
    }
  }

  getTotal(list: any[]): number {
    return list.reduce((total, invoice) => total + (invoice.value ? invoice.value : this.getValuePaid(invoice)), 0);
  }

  getPaymentDate(billet): Date {
    if (moment(billet.dataEntrada).isAfter(moment(billet.dataCredito))) {
      return billet.dataEntrada;
    }
    return billet.dataCredito;
  }

  openBilletManagement(): void {
    this.dialogRef.close();
    this.router.navigate(['/app/billing/billets-management/billet'], { queryParams: { filter: 'refused' } });
  }

}
