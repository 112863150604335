<div class="dialog-form">
    <div class="table">
        <section class="container-fluid no-breadcrumbs">
            <ng-container *ngIf="invoicesPaid.length > 0">
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Boletos lançados</h3>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="invoicesPaid" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Dt Pagamento</th>
                                        <th>Dt Vencimento</th>
                                        <th>Fatura</th>
                                        <th>Parcela</th>
                                        <th>Valor Boleto</th>
                                        <th>Valor Pago</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoice>
                                    <tr>
                                        <td>{{invoice.customerName}}</td>
                                        <td>{{ getPaymentDate(invoice) | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ invoice.dataVencimento | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ invoice.idFatura }}</td>
                                        <td>{{ invoice.sequence }}</td>
                                        <td class="text-right">{{ invoice.valorTitulo | number:'1.2-2'  }}</td>
                                        <td class="text-right">{{ getValuePaid(invoice)  | number:'1.2-2' }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="5"></td>
                                        <td class="text-right">Total: </td>
                                        <td class="text-right">{{getTotal(invoicesPaid) | number: '1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
                <br><br>
            </ng-container>
            <ng-container *ngIf="invoicesWasPaid.length > 0">
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Boletos lançados anteriormente</h3>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="invoicesWasPaid" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Dt Pagamento</th>
                                        <th>Dt Vencimento</th>
                                        <th>Fatura</th>
                                        <th>Parcela</th>
                                        <th>Valor Boleto</th>
                                        <th>Valor Pago</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoice>
                                    <tr>
                                        <td>{{ invoice.customerName }}</td>
                                        <td>{{ getPaymentDate(invoice) | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ invoice.dataVencimento | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ invoice.idFatura }}</td>
                                        <td>{{ invoice.sequence }}</td>
                                        <td class="text-right">{{ invoice.valorTitulo | number:'1.2-2'  }}</td>
                                        <td class="text-right">{{ getValuePaid(invoice)  | number:'1.2-2' }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="5"></td>
                                        <td class="text-right">Total: </td>
                                        <td class="text-right">{{getTotal(invoicesWasPaid) | number: '1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
                <br><br>
            </ng-container>
            <ng-container *ngIf="invoicesNotFound.length > 0">
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Boletos pagos não encontrados</h3>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="invoicesNotFound" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Dt Pagamento</th>
                                        <th>Dt Vencimento</th>
                                        <th>Nosso Número</th>
                                        <th>Valor Boleto</th>
                                        <th>Valor Pago</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoice>
                                    <tr>
                                        <td>{{invoice.customerName}}</td>
                                        <td>{{ getPaymentDate(invoice) | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ invoice.dataVencimento | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ invoice.nossoNumero }}</td>
                                        <td class="text-right">{{ invoice.valorTitulo | number:'1.2-2' }}</td>
                                        <td class="text-right">{{ getValuePaid(invoice)  | number:'1.2-2' }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="4"></td>
                                        <td class="text-right">Total: </td>
                                        <td class="text-right">{{getTotal(invoicesNotFound) | number: '1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
                <br><br>
            </ng-container>
            <ng-container *ngIf="invoicesRegistered.length > 0">
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Boletos registrados</h3>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="invoicesRegistered" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Dt Vencimento</th>
                                        <th>Fatura</th>
                                        <th>Parcela</th>
                                        <th>Valor Boleto</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoice>
                                    <tr>
                                        <td>{{ invoice.customerName}}</td>
                                        <td class="text-center">{{ invoice.dueDate | date: 'dd/MM/yyyy' }}</td>
                                        <td class="text-center">{{ invoice.invoiceNumber }}</td>
                                        <td class="text-center">{{ invoice.sequence }}</td>
                                        <td class="text-right">{{ invoice.value | number:'1.2-2'  }}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="3"></td>
                                        <td class="text-right">Total: </td>
                                        <td class="text-right">{{getTotal(invoicesRegistered) | number: '1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
                <br><br>
            </ng-container>
            <ng-container *ngIf="invoicesRefused.length > 0">
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Boletos recusados</h3>
                        <button class="danger easymarine" (click)="openBilletManagement()"
                            style="float: right;margin-top: -7px;">
                            Gerenciar
                        </button>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="invoicesRefused" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Dt Vencimento</th>
                                        <th>Fatura</th>
                                        <th>Parcela</th>
                                        <th>Valor Boleto</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-billet>
                                    <tr>
                                        <td>{{ billet.customerName}}</td>
                                        <td class="text-center">{{ billet.dueDate | date: 'dd/MM/yyyy' }}</td>
                                        <td class="text-center">{{ billet.invoiceNumber }}</td>
                                        <td class="text-center">{{ billet.sequence }}</td>
                                        <td class="text-right">{{ billet.value | number:'1.2-2'  }}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="3"></td>
                                        <td class="text-right">Total: </td>
                                        <td class="text-right">{{getTotal(invoicesRefused) | number: '1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
                <br><br>
            </ng-container>
            <ng-container *ngIf="invoicesError && invoicesError.length > 0">
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Boletos com erro. Favor contatar o suporte</h3>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="invoicesError" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Número da Fatura</th>
                                        <th>Identificador do Boleto / Nosso Número</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoice>
                                    <tr>
                                        <td>{{ invoice.invoiceId }}</td>
                                        <td>{{ invoice.identifier }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
            </ng-container>
            <ng-container *ngIf="registers && registers.length > 0">
                <app-notifications [isError]="true">Os códigos encontrados no arquivo de retorno são referentes a tarifas bancárias (028) e boletos cancelados (09). Não há atualizações de faturas a serem exibidas</app-notifications>
                <p-fieldset>
                    <p-header>
                        <h3 class="article-title" style="float: left; margin: 0px;">Registros ignorados (Tarifas, baixas, etc...)</h3>
                    </p-header>

                    <div class="row">
                        <div class="col-12">
                            <p-table [value]="registers" class="primeTable" class="primeTable optionsTable"
                                autoLayout="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Identificador do Boleto / Nosso Número</th>
                                        <th>Código de movimento</th>
                                        <th>Mensagem</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoice>
                                    <tr>
                                        <td>{{ invoice.nossoNumero }}</td>
                                        <td>{{ invoice.codigoMovimento }}</td>
                                        <td>{{ invoice.mensagemMovimento }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-fieldset>
            </ng-container>
            <br><br>
        </section>
    </div>
</div>