<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Faturamento > Gestão de Boletos > Arquivos de remessa</span>
        <button class="primary fab-button" (click)="getAll()">
            <i class="pi pi-refresh" style="font-size: 21px;"></i>
        </button>
    </p-header>
    <div class="filters filter-grid">
        <div class="filter-row" >
            <div class="form-control">
                <label>Data inicial</label>
                <p-calendar dateFormat="dd/mm/yy" name="filterDateStart" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [maxDate] ="currentDate" rPCalendarMask [(ngModel)]="filter.dateStart" [showIcon]="true"
                    i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Data final</label>
                <p-calendar dateFormat="dd/mm/yy" name="filterDateEnd" [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                [maxDate]="currentDate" rPCalendarMask [(ngModel)]="filter.dateEnd" [showIcon]="true" i18n>
                </p-calendar>
            </div>  
        </div>
    </div> 
    
    <div class="subtitles">
        <fieldset>
            <legend>Legenda (Arquivos de remessa)</legend>
            <div class="badges">
                <div class="badge" style="background-color: #77dc77;color: #77dc77"></div>
                Todos Pagos
                <div class="badge" style="background-color: white;color: white"></div>
                Todos registrados
                <div class="badge" style="background-color:  #ffff80;color:  #ffff80"></div>
                Registros pendentes
                <div class="badge" style="background-color: #f55959;color: #f55959"></div>
                Contém Recusado
                <div class="badge canceled text-center" style="background-color: white;color: red;">
                    &nbsp;&nbsp;&nbsp;&nbsp;</div>
                Cancelado
                <div class="badge" style="background-color: rgb(0, 183, 255);color: rgb(0, 183, 255)"></div>
                Aguardando
            </div>
        </fieldset>
        <fieldset>
            <legend>Legenda (Boleto)</legend>
            <div class="badges">
                <div class="badge" style="background-color: #77dc77;color: #77dc77"></div>
                Pago
                <div class="badge" style="background-color: white;color: white"></div>
                Registrado
                <div class="badge" style="background-color:  #ffff80;color:  #ffff80"></div>
                Registro pendente
                <div class="badge" style="background-color: #f55959;color: #f55959"></div>
                Recusado
                <div class="badge canceled text-center" style="background-color: white;color: red;">
                    &nbsp;&nbsp;&nbsp;&nbsp;</div>
                Cancelado
                <div class="badge" style="background-color: rgb(0, 183, 255);color: rgb(0, 183, 255)"></div>
                Aguardando
            </div>
        </fieldset>
    </div>

    <div class="filters filter-grid">
        <div class="filter-row" >
            <p-fileUpload chooseLabel="Importar arquivo de retorno" mode="basic" name="demo[]"
            accept=".ret,.crt,.txt" (uploadHandler)="onBasicUpload($event.files)" [auto]="true"
            customUpload="true" style="justify-self: left;" #upload>
            </p-fileUpload>
            <div class="buttons">
                <button class="easymarine primary" (click)="getAll()" type="button">{{ 'FIND' |
                    translate
                    }}</button>    
            </div>
        </div>
    </div>
    <br>
      
    <div class="table">
        <p-table [value]="files" #shippingFiles [resizableColumns]="true" [autoLayout]="true"
            sortMode="multiple" [rows]="10" [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[10,20,30,50,100]"
            styleClass="remessa">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th *ngIf="sequence">Sequencial</th>
                    <th class="text-center" [pSortableColumn]="'fileName'">
                        Arquivo Remessa
                        <p-sortIcon [field]="'fileName'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'issueDate'">
                        Dt Emissão
                        <p-sortIcon [field]="'issueDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'nomeCarteiraCobranca'">
                        Cart. Cobrança
                        <p-sortIcon [field]="'nomeCarteiraCobranca'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'qtyBillet'">
                        Bol
                        <p-sortIcon [field]="'qtyBillet'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'paidQty'">
                        Bol Pgo
                        <p-sortIcon [field]="'paidQty'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'registeredQty'">
                        Bol Reg
                        <p-sortIcon [field]="'registeredQty'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'value'">
                        Vl total
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'value'">
                        Vl Pago
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th class="text-center">Último download</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file let-index="rowIndex">
                <tr [ngClass]="getFileStatusColorClass(file)">
                    <td (click)="updateDownload(file)">
                        <a [href]="file.fileUrl" *ngIf="canUpload(file)" class="icon-button">
                            <i class="pi pi-download"></i>
                        </a>
                    </td>
                    <td *ngIf="sequence">
                        <span *ngIf="index < sequence">
                            {{sequence - index}}
                        </span>
                    </td>
                    <td (click)="show(file)" style="cursor:pointer">{{ file.fileName }}</td>
                    <td>{{ file.issueDate | date: 'dd/MM/yy - HH:mm' }}</td>
                    <td>{{ file.nomeCarteiraCobranca }}</td>
                    <td>{{ file.qtyBillet }}</td>
                    <td>{{ file.paidQty }}</td>
                    <td>{{ file.registeredQty }}</td>
                    <td>{{ file.value | number:'1.2-2' }}</td>
                    <td>{{ file.paidValue | number:'1.2-2' }}</td>
                    <td>{{ file.latestDownload | date: 'dd/MM/yy - HH:mm' }}</td>
                </tr>
                <tr *ngIf="file.bankingBillets.length !== 0" [hidden]="!file.show">
                    <td colspan="11">
                        <p-table [value]="file.bankingBillets" #bankingBillets [resizableColumns]="true"
                            [autoLayout]="true" sortMode="multiple" styleClass="boletos">
                            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'sequence'">
                        Parcela
                        <p-sortIcon [field]="'sequence'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'invoice.id'">
                        Fatura
                        <p-sortIcon [field]="'invoice.id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'customerName'">
                        Cliente
                        <p-sortIcon [field]="'customerName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'issueDate'">
                        Data de Emissão
                        <p-sortIcon [field]="'issueDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'dueDate'">
                        Data de Vencimento
                        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'value'">
                        Valor
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'valuePaid'">
                        Valor Pago
                        <p-sortIcon [field]="'valuePaid'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'documentUrl'">
                        Boleto
                        <p-sortIcon [field]="'documentUrl'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-billet>
                <tr [ngClass]="{'paid': billet.active && billet.valuePaid > 0, 'recent' : !billet.registered && isToday(billet),
                'pending': billet.active && !billet.valuePaid && !isToday(billet) && !billet.registered && !billet.refused,
                'not-reg': billet.refused && billet.active,
                'canceled': !billet.active }">
                    <td>{{ billet.sequence }}</td>
                    <td>{{ billet.invoiceNumber }}</td>
                    <td>{{billet.customerName}}</td>
                    <td>{{ billet.issueDate | date: 'dd/MM/yyyy - HH:mm' }}</td>
                    <td>{{ billet.dueDate | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ billet.value | number:'1.2-2' }}</td>
                    <td>{{ billet.valuePaid | number:'1.2-2' }}</td>
                    <td><a *ngIf="billet.active && billet.processed" [href]="billet.documentUrl" target="_blank">Clique p/ Visualizar</a>
                      <a *ngIf="!billet.processed" target="_blank">Registro Pendende</a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
</p-fieldset>
