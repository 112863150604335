import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sling } from 'src/app/models';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import Swal from 'sweetalert2';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-sling-confirm-operation',
  templateUrl: './sling-confirm-operation.component.html',
  styleUrls: ['./sling-confirm-operation.component.scss']
})
export class SlingConfirmOperationComponent implements OnInit {

  sling: Sling;
  availablePeriods: any[];

  constructor(
    private slingService: SlingService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    public dialog: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.sling = this.config.data.sling;
    this.getAvailablePeriods();
  }

  getAvailablePeriods(): void {
    this.spinner.show();
    this.slingService.getAvailablePeriods(moment().utc().valueOf(), this.sling?.boat?.slingQueueType?.id??0).subscribe({
      next: (data) => {
        this.availablePeriods = data.map((hour) => ({ label: moment(new Date(hour)).format('HH:mm'), value: hour }));
        this.availablePeriods.push(
          {
            label: moment(this.sling.executionForecast).format('HH:mm'),
            value: this.sling.executionForecast
          }
        );
        this.availablePeriods = this.availablePeriods.sort((a, b) => a - b);
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }}
    );
  }
   
  confirmSlingCheck(): void {
    Swal.fire({
      title: 'Confirmar Lingada?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.slingService.confirmSling(this.sling).subscribe(
          () => {
            this.toasty.success('Lingada confirmada com sucesso');
            this.dialog.close();
          },
          (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
        );
      }
    });
  }

  cancelSlingCheck(): void {
    Swal.fire({
      title: 'Cancelar Lingada?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.slingService.cancelSling(this.sling.id).subscribe(
          () => {
            this.toasty.success('Lingada cancelada com sucesso');
            this.dialog.close();
          },
          (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
        );
      }
    });
  }
}
