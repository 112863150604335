import { ConfirmCompetencyDialogComponent } from './confirm-competency-dialog/confirm-competency-dialog.component';
import { InvoiceDiscountComponent } from './invoice-discount/invoice-discount.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import * as FileSaver from 'file-saver'
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {LazyLoadEvent, MenuItem, SortEvent} from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { InvoicePaymentComponent } from 'src/app/components/invoice-payment/invoice-payment.component';
import { ChangeDateDialogComponent } from 'src/app/components/invoice/change-date-dialog/change-date-dialog.component';
import { DanfeUploadComponent } from 'src/app/components/invoice/danfe-upload/danfe-upload.component';
import { InvoiceEmailsComponent } from 'src/app/components/invoice/invoice-emails/invoice-emails.component';
import { InvoiceHelpComponent } from 'src/app/components/invoice/invoice-help/invoice-help.component';
import { InvoicePaidsComponent } from 'src/app/components/invoice/invoice-paids/invoice-paids.component';
import { ParcelComponent } from 'src/app/components/invoice/parcel/parcel.component';
import { InvoiceBankingBillet, Marina, Role, SlingConfig } from 'src/app/models';
import { InvoiceQuotaDTO } from 'src/app/models/dtos/invoiceQuotaDTO';
import { ImportResult } from 'src/app/models/import-result';
import { Invoice } from 'src/app/models/invoice';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { MarinaCompany } from 'src/app/models/marina-company';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { BilletService } from 'src/app/services/billet.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancesService } from 'src/app/services/finances.service';
import { GroupService } from 'src/app/services/group.service';
import { ImportResultService } from 'src/app/services/import-result.service';
import { environment } from 'src/environments/environment';
import { InvoiceReceiptService } from 'src/app/services/invoice-receipt.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { WorkSheet } from 'xlsx';
import { DuedateSelectionComponent } from '../order-invoice/duedate-selection/duedate-selection.component';
import { UploadDanfeRequestDTO } from 'src/app/models/dtos/upload-danfe-request-dto';
import { BilletDialogComponent } from '../billets-management/billet/billet-dialog/billet-dialog.component';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { InvoiceEmailDialogComponent, InvoiceEmaiDialogParams, InvoiceToEmailDTO } from './invoice-email-dialog/invoice-email-dialog.component';
import { RoleService } from 'src/app/services/role.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import dayjs from 'dayjs';
import {FederalIdType} from '../../../models/federal-id-type';
import { InvoiceUploadNfComponent } from './invoice-upload-nf/invoice-upload-nf.component';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { Observable, map } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Toast } from 'primeng/toast';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;
  @ViewChild('uploadSysAuto', { static: false }) uploadSysAuto: FileUpload;

  numberOfRows = 10;
  marinaCompanies: MarinaCompany[];
  loggedMarina: Marina;
  filter: any = {};
  invoices: any[] = new Array();
  invoicesTable: any[] = new Array();
  groups: any[] = [];
  filteredCustomers: any[] = [];
  filteredCompanies: any[] = [];
  totalBilling = 0;
  totalPending = 0;
  totalPaid = 0;
  totalDiscount = 0;
  totalInterest = 0;
  selectedCustomerId: number;
  selectedCompany: any = null;
  billingPortifolioList: any[] = [];
  selectedInvoices: any[] = [];
  paginationFilter: PaginationFilter = new PaginationFilter();
  globalFilterForm = new UntypedFormControl();
  loading = false;
  disableFlag = false;
  selectedInvoiceRow: number;
  cols: any[];
  enableInvoice: boolean;
  uploadStatus: any[];
  files: any[];
  globalFilter = '';
  interval: any;
  lastStatus = 'READY';
  changeDueDate = false;
  changeCompetenceDate = false;
  invoice: any;
  today = new Date();
  cityTax: number;
  slingConfigCityTax: number;
  isWorkshop: Boolean;
  isNixBank: boolean;
  nfpEmmiter: boolean = false;
  nixBankId: number;
  marinaId: number=StorageUtil.getMarinaId();
  itemsExportType: MenuItem[] = [];

  originOptions: any[];

  public statusList: Array<{ label: string, value: any }> = [
    { label: 'PAID', value: 'PAID' },
    { label: 'PENDING', value: 'PENDING' },
    { label: 'CANCELED', value: 'CANCELED' }
  ];
  public yesNoOptions: Array<{ label: string, value: any }> = [
    { label: 'BOTH', value: null },
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];
  totalRecords: any;
  invoicesBilletProblems: any[];
  slingConfig: SlingConfig = new SlingConfig();
  invoicesEmailProblems: any[];
  productNF: boolean = false;
  liberaChangePage: boolean = false
  constructor(
    private dialog: DialogService,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private groupsService: GroupService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private financesService: FinancesService,
    private messageUtil: MessageUtil,
    private slingConfigService: SlingConfigService,
    private router: Router,
    private importResultService: ImportResultService,
    private billetService: BilletService,
    private invoiceReceiptService: InvoiceReceiptService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private roleService: RoleService,
    private operationalConfigService: OperationalConfigService,
    private userService: UserService,
    private toasty:ToastService,
  ) {
    this.cols = [
      { field: 'nfeNumber', header: 'NF' },
      { field: 'invoiceNumber', header: 'Fatura' },
      { field: 'origin', header: 'Origem' },
      { field: 'customer.name', header: 'Cliente' },
      { field: 'groupName', header: 'Grupo' },
      { field: 'emissionDate', header: 'Data' },
      { field: 'dueDate', header: 'Vencimento' },
      { field: 'competenceDate', header: 'Competência' },
      { field: 'value', header: 'Total' },
      { field: 'discount', header: 'Desconto' },
      { field: 'interest', header: 'Juros' },
      { field: 'pendingValue', header: 'Pendente' },
      { field: 'totalPaid', header: 'Pago' },
      { field: 'status', header: 'Status' }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.clear();
    this.getConfig();
    this.getBilingPortifolioList();
    this.loadSlingConfig();
    await this.mountOptions();
    this.getCustomers();
    this.getGroups();
    await Promise.all([this.getWorkshopConfig(), this.nixBankConfig()]);
    this.getOriginOptions();
    this.getCompanies();
    this.verifyStatusSendEmail();
    this.setMenuExport();

  }

  private setMenuExport() {
    this.itemsExportType = [
      {
        label: 'Simplificado',
        command: (event) => this.exportTableExcel(),
      },
      {
        label: 'Detalhado',
        command: (event) => this.exportInvoiceDetailsReport(),
      },
    ]
  }

  exportInvoiceDetailsReport(): void {
    this.spinner.show();
    const idsInvoice = this.getInvoiceIds();
    this.invoiceService.exportDetailsReportPdf(idsInvoice).subscribe(
      (data) => {
        this.spinner.hide();
        if(data.path) {
          window.open(data.path, '_blank');
        } else {
          console.log('error');
        }
    },
      (error) => {
        console.log(error?.error?.message);
        this.spinner.hide();
      },
    );
  }

  getInvoiceIds() {
    return this.selectedInvoices && this.selectedInvoices.length > 0 ?
      this.selectedInvoices.map(i => i.id) : this.invoicesTable?.map(i => i.id);
  }

  async getWorkshopConfig() {
    this.isWorkshop =
      await this.operationalConfigService.isWorkshop();
  }

  async nixBankConfig() {
    this.isNixBank = await this.operationalConfigService.nixBankEnabled();
  }

  getOriginOptions() {
    this.originOptions = [
      {label: 'Pedido avulso', value: 'DETACHED'},
      {label: 'Lingada', value: 'SLING'},
      {label: 'Mensalidade', value: 'TUITION'},
      {label: 'Diversos', value: 'MIXED'},
      {label: 'Importado DANFE', value: 'LEGACY_IMPORT'},
      {label: 'Franquia', value: 'FRANCHISE'}];
      if (this.isWorkshop) {
        this.originOptions.push({label: 'Oficina', value: 'WORKSHOP' });
      }
    }
  getIconOrigin(invoice): string {
    if (!invoice.orders[0]) {
      return 'error';
    }
    const origin = invoice.orders[0].origin;
    if (invoice.orders.find((o) => o.origin !== origin) !== undefined) {
      return 'MULTIPLE';
    }
    return origin;
  }

  async verifyStatusSendEmail(): Promise<void> {
    const res = await this.findLastResult();
    if (res && (res.result || res.status === 'READY')) {
      this.lastStatus = res.status;
    } else if (res) {
      this.lastStatus = res.status;
      this.startInterval();
    }
  }

  startInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    // tslint:disable-next-line: variable-name
    const this_ = this;
    this.interval = setInterval(async () => {
      const result = await this.findLastResult();
      if (result && result.status === 'READY') {
        this.lastStatus = result.status;
        this.searchInvoices();
        clearInterval(this_.interval);
      }
    }, 30000);
  }

  async findLastResult(): Promise<ImportResult> {
    return new Promise<ImportResult>(
      async (res) => {
        this.importResultService.findBySource('EMAIL').subscribe(
          async (result: ImportResult) => {
            res(result);
          },
          async (err) => {
            res(null);
          }
        );
      }
    );
  }

  isActiveSendEmail(): boolean {
    return this.lastStatus === 'READY';
  }

  getLastPayment(invoicePaids: InvoicePaid[]): string {
    if (invoicePaids !== null && invoicePaids.length > 0) {
      const filter = invoicePaids.filter(payment => !payment.canceled);
      if (filter.length > 0) {
        const lastPayment = filter[filter.length - 1];
        const lastDatePayment = moment(lastPayment.datePaid).format('DD/MM/YY');
        return `Último pgto. em ${lastDatePayment}`;
      }
      return '';
    }
  }

  hasInvoiceByWhatsapp(): boolean{
    if (this.slingConfig?.sendInvoiceViaWhatsapp) {
      return this.slingConfig.sendInvoiceViaWhatsapp;
    } return false;
  }

  getCompanies(): void {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    this.filteredCompanies = this.marinaCompanies.map((c) => ({ label: c.companyFederalName, value: c }));
    this.filteredCompanies.unshift({ label: marina.companyName, value: marina });
    this.filteredCompanies.unshift({ label: 'Todas', value: null });
    this.loggedMarina = StorageUtil.getMarina();
    if (this.productNF) {
      this.nfpEmmiter = this.productNF;
    }
  }

  getConfig(){
    this.operationalConfigService.hasNFProduct().then((res) => {
      this.productNF = res;
    });
  }

  filterGlobal(): void {
    this.invoicesTable = this.invoices.filter(
      (i) =>
        (i.nfeNumber && i.nfeNumber.toString().includes(this.globalFilter)) ||
        (i.invoiceNumber && i.invoiceNumber.toString().includes(this.globalFilter)) ||
        (FormatUtil.getNotAccents(i.customer.name).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          i.customer.name.toUpperCase().includes(this.globalFilter.toUpperCase())
        ) ||
        (i.customer.group &&
          (FormatUtil.getNotAccents(i.customer.group.name).toUpperCase()
            .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
            i.customer.group.name.toUpperCase().includes(this.globalFilter.toUpperCase()))
        )
    ).map((i) => {
      i.showBillets = false;
      return i;
    });
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        if (this.slingConfig != null) {
          this.slingConfigCityTax = this.slingConfig.cityTax;
          this.numberOfRows = this.slingConfig.numberOfRecordsPerPage;
          this.enableInvoice = this.slingConfig.marina.enableInvoice;
          this.paginationFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
        } else {
          this.numberOfRows = 10;
          this.enableInvoice = false;
        }
        this.searchInvoices();
      },
      (error) => null);
  }

  getCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(data.map((c) => ({ label: c.name, value: c.id })));
    });
  }

  displayFn(state): string {
    if (state) {
      return state.name;
    }
  }

  getGroups(): void {
    this.groupsService.findAllActive().subscribe((data) => {
      this.groups = data.map((g) => ({ label: g.name, value: g }));
      this.groups.unshift({ label: 'Sem grupo', value: undefined });
    });
  }

  createFilter(): void {
    this.filter.startEmission = this.filter.startEmission ? this.filter.startEmission : null;
    this.filter.endEmission = this.filter.endEmission ? this.filter.endEmission : null;
    this.filter.startDueDate = this.filter.startDueDate ? this.filter.startDueDate : null;
    this.filter.endDueDate = this.filter.endDueDate ? this.filter.endDueDate : null;
    this.filter.startCompetenceDate = this.filter.startCompetenceDate ? this.filter.startCompetenceDate : null;
    this.filter.endCompetenceDate = this.filter.endCompetenceDate ? this.filter.endCompetenceDate : null;
  }

  getTextToolTipRefDateTuitionOrigin(invoice): string {
    let toolTipText = '';

    if (!invoice.orders.find((o) => o.origin !== 'TUITION')) {
      invoice.orders.forEach(order => {
        toolTipText += moment(order.dateReferenceTuition).format('MM/YY') + ' \n';
      });

      toolTipText = toolTipText.substring(0, toolTipText.length - 2);
    }

    switch(invoice.origin) {
      case 'TUITION':
        return toolTipText;
      case 'DETACHED':
        return 'Pedido avulso';
      case 'SLING':
        return 'Lingada';
      case 'FRANCHISE':
        return 'Franquia';
      case 'MIXED':
        return 'Diversos';
      default:
        return '';
    }

  }

  validateSearch(): boolean {
    const statusSelecionados = this.filter.status || [];
    const exigeDatas = statusSelecionados.includes("PAID");

    if (this.selectedCustomerId!=null || this.filter.invoiceNumberStart!=null || this.filter.invoiceNumberEnd !=null ||
      this.filter.nfeNumber!=null || (this.filter.startCompetenceDate!=null && this.filter.endCompetenceDate!=null) ||
      (this.filter.startPayment!=null && this.filter.endPayment!=null )
    ){return true}

    if (exigeDatas) {
      const preenchidoVencimento = this.filter.startDueDate || this.filter.endDueDate;
      const preenchidoEmissao = this.filter.startEmission || this.filter.endEmission;

      if (!preenchidoVencimento && !preenchidoEmissao) {
          Swal.fire("Atenção", "Para buscar pagamentos, preencha pelo menos um intervalo de datas!", "warning");
          return false;
        }

        if (!this.validarIntervalo(this.filter.startDueDate, this.filter.endDueDate) ||
            !this.validarIntervalo(this.filter.startEmission, this.filter.endEmission)) {
          Swal.fire("Atenção", "Os intervalos de datas não podem ser superiores a 365 dias.", "warning");
          return false;
        }
    }

    return true;
  }

  validarIntervalo(dataInicio?: Date, dataFim?: Date): boolean {
    if (!dataInicio || !dataFim) return true;

    const diff = Math.abs(dataFim.getTime() - dataInicio.getTime());
    const dias = diff / (1000 * 60 * 60 * 24);

    return dias <= 365;
  }

  async searchInvoices(): Promise<void> {

    if (this.validateSearch()===false)
    {
      return;
    }
    this.selectedInvoices = [];
    this.filter.customerId = this.selectedCustomerId;
    if (this.selectedCompany && this.selectedCompany.marinaCompanies) {
      this.filter.onlyMarina = true;
      this.filter.marinaCompanyId = null;
    } else if (this.selectedCompany) {
      this.filter.onlyMarina = false;
      this.filter.marinaCompanyId = this.selectedCompany.id;
    } else {
      this.filter.onlyMarina = null;
      this.filter.marinaCompanyId = null;
    }
    this.spinner.show();
    await this.findInvoices();
    this.invoicesBilletProblems = [];

  }

  private async findInvoices(): Promise<void>{
    return new Promise(resolve => {
      this.invoiceService.getAll(this.filter, this.paginationFilter).subscribe((data: any) => {
        this.invoices = [...data.invoices.content];
        this.totalRecords = data.invoices.totalElements;
        this.invoicesTable = [...this.invoices];
        this.getTotalValues(data);
        this.spinner.hide();
        this.liberaChangePage=true;
        resolve();
      },
        (err) => { },
        () => {
          this.spinner.hide();
          resolve();
        });
    });
  }

  openInvoiceDetailDialog(invoice: any): void {
    this.dialog.open(InvoicePaymentComponent, {
      width: '930px',
      height: '70%',
      dismissableMask: false,
      data: { invoice },
      header: 'Baixar fatura n.º ' + invoice.invoiceNumber
    }).onClose.subscribe(() => {
      //this.searchInvoices();
    });

  }

  // (click)="invoice.pendingValue !== 0 && invoice.orders.length !== 0 && openInvoiceDetailDialog(invoice)"
  // *ngIf="invoice.orders.length !== 0 && invoice.status !== 'CANCELED'">

  changeDiscount(): void {
    const invoice :any = this.selectedInvoices[0];
    this.dialog.open(InvoiceDiscountComponent, {
      width: '730px',
      height: '50%',
      dismissableMask: false,
      data: { invoice },
      header: 'Baixar fatura n.º ' + invoice.invoiceNumber
    }).onClose.subscribe(() => {
      this.searchInvoices();
    });

  }


  openInvoiceEmailDetailsDialog(invoice: Invoice): void {
    const dialog = this.dialog.open(InvoiceEmailsComponent, {
      dismissableMask: false,
      data: {
        invoice,
        emailLogs: invoice.emailsLog,
        loggedMarina: this.loggedMarina,
        hasInvoiceReceipt: this.hasInvoiceReceipt()
      },
      header: 'Log de envio de emails da fatura ' + invoice.invoiceNumber
    });

    dialog.onClose.subscribe(() => {
      invoice.emailsLog.filter((l) => !l.logRead).forEach(element => {
        element.logRead = true;
      });
    });

  }

  getTotalValues(data): void {
    this.totalBilling = data.total;
    this.totalPaid = data.totalPaid;
    this.totalDiscount = data.totalDiscount;
    this.totalInterest = data.totalInterest;
    this.totalPending = data.totalPending;
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {

        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Extrato da fatura - PDF'
    });
  }


  async checkAndCancel(invoice: Invoice): Promise<void> {
    let credentialsAdm = await this.isCurrentUserAdministrator();
    if (!credentialsAdm) {
      this.messageUtil.generateMessage(MessageUtil.ERROR, 'Erro', "Você não possui permissão para executar essa ação");
    } else {
      let message = 'Deseja realizar o cancelamento da fatura ' + invoice.invoiceNumber + '?';
      if (invoice.legacyInvoiceNumber) {
        message = 'Deseja realizar o cancelamento das faturas ' + invoice.invoiceNumber + '/' + invoice.legacyInvoiceNumber + ' ?';
      }
      Swal.fire(
        {
          title: 'Cancelamento de faturas',
          text: message,
          icon: 'warning',
          backdrop: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.cancel(invoice);
          }
        }
      );
    }
  }

  cancel(invoice: Invoice): void {
    if (invoice.activeBillet) {
      Swal.fire({
        title: this.messageUtil.translateKey('SUMMARY.WARNING'),
        icon: 'warning',
        text: 'Primeiro cancele os boletos da fatura no sistema e no banco para cancelar a fatura.',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Gerenciar boletos',
        cancelButtonText: 'Fechar'
      }).then((r) => {
        if (r.value) {
          this.openBilletsModal(invoice);
        }
      });
      return;
    }
    if (invoice.origin !== 'TUITION') {
      Swal.fire(
        {
          title: 'Cancelamento de faturas',
          text: 'Deseja criar cópia dos pedidos para novo faturamento?',
          icon: 'warning',
          backdrop: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true
        }).
        then((result) => {
          if (invoice.origin === 'WORKSHOP'){
            this.cancelInvoice(invoice, true);
            return;
          }
          if (result.value) {
            if (this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(this.slingConfig)) {
              this.cancelAndCopyInvoiceWithFinancialByProduct(invoice);
            } else {
              this.cancelInvoice(invoice, true);
            }
          } else if (result.dismiss) {
            this.cancelInvoice(invoice, false);
          }
        });
    } else {
      this.cancelInvoice(invoice, false);
    }
  }

  cancelAndCopyInvoiceWithFinancialByProduct(invoice: Invoice) {
    Swal.fire({
      title: 'Cancelamento de faturas',
      text: 'Caso a fatura tenha pedidos com produtos e serviços de financeiros diferentes, a copia destes pedidos será dividida pelo financeiro dos produtos e serviços. Deseja continuar o cancelamento?',
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.cancelInvoice(invoice, true);
      }
    })
  }

  getBilingPortifolioList(): void {
    const company = this.selectedCompany;
    this.financesService.getAllBilingPortifolio((!company || company.marinaCompanies || !company.finances) ? null : company).subscribe(
      (data) => { this.billingPortifolioList = data;
        this.getNixBankId();
       },
      (error) => { console.log(error); }
    );
  }

  getNixBankId() {
    if(this.isNixBank) {
      const nixBank = this.billingPortifolioList.find((billet) => billet.codigoCarteira === 332);
      if(nixBank) {
        this.nixBankId = nixBank?.idCarteiraCobranca;
      } else {
        //TODO: Ativar quando o nix entrar em produção
      //  this.messageUtil.generateMessage(MessageUtil.ERROR, 'Erro', "Carteira de cobrança do banco Nix não configurada");
      }
      this.loadSlingConfig()
    }
  }

  checkAndGenerateBillets(group?:boolean): void {
    if (group==null || group==undefined){
      group = false;
    }
    this.selectedInvoices = this.selectedInvoices.filter((i) => i.status !== 'CANCELED');
    const portifolioMap = {};
    // tslint:disable-next-line:prefer-for-of
    let filteredWallets = [];
    if (this.selectedCompany && this.selectedCompany.companyFederalId && this.selectedCompany.companyFederalId !== '') {

      if(this.selectedCompany.mainBillingPortifolio){
        filteredWallets = this.billingPortifolioList.filter(
          (x) => x.beneficiarioDocumento === this.loggedMarina.companyFederalId.replace(/[^0-9]+/g, '')
        );
      }else{
        filteredWallets = this.billingPortifolioList.filter(
          (x) => x.beneficiarioDocumento === this.selectedCompany.companyFederalId.replace(/[^0-9]+/g, '')
        );
      }

    } else {
      filteredWallets = this.billingPortifolioList;
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < filteredWallets.length; ++i) {
      portifolioMap[filteredWallets[i].idCarteiraCobranca + ''] = filteredWallets[i].nome;
    }
    this.invoicesBilletProblems = [];
    this.invoicesBilletProblems = this.selectedInvoices.filter((i) => (i.pendingValue === 0 && i.value !== 0) || (i.invoiceFatherId!==null && i.invoiceFatherId!==undefined && i.id !==i.invoiceFatherId))
      .concat(this.selectedInvoices.filter((i) => i.value < 10))
      .concat(this.selectedInvoices.filter((i) => new Date(i.dueDate) <= new Date()))
      .concat(this.selectedInvoices.filter((i) => this.getActiveBillets(i) > 0));

    if (this.selectedInvoices.length === 0) {
      Swal.fire('Geração de boleto', 'Não há faturas selecionadas!', 'warning');
    } else if (this.invoicesBilletProblems.length > 0) {
      Swal.fire('Geração de boleto',
        'Faturas inválidas na seleção. Verifique as faturas com a caixa de seleção em <span style="color: red">VERMELHO</span> para corrigir!', 'warning');
    } else {
      this.generatesBillets(portifolioMap,group);
    }
  }

  generatesBillets(portifolioMap, group:boolean): void {
    Swal.fire({
      title: 'Geracao de Boletos',
      text: 'Escolha uma carteira de cobrança:',
      icon: 'warning',
      input: 'select',
      backdrop: false,
      inputOptions: portifolioMap,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).
      then((result: any) => {

        if (result.value) {
          Swal.fire({
            title: 'Serão gerados boletos para <br><br><strong>' +
              this.getSelecteds() + ' fatura(s)</strong><br> Deseja continuar?',
            icon: 'warning',
            backdrop: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          }).then((result1) => {
              //TODO: Verificar se o usuário Tem permissao para o namco nix
            if (result1.value) {

              this.loading = true;
              this.disableFlag = true;
              if (this.marinaId===2 || this.marinaId===230){
                Swal.fire({
                  title: "Fila de Processamento",
                  imageUrl: "assets/images/processoEasy.png",
                  imageWidth: 200,
                  imageHeight: 100,
                  text: "Sua solicitação foi recebida e está em processamento. Você pode acompanhar o processo no ícone a cima.",
                  showCloseButton: true,})


                  this.invoiceService.generateBulkBankingBillets(
                    result.value,
                    this.selectedInvoices.map((inv) => inv.id
                    ).join(),group
                  ).subscribe(
                    (data) => {
                      this.toasty.success("Processamento de boletos terminado confira o resultado");

                    },
                    (error) => {

                      const exception = error.error.data.exception;
                      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
                      this.loading = false;
                      this.disableFlag = false;
                    }
                  );

                  this.searchInvoices();
                  this.loading = false;
                  this.disableFlag = false;
                }
              else
              {this.spinner.show();

                this.invoiceService.generateBulkBankingBillets(
                  result.value,
                  this.selectedInvoices.map((inv) => inv.id
                  ).join(),group
                ).subscribe(
                  (data) => {

                    Swal.fire({
                      title: this.messageUtil.translateKey('SUMMARY.SUCCESS'),
                      icon: 'success',
                      text: this.messageUtil.translateKey('MSG.GENERATED-BILLETS'),
                      showCloseButton: true,
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonText: 'Baixar remessa',
                      cancelButtonText: 'Fechar'
                    }).then((r) => {
                      if (r.value) {
                        FileSaver.saveAs(data.outFileUrl);
                        this.updateDownload(data);
                      } else {
                        this.ngOnInit();
                      }
                    });

                    this.searchInvoices();
                    this.loading = false;
                    this.disableFlag = false;
                  },
                  (error) => {
                    this.spinner.hide();
                    const exception = error.error.data.exception;
                    this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
                    this.loading = false;
                    this.disableFlag = false;
                  }
                );
              }


            }
          });
        }
      });
  }

  updateDownload(file: any): void {
    this.billetService.updateDownload(file.id).subscribe(() => {}, (error) => {
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    });
  }

  checkAndGenerateDocuments(): void {

    if (this.slingConfig.competency==0) {

      let conf = this.slingConfig;

        const data = {
          config:  null,
        };

        this.dialog
          .open(ConfirmCompetencyDialogComponent, {
            width: '105vh',
            height: '90vh',
            dismissableMask: false,
            data: {conf},
            header: this.messageUtil.translateKey('Nova Funcionalidade'),
          })
          .onClose.subscribe((d: any) => {
            if (d) {
              this.slingConfig = d;
               if (this.slingConfig.competency==0) {
                Swal.fire('Geração de Notas', 'Data de Competência não selecionada', 'warning');
               }
               else{
                this.checkAndGenerateDocumentsValidation()
               }

            }
            else{
              Swal.fire('Geração de Notas', 'Data de Competência não selecionada', 'warning');
            }
          });

      }
    else{
      this.checkAndGenerateDocumentsValidation()
    }
  }

  checkAndGenerateDocumentsValidation(): void {
    this.selectedInvoices = this.selectedInvoices.filter((i) => i.status !== 'CANCELED');
    const hasDocumentsZero = this.selectedInvoices.filter((i) => i.value === 0).length === 0 ? false : true;
    const countRequestDocuments = this.selectedInvoices.filter((i) => i.origin === 'SLING' || i.origin === 'DETACHED').length;
    if (this.selectedInvoices.length === 0) {
      Swal.fire('Geração de NF', 'Não há faturas selecionadas!', 'warning');
    } else if (this.selectedInvoices.length === 1 && hasDocumentsZero) {
      Swal.fire('Geração de NF', 'A fatura selecionada não possui valor.', 'warning');
    } else if (countRequestDocuments !== 0) {
        const invoiceList = this.selectedInvoices;
        this.router.navigate(['/app/billing/nf-emitter'], { state: { invoiceList: this.selectedInvoices,slingConfig: this.slingConfig, filter:this.filter } });
    } else {
      this.router.navigate(['/app/billing/nf-emitter'], { state: { invoiceList: this.selectedInvoices,slingConfig: this.slingConfig, filter:this.filter } });
    }
  }

  generateDocuments(): void {
    const aliquota = (this.cityTax && this.cityTax > 0) ?'<strong style="color:red;">Aliquota '+ this.cityTax+'% </strong><br>' :'';
    Swal.fire({
      title: aliquota+'Serão geradas notas fiscais para <br><br><strong>' +
        this.getSelecteds() + ' fatura(s)</strong><br> Deseja continuar?',
      icon: 'warning',
      showCancelButton: true,
      backdrop: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      allowOutsideClick:false
    }).
      then((result) => {
        if (result.value) {
          this.loading = true;
          this.disableFlag = true;
          this.spinner.show();

          this.selectedInvoices = this.selectedInvoices.filter(
            (invoice) => (
              invoice.value !== 0
            )
          );
          this.invoiceService.generateDocuments(this.selectedInvoices.map((inv) => inv.id).join()).subscribe(
            (data) => {
              this.spinner.hide();

              if (data.rejected.length < 1) {
                this.messageUtil.generateMessage(
                  'success',
                  'SUMMARY.SUCCESS',
                  (this.selectedInvoices.length === 1 ? 'MSG.GENERATED-INVOICE-DOCUMENT' : 'MSG.GENERATED-INVOICE-DOCUMENTS')
                );
              } else {
                this.messageUtil.generateMessageParameter(
                  'warning',
                  'SUMMARY.WARNING',
                  (this.selectedInvoices.length === 1 ? 'MSG.REJECTED-GENERATE-INVOICE-DOCUMENT' : 'MSG.REJECTED-GENERATE-INVOICE-DOCUMENTS'),
                  { value: data.rejected.join(', ') }
                );

              }

              this.searchInvoices();
              this.loading = false;
              this.disableFlag = false;
            },
            (error) => {
              this.spinner.hide();
              const exception = error.error.data.exception;
              this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
              this.loading = false;
              this.disableFlag = false;
            }
          );
        }
      });
  }

  async sendEmailModal(single: boolean = false, serverOwn: boolean = false, isCopy: boolean = false): Promise<any> {
    const selecteds = this.getInvoicesSelected();
    this.invoicesEmailProblems = [];
    selecteds.forEach((i) => {
      if (i.invoiceBankingBillets.length>0){
        i.invoiceBankingBillets.forEach((b) => {
          if (!b.processed && b.active) {
            this.invoicesEmailProblems.push(i);
          }
        })
      }
    })

    if (!this.selectedInvoices || selecteds.length <= 0) {
      return Swal.fire('Envio de emails', 'Não há faturas selecionadas!', 'warning').then(() => false);
    } else if (this.invoicesEmailProblems.length > 0) {
      return Swal.fire('Envio de emails', 'Boletos não registrados na seleção. Verifique os boletos com a caixa de seleção em <span style="color: red">VERMELHO</span> para corrigir!', 'warning').then(() => false);
    } else {
      const dialogParam: InvoiceEmaiDialogParams = {
        serverOwn,
        single,
        isCopy,
        invoices: (isCopy ? [selecteds[0]] : selecteds) as InvoiceToEmailDTO[],
        hasInvoiceReceipt: this.hasInvoiceReceipt()
      };
      const dialogOpened: DynamicDialogRef = this.dialog.open(InvoiceEmailDialogComponent, {
        dismissableMask: false,
        data: {
          params: dialogParam
        },
        header: 'Envio de emails'
      });
      return new Promise((resolve, _reject) => {
        dialogOpened.onClose.subscribe(observer => {
          resolve(observer)
        },
        (_error) => resolve(false))
      });
    }
  }

  checkAndSendEmails(serverOwn: boolean = false): void {
    this.sendEmailModal(false, serverOwn, false)
    .then((result) => {
      if (result) {
        this.searchInvoices();
        this.loading = false;
        this.disableFlag = false;
        this.lastStatus = 'PROCESSING';
        this.startInterval();
      }
    })
    .catch((_error) => {});
  }

  addEmails(serverOwn: boolean = false): void {
    this.sendEmailModal(true, serverOwn, true)
    .then((result) => {
      console.log('Envio de e-mail único da fatura', result);
    });
  }

  clear(): void {
    this.filter = {
      origin: ['DETACHED', 'SLING', 'TUITION', 'MIXED', 'LEGACY_IMPORT', 'WORKSHOP', 'FRANCHISE'],
      status: ["PAID", "PENDING"]
    };
    if (history.state.filter==null) {
    this.filter.startDueDate = new Date();
    this.filter.startDueDate.setDate(this.filter.startDueDate.getDate() - 90);
   // this.filter.endDueDate = new Date();
    }
    else{
      this.filter = history.state.filter;
      history.state.filter = null;
    }
    this.getGroups();
    this.invoices = [];
    this.invoicesTable = [];
    this.selectedCustomerId = null;
    this.selectedCompany = null;
  }

  getLastStatusFromNF(invoice): string {
    if (invoice.invoiceLogs !== undefined && invoice.invoiceLogs.length !== 0) {
      const last = JSON.parse(invoice.invoiceLogs[invoice.invoiceLogs.length - 1].response);

      if (last.nfeStatus === 'Autorizada' || last.nfeStatus === 'SolicitandoAutorizacao' || last.nfeStatus === 'AguardandoAutorizacao'
        || last.nfeStatus === 'EmProcessoDeAutorizacao' || last.nfeStatus === 'Cancelada') {
        return last.nfeStatus;
      } else if (last.nfeStatus === 'Negada') {
        if (last.nfeNumero !== invoice.nfeNumber) {
           return 'Importada';
        }
        return last.nfeStatus + '\n' + last.nfeMotivoStatus;
      } else {
        return 'AguardandoAutorizacao';
      }
    }else {
    if (invoice.nfeNumber !=null && invoice.nfeNumber >= 0) {
      return 'Importada';
      }
    }
  }

  openModalHelp(): void {
    this.dialog.open(InvoiceHelpComponent, {
      width: '45%',
      height: '95vh',
      header: 'Legendas'
    });
  }

  openInvoicePaidsDialog(invoice: any): void {
    this.dialog.open(InvoicePaidsComponent, {
      width: '70%',
      header: 'Pagamentos da fatura ' + invoice.invoiceNumber,
      data: { invoice }
    }).onClose.subscribe(() => {
      this.searchInvoices();
    });
  }

  getRole(): Observable<Role> {
    const currentUserID = StorageUtil.getUserId();

    const user = this.userService.getRoleById(currentUserID);

    return user.pipe(map((u) => u.roleId));

  }

  updateInvoiceDates() {
    let invoices: any[];
    let enableChangeDueDate = true;
    invoices = this.selectedInvoices;
    if (this.selectedInvoices.length === 1) {
      enableChangeDueDate = enableChangeDueDate && this.canChangeDueDateByStatus(invoices[0]) && !invoices[0].activeBillet;
    } else if (this.selectedInvoices.length > 1) {
      invoices.forEach(invoice => {
         enableChangeDueDate = enableChangeDueDate && this.canChangeDueDateByStatus(invoice) && !invoice.activeBillet;
      });
    } else {
      return;
    }
    this.dialog.open(ChangeDateDialogComponent, {
      header: 'Atualizar data',
      height: '650px',
      width: '350px',
      data: {
        invoices,
        today:  this.today,
        canChangeDueDate: enableChangeDueDate
      }
    }).onClose.subscribe(() => {
      this.searchInvoices();
    });
  }

  canChangeDueDateByStatus(invoice: any): boolean {
    return invoice.status !== 'PAID' && invoice.status !== 'CANCELED';
  }

  showChangeDueDate(invoice): void {
    const subscription = this.getRole().subscribe({
      next: (role) => {
        if(role.changeInvoiceDates){
          if (invoice.activeBillet) {
            Swal.fire({
              title: this.messageUtil.translateKey('SUMMARY.WARNING'),
              icon: 'warning',
              text: 'A fatura possui boletos. Cancele os boletos para alterar a data de vencimento.',
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Gerenciar boletos',
              cancelButtonText: 'Fechar'
            }).then((r) => {
              if (r.value) {
                this.openBilletsModal(invoice);
              }
            });
          } else {
            this.changeDueDate = true;
            this.invoice = invoice;
          }
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  checkIfHaveNixBillet2(invoice: any): boolean {
    const invoiceBankBillets = invoice?.invoiceBankingBillets;
    if (invoiceBankBillets) {
        for (let i = 0; i < invoiceBankBillets.length; i++) {
            const element = invoiceBankBillets[i];
            if (element?.bankNumber === 332) {
                return true;
            }
        }
    }
    return false;
}

  showChangeCompetenceDate(invoice):void {
    const subscription = this.getRole().subscribe({
      next: (role) => {
        if(role.changeInvoiceDates){
          this.changeCompetenceDate = true;
          this.invoice = invoice;
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        subscription.unsubscribe();
      }
    });

  }

  changeDueDateWithActiveBillet(invoice): void {

  }

  sendToWhatsapp(invoice: Invoice){

    if (invoice?.customer?.phone) {
      const re = /(\(|\)|-)/gi;
      const phoneNumber = invoice?.customer?.phone.replace(re, '').replace(' ', '');
      const marina = StorageUtil.getMarinaName();
      const msg = `Olá ${invoice?.customer?.name}, segue a sua fatura com vencimento ${dayjs(invoice?.dueDate).format('DD/MM/YYYY')} da ${this.slingConfig?.marina?.companyName}.\nSeguem os links: \nFatura: ${window.location.host}/#/billing-area?marinaId=${this.slingConfig.marina.id}&customerId=${invoice.customer?.id}&federalIdType=${invoice.customer?.federalIdType ?? FederalIdType.Physical}\n${this.checkInvoiceHasEnabletoGenerateIssueReceipt(invoice) ? `Recibo: ${environment.apiHost}/report/r/${btoa(JSON.stringify({invoiceId: invoice?.id, customerId: invoice?.customer?.id, marinaId: this.slingConfig.marina.id}))}`+'\n' : ''}
      ${this.getInvoiceBankingBilletsUrl(invoice)}
      `;

      const uriMessage = encodeURIComponent(msg);
      const path = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${uriMessage}&app_absent=0`;

      window.open(path);
    } else {
      return '';
    }
  }

  getInvoiceBankingBilletsUrl(invoice: Invoice): string{
    if (invoice.invoiceBankingBillets) {
      const invoiceBankingBillets: InvoiceBankingBillet[] = invoice.invoiceBankingBillets.filter((b) => b.active && !b.paid);

      if(invoiceBankingBillets.length > 0){
        let str = `Boletos: \n`;

        invoiceBankingBillets.map((billet: InvoiceBankingBillet)  => {
          str += `${dayjs(invoice?.dueDate).format('DD/MM/YYYY')} - ${billet.documentUrl}
          `
        });

        return str;
      }
      else{
        return ''
      }
    }
    else {
      return '';
    }
  }

  selectDueDate($value): void {
    this.spinner.show();
    this.changeDueDate = false;
    this.invoiceService.changeDueDate(this.invoice.id, $value.value).subscribe(() => {
      this.spinner.hide();
      Swal.fire('Alteração da data de vencimentos', 'Data de vencimento modificada com sucesso!', 'success');
      this.searchInvoices();
    },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        this.loading = false;
        this.disableFlag = false;
      }, () => {
        ///this.searchInvoices();
      });
  }

  getNixBankClass(invoice: Invoice) {
    if (invoice.activeBillet) {
      const invoiceBillet = invoice.activeBillet;
      if (invoice.invoiceBankingBillets.length === 1) {
        if (invoiceBillet.paid) return "big-box nix-bank--payed";
        if (invoiceBillet.registered) return "big-box nix-bank--registered";
        if (invoiceBillet.refused) return "big-box nix-bank--refused";
        if (!invoiceBillet.active) return "big-box nix-bank--canceled";
        if (!invoiceBillet.registered) return "big-box nix-bank--registered-pending";
      } else {
        return "big-box nix-bank--more-then-one-billet";
      }
    }
  }

  getNixBankIcon(invoice: Invoice) {
    if (invoice.activeBillet) {
      const invoiceBillet = invoice.activeBillet;
      if (invoice.invoiceBankingBillets.length === 1) {
        if (invoiceBillet.paid) {
          return "paid"};
        if (invoiceBillet.registered) {
          return "registered"};
        if (invoiceBillet.refused) {
          return "refused"};
        if (!invoiceBillet.active) {
          return "canceled"};
        if (!invoiceBillet.registered) {
          return "pending"};
      } else {
        return "registered";
      }
    }
  }

  selectCompetenceDate($value): void {
    this.spinner.show();
    this.changeCompetenceDate = false;
    this.invoiceService.changeCompetenceDate(this.invoice.id, $value.value).subscribe(() => {
      this.spinner.hide();
      Swal.fire('Alteração da data de Competencia', 'Data de competência modificada com sucesso!', 'success');
      this.searchInvoices();
    },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        this.loading = false;
        this.disableFlag = false;
      }, () => {
        //this.searchInvoices();
      });
  }

  chengedInvoiceDate($event): void {
    this.changeDueDate = false;
    this.searchInvoices();
    if ($event) {
      this.loading = false;
      this.disableFlag = false;
    }
  }

  validImportNf(): boolean {
    if (this.selectedInvoices.length === 0) {
      return false;
    }
    if (this.selectedInvoices.length > 1) {
      return false;
    }
    if (this.getLastStatusFromNF(this.selectedInvoices[0]) && this.getLastStatusFromNF(this.selectedInvoices[0]).includes('Negada')){
      return true;
    }
    if (this.selectedInvoices[0].documentId === null || this.selectedInvoices[0].documentId === undefined || this.selectedInvoices[0].documentId === '') {
      return true;
    }

    return false;
  }

  uploadNf(): void {
    const invoice = this.selectedInvoices[0];
    this.dialog.open(InvoiceUploadNfComponent, {
      width: '40%',
      height: '55vh',
      header: 'Importação de NF',
      data: { invoice }
    }).onClose.subscribe(() => {
      this.searchInvoices();
    });
  }

  onDanfeBasicUpload(files): void {
    this.doBasicUpload(files,"Danfe")
  }

  onSysAutoeBasicUpload(files): void {
    this.doBasicUpload(files,"SysAuto")
  }
  
  
  
  doBasicUpload(files,fileType: string): void {
    this.files = [];
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < files.length; index++) {
      const x = files[index];
      this.files.push({ name: x.name, date: new Date(), value: x });

    }


    Swal.fire({
      title: "Upload " + fileType,
      text: 'Desejas realizar a importação de '+ this.files.length + ' ' + fileType + ' ' + (files.length>1 ? 's?':'?'),
      showCancelButton: true,
      width: 600,
      icon: 'question',
      showDenyButton: true,
      showConfirmButton: true,
      focusDeny: true,
      reverseButtons: true,
      denyButtonText: `Definir Vencimento`,
      confirmButtonText: 'Vencimento ' + fileType,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const uploadDanfe: UploadDanfeRequestDTO = {
          files
        };
        this.sendDanfes(uploadDanfe,fileType);
      } else if (result.isDenied) {
          this.dialog.open(DuedateSelectionComponent, {
            width: '450px',
            height: '50vh',
            header: 'Importação ' + fileType
          }).onClose.subscribe((value) => {
            if (value) {
              const uploadDanfe: UploadDanfeRequestDTO = {
                dueDate: value.dueDate ? moment(value.dueDate).format('YYYY-MM-DD') : null,
                referenceMonth: value.referenceMonth ? value.referenceMonth : null,
                referenceYear: value.referenceYear ? value.referenceYear : null,
                files
              };
              this.sendDanfes(uploadDanfe,fileType);
            } else {
              this.clearUpload(fileType);              
            }
          });
      } else {
        this.clearUpload(fileType);      
      }
    });

  }

  private clearUpload(fileType: string) {
    if (fileType == 'Danfe') {
      this.upload.clear();
    } else if (fileType == 'SysAuto') {
      this.uploadSysAuto.clear();
    }
  }

  sendDanfes(uploadDanfe: UploadDanfeRequestDTO,fileType:string){
    this.spinner.show();
    this.invoiceService.uploadDanfe(uploadDanfe,fileType).subscribe(
      (response) => {
        this.uploadStatus = response;
      },
      (error) => { },
      () => {
        this.spinner.hide();
        this.openInvoiceUploadsDialog(this.uploadStatus,fileType);
      }
    );
  }

  getMarinaCompanyIndex(marinaCompany: MarinaCompany): number {
    const company = this.marinaCompanies.filter((x) => x.id === marinaCompany.id)[0];
    const index = this.marinaCompanies.indexOf(company);
    return index + 2;
  }

  changeCompany($evt): void {
    this.selectedCompany = $evt.value;
    this.getBilingPortifolioList();
    this.searchInvoices();

    if (this.selectedCompany.cityTax && this.selectedCompany.cityTax >= 0 ){
      this.cityTax  = this.selectedCompany.cityTax;
    }else{
      this.cityTax = this.slingConfigCityTax;
    }
  }

  openInvoiceUploadsDialog(invoices: any[],fileType:string): void {
    let uploads = [];
    if (fileType == 'Danfe') {
    
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];
        const result = invoices[index];
        uploads.push(
          {
            name: element.name,
            date: element.date,
            success: result.sucess,
            errorMessage: (
              result.sucess ? 'Importado com sucesso' : result.errorMessage
            )
          }
        );
      } 
    } else {
      uploads = invoices.map((i) => {
        return {
          "name": this.files[0].name,
          "date":  this.files[0].date,
          "success": i.sucess,
          "errorMessage": i.sucess ? 'Fat AutoSys : ' + i.legacySystemInvoiceId + 'importada!' : i.errorMessage
        }
      })
    }
    this.dialog.open(DanfeUploadComponent, {
      width: '80%',
      header: 'Importação de DANFE',
      data: { uploads }
    }).onClose.subscribe(() => {
      this.clearUpload(fileType);   
      //this.searchInvoices();
    });

  }

  async mountOptions(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.yesNoOptions = await this.translateList(this.yesNoOptions);
        this.statusList = await this.translateList(this.statusList);
        resolve();
      }
    );
  }

  cancelInvoice(invoice, copy: boolean): void {
    this.invoiceService.cancel(invoice.id, copy).subscribe(
      () => {
        Swal.fire('Cancelamento de Faturas', 'Faturas canceladas com sucesso', 'success');
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => {
        this.searchInvoices();
      }
    );
  }

  hasNewLog(invoice: Invoice): boolean {
    return invoice.emailsLog &&
      invoice.emailsLog.length > 0 &&
      invoice.emailsLog.find((log) => !log.logRead) !== undefined;
  }

  hasLastLogWithError(invoice: Invoice): boolean {
      var mostRecentDate = new Date(Math.max.apply(null, invoice.emailsLog.map( e => {
        return new Date(e.registerDate);
        })));
      return invoice.emailsLog.filter( e => {
          var d = new Date( e.registerDate );
          return d.getTime() == mostRecentDate.getTime();
        })[0].emailError;
  }

  parcelInvoice(): void {
    if (!this.selectedInvoices || this.selectedInvoices.length === 0) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Selecione pelo menos uma fatura');
      return;
    } else if (this.selectedInvoices && this.selectedInvoices.length > 1) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Só é possível parcelar uma fatura por vez');
      return;
    } else if (this.selectedInvoices && !this.showParcel(this.selectedInvoices[0])) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'A fatura já possui valor parcelado ou não possui valor pendente.');
      return;
    }
    const invoice = this.selectedInvoices[0];
    const hasPeriodicalService: boolean = invoice.orders.some((order) => order.origin.includes("TUITION"));
    if ((hasPeriodicalService) && (!this.loggedMarina.invoiceSplit) ) {
      this.messageUtil.generateMessage('warning', "SUMMARY.WARNING", "Não é possível parcelar Serviços Periódicos");
      return;
    }

    this.getBilingPortifolioList();

    const data = {
      invoice: { ...invoice },
      invoiceQuotaDTO: new InvoiceQuotaDTO(invoice.id),
      billingPortfolioList: [],
    };
    let filteredWallets = [];
    if (this.selectedCompany && this.selectedCompany.companyFederalId && this.selectedCompany.companyFederalId !== '') {
      if (this.selectedCompany.mainBillingPortifolio) {
        filteredWallets = this.billingPortifolioList.filter(
          (x) => x.beneficiarioDocumento === this.loggedMarina.companyFederalId.replace(/[^0-9]+/g, '')
        );
      }else{
        filteredWallets = this.billingPortifolioList.filter(
          (x) => x.beneficiarioDocumento === this.selectedCompany.companyFederalId.replace(/[^0-9]+/g, '')
        );
      }
    }
    else {
      filteredWallets = this.billingPortifolioList;
    }
    data.billingPortfolioList = filteredWallets.map(wallet => (({label: wallet.nome, value: wallet.idCarteiraCobranca})));

    data.invoiceQuotaDTO.billingPortfolioId = data.billingPortfolioList[0].value;
    data.invoice.pendingValue = this.calculatePendingValueFromBillets(invoice);

    this.dialog.open(ParcelComponent, {
      height: '70vh',
      data,
      header: 'Parcelar fatura ' + invoice.invoiceNumber
    }).onClose.subscribe((d) => {
      if (d) {
        this.searchInvoices();
      }
    });
  }

  hasUnregistered(invoice: any): any {
    return invoice.invoiceBankingBillets.find((b) => !b.registered);
  }

  billingPortfolio = (idCarteiraCobranca) => {
    const carteira = this.billingPortifolioList.find((c) => c.idCarteiraCobranca === idCarteiraCobranca);
    return carteira ? carteira : { conta: { nome: '' }, nome: '' };
  }

  showParcel(invoice): boolean {
    return invoice.pendingValue > 0 &&
      (!invoice.invoiceBankingBillets || invoice.invoiceBankingBillets.filter((b) => b.active).length === 0 ||
        (invoice.pendingValue > invoice.invoiceBankingBillets.filter((b) => b.active).reduce((tot, b) => tot += b.value, 0)));
  }

  getActiveBillets(invoice): number {
    return invoice.invoiceBankingBillets ? invoice.invoiceBankingBillets.filter((b) => b.active && !b.paid).length : 0;
  }

  getAllActiveBillets(invoice): InvoiceBankingBillet[] {
    return invoice.invoiceBankingBillets.filter((b) => b.active);
  }

  getColorBillertActive( invoiceBankingBillet ):number{
    let bankBillet = new InvoiceBankingBillet();
    bankBillet=invoiceBankingBillet;
    if (bankBillet.paid ){return 1;} //classe paid
    if (bankBillet.registered){return 0;} //classe sem classe
    if (bankBillet.refused ){ return 2;} //classe red-icon
    if (!this.isToday(bankBillet)){return 3;}else //classe yellow-icon
    {return 4;} //classe recent
  }

  calculatePendingValueFromBillets(invoice): number {
    return invoice.pendingValue -
      invoice.invoiceBankingBillets.filter((b) => b.active && !b.paid).reduce((total, b) => total += b.value, 0);
  }


  async openBilletsModal(invoice): Promise<void> {
    const paginationFilter = new PaginationFilter();
    paginationFilter.size = 7;
    this.dialog.open(BilletDialogComponent, {
      data: {
        filters: false,
        filter: {
          invoiceNumberStart: invoice.invoiceNumber,
          invoiceNumberEnd: invoice.invoiceNumber
        },
        numberOfRows: 7,
        paginationFilter,
        invoice
      },
      header: 'Boletos da fatura ' + invoice.invoiceNumber
    }).onClose.subscribe(() => {
      this.searchInvoices();
    });
  }

  onChangePage(event: LazyLoadEvent): void {
    if(this.slingConfig && this.liberaChangePage) {
      const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.selectedInvoices = [];
      this.searchInvoices();
    }
  }

  onSort(event: SortEvent): void {
    this.paginationFilter.order = event.order === 1 ? 'ASC' : 'DESC';
    this.paginationFilter.sort = event.field;
    this.searchInvoices();
  }

  getSelecteds(): number {
    return this.getInvoicesSelected().length;
  }

  getInvoicesSelected(): Invoice[] {
    return this.selectedInvoices.filter((i) => i.orders.length !== 0 && i.status !== 'CANCELED');
  }

  async translateList(list: any[]): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        const options = [...list];
        const newList = [];
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < options.length; index++) {
          newList.push({
            label: await this.messageUtil.translateKeyAsync(options[index].label),
            value: options[index].value
          });
        }
        resolve(newList);
      }
    );

  }

  wrongInvoice(invoice): boolean {
    return (this.invoicesBilletProblems && this.invoicesBilletProblems.find((i) => i.id === invoice.id))
    || invoice.errorReceipt
    || (this.invoicesEmailProblems && this.invoicesEmailProblems.find((i) => i.id === invoice.id))
  }

  removeMessageWrongInvoice(invoice): void {
    if ( this.invoicesBilletProblems !== undefined && this.invoicesBilletProblems !== null ){
      this.invoicesBilletProblems = this.invoicesBilletProblems.filter((i) => i.id !== invoice.id);
    }
    invoice.errorReceipt = null;
    if ( this.invoicesEmailProblems !== undefined && this.invoicesEmailProblems !== null ){
      this.invoicesEmailProblems = this.invoicesEmailProblems.filter((i) => i.id === invoice.id);
    }
  }

  getTooltipError(invoice): string {
    if(invoice.errorReceipt){
      return invoice.errorReceipt;
    }
    if (invoice.invoiceFatherId!==null && invoice.id !== invoice.invoiceFatherId) {
      return 'Esta fatura possui vinculação de boleto.';
    }
    if (invoice.pendingValue === 0 && invoice.value !== 0) {
      return 'Fatura já está paga';
    } else if (this.getActiveBillets(invoice) > 0) {
      return 'Fatura já possui boleto(s).';
    } else if (invoice.value === 0) {
      return 'Fatura sem valor.';
    } else if (new Date(invoice.dueDate) <= new Date()) {
      return 'Data de vencimento deve ser maior que o dia de hoje.';
    }
    return '';
  }

  isToday(invoice): boolean {
    return moment(invoice.issueDate).hours(0).minutes(0).seconds(0).milliseconds(0).isSame(
      moment().hours(0).minutes(0).seconds(0).milliseconds(0)
    );
  }

  getNumBilletsFromInvoice(invoice: any): string {
    return invoice.invoiceBankingBillets.length <= 9 ? invoice.invoiceBankingBillets.length : '9+';
  }

  async exportTableExcel(){
    const invoices = await this.findDataExport();
    // console.log(invoices)
    // setTimeout(() => {
    //   this.exportExcel(invoices);
    // }, 500);

    const data = { table: invoices, type: 'INVOICE' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Faturas',
    });
  }

  async exportExcel(invoices) {
    import("xlsx").then(xlsx => {
        let worksheet = xlsx.utils.json_to_sheet(invoices);
        this.changeHeaderWorksheet(worksheet);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data']};
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "faturas");
    });
  }

  changeHeaderWorksheet(worksheet: WorkSheet) {
    worksheet.A1.v = this.cols[0].header
    worksheet.B1.v = this.cols[1].header
    worksheet.C1.v = this.cols[2].header
    worksheet.D1.v = this.cols[3].header
    worksheet.E1.v = this.cols[4].header
    worksheet.F1.v = this.cols[5].header
    worksheet.G1.v = this.cols[6].header
    worksheet.H1.v = this.cols[7].header
    worksheet.I1.v = this.cols[8].header
    worksheet.J1.v = this.cols[9].header
    worksheet.K1.v = this.cols[10].header
    worksheet.L1.v = this.cols[11].header
    worksheet.M1.v = this.cols[12].header
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  findDataExport(): Promise<any[]> {
    return new Promise(async (resolve) =>{
      this.spinner.show();
      const paginationFilter = { ...this.paginationFilter };
      paginationFilter.page = 0;
      paginationFilter.size = 1000000;
      this.invoiceService.getAll(this.filter, paginationFilter).subscribe((data: any) => {
        const invoices = [...data.invoices.content].map(invoice => {
          return this.formatDataExport(invoice);
        });
        this.spinner.hide();
        resolve(invoices);
      },
        (err) => {
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          resolve([]);
        });
    })
  }

  formatDataExport(invoice): any{

    return {
      NF: invoice.nfeNumber,
      invoiceNumber: invoice.invoiceNumber,
      origin: this.messageUtil.translateKey(invoice.origin),
      customer: invoice.customer.name,
      group: this.verifyGroupExport(invoice.customer.group),
      issueDate: moment(invoice.issueDate).format('DD/MM/yy'),
      dueDateToPay: moment(invoice.dueDateToPay).format('DD/MM/yy'),
      competenceDate: moment(invoice.competenceDate).format('DD/MM/yy'),
      value: invoice.value,
      discount: invoice.discount,
      interest: invoice.interest,
      pendingValue: invoice.pendingValue,
      totalPaid: invoice.totalPaid,
      status: this.messageUtil.translateKey(invoice.status),
    }
  }

  verifyGroupExport(group): string{
    return group?.name ? group.name : "Sem grupo";
  }


  generateInvoiceReceipt() {

    if (this.selectedInvoices.length === 0) {
      Swal.fire('Geração de recibo', 'Não há faturas selecionadas!', 'warning');
      return
    }

    const notEnableInvoice : any[] = this.selectedInvoices.filter(i => !this.checkInvoiceHasEnabletoGenerateIssueReceipt(i));
    if(notEnableInvoice.length > 0){
      notEnableInvoice.forEach( invoice => {
        invoice.errorReceipt = invoice.status != 'PAID' ? 'Fatura em aberto' : 'Fatura já possui recibo';
      });
      Swal.fire(
        {
          title: this.messageUtil.translateKey('SUMMARY.WARNING'),
          html: notEnableInvoice.length + (notEnableInvoice.length == 1 ? ' Fatura selecionada não pode gerar recibo':' Faturas selecionadas não podem gerar recibo'),
          icon: 'warning'
        });
      return;
    }

    const selectedInvoices = this.selectedInvoices
        .map(i => i.id);

    Swal.fire(
      {
        title: 'Geração de Recibo',
        text: (
            `Será gerado recibo somente para as faturas pagas com serviços que emitem recibo.
            Desejas gerar recibo para ${selectedInvoices.length} fatura${selectedInvoices.length>1?'s':''}?`
        ),
        icon: 'warning',
        showCancelButton: true,
        backdrop: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).
      then((result) => {
        if (result.value) {
          this.sendGenerateInvoiceReceipt(selectedInvoices);
        }
      });
  }

  private checkInvoiceHasEnabletoGenerateIssueReceipt(invoice: any): boolean {
    return invoice.status == 'PAID' && !invoice.invoiceReceipt
  }

  private sendGenerateInvoiceReceipt(invoiceIds: number[]){
    this.spinner.show();
    this.invoiceReceiptService.generate(invoiceIds).subscribe(async (data) => {
      if(data.notGeneratedReceipt.length > 0) {
        const message  = 'Não foi gerado recibo para ' +
        (data.notGeneratedReceipt.length === 1 ? 'a fatura ' : 'as faturas ') +
        data.notGeneratedReceipt.join(', ') + '<br><br> Verifique se essas faturas contém serviço que emite recibo.';

        await this.searchInvoices();

        this.selectedInvoices = this.invoicesTable.filter(i => data.notGeneratedReceipt.find( notGenerated => i.invoiceNumber == notGenerated));
        this.selectedInvoices.forEach(i => i['errorReceipt'] = 'Fatura sem serviço que emite recibo');
        Swal.fire(
          {
            title: this.messageUtil.translateKey('SUMMARY.WARNING'),
            html: message,
            icon: 'warning'
          });
      } else {
        await this.searchInvoices();
      }
    },
    error => {
      this.spinner.hide();
      const exception = error?.error?.data?.exception;
      if(exception){
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      } else {
        Swal.fire({
            title: this.messageUtil.translateKey('SUMMARY.WARNING'),
            html: this.messageUtil.translateKey('ERROR-GENERATE-INVOICE-RECEIPT-PDF'),
            icon: 'warning'
          });
      }
    });
  }

  hasInvoiceReceipt(): boolean{
    return true;
  }

  async isCurrentUserAdministrator(): Promise<Boolean> {
    const currentUserID = StorageUtil.getUserId();
    return new Promise<Boolean> (async (resolve) => {
      this.roleService.currentUserIsAdministrator(currentUserID).subscribe({
        next: (administratorRole: Boolean) => resolve(administratorRole),
        error: (e) => console.log(e),
      });
    });
  }

  checkIfHaveNixBillet(invoice: any): boolean{
    let retorno : boolean = false
    if(!invoice?.invoiceBankingBillets){
      return retorno;
    }
    if (invoice?.invoiceBankingBillets==null){
      return retorno;
    }
    if(invoice?.invoiceBankingBillets?.length === 0){
      return retorno;
    }
    const invoiceBankBillets = invoice?.invoiceBankingBillets;
    for (let i = 0; i < invoiceBankBillets.length; i++) {
      const element = invoiceBankBillets[i];
      if (element?.bankNumber === 332) {
        retorno = true;
      }
    }

    return retorno;
  }

  checkBilletsShared(invoice: any): boolean {
    if (invoice.value !== 0 && invoice.id !== invoice?.invoiceFatherId && invoice?.invoiceFatherId !== null) {
      return true;
    } else {
      return false;
    }
  }
}
