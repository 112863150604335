import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MultiQueue } from 'src/app/models/multi-queue';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlingTimesService {

  apiPath: string = environment.apiHost + '/api-core/secure/sling-times';

  constructor(private http: HttpClient) {}

  findAppointmentByMarinaId(marinaId: number): Observable<any[]> {
    return this.http
      .get(this.apiPath + '/marina/' + marinaId + '/appointment')
      .pipe(map((response: any) => response as any[]));
  }

  findOperationByMarinaId(marinaId: number): Observable<any[]> {
    return this.http
      .get(this.apiPath + '/marina/' + marinaId + '/operation')
      .pipe(map((response: any) => response as any[]));
  }

}
