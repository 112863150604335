<div class="dialog-form">
  <div class="table mt-2">
    <p-table
      #securitiesPropertyHistoric
      [value]="listHistoric"
      [paginator]="true"
      [rows]="listHistoric.length"
      [lazy]="false"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      dataKey="id"
      csvSeparator=";"
      [totalRecords]="listHistoric.length"
      styleClass="easy-table"
      id="historic-table"
      [alwaysShowPaginator]="false"
      autoLayout="true"
      [globalFilterFields]="[
        'numeroId',
        'currentCustomerName',
        'currentCustomerPhone'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="export-buttons">Histórico de Proprietários</div>
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}:
          {{ listHistoric?.length }}
        </span>
      </ng-template>

      <ng-template pTemplate="header">
        <div class="header-buttons button-left">
          <button
          class="fab-button primary item-left"
          label="Exportar valores"
          pTooltip="Exportar Valores"
          tooltipPosition="left"
          (click)="buildPDF()"
        >
          <i class="pi pi-file"></i>
        </button>
      </div>
        <tr>
          <th [pSortableColumn]="'customerName'" class="text-center">
            {{ "Proprietário" | translate }}
            <p-sortIcon [field]="'customerName'"></p-sortIcon>
          </th>

          <th [pSortableColumn]="'dateStart'" class="text-center">
            {{ "Entrada" | translate }}
            <p-sortIcon [field]="'dateStart'"></p-sortIcon>
          </th>

          <th class="text-center" [pSortableColumn]="'dateEnd'">
            {{ "Cancelamento" | translate }}
            <p-sortIcon [field]="'dateEnd'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'block'">
            {{ "Ação" | translate }}
            <p-sortIcon [field]="'block'"></p-sortIcon>
          </th>
          <th class="text-center">
            {{ "Aceito por:" | translate }}
          </th>
          <th class="text-center">
            {{ "Cancelado por:" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-property>
        <tr>
          <td class="text-center">
            {{ property?.customerName }}
          </td>
          <td class="text-center">
            {{ property?.dateStart | date : "dd/MM/yyyy" }}
          </td>
          <td class="text-center">
            {{ property?.dateEnd | date : "dd/MM/yyyy" }}
          </td>
          <td class="text-center">
            {{ property?.action }}
          </td>
          <td class="text-center">
            {{ property?.userNameStart }}
          </td>

          <td class="text-center">
            {{ property?.userNameEnd }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
