<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "TITLE-BILLING-ORDER" | translate }}</span>
    <div class="header-buttons">
      <button *ngIf="marinaId===11" class="fab-button color-orange" pTooltip="Importar Pedidos"
           tooltipPosition="left"  (click)="fileInput.click()" >
        <i class="pi pi-file-import"></i>
      </button>
      <input type="file" #fileInput multiple hidden (change)="onFileSelected($event)" />
      <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
        <i class="pi pi-filter"></i>
      </button>
      <button class="fab-button primary" (click)="exportTableExcel()" label="Exportar valores"
        pTooltip="Exportar Valores" tooltipPosition="left">
        <i class="pi pi-file"></i>
      </button>
    </div>
  </p-header>
  <div class="subtitles">
    <fieldset>
      <legend>Legenda (Status do pedido)</legend>
      <div class="badges">
        <i class="attempted pi pi-circle-on"></i>
        Atendido
        <i class="partially-attempted pi pi-circle-on"></i>
        Parcial
        <i class="ordered pi pi-circle-on"></i>
        Solicitado
        <i class="canceled pi pi-circle-on"></i>
        Cancelado
      </div>
    </fieldset>
  </div>
  <form class="filters" (submit)="find()" appFilters>
    <div class="filter-row">
      <div class="form-control">
        <label>N° Pedido</label>
        <input pInputText type="number" [(ngModel)]="filter.orderNumber" name="orderNumber" />
      </div>
      <div class="form-control">
        <label>N° Fatura</label>
        <input pInputText type="number" [(ngModel)]="filter.invoiceNumber" name="invoiceNumber" />
      </div>
      <div class="form-control">
        <label>Faturado</label>
        <p-dropdown placeholder="Selecione..." [options]="[
            { label: 'Ambos', value: '' },
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
          ]" [(ngModel)]="filter.hasInvoice" name="hasInvoice" appendTo="body">
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "CUSTOMER" | translate }}</label>
        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="filteredCustomers" appendTo="body"
          [(ngModel)]="selectedCustomerId" name="selectedCustomerId" (onChange)="selectCustomer()">
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "BOAT" | translate }}</label>
        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="boatsFilter" appendTo="body"
          [(ngModel)]="selectedBoatId" name="selectedBoatId" (onChange)="selectBoat()"></p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "GROUPS" | translate }}</label>
        <p-multiSelect defaultLabel="Selecione..." [options]="filteredGroup" [style]="{ height: '30px' }"
          appendTo="body" [(ngModel)]="filter.groupsList" selectedItemsLabel="{0} itens selecionados"
          name="groupsList"></p-multiSelect>
      </div>
      <div class="form-control">
        <label>Data inicial</label>
        <p-calendar dateFormat="dd/mm/yy" name="dateStart" [monthNavigator]="true" [yearNavigator]="true"
          appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateStart" [showIcon]="true"
          i18n>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>Data final</label>
        <p-calendar dateFormat="dd/mm/yy" name="dateEnd" [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
          [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateEnd" [showIcon]="true" i18n>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>Origem</label>
        <p-dropdown placeholder="Selecione..." [options]="originOptions" [(ngModel)]="filter.origim" appendTo="body"
          name="filter.origim">
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>Controle</label>
        <p-dropdown placeholder="Selecione..." [options]="[
            { label: 'Todos', value: null },
            { label: 'Easymarine', value: 'EASYMARINE' },
            { label: 'Terceiro', value: 'THIRD_PARTY' },
            { label: 'Terceiro importação', value: 'THIRD_PARTY_IMPORT' }
          ]" appendTo="body" [(ngModel)]="filter.orderControl" name="filter.orderControl">
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>Filtro status</label>
        <p-multiSelect defaultLabel="Selecione..." [options]="orderStatusList" name="orderStatusList" appendTo="body"
          [(ngModel)]="filter.orderStatusList" selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-control">
        <label>Produto(s)</label>
        <p-multiSelect defaultLabel="Selecione..." [options]="orderProductFiltered" name="orderProductsList"
          appendTo="body" [(ngModel)]="filter.orderProductsList" selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-control">
      <label>Data inicial Atendimento</label>
      <p-calendar dateFormat="dd/mm/yy" name="dateStartAtend" [monthNavigator]="true" [yearNavigator]="true"
        appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateStartAtend" [showIcon]="true"
        i18n>
      </p-calendar>
    </div>
    <div class="form-control">
      <label>Data final Atendimento</label>
      <p-calendar dateFormat="dd/mm/yy" name="dateEndAtend" [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
        [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateEndAtend" [showIcon]="true" i18n>
      </p-calendar>
    </div>
      <app-filter-billing-company class="form-control"
        *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)" [slingConfig]="slingConfig"
        [(filter)]="filter">
      </app-filter-billing-company>
    </div>
    <div class="filter-row">
      <div class="notifications">
        <div class="error">
          <fa-icon class="icon" [icon]="'exclamation-circle'"></fa-icon>
          <h3>
            Atenção! Ao utilizar o filtro de produtos, serão pesquisados apenas
            pedidos que tenham EXATAMENTE os produtos do filtro.
          </h3>
        </div>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button class="danger easymarine" (click)="clear()" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button type="submig" class="primary easymarine">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table #tt [value]="ordersTable" [paginator]="true" [rows]="paginationFilter.size" resetPageOnSort="true"
      [totalRecords]="totalRecords" autoLayout="true" [lazy]="true" styleClass="easy-table flexend-header"
      [alwaysShowPaginator]="false" (onLazyLoad)="onChangePage($event)">
      <ng-template pTemplate="caption">
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}: {{ ordersTable.length }}
          {{ "OF" | translate }} {{ tt.totalRecords }}
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th colspan="4"></th>
          <th class="text-right">{{ totalOrders | number: "1.2-2" }}</th>
          <th class="text-center" *ngIf="filter.orderProductsList?.length==1">{{ getTotalProduct() | number: "1.2-2" }}</th>
          <th colspan="9"></th>
        </tr>

        <tr class="thead">
          <th [pSortableColumn]="'orderNumber'">
            Ped
            <p-sortIcon [field]="'orderNumber'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'orderDate'">
            Data de Solicitação
            <p-sortIcon [field]="'orderDate'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'lastDateAttempt'">
            Data do Atendimento
            <p-sortIcon [field]="'lastDateAttempt'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'origin'">
            Orig.
            <p-sortIcon [field]="'origin'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'value'">
            Total
            <p-sortIcon [field]="'value'"></p-sortIcon>
          </th>
          <th class="text-center"  *ngIf="filter.orderProductsList?.length==1">
            Qtd. Atendida
          </th>
          <th class="text-center" [pSortableColumn]="'boat.name'">
            Embarcação
            <p-sortIcon [field]="'boat.name'"></p-sortIcon>
          </th>
          <th class="text-center">Cliente</th>
          <th class="text-center">Fatura</th>
          <th class="text-center">Rateio</th>
          <th class="text-center">Valor</th>
          <th class="text-center" [pSortableColumn]="'orderStatus'">
            Status
            <p-sortIcon [field]="'orderStatus'"></p-sortIcon>
          </th>
          <th class="text-center" colspan="3">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-index>
        <tr>
          <td class="text-center">{{ order.orderNumber }}</td>
          <td class="text-center">{{ order.orderDate | date: "dd/MM/yy" }}</td>
          <td class="text-center">{{ order.lastDateAttempt | date: "dd/MM/yy" }}</td>
          <td class="text-center" [ngClass]="{
              'color-third-party': order.orderControl == 'THIRD_PARTY',
              'color-third-party-import':
                order.orderControl == 'THIRD_PARTY_IMPORT'
            }">
            <div [ngSwitch]="order.origin">
              <div *ngSwitchCase="'DETACHED'">
                <fa-icon [pTooltip]="'Pedido Avulso'" [icon]="'shopping-cart'" style="font-size: 21px"></fa-icon>
              </div>
              <div *ngSwitchCase="'FRANCHISE'">
                <img  tooltipPosition="right" [pTooltip]="'Pedido Franquia'" [src]="'../../../assets/icons/Franquia.svg'" style="font-size: 21px"/>
              </div>
              <div *ngSwitchCase="'INVITE'">
                <fa-icon [pTooltip]="'Pedido de Convite'" [icon]="'address-card'" style="font-size: 21px"></fa-icon>
              </div>
              <div *ngSwitchCase="'SLING'">
                <fa-icon [icon]="'ship'" [pTooltip]="'Lingada'" style="font-size: 21px"></fa-icon>
              </div>
              <div *ngSwitchCase="'TUITION'">
                <fa-icon [icon]="'university'" style="font-size: 21px" [pTooltip]="'Mensalidade'"></fa-icon>
              </div>
              <div *ngSwitchCase="'WORKSHOP'">
                <fa-icon [icon]="'tools'" [pTooltip]="'Oficina'" style="font-size: 21px"></fa-icon>
              </div>

            </div>
          </td>
          <td class="text-center colorValue">
            {{ order.value | number: "1.2-2" }}
          </td>
          <td class="text-center"  *ngIf="filter.orderProductsList?.length==1">
            {{ getSupplier(order) | number: "1.2-3" }}
          </td>
          <td class="text-center colorValue">{{ order.boat.name }}</td>
          <td>
        <tr *ngFor="let item of order.orderQuotas" class="centralize">
          <td>{{ item.customer.name }}</td>
        </tr>
        </td>
        <td>
          <tr *ngFor="let item of order.orderQuotas" class="centralize">
            <span class="{{
                  item.invoiceNumber != null &&
                  item.statusInvoice === 'CANCELED'
                    ? 'canceledOrder'
                    : item.invoiceNumber != null
                    ? 'paidOrder'
                    : 'paidOrderDisabled'
                }}" (click)="item.invoiceNumber != null && extract(item.idInvoice)">
              {{ item.invoiceNumber != null ? item.invoiceNumber : "----" }}
            </span>
          </tr>
        </td>
        <td>
          <tr *ngFor="let item of order.orderQuotas" class="centralize">
            <td class="text-center colorValue">
              {{ item.quota | number: "1.0-0" }}%
            </td>
          </tr>
        </td>
        <td>
          <tr *ngFor="let item of order.orderQuotas" class="centralize">
            <td class="text-center colorValue">
              {{
              calculateQuotaValue(order.value, item.quota) | number: "1.2-2"
              }}
            </td>
          </tr>
        </td>
        <td>
          <div class="centralize" style="padding-top: 10px">
            <p>
              <i class="pi pi-circle-on" [ngClass]="{
                    canceled: order.orderStatus === 'Canceled',
                    'partially-attempted':
                      order.orderStatus === 'PartiallyAttempted',
                    attempted: order.orderStatus === 'Attempted',
                    ordered: order.orderStatus === 'Ordered'
                  }">
              </i>
            </p>
          </div>
        </td>

        <td colspan="3" class="text-center">
          <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true" [showTransitionOptions]="'150ms'"
            [hideTransitionOptions]="'150ms'"></p-menu>
          <button (click)="menu.toggle($event); changeSelectedItem(order)" pButton type="button" icon="pi pi-ellipsis-v"
            class="p-button-text p-button-rounded p-button-lg"></button>
        </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
