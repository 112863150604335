<div class="dialog-form">
  <p-fieldset class="operation-date">
    <p-header>
      <div>Informações</div>
    </p-header>
    <div class="form-group">
      <div
        class="form-control flex-end"
        style="max-width: 100px; min-width: 80px; align-items: center"
      >
        <label for="active">{{ "ACTIVE" | translate }}</label>
        <p-inputSwitch
          styleClass="myswitch"
          [(ngModel)]="securitiesProperty.active"
          name="active"
        >
        </p-inputSwitch>
      </div>
      <div class="form-control">
        <label>{{ "Número do Título" | translate }}</label>
        <p-inputNumber
          name="numberId"
          [(ngModel)]="securitiesProperty.numberId"
          appendTo="body"
          [showButtons]="false"
          [minFractionDigits]="0"
          [maxFractionDigits]="0"
          disabled="true"
        ></p-inputNumber>
      </div>
      <div
        class="form-control flex-end"
        style="max-width: 100px; min-width: 80px; align-items: center"
      >
        <label for="active">{{ "Bloqueado" | translate }}</label>
        <p-inputSwitch
          styleClass="myswitch"
          [(ngModel)]="securitiesProperty.block"
          name="active"
        >
        </p-inputSwitch>
      </div>

      <div class="form-control" *ngIf="securitiesProperty.block">
        <label>{{ "Tipo de bloqueio:" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [disabled]="false"
          required
          [filter]="true"
          [options]="blocktypes"
          [(ngModel)]="securitiesProperty.blockType"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          name="selectedCustomerId"
        >
        </p-dropdown>
      </div>
    </div>
  </p-fieldset>
  <p-fieldset class="operation-date mt-2 pb-0">
    <p-header>
      <div>Proprietario Atual</div>
    </p-header>
    <div class="form-group">
      <div class="form-control">
        <label for="currentCustomer">{{ "Proprietário:" | translate }}</label>
        <input
          *ngIf="!isNew"
          pInputText
          type="text"
          [(ngModel)]="securitiesProperty.currentCustomerName"
          name="currentCustomer"
          disabled="true"
        />
        <p-dropdown
          *ngIf="isNew "
          placeholder="Selecione..."
          [disabled]="securitiesProperty.block"
          [filter]="true"
          [options]="customerList"
          [(ngModel)]="securitiesProperty.currentCustomerId"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          name="selectedCustomerId"
          (onChange)="getCustomerName($event.value)"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="currentCustomer">{{ "Telefone:" | translate }}</label>
        <p-inputMask
          mask="(99) 99999-9999"
          name="currentCustomer"
          [(ngModel)]="securitiesProperty.currentCustomerPhone"
          appendTo="body"
          disabled="true"
        >
        </p-inputMask>
      </div>

      <div class="form-control">
        <label for="currentCustomer">Tipo de titulo</label>
        <p-dropdown
          optionLabel="name"
          [options]="listTitleProperties"
          [(ngModel)]="selectedTitleProperties"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="currentCustomer">{{
          "Data de Associação:" | translate
        }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="currentCustomer"
          [(ngModel)]="securitiesProperty.lastAssociation"
          appendTo="body"
          [showIcon]="true"
          i18n
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="'2000:2055'"
          rPCalendarMask
        >
        </p-calendar>
      </div>
    </div>
    <div class="buttons" *ngIf="!isNew">
      <div class="form-button" *ngIf="!securitiesProperty.block">
        <button (click)="cancelProp()" class="easymarine danger" type="button">
          {{ "Cancelar Associação" | translate }}
        </button>
      </div>
    </div>
  </p-fieldset>
  <div class="buttons" style="max-height: 51px">
    <div class="form-button">
      <button (click)="save()" class="easymarine primary" type="button">
        {{ "Salvar as Alterações" | translate }}
      </button>
    </div>
  </div>
</div>
