import { Fuel } from './fuel';
import { Measurement } from './measurement';
import { Price } from './price';
import { PrincingType } from './pricing-type';
import { ProductCategory } from './product-category';
import { Marina } from './marina';
import { OrderControl } from './enums/order-control';
import { MarinaCompany } from './marina-company';
import { ProductType } from 'src/app/models/enums';

export class Product {
    id?: number;
    name?: string;
    barCode?: string;
    stock?: number;
    avaliable?: number;
    minimalStock?: number;
    reserved?: number;
    file?: string;
    productType?: ProductType;
    measurement?: Measurement;
    price?: Price;
    pricingType?: PrincingType;
    productCategory?: ProductCategory;
    marina?: Marina;
    discount?: boolean;
    active?: boolean;
    chartAccountId?: number;
    fuel?: Fuel;
    showApp?: boolean = false;
    appMinimalStock?: number;
    orderControl?: OrderControl;
    issueReceipt?: boolean = false;
    issueNotaFiscal?: boolean = false;
    vacancyReference?: boolean = false;
    issueNotaFiscalConsumidor?: boolean = false;
    billingCompany?: MarinaCompany;
    costCenterId?: number;
    readjustmentProduct?: any[] = []
    ncmCod?: string;
    ncmDescription?: string;
    cfop?: string;
    icms?: number;
    averageCustPrice?: number;
    lastCustPrice?: number;
    markup?: number;
    pis?: number;
    priceCust?: number;
    cofins?: number;
    icmsOrigem?: number;
    icmsSituacaoTributaria?: string;
    icmsBaseCalculo?: string;
    enableReferenceDate?: boolean = false;
    quantity?: number;
    maxStock?: number;
    thumb?: string;
    pisSituacaoTributaria?: string;
    cofinsSituacaoTributaria?: string;
    ipiSituacaoTributaria?: string;
    ipiCodigoEnquadramento?: string;
    ipi?: number;
    cest?: string;
    codAnp?: string;
    combustivelPercentualBiodiesel?: number;

    constructor() {
        this.price = new Price();
        this.pricingType = PrincingType.SimplePrice;
        this.productCategory = new ProductCategory();
        this.marina = new Marina();
        this.fuel = new Fuel();
    }
}
