<p-toolbar>
  <div class="p-toolbar-group-left">
    <p-selectButton [style]="{'font-size':'12px'}" optionValue="value" optionLabel="icon" [options]="editModes" [multiple]="false" (onChange)="editModeChange($event)"	[(ngModel)]="selectedEditMode" >
      <ng-template let-item pTemplate>
        <i [pTooltip]="item.label" tooltipPosition="top" [class]="item.icon"></i>
      </ng-template>
    </p-selectButton>
    &nbsp; &nbsp; &nbsp;
    <i class="pi pi-bars"></i>
    &nbsp; &nbsp; &nbsp;
    <p-button [style]="{'font-size':'12px'}" pTooltip="Atualiza Status" tooltipPosition="top" icon="pi pi-sync " (onClick)="reloadStatus()" [disabled]="refreshButtonDisabled" ></p-button><br>
    &nbsp; &nbsp; &nbsp;
    <p-button [style]="{'font-size':'12px'}" pTooltip="Zoom +" tooltipPosition="top" icon="pi pi-search-plus" (onClick)="zoomIn()"  ></p-button><br>
    &nbsp;
    <p-button [style]="{'font-size':'12px'}" pTooltip="Zoom -" tooltipPosition="top" icon="pi pi-search-minus" (onClick)="zoomOut()" ></p-button><br>
    
  </div>
  <div class="p-toolbar-group-right">
    <!-- p-button label="Veleiro" (onClick)="addBoatImage('recones/Veleiro-1.svg')"></p-button><br>
    <p-button label="Lancha" (onClick)="addBoatImage('recones/Lancha-4.svg')"></p-button><br-->   
    <p-button  label="Salvar" (onClick)="saveMap()" [disabled]="saveButtonDisabled"></p-button><br>
    

  </div>  

</p-toolbar>

<div #canvasContainer style="width: 100%; height: 100%;">

    <canvas id="canvas" #canvas  ></canvas>

</div>

<!-- div class="grid">
  <div class="col-12"></div>
</div-->

  <div (contextmenu)="false">
    <map-context-menu
      *ngIf="isDisplayMenuMap && selectedEditMode == 'ED'"
      [ngStyle]="getRightClickMenuStyle()"     
      (onContextMenuItemClick)="handleMenuMapClick($event)"
    ></map-context-menu>
  </div>


  <div (contextmenu)="false">
    <berth-context-menu
      *ngIf="isDisplayBerthContextMenu && selectedEditMode == 'ED'"
      [ngStyle]="getRightClickMenuStyle()" 
      [fabricObj]="rightClickedFabricObj"    
      (onContextMenuItemClick)="handleBerthContextMenuClick($event)"
    ></berth-context-menu>
  </div>


  <div (contextmenu)="false">
    <berth-operation-menu
      *ngIf="isDisplayBerthContextMenu && selectedEditMode == 'OP'"
      [ngStyle]="getRightClickMenuStyle()" 
      [fabricObj]="rightClickedFabricObj"    
      (onContextMenuItemClick)="handleBerthOperationMenuClick($event)"
    ></berth-operation-menu>
  </div>




    
