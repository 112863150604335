export class SlingQueueType {
    id: number;
    marinaId: number;
    name: string;
    queueColor: string;
    queueType: string;
    scheduleType: string;
    status: boolean;
}

