import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { VerifyContractsDialogComponent } from 'src/app/components/contracts/verify-contracts-dialog/verify-contracts-dialog.component';
import { Marina, PriceRange, Product, SlingConfig } from 'src/app/models';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';
import { Measurement, PrincingType, ProductCategoryType, ProductType } from 'src/app/models/enums';
import { DocumentStatus } from 'src/app/models/enums/document-status';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { OrderControl } from 'src/app/models/enums/order-control';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { FuelService } from 'src/app/pages/forms/boat/boat-form/fuel.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { ContractDocumentService } from 'src/app/services/contract-document.service';
import { FinancialMultipleCompaniesOption, FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProductService } from 'src/app/services/product.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { DeepCloneUtil } from 'src/app/utils/deep-clone.util';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { NcmTable } from './../../../../utils/ncm-table';
import { FocusService } from 'src/app/services/focus.service';
import { Subscription } from 'rxjs';
import { ProductCodes } from 'src/app/models/product-codes';
import { ServiceLinkedBoats } from 'src/app/models/dtos/service-linked-boats';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;

  uploadFileInProgress = false;

  product: Product = new Product();
  productForm: UntypedFormGroup;
  loading = false;
  isFixedPrice = true;
  suggestedPrice =0;
  realMarkup=0;
  vlIcms =0;
  vlIpi =0;
  vlPis =0;
  vlCofins =0;
  vlLucro =0;
  ncmDescription :any;

  isNew: boolean =true;
  isWorkshop: Boolean;

  isEditionPeriodicalService = false;

  newStartLengthRange: number;
  newEndLengthRange: number;
  newRangeValue: number;

  rangeList = [];

  chartAccountsFlatList: any[];
  fuels: { label: string, value: any }[];
  costCenters:any[];

  productTypeOptions = [];
  situationTributariaOptions = [
  { value: '00', label: '00 - Tributada integralmente' },
  { value: '01', label: '01 - Operação Tributável com Alíquota Básica' },
  { value: '02', label: '02 - Operação Tributável com Alíquota Diferenciada' },
  { value: '60', label: '060 - Icms Pago Anteriormente Por Substituição Tributária' },
  { value: '61', label: '061 - Substituição Tributária' },
  { label: '102-Simples nacional sem permissão de crédito', value: "102" },
  { label: '300-Imune', value: "300" },
  { label: '500-Cobrado anteriormente por substituição tributária', value: "500" }];

  ncmOptions = [];


  icmsOrigemOptions = [
    { label: '0-Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8', value: 0 },
    { label: '1-Estrangeira - Importação direta, exceto a indicada no código 6', value: 1 },
    { label: '2-Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: 2 },
    { label: '3-Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%', value: 3 },
    { label: '4-Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/67, e as Leis nºs 8.248/91, 8.387/91, 10.176/01 e 11.484/07', value: 4 },
    { label: '5-Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%', value: 5 },
    { label: '6-Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX e gás natural', value: 6 },
    { label: '7-Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural', value: 7 },
    { label: '8-Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%', value: 8 },
  ];

  cofinsOrigenOptions = [
    { label: '01 - Operação Tributável com Alíquota Básica', value: '01' },
    { label: '02 - Operação Tributável com Alíquota Diferenciada', value: '02' },
    { label: '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto', value: '03' },
    { label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero', value: '04' },
    { label: '05 - Operação Tributável por Substituição Tributária', value: '05' },
    { label: '06 - Operação Tributável a Alíquota Zero', value: '06' },
    { label: '07 - Operação Isenta da Contribuição', value: '07' },
    { label: '08 - Operação sem Incidência da Contribuição', value: '08' },
    { label: '09 - Operação com Suspensão da Contribuição', value: '09' },
    { label: '49 - Outras Operações de Saída', value: '49' },
    { label: '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno', value: '50' },
    { label: '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno', value: '51' },
    { label: '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação', value: '52' },
    { label: '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno', value: '53' },
    { label: '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação', value: '54' },
    { label: '55 - Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação', value: '55' },
    { label: '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação', value: '56' },
    { label: '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno', value: '60' },
    { label: '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno', value: '61' },
    { label: '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação', value: '62' },
    { label: '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno', value: '63' },
    { label: '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação', value: '64' },
    { label: '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação', value: '65' },
    { label: '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação', value: '66' },
    { label: '67 - Crédito Presumido - Outras Operações', value: '67' },
    { label: '70 - Operação de Aquisição sem Direito a Crédito', value: '70' },
    { label: '71 - Operação de Aquisição com Isenção', value: '71' },
    { label: '72 - Operação de Aquisição com Suspensão', value: '72' },
    { label: '73 - Operação de Aquisição a Alíquota Zero', value: '73' },
    { label: '74 - Operação de Aquisição sem Incidência da Contribuição', value: '74' },
    { label: '75 - Operação de Aquisição por Substituição Tributária', value: '75' },
    { label: '98 - Outras Operações de Entrada', value: '98' },
    { label: '99 - Outras Operações', value: '99' },
  ];

  pisOriginOptions = [
    { label: '01 - Operação Tributável com Alíquota Básica', value: '01' },
    { label: '02 - Operação Tributável com Alíquota Diferenciada', value: '02' },
    { label: '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto', value: '03' },
    { label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero', value: '04' },
    { label: '05 - Operação Tributável por Substituição Tributária', value: '05' },
    { label: '06 - Operação Tributável a Alíquota Zero', value: '06' },
    { label: '07 - Operação Isenta da Contribuição', value: '07' },
    { label: '08 - Operação sem Incidência da Contribuição', value: '08' },
    { label: '09 - Operação com Suspensão da Contribuição', value: '09' },
    { label: '49 - Outras Operações de Saída', value: '49' },
    { label: '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno', value: '50' },
    { label: '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno', value: '51' },
    { label: '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação', value: '52' },
    { label: '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno', value: '53' },
    { label: '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação', value: '54' },
    { label: '55 - Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação', value: '55' },
    { label: '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação', value: '56' },
    { label: '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno', value: '60' },
    { label: '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno', value: '61' },
    { label: '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação', value: '62' },
    { label: '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno', value: '63' },
    { label: '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação', value: '64' },
    { label: '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação', value: '65' },
    { label: '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação', value: '66' },
    { label: '67 - Crédito Presumido - Outras Operações', value: '67' },
    { label: '70 - Operação de Aquisição sem Direito a Crédito', value: '70' },
    { label: '71 - Operação de Aquisição com Isenção', value: '71' },
    { label: '72 - Operação de Aquisição com Suspensão', value: '72' },
    { label: '73 - Operação de Aquisição a Alíquota Zero', value: '73' },
    { label: '74 - Operação de Aquisição sem Incidência da Contribuição', value: '74' },
    { label: '75 - Operação de Aquisição por Substituição Tributária', value: '75' },
    { label: '98 - Outras Operações de Entrada', value: '98' },
    { label: '99 - Outras Operações', value: '99' },
  ];

  ipiOriginOptions = [
    { label: '00 - Entrada com Recuperação de Crédito', value: '00' },
    { label: '01 - Entrada Tributável com Alíquota Zero', value: '01' },
    { label: '02 - Entrada Isenta', value: '02' },
    { label: '03 - Entrada Não-Tributada', value: '03' },
    { label: '04 - Entrada Imune', value: '04' },
    { label: '05 - Entrada com Suspensão', value: '05' },
    { label: '49 - Outras Entradas', value: '49' },
    { label: '50 - Saída Tributada', value: '50' },
    { label: '51 - Saída Tributável com Alíquota Zero', value: '51' },
    { label: '52 - Saída Isenta', value: '52' },
    { label: '53 - Saída Não-Tributada', value: '53' },
    { label: '54 - Saída Imune', value: '54' },
    { label: '55 - Saída com Suspensão', value: '55' },
    { label: '99 - Outras Saídas', value: '99' },
  ];


  ipiCodigoEnquadramentoOptions = [
    { label: '999 - Tributação normal IPI; Outros;', value: '999' },
  ];


    // Ir liberando se o cliente precisar
    // 001 Imunidade Livros, jornais, periódicos e o papel destinado à sua impressão - Art. 18 Inciso I do Decreto 7.212/2010
    // 002 Imunidade Produtos industrializados destinados ao exterior - Art. 18 Inciso II do Decreto 7.212/2010
    // 003 Imunidade Ouro, definido em lei como ativo financeiro ou instrumento cambial - Art. 18 Inciso III do Decreto 7.212/2010
    // 004 Imunidade Energia elétrica, derivados de petróleo, combustíveis e minerais do País - Art. 18 Inciso IV do Decreto 7.212/2010
    // 005 Imunidade Exportação de produtos nacionais - sem saída do território brasileiro - venda para empresa sediada no exterior - atividades de pesquisa ou lavra de jazidas de petróleo e de gás natural - Art. 19 Inciso I do Decreto 7.212/2010
    // 006 Imunidade Exportação de produtos nacionais - sem saída do território brasileiro - venda para empresa sediada no exterior - incorporados a produto final exportado para o Brasil - Art. 19 Inciso II do Decreto 7.212/2010
    // 007 Imunidade Exportação de produtos nacionais - sem saída do território brasileiro - venda para órgão ou entidade de governo estrangeiro ou organismo internacional de que o Brasil seja membro, para ser entregue, no País, à ordem do comprador - Art. 19 Inciso III do Decreto 7.212/2010
    // 101 Suspensão Óleo de menta em bruto, produzido por lavradores - Art. 43 Inciso I do Decreto 7.212/2010
    // 102 Suspensão Produtos remetidos à exposição em feiras de amostras e promoções semelhantes - Art. 43 Inciso II do Decreto 7.212/2010
    // 103 Suspensão Produtos remetidos a depósitos fechados ou armazéns-gerais, bem assim aqueles devolvidos ao remetente - Art. 43 Inciso III do Decreto 7.212/2010
    // 104 Suspensão Produtos industrializados, que com matérias-primas (MP), produtos intermediários (PI) e material de embalagem (ME) importados submetidos a regime aduaneiro especial (drawback - suspensão/isenção), remetidos diretamente a empresas industriais exportadoras - Art. 43 Inciso IV do Decreto 7.212/2010
    // 105 Suspensão Produtos, destinados à exportação, que saiam do estabelecimento industrial para empresas comerciais exportadoras, com o fim específico de exportação - Art. 43, Inciso V, alínea "a" do Decreto 7.212/2010
    // 106 Suspensão Produtos, destinados à exportação, que saiam do estabelecimento industrial para recintos alfandegados onde se processe o despacho aduaneiro de exportação - Art. 43, Inciso V, alíneas "b" do Decreto 7.212/2010
    // 107 Suspensão Produtos, destinados à exportação, que saiam do estabelecimento industrial para outros locais onde se processe o despacho aduaneiro de exportação - Art. 43, Inciso V, alíneas "c" do Decreto 7.212/2010
    // 108 Suspensão Matérias-primas (MP), produtos intermediários (PI) e material de embalagem (ME) destinados ao executor de industrialização por encomenda - Art. 43 Inciso VI do Decreto 7.212/2010
    // 109 Suspensão Produtos industrializados por encomenda remetidos ao estabelecimento de origem - Art. 43 Inciso VII do Decreto 7.212/2010
    // 110 Suspensão Matérias-primas ou produtos intermediários remetidos para emprego em operação industrial realizada pelo remetente fora do estabelecimento - Art. 43 Inciso VIII do Decreto 7.212/2010
    // 111 Suspensão Veículo, aeronave ou embarcação destinados a emprego em provas de engenharia pelo fabricante - Art. 43 Inciso IX do Decreto 7.212/2010
    // 112 Suspensão Produtos remetidos, para industrialização ou comércio, de um para outro estabelecimento da mesma firma - Art. 43 Inciso X do Decreto 7.212/2010
    // 113 Suspensão Bens do ativo permanente remetidos a outro estabelecimento da mesma firma, para serem utilizados no processo industrial do recebedor - Art. 43 Inciso XI do Decreto 7.212/2010
    // 114 Suspensão Bens do ativo permanente remetidos a outro estabelecimento, para serem utilizados no processo industrial de produtos encomendados pelo remetente - Art. 43 Inciso XII do Decreto 7.212/2010
    // 115 Suspensão Partes e peças destinadas ao reparo de produtos com defeito de fabricação, quando a operação for executada gratuitamente, em virtude de garantia - Art. 43 Inciso XIII do Decreto 7.212/2010
    // 116 Suspensão Matérias-primas (MP), produtos intermediários (PI) e material de embalagem (ME) de fabricação nacional, vendidos a estabelecimento industrial, para industrialização de produtos destinados à exportação ou a estabelecimento comercial, para industrialização em outro estabelecimento da mesma firma ou de terceiro, de produto destinado à exportação - Art. 43 Inciso XIV do Decreto 7.212/2010
    // 999 Outras Suspensões


   cfopOptions = [
    { label: '5102 – Venda de mercadoria adquirida ou recebida de terceiros', value: "5102" },

  ];

  orderControlOptions = [
    { label: 'Easymarine', value: OrderControl.EASYMARINE },
    { label: 'Terceiro', value: OrderControl.THIRD_PARTY },
    { label: 'Terceiro importação', value: OrderControl.THIRD_PARTY_IMPORT },
  ];

  pricingTypeOptions = [];

  measurementOptions = [];

  productCategories = [];

  documentContracts: DocumentContractView[] = [];
  slingConfig: SlingConfig

  billingCompanyOptions: FinancialMultipleCompaniesOption[];

  productCodes: ProductCodes[] = [];

  serviceLinkedBoats: ServiceLinkedBoats[] = [];

  private unsubscriber: Subscription = new Subscription();
  activateTuitionByCustomer: boolean;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private productService: ProductService,
    private productCategoryService: ProductCategoryService,
    private messageService: MessageUtil,
    private spinner: NgxSpinnerService,
    private fuelService: FuelService,
    private toasty: ToastService,
    private dialog: DialogService,
    private messageUtil: MessageUtil,
    private contractDocumentService: ContractDocumentService,
    private slingConfigService: SlingConfigService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private nav: Router,
    private operationalConfigService: OperationalConfigService,
    private focusService : FocusService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getTuitionConfig();
    this.getWorkshopConfig();
    this.ncmOptions = NcmTable.ncmList.Nomenclaturas.map((d) => ({
      label: d.Codigo + ' - ' + d.Descricao,
      value: d.Codigo,
    }));
    if (this.config.data && this.config.data.product) {
      this.product = this.config.data.product;
      if (this.product.id) {
        this.isNew = false;
        this.getProductCodes();
        if(this.product.productType == ProductType.Service || this.product.productType == ProductType.PeriodicalService){
          this.getServiceBoats();
        }
      }
      this.isEditionPeriodicalService = this.product.productType === ProductType.PeriodicalService || this.product.productType === ProductType.PeriodicalServiceCustomer;
    } else {
      this.product.productCategory = null;
    }
    this.chartAccountsFlatList = this.config.data.chartAccountsFlatList;
    this.costCenters= this.config.data.costCenters;
    this.costCenters.unshift({ label: 'Sem centro de Custo', value: null });
    this.loadLists();
    this.startForm();
    this.findCfopOptions();
    this.getProductCategories();
    await Promise.all([
      this.findSlingConfig(),
      this.loadFuels(),
    ]);
    this.switchpricingType();
    if (this.config.data.product) {
      this.changeCategory(this.config.data.product);
      this.verifyContract();
    }
    this.checkMarinaHasPeriodicalServiceByCustomer();
    this.rangeList = this.productForm.get("price.priceRanges")?.value
  }

  async getTuitionConfig() {
    this.activateTuitionByCustomer = await this.operationalConfigService.hasCustomerTuition();
  }

  getServiceBoats(){
    this.productService.getBoatsFromService(this.product.id).subscribe({
      next: (res) => {
        this.serviceLinkedBoats = res;
      }
    });
  }


  getProductCodes(){
    if(this.product.id){
      this.productService.getProductsCodes(this.product.id).subscribe({
        next: (data) => {
          this.productCodes = data;
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }

  async getWorkshopConfig() {
    this.isWorkshop =
      await this.operationalConfigService.isWorkshop();
  }

  findCfopOptions(){
    this.unsubscriber.add(
      this.productService.getCfopOptions().subscribe(
        (data) => {
          this.cfopOptions = [];
          data.map(i => {
            this.cfopOptions.push({ label: `${i.value} - ${i.description}`, value: i.value.toString() })
          });
        },
        (error) => {
          console.log(error);
        }
        )
    );
  }

  findNcmDescription(){
    if (this.productForm.get('ncmCod').value == null || this.productForm.get('ncmCod').value == ""){
      return
    }
    if(this.productForm.get('ncmCod').value.length < 8){
      return
    }
    this.focusService.getNCM(this.productForm.get('ncmCod').value).subscribe(
      (data) => {
        if( data.length ==1)
       {
        this.productForm.get('ncmDescription').setValue(data[0].descricao_completa);}
       else
       {this.productForm.get('ncmDescription').setValue("NCM não encontrado")}
      },
      (error) => {
        console.log(error);
      }
    );

  }

  loadImageProduct(): void {
    this.productService.getImageProduct(this.productForm.get('barCode').value).subscribe(
      (data) => {
        this.productForm.get('name').setValue(data.name);
        this.productForm.get('file').setValue(data.urlPhoto);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deletePriceRange(priceRange: PriceRange, index: number): void {
    if (priceRange.endLengthRange && priceRange.startLengthRange) {
      Swal.fire({
        title: 'Atenção',
        text: 'Deseja realmente remover o item: ' + priceRange.startLengthRange + 'ft - ' + priceRange.endLengthRange + 'ft',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.removeRangeAtIndex(index);
        }
      });
    } else {
      this.removeRangeAtIndex(index);
    }
  }

  async getProductCategories(): Promise<void> {
    this.productCategoryService.getAll().subscribe((data) => {
      this.productCategories = data.map((category) => {
            if (this.isWorkshop) {
              return ({ label: category.name, value: category })
            } else if (category.name!="Oficina") {
              return ({ label: category.name, value: category })
            }
            else {
              return null;
        }})
      })
  }

  async findSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.slingConfigService.getSlingConfigToday().subscribe(
          async (slingConfig) => {
            this.slingConfig = slingConfig;
            resolve();
          },
          async (error) => {
            console.log(error)
          }
        );
      }
    );
  }

  validateSave(): void {

    if(this.validateServiceHasServiceBoats()){
      Swal.fire({
        title: `Serviço vinculado`,
        html: (
          `O Serviço não pode ser alterado pois possui vínculo com <b>${this.serviceLinkedBoats.length}</b> embarcações`
        ),
        icon: 'warning',
        showCancelButton: false,
        backdrop: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then((result) => {
        this.spinner.hide();
      })
    }else{
       this.save();
    }
  }

  validateServiceHasServiceBoats(){
    return this.serviceLinkedBoats.length > 0;
  }

  async loadFuels(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.fuelService.findAll().subscribe(
          async (data) => {
            this.fuels = data.map((fuel) => ({ label: fuel.name, value: fuel }));
            resolve();
          },
          async (error) => {
            this.fuels = new Array();
            const exception = error.error.data.exception;
            this.messageService.generateMessage(exception.type, exception.title, exception.message);
            resolve();
          }
        );
      }
    );
  }

  switchpricingType(): void {
    if (this.productForm.get('pricingType').value === PrincingType.SimplePrice) {
      this.isFixedPrice = true;
    } else if (this.productForm.get('productType').value === ProductType.Product) {
      this.isFixedPrice = true;
      this.productForm.get('pricingType').setValue(PrincingType.SimplePrice);
    } else {
      this.isFixedPrice = false;
      this.productForm.get('price.priceFree').setValue(false);
    }
  }

  clearNewRangeFields(): void {
    this.newStartLengthRange = null;
    this.newEndLengthRange = null;
    this.newRangeValue = null;
  }

  priceFree(): void {
    this.productForm.get('price.value').setValue(0);
  }

  save(): void {
    this.loading = true;

    if (((this.productForm.get("issueNotaFiscal").value) && this.productForm.get("productType").value==="Product" )){
      if ((this.productForm.get('ncmDescription').value === null || this.productForm.get('ncmDescription').value === "NCM não encontrado")){
        FormUtil.touchAndSendFormGroup(this.productForm);
        Swal.fire('Alerta!', 'Para emissão de nota fiscal de produtos é obrigatório informar a Nomenclatura comum do Mercosul', 'warning');
        return;
      }
    }

    if ((!this.productForm.valid)) {
      FormUtil.touchAndSendFormGroup(this.productForm);
      Swal.fire('Alerta!', 'Existem campos inválidos ou não informados, verifique os dados.', 'warning');
      return;
    }
    if (this.productForm.get('productCategory').value.id !== 3) {
      this.productForm.get('fuel').setValue(null);
    }
        this.spinner.show();
    if (this.productForm.get('id').value) {
      this.contractAlert();
    } else {
      this.productForm.get('active').setValue(true);
      this.productService.create(this.productForm.getRawValue()).subscribe(
        () => {
          this.spinner.hide();
          this.toasty.success('Cadastro realizado com sucesso!');
          this.dialogRef.close();
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    }
  }

  startForm(): void {
    const marina = new Marina();
    marina.id = StorageUtil.getMarinaId();
    this.productForm = new UntypedFormGroup({
      id: new FormControl<number>({ value: this.product.id, disabled: this.product.id ? false : true }, Validators.required),
      name: new FormControl<string>(this.product.name, Validators.required),
      barCode: new UntypedFormControl(this.product.barCode),
      stock: new UntypedFormControl(this.product.stock, [Validators.required]),
      minimalStock: new UntypedFormControl(this.product.minimalStock, Validators.required),
      // reserved: new FormControl(this.product.reserved),
      file: new UntypedFormControl(this.product.file),
      thumb: new UntypedFormControl(this.product.thumb),
      productType: new UntypedFormControl(this.product.productType, Validators.required),
      measurement: new UntypedFormControl(this.product.measurement, Validators.required),
      price: new UntypedFormGroup({
        id: new UntypedFormControl(this.product.price.id),
        value: new UntypedFormControl(this.product.price.value, Validators.required),
        priceRanges: new UntypedFormArray([]),
        priceFree: new UntypedFormControl(this.product.price.priceFree),
        priceVersion: new UntypedFormControl(this.product.price.priceVersion)
      }),
      pricingType: new UntypedFormControl(this.product.pricingType, Validators.required),
      productCategory: new UntypedFormControl(this.product.productCategory, Validators.required),
      marina: new UntypedFormControl(marina, Validators.required),
      discount: new UntypedFormControl(this.product.discount),
      active: new FormControl<boolean>(this.product.active),
      chartAccountId: new UntypedFormControl(this.product.chartAccountId, Validators.required),
      costCenterId: new UntypedFormControl(this.product.costCenterId),
      fuel: new UntypedFormControl(this.product.fuel, Validators.required),
      showApp: new FormControl<boolean>(this.product.showApp),
      issueReceipt: new FormControl<boolean>(this.product.issueReceipt),
      issueNotaFiscal: new FormControl<boolean>(this.product.issueNotaFiscal),
      vacancyReference: new FormControl<boolean>(this.product.vacancyReference),
      issueNotaFiscalConsumidor: new FormControl<boolean>(this.product.issueNotaFiscalConsumidor),
      appMinimalStock: new UntypedFormControl(this.product.appMinimalStock),
      maxStock: new UntypedFormControl(this.product.maxStock),
      rangeValue: new UntypedFormControl(),
      orderControl: new UntypedFormControl(this.product.orderControl, Validators.required),
      billingCompany: new UntypedFormControl({
        value: this.product.billingCompany === undefined || this.product.billingCompany === null ? null : {...this.product.billingCompany, marina},
        disabled: this.product.id ? true : false
      }),
      ncmCod: new UntypedFormControl(this.product.ncmCod),
      ncmDescription: new UntypedFormControl(this.product.ncmDescription),
      cfop: new UntypedFormControl(this.product.cfop),
      icms: new UntypedFormControl(this.product.icms),
      averageCustPrice: new UntypedFormControl(this.product.averageCustPrice),
      lastCustPrice: new UntypedFormControl(this.product.lastCustPrice),
      markup: new UntypedFormControl(this.product.markup),
      pis: new UntypedFormControl(this.product.pis),
      priceCust: new UntypedFormControl(this.product.priceCust),
      cofins: new UntypedFormControl(this.product.cofins),
      icmsOrigem: new UntypedFormControl(this.product.icmsOrigem),
      icmsSituacaoTributaria: new UntypedFormControl(this.product.icmsSituacaoTributaria),
      icmsBaseCalculo: new UntypedFormControl(this.product.icmsBaseCalculo),
      enableReferenceDate: new UntypedFormControl(this.product.enableReferenceDate),
      pisSituacaoTributaria: new UntypedFormControl(this.product.pisSituacaoTributaria),
      cofinsSituacaoTributaria: new UntypedFormControl(this.product.cofinsSituacaoTributaria),
      ipiSituacaoTributaria: new UntypedFormControl(this.product.ipiSituacaoTributaria),
      ipiCodigoEnquadramento: new UntypedFormControl(this.product.ipiCodigoEnquadramento),
      ipi: new UntypedFormControl(this.product.ipi),
      cest: new UntypedFormControl(this.product.cest),
      codAnp: new UntypedFormControl(this.product.codAnp),
      combustivelPercentualBiodiesel: new UntypedFormControl(this.product.combustivelPercentualBiodiesel),
    });
    if (this.product.price && this.product.price.priceRanges) {

      this.product.price.priceRanges = this.sortPriceRanges();
      this.product.price.priceRanges.forEach((priceRange: PriceRange) => {
        this.addNewRangeValue(priceRange);
      });
      this.calcSuggestPrice();
    }

    this.findNcmDescription();
    // if (this.isNew)
    // {
      // this.slingConfig.pis;
      // this.slingConfig.cofins;
    //  this.productForm.get('pis').setValue(0);
    //  this.productForm.get('cofins').setValue(0);
      //TODO ADICIOMAR PIS E COFINS DO SLINGCONFIG APOS A TASK DE CRIAR NA CONFIGURAÇÃO
    //}
    if (this.productForm.get('pricingType').value !== 'SimplePrice' || this.productForm.get('price.priceFree').value) {
      this.productForm.get('price.value').disable();
    }
  }

  sortPriceRanges(): any[] {
    return this.product.price.priceRanges.sort((a, b) => {
      if (a.startLengthRange > b.startLengthRange) {
        return 1;
      } else if (a.startLengthRange < b.startLengthRange) {
        return -1;
      }
      return 0;
    });
  }

  addNewRangeValue(priceRange?: PriceRange): void {
    if (!priceRange) {
      priceRange = new PriceRange();
    }
    const controls = [...(this.productForm.get('price.priceRanges') as UntypedFormArray).controls];
    if(controls.length > 0){
      const lastRange = controls[controls.length - 1];
      const lastEndLengthRange = lastRange.get('endLengthRange').value;
      if (lastEndLengthRange) {
        priceRange.startLengthRange = lastEndLengthRange + 0.01;
      }
    }
    else{
      priceRange.startLengthRange = 0;
    }


    controls.push(new UntypedFormGroup({
      id: new UntypedFormControl(priceRange.id),
      price: new UntypedFormControl(priceRange.price),
      value: new UntypedFormControl(priceRange.value, Validators.required),
      startLengthRange: new UntypedFormControl(priceRange.startLengthRange, Validators.required),
      endLengthRange: new UntypedFormControl(priceRange.endLengthRange, Validators.required),
    }));
    (this.productForm.get('price') as UntypedFormGroup).setControl('priceRanges', new UntypedFormArray([...controls]));
  }

 updateStartLengthRange(): void {
    const controls = (this.productForm.get('price.priceRanges') as UntypedFormArray).controls;
    controls.forEach((control, index) => {
      if (index === 0) {
        control.get('startLengthRange').setValue(0);
      } else {
        control.get('startLengthRange').setValue(controls[index - 1].get('endLengthRange').value + 0.01);
      }
    });

    (this.productForm.get('price') as UntypedFormGroup).get('priceRanges').patchValue(controls.map((control) => control.value), {emitViewToModelChange: true});
  }

  loadLists(): void {
    this.productTypeOptions = [
      { label: this.messageService.translateKey('PRODUCT'), value: ProductType.Product },
      { label: this.messageService.translateKey('SERVICE'), value: ProductType.Service },
      { label: this.messageService.translateKey('PERIODICAL-SERVICE'), value: ProductType.PeriodicalService },
      { label: this.messageService.translateKey('PERIODICAL-SERVICE-CUSTOMER'), value: ProductType.PeriodicalServiceCustomer }
    ];

    this.measurementOptions = [
      { label: this.messageService.translateKey('UNITS'), value: Measurement.Units },
      { label: this.messageService.translateKey('LITERS'), value: Measurement.Liters },
      { label: this.messageService.translateKey('KILOGRAMS'), value: Measurement.Kilograms },
      { label: this.messageService.translateKey('METERS'), value: Measurement.Meters },
      { label: this.messageService.translateKey('DAYS'), value: Measurement.Days },
      { label: this.messageService.translateKey('HOURS'), value: Measurement.Hours },
    ];

    this.pricingTypeOptions = [
      { label: this.messageService.translateKey('SIMPLE-PRICE'), value: PrincingType.SimplePrice },
      { label: this.messageService.translateKey('FIXED-PER-RANGE'), value: PrincingType.FixedPerRange },
      { label: this.messageService.translateKey('PER-RANGE-LENGTH'), value: PrincingType.PerRangeLength },
    ];
  }

  changeCategory(product?:any): void {
    this.productForm.get('pricingType').enable()
    this.productForm.get('fuel').setValue(null);
    this.productForm.get('stock').disable();
    this.productForm.get('minimalStock').disable();
    this.productForm.get('appMinimalStock').disable();
    this.productForm.get('maxStock').disable();
    this.productForm.get('showApp').disable();
    this.productForm.get('issueReceipt').enable();
    this.productForm.get('discount').disable();
      if (this.productForm.get('productType').value === ProductType.Product) {
        this.productForm.get('stock').enable();
        this.productForm.get('minimalStock').enable();
        this.productForm.get('appMinimalStock').enable();
        this.productForm.get('maxStock').enable();
        this.productForm.get('showApp').enable();
        this.productForm.get('discount').enable();
        this.productForm.get('pricingType').setValue(PrincingType.SimplePrice);
      }
      if (this.productForm.get('productType').value === ProductType.PeriodicalService || this.productForm.get('productType').value === ProductType.PeriodicalServiceCustomer) {
        this.changingCategoryToService();
      }
      if (this.productForm.get('productType').value === ProductType.Service) {
        this.productForm.get('orderControl').setValue(OrderControl.EASYMARINE);
        this.productForm.get('issueReceipt').enable();
      }
      if (this.productForm.get('productCategory').value.productCategoryType === ProductCategoryType.Fuel) {
        this.productForm.get('fuel').enable();
        if(product?.fuel){
          this.productForm.get('fuel').setValue({ id: product.fuel.id, name: product.fuel.name });
        }
      } else {
        this.productForm.get('fuel').disable();
      }
  }

  private changingCategoryToService() {
    const service = this.productCategories.find((c) => c.value.productCategoryType === ProductCategoryType.Service);
    this.productForm.get('productCategory').setValue(service ? service.value : null);
    this.productForm.get('orderControl').setValue(OrderControl.EASYMARINE);
    this.productForm.get('measurement').setValue(Measurement.Units);
    this.productForm.get('issueReceipt').enable();
    if (this.productForm.get('productType').value === ProductType.PeriodicalServiceCustomer){
      this.productForm.get('pricingType').setValue(PrincingType.SimplePrice)
      this.productForm.get('pricingType').disable()
    }
  }

  uploadFile(files): void {
    this.uploadFileInProgress = true;
    this.productService.uploadImage(files).subscribe(
      (result) => {
        this.uploadFileInProgress = false;
        this.productForm.get('file').setValue(result.url);
        this.upload.clear();
      },
      (err) => {
        this.uploadFileInProgress = false;
        console.log(err);
      }
    );
  }

  getClass(): string {
    if (this.productForm && this.productForm.get('productType') && (this.productForm.get('productType').value === 'PeriodicalService'|| this.productForm.get('productType').value === 'PeriodicalServiceCustomer')) {
      return 'readonly';
    }
    return '';
  }

  removeRangeAtIndex(index: number): void {
    const controls = (this.productForm.get('price.priceRanges') as UntypedFormArray).controls;
    controls.splice(index, 1);
    (this.productForm.get('price') as UntypedFormGroup).get('priceRanges').patchValue(controls.map((control) => control.value), {emitViewToModelChange: true});
    this.updateStartLengthRange();
  }

  priceFreeChange(): void {
    if (this.productForm.get('price.priceFree').value) {
      this.productForm.get('price.value').disable();
    } else {
      this.productForm.get('price.value').enable();
    }
  }

  changePriceType(): void {
    if (this.productForm.get('pricingType').value === PrincingType.SimplePrice) {
      this.productForm.setControl('price', new UntypedFormGroup({
        id: new UntypedFormControl(this.product.price.id),
        value: new UntypedFormControl(this.product.price.value, Validators.required),
        priceRanges: new UntypedFormArray([]),
        priceFree: new UntypedFormControl(this.product.price.priceFree),
        priceVersion: new UntypedFormControl(this.product.price.priceVersion)
      }));
    } else {
      this.productForm.setControl('price', new UntypedFormGroup({
        id: new UntypedFormControl(this.product.price.id),
        value: new UntypedFormControl(this.product.price.value),
        priceRanges: new UntypedFormArray((this.rangeList.length > 0 ?  this.rangeList : []), Validators.required),
        priceFree: new UntypedFormControl(this.product.price.priceFree),
        priceVersion: new UntypedFormControl(this.product.price.priceVersion)
      }));
    }
  }


  verifyContract(): void {
    if (!this.product.id || this.product.productType !== ProductType.PeriodicalService  || !this.slingConfig.hasContracts) {
      return;
    }

    const pagination: PaginationFilter = {
      size: 100,
      page: 0,
      order: 'DESC',
      sort: 'createdAt'
    };

    const statusList = [
      DocumentStatus.SIGNED,
      DocumentStatus.WAITING_SIGNATORIES,
      DocumentStatus.WAITING_SIGNATURES,
      DocumentStatus.PROCESSING_SIGNATURES,
      DocumentStatus.PROCESSING_FILE
    ];

    const serviceids:number[] = [];
    serviceids.push(this.product.id);
    this.contractDocumentService.getDocuments({ serviceId: serviceids , statusList, serviceActive: true }, pagination)
      .subscribe((data) => {
        this.documentContracts = data.content.filter(d => d.hasNewer === 0);
      }, (error) => console.log(error));
  }

  contractAlert(): void {
    this.spinner.hide();
    if (this.documentContracts.length > 0) {
      Swal.fire({
        title: this.messageUtil.translateKey('SUMMARY.WARNING'),
        icon: 'warning',
        text: 'Este registro possui contratos. Verifique se eles serão afetados.',
        showCancelButton: true,
        width: 600,
        showDenyButton: true,
        showConfirmButton: true,
        focusDeny: true,
        reverseButtons: true,
        denyButtonText: `Gerenciar contratos`,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveVerifiedService();
        } else if (result.isDenied) {
          this.managerContract();
        }
      });
    } else {
      this.saveVerifiedService();
    }
  }

  managerContract(): void {
    this.dialog.open(VerifyContractsDialogComponent, {
      data: {
        documents: this.documentContracts
      },
      header: 'Gerenciar contratos'
    });
  }

  saveVerifiedService(): void {
    this.spinner.show();
    this.productService.update(this.productForm.getRawValue()).subscribe(
      () => {
        this.spinner.hide();
        this.toasty.success('Registro atualizado com sucesso!');
        this.dialogRef.close();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

  hasInvoiceReceipt(): boolean{
    return this.slingConfig?.generateInvoiceReceipt;
  }

  enableFinancialMultipleCompaniesByProduct(): boolean {
    const enable = this.slingConfig
      && this.slingConfig.financialMultipleCompaniesType === FinancialMultipleCompaniesType.ByProduct
      && this.productForm.value.orderControl === OrderControl.EASYMARINE
      && StorageUtil.getMarinaCompanies().length > 0;
    return enable;
  }

  getBillingCompanyOption(): {label: string, value: { id: number }}[] {
    if (this.billingCompanyOptions === undefined || this.billingCompanyOptions.length <= 0) {
      this.billingCompanyOptions = this.financialMultipleCompaniesService.getBillingCompanyOptions();
      const billingCompany = this.product.billingCompany;
      if (billingCompany !== null) {
        let value = this.billingCompanyOptions.filter((companyOption) => companyOption.value !== null && companyOption.value.id === billingCompany.id);
        if (value && value.length > 0) {
          this.productForm.controls.billingCompany.setValue(value[0].value);
        }
      }
    }
    return this.billingCompanyOptions;
  }
  copy() {
    this.verifyToClose(() => {
      const copy = this.generateCopy();
      this.openModalCopy(copy);
    });
  }


  private openModalCopy(productCopy){
    this.dialog.open(ProductFormComponent, {
      width: '70%',
      dismissableMask: false,
      data: {
        chartAccountsFlatList: this.chartAccountsFlatList,
        costCenters:this.costCenters,
        product: productCopy
      },
      header: 'Cadastro de produto / serviço'
    }).onClose.subscribe( () => this.dialogRef.close());
  }

  private generateCopy() {
    let copy : Product  = DeepCloneUtil.clone(this.productForm.getRawValue());
    copy.price.id = undefined;
    copy.id = undefined;
    copy.barCode = undefined;
    copy.name = copy.name + ' (Copia)';
    copy.price.priceRanges.forEach((priceRange: PriceRange) => {
      priceRange.id = undefined;
    });

    return copy;
  }

  goToLogIn(date) {
    this.verifyToClose(() => {
      this.nav.navigate(['app','logs','boat-log'], { queryParams: { date } });
    });
  }


  verifyToClose(action: Function) {
    Swal.fire({
      title: this.messageUtil.translateKey('SUMMARY.WARNING'),
      icon: 'warning',
      text: ' O produto atual será fechado, Desejas salva-lo?',
      showCloseButton: true,
      showDenyButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      denyButtonColor: '#d33'
    }).then((r) => {
      if(r.isDismissed){
        return;
      }

      if (r.isConfirmed) {
        this.save();
      } else if (r.isDenied){
        this.dialogRef.close();
      }
      action();
    });
  }

  isFuel() {
    if(this.productForm?.get('productCategory').value?.productCategoryType === ProductCategoryType.Fuel){
      return 3;
    }
    return 2;
  }

  isMeasurement() {
    if (this.productForm?.get('measurement').value === Measurement.Liters
        || this.productForm?.get('measurement').value === Measurement.Kilograms) {
      return 3;
    }
    return 0;
  }

  calcSuggestPrice() {

    if ( this.productForm.get("pis").value===null) this.productForm.get("pis").setValue(0);
    if ( this.productForm.get("cofins").value===null) this.productForm.get("cofins").setValue(0);
    if ( this.productForm.get("icms").value===null) this.productForm.get("icms").setValue(0);
    if ( this.productForm.get("ipi").value===null) this.productForm.get("ipi").setValue(0);
    if ( this.productForm.get("markup").value===null) this.productForm.get("markup").setValue(0);
    if ( this.productForm.get("priceCust").value===null) this.productForm.get("priceCust").setValue(0);

    const pc = this.productForm.get('priceCust').value;
    const ma = this.productForm.get('markup').value;

    if (pc === 0 || ma === 0) {
      this.suggestedPrice = 0;
      return;
    }
    let price = (pc *( 1 + ma / 100));
    let taxas = this.productForm.get("pis").value + this.productForm.get("cofins").value + this.productForm.get("icms").value+ this.productForm.get("ipi").value;
    price = (pc*(1+ma/100))/(1-taxas/100);
    // let m =(((price-(price/100 * taxas) )/pc) -1) *100
    let m =((price/pc) -1) *100
    this.suggestedPrice = price;
    this.realMarkup = m;

    this.vlIcms =price*( this.productForm.get("icms").value/100);
    this.vlIpi =price*( this.productForm.get("ipi").value/100);;
    this.vlPis =price*( this.productForm.get("pis").value/100);
    this.vlCofins =price*( this.productForm.get("cofins").value/100);
    this.vlLucro =pc*( this.productForm.get("markup").value/100);

  }
  private checkMarinaHasPeriodicalServiceByCustomer() {
    if (!this.activateTuitionByCustomer){
      this.productTypeOptions.pop()
    }
  }
}
