import { CurrentLocationDTO } from './dtos/movements/boat-movement-dto';
import { BoatSailor } from './boat-sailor';
import { Fuel } from './fuel';
import { TIE } from './tie';
import { DPEM } from './dpem';
import { ShipyardModel } from './shipyard-model';
import { Bow } from './bow';
import { CustomerBoat } from './customer-boat';
import { AccessoryBoat } from './accessory-boat';
import { ServiceBoat } from './service-boat';
import { Vacancy } from './vacancy';
import { Marina } from './marina';
import { InsurerType } from './enums/insurer-type';
import { BoatInsurer } from './boat-insurer';
import { User } from './user';
import { MovementLocation } from './movement/movement-location';
import { BoatEngine } from './boat-engine';
import { SlingQueueType } from './SlingQueueType';


export class Boat {
  id: number;
  name: string;
  tie: TIE;
  dpem: DPEM;
  insurerType: InsurerType;
  shipyardModel: ShipyardModel;
  mainColor: string;
  manufactureYear: number;
  modelYear: number;
  length: number;
  beam: number;
  draft: number;
  commercialLength: number;
  passengersDay: number;
  passengersNight: number;
  cabins: number;
  bathrooms: number;
  bowType: Bow;
  flyBridge: boolean;
  boatCustomers: CustomerBoat[];
  boatAccessories: AccessoryBoat[];
  boatServices: ServiceBoat[];
  movementLocation: MovementLocation;
  vacancy: Vacancy;
  fuel: Fuel;
  marina: Marina;
  active: boolean;
  updateDate: any;
  twoTimeEngine: boolean;
  boatInsurer: BoatInsurer;
  availableOnApp: boolean;
  maintenance: boolean;
  crew: number;
  operator: User;
  boatSailors: BoatSailor[];
  boatEngines: BoatEngine[];
  boatProfile: any;
  chassiHull: String;
  movementGroup: any;
  
  currentLocation: CurrentLocationDTO;

  blocked: boolean;
  oldMovementLocation: MovementLocation;
  changeMovementLocationDate: any;
  independentTanks: boolean;
  owners: string;
  qtdOwners: number;
  contact: string;
  movementGroupIconUrl: string;
  movementGroupName: string;
  apprehend: boolean;
  fullTank: boolean;
  showAllCustomerData: boolean;
  wetVacancy: boolean;
  franchiseNumber: number;
  franchiseGroup: any;
  franchiseUseInCurrentMonth:number;
  boatValue: number;
  slingQueueType: SlingQueueType;

  constructor() {
    // this.tie = new TIE();
    // this.dpem = new DPEM();
    this.shipyardModel = new ShipyardModel();
    this.fuel = new Fuel();
    this.marina = new Marina();
    this.vacancy = new Vacancy();
    this.boatCustomers = [];
    this.boatAccessories = [];
    this.boatServices = [];
    this.flyBridge = false;
    this.insurerType = InsurerType.NOT_VERIFIED;
    this.availableOnApp = true;
    this.wetVacancy = true;
    this.franchiseNumber = 0;
    this.slingQueueType = new SlingQueueType();
  }
}
