<div class="dialog-form">
  <form (submit)="save()" *ngIf="loadedForm" [formGroup]="customerForm">
    <p-tabView>
      <p-tabPanel header="{{ 'DATA' | translate }}">
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">Informações pessoais</div>
          </p-header>
          <div class="form-group separated">
            <div class="separated-left">
              <app-sailor-upload
                [path]="imgPreviewPhoto"
                title="Foto"
                (finishEvent)="receiveUploadUrlPhoto($event)"
              >
              </app-sailor-upload>
              &nbsp;&nbsp;&nbsp;
              <div class="form-control radio">
                <p-radioButton
                  name="federalIdType"
                  formControlName="federalIdType"
                  value="Physical"
                  label="{{ 'FEDERAL-ID.PHYSICAL' | translate }}"
                  inputId="federalIdPhysical"
                  [(ngModel)]="federalIdType"
                  (onClick)="validateFederalIdType()"
                >
                </p-radioButton>
                <p-radioButton
                  name="federalIdType"
                  formControlName="federalIdType"
                  value="Juridical"
                  label="{{ 'FEDERAL-ID.JURIDICAL' | translate }}"
                  inputId="federalIdJuridical"
                  [(ngModel)]="federalIdType"
                  (onClick)="validateFederalIdType()"
                >
                </p-radioButton>
                <p-radioButton
                  name="federalIdType"
                  formControlName="federalIdType"
                  value="Foreigner"
                  label="{{ 'FEDERAL-ID.FOREIGNER' | translate }}"
                  inputId="federalIdForeigner"
                  [(ngModel)]="federalIdType"
                  (onClick)="validateFederalIdType()"
                >
                </p-radioButton>
              </div>
            </div>

            <div class="separated-right">
              <div
                class="user-app blink aligned-up"
                *ngIf="userApp; else notUser"
              >
                <img
                  src="assets/images/mobile.png"
                  alt=""
                  style="height: 30px; width: auto"
                />
                &nbsp;Usuário do App Cliente&nbsp;
              </div>
              <ng-template #notUser>
                <div class="user-app aligned-up">&nbsp;&nbsp;</div>
              </ng-template>
              <div class="form-control" *ngIf="customer.id">
                <input
                  pInputText
                  placeholder="Id"
                  name="id"
                  formControlName="id"
                  readonly
                />
              </div>
              <div class="form-control" formGroupName="customerForm" *ngIf="customerForm?.controls?.titleNumber.value !=null ">
                <label for="formTitleProperty">Número de título</label>
                <input
                pInputText
                placeholder="Número de título"
                [formControl]="customerForm.get('titleNumber')"
                (blur)="onchangeTitleProperty()"
                readonly
              />
              </div>

              <div class="form-control field" *ngIf="customerForm?.controls?.titleNumber.value !=null "> 
                <label for="formGroupName">Tipo de título</label>
                <input
                pInputText
                placeholder="Número de título"
                [value]="customerForm.get('formTitleProperty').value.name"
                readonly
                />
              </div>
  
              <div class="form-control field" >
                
                <label for="accessGroup">Grupo de Acesso</label>
                <p-dropdown [options]="accessGroupOptions" name="dueDate" formControlName="accessGroup" optionLabel="label"
                            appendTo="body" [pTooltip]="getErrorTooltip(customerForm.get('accessGroup'))" (onChange)="validateRequiredFields()"
                            tooltipPosition="bottom">
                </p-dropdown>
                <small *ngIf="customerForm?.controls?.accessGroup?.errors?.required" class="p-error block">Grupo de Acesso Obrigatório</small>
            </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-control">
              <label for="name">{{ nameLabel | translate }} *</label>
              <input
                pInputText
                name="name"
                formControlName="name"
                maxlength="255"
                [pTooltip]="getErrorTooltip(customerForm.get('name'))"
                tooltipPosition="bottom"
              />
              <small
                *ngIf="
                  customerForm?.controls?.name.touched &&
                  customerForm?.controls?.name?.errors?.required
                "
                class="p-error block"
                >Nome Obrigatório</small
              >
            </div>

            <div class="form-control" *ngIf="isFederalTypePhysical">
              <label for="name"
                >{{ "FEDERAL-ID.PHYSICAL" | translate }} *</label
              >
              <p-inputMask
                type="text"
                mask="999.999.999-99"
                name="federalId"
                formControlName="federalId"
                (onBlur)="checkFederalIdField()"
                [pTooltip]="getErrorTooltip(customerForm.get('federalId'))"
                tooltipPosition="bottom"
              >
              </p-inputMask>
              <small
                *ngIf="
                  customerForm.controls?.federalId.touched &&
                  customerForm.controls?.federalId?.errors?.invalidFederalId
                "
                class="p-error block"
                >CPF inválido</small
              >
              <small
                *ngIf="
                  customerForm.controls?.federalId.touched &&
                  customerForm.controls?.federalId?.errors?.federalIdInUse
                "
                class="p-error block"
                >Usuário já cadastrado</small
              >
              <small
                *ngIf="
                  customerForm?.controls?.federalId.touched &&
                  customerForm?.controls?.federalId?.errors?.required
                "
                class="p-error block"
                >CPF obrigatório</small
              >
            </div>

            <div class="form-control" *ngIf="isFederalTypeForeigner">
              <label for="name"
                >{{ "FEDERAL-ID.FOREIGNER" | translate }} *</label
              >
              <input
                pInputText
                type="text"
                name="federalId"
                formControlName="federalId"
                (onBlur)="checkFederalIdField()"
                [pTooltip]="getErrorTooltip(customerForm.get('federalId'))"
                tooltipPosition="bottom"
              />
              <small
                *ngIf="
                  customerForm?.controls?.federalId?.touched &&
                  customerForm.controls?.federalId?.errors?.invalidFederalId
                "
                class="p-error block"
                >Doc inválido</small
              >
              <small
                *ngIf="
                  customerForm?.controls?.federalId?.touched &&
                  customerForm.controls?.federalId?.errors?.federalIdInUse
                "
                class="p-error block"
                >Usuário já cadastrado</small
              >
              <small
                *ngIf="
                  customerForm?.controls?.federalId?.touched &&
                  customerForm?.controls?.federalId?.errors?.required
                "
                class="p-error block"
                >Doc obrigatório</small
              >
            </div>
            <div class="form-control">
              <label for="rg">{{ registrationLabel | translate }} </label>
              <ng-container *ngIf="!isFederalTypeForeigner; else maskedInput">
                <input
                  pInputText
                  name="rg"
                  formControlName="registration"
                  maxlength="50"
                />
              </ng-container>
              <ng-template #maskedInput>
                <p-inputMask
                  type="text"
                  mask="999.999.999-99"
                  name="rg"
                  formControlName="registration"
                  [required]="isFederalTypePhysical"
                  tooltipPosition="bottom"
                ></p-inputMask>
                <small
                  *ngIf="
                    customerForm.controls?.registration.touched &&
                    customerForm.controls?.registration?.errors?.required
                  "
                  class="p-error block"
                  >Campo obrigatório</small>
                <small
                  *ngIf="
                    customerForm.controls?.registration.touched &&
                    customerForm.controls?.registration?.errors?.pattern
                  "
                  class="p-error block"
                  >CPF inválido</small>
              </ng-template>
            </div>

            <ng-template #isFederalIdJuridical>
              <div class="form-control">
                <label for="federalId">{{
                  "FEDERAL-ID.JURIDICAL" | translate
                }}</label>
                <p-inputMask
                  type="text"
                  mask="99.999.999/9999-99"
                  name="federalId"
                  #federalId
                  formControlName="federalId"
                  (onBlur)="checkFederalIdField()"
                  [pTooltip]="getErrorTooltip(customerForm.get('federalId'))"
                  tooltipPosition="bottom"
                >
                </p-inputMask>
              </div>
            </ng-template>
          </div>
          <div class="form-group">
            <div
              class="form-control"
              formGroupName="group"
              *ngIf="
                isFederalTypePhysical || isFederalTypeForeigner;
                else isFederalIdJuridical
              "
            >
              <!-- 
            MARCELAO ORGANIZOU ESSA BIROSCA
            -->
              <label for="gender">{{ "SEX" | translate }}</label>
              <p-dropdown
                [options]="[
                  { label: 'Masculino', value: 'M' },
                  { label: 'Feminino', value: 'F' }
                ]"
                name="gender"
                formControlName="gender"
                [(ngModel)]="gender"
                appendTo="body"
                [pTooltip]="getErrorTooltip(customerForm.get('gender'))"
                tooltipPosition="bottom"
              >
              </p-dropdown>

              <!-- 
              <p-dropdown
                [options]="maritalStatus"
              ></p-dropdown>
            </div> -->
            </div>
            <div class="form-control">
              <label for="birthday">{{ dateLabel | translate }}</label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="birthday"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="'1940:2055'"
                rPCalendarMask
                appendTo="body"
                formControlName="birthday"
                [showIcon]="true"
                i18n
              >
              </p-calendar>
            </div>
            <div
              class="form-control"
              *ngIf="isFederalTypePhysical || isFederalTypeForeigner"
            >
              <label for="maritalStatus">{{
                "MARITAL STATUS" | translate
              }}</label>
              <p-dropdown
                [options]="maritalStatus"
                placeholder="Selecione"
                name="maritalStatus"
                formControlName="maritalStatus"
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group">
            <div
              class="form-control"
              *ngIf="isFederalTypePhysical || isFederalTypeForeigner"
            >
              <label for="spouse">{{ "SPOUSE" | translate }}</label>
              <input
                pInputText
                name="spouse"
                formControlName="spouse"
                maxlength="50"
              />
            </div>
            <div class="form-control">
              <label for="profession">{{ "PROFESSION" | translate }}</label>
              <input
                pInputText
                name="profession"
                formControlName="profession"
                maxlength="50"
              />
            </div>
            <div class="form-control" formGroupName="group">
              <label for="group">{{ "Grupo de Faturamento" | translate }}</label>
              <p-dropdown
                [options]="groups"
                name="group"
                formControlName="id"
                appendTo="body"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="form-group">
            <div class="form-control">
              <label for="email">E-mail pessoal (Login app) *</label>
              <input
                pInputText
                name="email"
                formControlName="email"
                type="email"
                maxlength="50"
                id="email"
                [pTooltip]="getErrorTooltip(customerForm.get('email'))"
                tooltipPosition="bottom"
              />
              <small
                *ngIf="
                  customerForm?.controls?.email.touched &&
                  customerForm?.controls?.email?.errors?.required
                "
                class="p-error block"
                >E-mail Obrigatório</small
              >
            </div>
            <div class="form-control">
              <label for="emailCollection"
                >{{ "EMAIL-COLLECTION" | translate }} *</label
              >
              <input
                pInputText
                name="emailCollection"
                formControlName="emailCollection"
                type="email"
                maxlength="255"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('emailCollection'))
                "
                tooltipPosition="bottom"
              />
              <small
                *ngIf="
                  customerForm?.controls?.emailCollection.touched &&
                  customerForm?.controls?.emailCollection?.errors?.required
                "
                class="p-error block"
                >E-mail de Cobrança Obrigatório</small
              >
            </div>
            <div class="form-control">
              <label for="phone1">{{ "CELLPHONE" | translate }} 1 *</label>
              <p-inputMask
                type="text"
                mask="+99 (99) 99999-9999"
                name="phone1"
                formControlName="phone"
                [pTooltip]="getErrorTooltip(customerForm.get('phone'))"
                tooltipPosition="bottom"
              >
              </p-inputMask>
              <small
                *ngIf="
                  customerForm?.controls?.phone.touched &&
                  customerForm?.controls?.phone?.errors?.required
                "
                class="p-error block"
                >Celular Obrigatório</small
              >
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <label for="phone2">{{ "CELLPHONE" | translate }} 2</label>
              <p-inputMask
                type="text"
                mask="+99 (99) 99999-9999"
                name="phone2"
                formControlName="phone2"
              >
              </p-inputMask>
            </div>
            <div class="form-control">
              <label for="residentialPhone">{{
                "RESIDENTIAL-PHONE" | translate
              }}</label>
              <p-inputMask
                type="text"
                mask="(99) 9999-9999"
                name="residentialPhone"
                formControlName="residentialPhone"
              >
              </p-inputMask>
            </div>
            <div class="form-control">
              <label for="commercialPhone">{{
                "COMMERCIAL-PHONE" | translate
              }}</label>
              <p-inputMask
                type="text"
                mask="(99) 9999-9999"
                name="commercialPhone"
                formControlName="commercialPhone"
              >
              </p-inputMask>
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <label for="billingDay">Início Faturamento</label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="billingDay"
                formControlName="billingDay"
                [showIcon]="true"
                i18n
                [monthNavigator]="true"
                [yearNavigator]="true"
                appendTo="body"
                [yearRange]="'2000:2055'"
                rPCalendarMask
              >
              </p-calendar>
            </div>
            <div class="form-control">
              <label for="dueDate">Dia do vencimento *</label>
              <p-dropdown
                [options]="availableDays"
                name="dueDate"
                formControlName="dueDate"
                appendTo="body"
                [pTooltip]="getErrorTooltip(customerForm.get('dueDate'))"
                tooltipPosition="bottom"
              >
              </p-dropdown>
              <small
                *ngIf="
                  customerForm?.controls?.dueDate.touched &&
                  customerForm?.controls?.dueDate?.errors?.required
                "
                class="p-error block"
                >Dia de Vencimento Obrigatório</small
              >
            </div>

            <div class="form-control" *ngIf="isWorkshop">
              <label for="customerProfile">{{
                "CUSTOMER_PROFILE" | translate
              }}</label>
              <p-dropdown
                [(options)]="customerProfile"
                name="customerProfile"
                formControlName="customerProfile"
              ></p-dropdown>
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset
          formGroupName="residentialAddress"
          *ngIf="isFederalTypePhysical || isFederalTypeForeigner"
        >
          <p-header class="article-title">
            <div class="article-title">
              {{ "RESIDENTIAL-ADDRESS" | translate }}
            </div>
          </p-header>
          <div class="form-group">
            <div class="form-control field">
              <label for="residentialAddressZip"
                >{{ "ZIP" | translate }} *</label
              >
              <p-inputMask
                type="text"
                mask="99999-999"
                name="residentialAddressZip"
                formControlName="zip"
                (onBlur)="getCep('residential')"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('residentialAddress.zip'))
                "
                tooltipPosition="bottom"
              >
              </p-inputMask>
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.zip
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.zip?.errors
                    ?.required
                "
                class="p-error block"
                >CEP Obrigatório</small
              >
            </div>
            <div class="form-control field">
              <label for="country">{{ "COUNTRY" | translate }} *</label>
              <p-dropdown
                [options]="countries"
                name="country"
                appendTo="body"
                (onChange)="loadStates(customer.residentialAddress.country)"
                formControlName="country"
                [pTooltip]="
                  getErrorTooltip(
                    customerForm.get('residentialAddress.country')
                  )
                "
                tooltipPosition="bottom"
              >
              </p-dropdown>
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.country
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.country
                    ?.errors?.required
                "
                class="p-error block"
                >País Obrigatório</small
              >
            </div>
            <div class="form-control field">
              <label for="state">{{ "FS" | translate }} *</label>
              <p-dropdown
                [options]="states"
                name="state"
                appendTo="body"
                (onChange)="
                  loadCities(
                    customer.residentialAddress.country,
                    customer.residentialAddress.state
                  )
                "
                formControlName="state"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('residentialAddress.state'))
                "
                tooltipPosition="bottom"
              >
              </p-dropdown>
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.state
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.state
                    ?.errors?.required
                "
                class="p-error block"
                >Estado Obrigatório</small
              >
            </div>
          </div>
          <div class="form-group">
            <div class="form-control field">
              <label for="city">{{ "CITY" | translate }} *</label>
              <p-autoComplete
                formControlName="city"
                [suggestions]="filteredCities"
                autoHighlight="true"
                name="city"
                (completeMethod)="filterCities($event)"
                appendTo="body"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('residentialAddress.city'))
                "
                tooltipPosition="bottom"
              >
              </p-autoComplete>
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.city
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.city?.errors
                    ?.required
                "
                class="p-error block"
                >Cidade Obrigatória</small
              >
            </div>
            <div class="form-control field">
              <label for="residentialAddressStreet"
                >{{ "STREET" | translate }} *</label
              >
              <input
                pInputText
                name="residentialAddressStreet"
                formControlName="street"
                maxlength="50"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('residentialAddress.street'))
                "
                tooltipPosition="bottom"
              />
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.street
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.street
                    ?.errors?.required
                "
                class="p-error block"
                >Rua Obrigatória</small
              >
            </div>
          </div>
          <div class="form-group field">
            <div class="form-control">
              <label for="residentialAddressDistrict"
                >{{ "DISTRICT" | translate }} *</label
              >
              <input
                pInputText
                name="residentialAddressDistrict"
                formControlName="district"
                maxlength="50"
              />
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.district
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.district
                    ?.errors?.required
                "
                class="p-error block"
                >Bairro Obrigatório</small
              >
            </div>
            <div class="form-control">
              <label for="residentialAddressNumber"
                >{{ "NUMBER" | translate }} *</label
              >
              <input
                pInputText
                name="residentialAddressNumber"
                formControlName="number"
                maxlength="10"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('residentialAddress.number'))
                "
                tooltipPosition="bottom"
              />
              <small
                *ngIf="
                  customerForm?.get('residentialAddress')?.controls?.number
                    .touched &&
                  customerForm?.get('residentialAddress').controls?.number
                    ?.errors?.required
                "
                class="p-error block"
                >Número Obrigatório</small
              >
            </div>
            <div class="form-control">
              <label for="residentialAddressComplement">{{
                "COMPLEMENT" | translate
              }}</label>
              <input
                pInputText
                name="residentialAddressComplement"
                formControlName="complement"
                maxlength="50"
              />
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset formGroupName="commercialAddress">
          <p-header class="article-title">
            <div class="article-title">
              {{ "COMMERCIAL-ADDRESS" | translate }}
            </div>
          </p-header>
          <div class="form-group">
            <div class="form-control">
              <label for="commercialAddressZip"
                >{{ "ZIP" | translate
                }}{{
                  !isFederalTypePhysical && !isFederalTypeForeigner ? " *" : ""
                }}</label
              >
              <p-inputMask
                type="text"
                mask="99999-999"
                name="commercialAddressZip"
                formControlName="zip"
                (onBlur)="getCep('commercial')"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('commercialAddress.zip'))
                "
                tooltipPosition="bottom"
              >
              </p-inputMask>
            </div>
            <div class="form-control">
              <label for="commercialAddressCountry"
                >{{ "COUNTRY" | translate
                }}{{
                  !isFederalTypePhysical && !isFederalTypeForeigner ? " *" : ""
                }}</label
              >
              <p-dropdown
                [options]="countries"
                name="commercialAddressCountry"
                appendTo="body"
                (onChange)="loadStates(customer.commercialAddress.country)"
                formControlName="country"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('commercialAddress.country'))
                "
                tooltipPosition="bottom"
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="commercialAddresState"
                >{{ "FS" | translate
                }}{{
                  !isFederalTypePhysical && !isFederalTypeForeigner ? " *" : ""
                }}</label
              >
              <p-dropdown
                [options]="states"
                name="commercialAddresState"
                appendTo="body"
                (onChange)="
                  loadCities(
                    customer.commercialAddress.country,
                    customer.commercialAddress.state
                  )
                "
                formControlName="state"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('commercialAddress.state'))
                "
                tooltipPosition="bottom"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <label for="commercialAddressCity"
                >{{ "CITY" | translate
                }}{{
                  !isFederalTypePhysical && !isFederalTypeForeigner ? " *" : ""
                }}</label
              >
              <p-autoComplete
                formControlName="city"
                [suggestions]="filteredCities"
                autoHighlight="true"
                name="commercialAddressCity"
                (completeMethod)="filterCities($event)"
                appendTo="body"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('commercialAddress.city'))
                "
                tooltipPosition="bottom"
              >
              </p-autoComplete>
            </div>
            <div class="form-control">
              <label for="commercialAddressStreet"
                >{{ "STREET" | translate
                }}{{
                  !isFederalTypePhysical && !isFederalTypeForeigner ? " *" : ""
                }}</label
              >
              <input
                pInputText
                name="commercialAddressStreet"
                formControlName="street"
                maxlength="50"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('commercialAddress.street'))
                "
                tooltipPosition="bottom"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <label for="commercialAddressDistrict">{{
                "DISTRICT" | translate
              }}</label>
              <input
                pInputText
                name="commercialAddressDistrict"
                formControlName="district"
                maxlength="50"
              />
            </div>
            <div class="form-control">
              <label for="commercialAddressNumber"
                >{{ "NUMBER" | translate
                }}{{
                  !isFederalTypePhysical && !isFederalTypeForeigner ? " *" : ""
                }}</label
              >
              <input
                pInputText
                name="commercialAddressNumber"
                formControlName="number"
                maxlength="10"
                [pTooltip]="
                  getErrorTooltip(customerForm.get('commercialAddress.number'))
                "
                tooltipPosition="bottom"
              />
            </div>
            <div class="form-control">
              <label for="commercialAddressComplement">{{
                "COMPLEMENT" | translate
              }}</label>
              <input
                pInputText
                name="commercialAddressComplement"
                formControlName="complement"
                maxlength="50"
              />
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">
              {{ "EMERGENCY-CONTACT" | translate }}
            </div>
          </p-header>
          <div class="form-group">
            <div class="form-control">
              <label for="emergencyContact">{{
                "EMERGENCY-CONTACT" | translate
              }}</label>
              <input
                pInputText
                name="emergencyContact"
                formControlName="emergencyContact"
                maxlength="50"
              />
            </div>
            <div class="form-control">
              <label for="emergencyContact">{{
                "EMERGENCY-PHONE" | translate
              }}</label>
              <p-inputMask
                type="text"
                mask="+99 (99) 99999-9999"
                name="emergencyPhone"
                formControlName="emergencyPhone"
              >
              </p-inputMask>
            </div>
          </div>
          <div class="form-group">
            <div class="form-control basic">
              <label for="description">Observações</label>
              <textarea
                pInputText
                name="description"
                rows="5"
                cols="90"
                formControlName="observation"
              ></textarea>
            </div>
          </div>
        </p-fieldset>
      </p-tabPanel>
      <p-tabPanel
        header="{{ 'LICENCE' | translate }}"
        [disabled]="!isFederalTypePhysical && !isFederalTypeForeigner"
      >
        <p-fieldset formGroupName="licence">
          <p-header class="article-title">
            <div class="article-title">Habilitação</div>
          </p-header>
          <div class="form-group">
            <div class="form-control">
              <label for="register">{{ "REGISTER" | translate }}</label>
              <input
                pInputText
                name="register"
                formControlName="register"
                maxlength="50"
              />
            </div>
            <div class="form-control">
              <label for="expiration">{{ "Validade" | translate }}</label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="expiration"
                formControlName="expiration"
                appendTo="body"
                [showIcon]="true"
                i18n
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="'2000:2055'"
                rPCalendarMask
              >
              </p-calendar>
            </div>
            <div class="form-control">
              <label for="typeLicense">{{
                "Tipo de habilitação" | translate
              }}</label>
              <p-dropdown
                [options]="typesLicense"
                name="typeLicense"
                formControlName="typeLicense"
                appendTo="body"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="form-group">
            <div class="form-control">
              <label for="licenceCountry">{{ "COUNTRY" | translate }}</label>
              <p-dropdown
                [options]="countries"
                name="licenceCountry"
                appendTo="body"
                (onChange)="loadStates(customerForm.value.licence.country)"
                formControlName="country"
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="licenceState">{{ "FS" | translate }}</label>
              <p-dropdown
                [options]="states"
                name="licenceState"
                appendTo="body"
                (onChange)="
                  loadCities(
                    customerForm.value.licence.country,
                    customerForm.value.licence.state
                  )
                "
                formControlName="state"
              >
              </p-dropdown>
            </div>
            <div class="form-control">
              <label for="licenceCity">{{ "CITY" | translate }}</label>
              <p-autoComplete
                formControlName="city"
                [suggestions]="filteredCities"
                autoHighlight="true"
                appendTo="body"
                name="licenceCity"
                (completeMethod)="filterCities($event)"
              >
              </p-autoComplete>
            </div>
          </div>
        </p-fieldset>
        <br />

        <p-fieldset formGroupName="licence">
          <p-header class="article-title">
            <div class="article-title">{{ "FILES" | translate }}</div>
          </p-header>
          <div class="table" *ngIf="customerForm" formArrayName="files">
            <p-table
              [value]="customerForm.get('licence.files').controls"
              [rows]="5"
              [paginator]="true"
              autoLayout="true"
              alwaysShowPaginator="false"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ "NAME" | translate }}</th>
                  <th class="flex justify-content-center">
                    <!-- <button class="fab-button primary" type="button" 
                                            pTooltip="Adicionar arquivo"
                                            tooltipPosition="bottom" >
                                            <i class="pi pi-plus"></i></button>-->

                    <p-fileUpload
                      name="attachment"
                      mode="basic"
                      auto="true"
                      maxFileSize="100000000"
                      chooseLabel="Anexar"
                      accept=".png,.jpg,.jpeg,.pdf"
                      #upload
                      [auto]="true"
                      customUpload="true"
                      (uploadHandler)="onBasicUpload($event.files)"
                    >
                    </p-fileUpload>
                    <p-progressSpinner
                      [hidden]="!uploadLicenceFileInProgress"
                      [style]="{ width: '25px', height: '25px' }"
                    >
                    </p-progressSpinner>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-file let-rowIndex="rowIndex">
                <tr [formGroupName]="rowIndex">
                  <td>
                    <input
                      pInputText
                      name="name"
                      formControlName="name"
                      maxlength="255"
                    />
                  </td>
                  <td class="flex-row justify-content-center">
                    <div
                      style="color: blue"
                      class="m-1 table-icon cursor-pointer"
                      pTooltip="Ver arquivo"
                      (click)="openFile(file.value)"
                    >
                      <i class="pi pi-eye"></i>
                    </div>

                    <div
                      style="color: red"
                      class="m-1 table-icon cursor-pointer"
                      pTooltip="Remover arquivo"
                      (click)="deleteFile(rowIndex)"
                    >
                      <i class="pi pi-trash"></i>
                    </div>
                  </td>

                  <!-- <td class="text-center" *ngIf="!isMovimentOperation">
                                        <p-inputSwitch formControlName="viewHistory"
                                            name="sharedInvoiceCustomerApp"
                                            pTooltip="Permitir visualização do histórico da embarcação?"
                                            tooltipPosition="bottom">
                                        </p-inputSwitch>
                                    </td> -->
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-fieldset>

        <!-- <p-fieldset>
                    <p-header class="article-title">
                        <div class="article-title">{{'IMAGE' | translate}}</div>
                    </p-header>
                    <div class="form-group">
                        <div class="form-control">
                            <p-fileUpload name="attachment" mode="basic" auto="true" maxFileSize="100000000"
                                chooseLabel="Anexar" accept=".png,.jpg,.jpeg,.pdf" #upload [auto]="true" customUpload="true"
                                (uploadHandler)="onBasicUpload($event.files)">
                            </p-fileUpload>
                            <p-progressSpinner [hidden]="!uploadLicenceFileInProgress"
                                [style]="{ width: '25px', height: '25px' }">
                            </p-progressSpinner>
                        </div>
                        <div class="form-control">

                            <a *ngIf="customerForm?.value?.licence?.file" href="{{customerForm?.value?.licence?.file}}" target="_blank" >

                                <img *ngIf="!uploadIsPdf" src="{{customerForm?.value?.licence?.file}}" width="128">
                                <i *ngIf="uploadIsPdf" class="pi pi-file"></i> 
                                <pdf-viewer *ngIf="uploadIsPdf" [src]="this.licensePdf"
                                [render-text]="true"
                                [original-size]="false"
                                style="width: 400px; height: 500px"
                                ></pdf-viewer>


                            </a>

                          <ng-template #showDocPDF>
                            <a [href]="customerForm?.value?.licence?.file" target="_blank">
                              <i class="pi pi-file-pdf" style="font-size: 50px;"></i>
                            </a>
                            <a [href]="customerForm?.value?.licence?.file" target="_blank">
                              {{getNameDocumentFile(customerForm?.value?.licence?.file)}}
                            </a>
                          </ng-template>
                        </div>
                    </div>
                </p-fieldset> -->
      </p-tabPanel>
      <p-tabPanel header="{{ 'BOATS' | translate }}" *ngIf="customer?.id">
        <ng-container *ngFor="let boat of customer.boatCustomersDTO">
          <p-fieldset class="boats">
            <p-header class="article-title">
              <a
                class="article-title"
                (click)="closeaDialog()"
                [routerLink]="['/app/forms/boat']"
                [queryParams]="{ boatId: boat.boatId }"
              >
                <div>{{ boat.boatName }}</div>
              </a>
              <div
                *ngIf="!boat.active"
                style="color: red"
                class="article-title"
              >
                <span>{{ "INACTIVE-BOAT" | translate }}</span>
              </div>
              <button
                class="primary fab-button"
                (click)="viewDocumentation(boat)"
                [disabled]="!boat.tieFile"
                pTooltip="Ver documento"
                tooltipPosition="bottom"
                type="button"
              >
                <i class="pi pi-eye" style="font-size: 21px"></i>
              </button>
            </p-header>
            <div class="table">
              <p-table [value]="boat.customers">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Proprietário</th>
                    <th>Grupo</th>
                    <th>Rateio</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                  <tr *ngIf="customer.customerType == 'Owner'">
                    <td>{{ customer.name }}</td>
                    <td>{{ customer.groupName }}</td>
                    <td>{{ customer.quota }}%</td>
                  </tr>
                </ng-template>
              </p-table>
              <br />
              <p-table
                [value]="boat.services"
                *ngIf="boat.services.length !== 0"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Serviço</th>
                    <th>Valor</th>
                    <th>Desconto</th>
                    <th>Total</th>
                    <th>Observação</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-service>
                  <tr>
                    <td>{{ service.service }}</td>
                    <td>{{ service.value | number : "1.2-2" }}</td>
                    <td>{{ service.discount | number : "1.2-2" }}</td>
                    <td>
                      {{ service.value - service.discount | number : "1.2-2" }}
                    </td>
                    <td>{{ service.observation }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-fieldset>
          <br />
        </ng-container>
      </p-tabPanel>
      <p-tabPanel header="Faturamento">
        <p-fieldset *ngIf="enableFinancialCompanyOptions()">
          <p-header class="article-title">
            <div class="article-title">Empresa Faturamento</div>
          </p-header>

          <div class="form-group">
            <div class="form-control">
              <label for="billingCompanyOptionId">{{
                "Empresa Faturamento" | translate
              }}</label>
              <p-dropdown
                [options]="billingCompanyOptions"
                name="billingCompanyOptionId"
                appendTo="body"
                formControlName="billingCompanyOptionId"
              >
              </p-dropdown>
            </div>
          </div>
        </p-fieldset>
        <br />
        <p-fieldset>
          <p-header class="article-title">
            <div class="article-title">Nota Fiscal</div>
          </p-header>

          <div class="form-group">
            <div class="form-control">
              <label for="issRetidoFonte">{{ "ISS-RETIDO" | translate }}</label>
              <p-inputSwitch
                name="issRetidoFonte"
                formControlName="issRetidoFonte"
              >
              </p-inputSwitch>
            </div>
          </div>
        </p-fieldset>
      </p-tabPanel>
    </p-tabView>
    <div class="form-group align-center">
      <div class="form-control">
        {{ "REQUIRED-FIELDS" | translate }}
      </div>
      <div class="form-control" *ngIf="customer?.id">
        {{ "UPDATE-DATE" | translate }}
        {{ customer.updateDate | date : "dd/MM/yyyy" }}
      </div>
      <div class="buttons">
        <button
          type="button"
          (click)="dialogRef.close()"
          class="easymarine danger"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" type="submit">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
