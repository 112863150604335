import { OrderItem } from './../../order-item';
import { FuelTankFraction } from "../../enums";

export class OrderItemApi2 {
      id: number;
      qtyRequested: number;
      qtySupplied: number;
      itemValue: number;
      totalValue: number;
      itemStatus: boolean;
      itemObs: string;
      fuelTankFraction: FuelTankFraction;
      fuelMeasurementType: string;
      productId: number;
      qtyStock: number;
      marinaOrderId: number;
      dateSupply?: any;
      createdAt?: any;
      dateReference: Date;
      duedateDiscount: number;


      constructor(orderItem: OrderItem) {
            this.id = orderItem.id;
            this.qtyRequested = orderItem.qtyRequested;
            this.qtySupplied = orderItem.qtySupplied;
            this.itemValue = orderItem.itemValue;
            this.totalValue = orderItem.totalValue;
            this.itemStatus = orderItem.itemStatus;
            this.itemObs = orderItem.itemObs;
            this.fuelTankFraction = orderItem.fuelTankFraction;
            this.fuelMeasurementType = orderItem.fuelMeasurementType;
            this.productId = orderItem.product.id;
            this.qtyStock = orderItem.qtyStock;
            this.marinaOrderId = orderItem.marinaOrder.id;
            this.dateSupply = orderItem.dateSupply;
            this.createdAt = orderItem.createdAt;
            this.dateReference = orderItem.dateReference;
            this.duedateDiscount = orderItem.duedateDiscount;
      }

}
