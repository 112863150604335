<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'BOATS-LIST' | translate}}</span>
        <div class="header-buttons">
            <button class="primary fab-button" (click)="openBoatFormDialog()" pTooltip="Adicionar nova embarcação"
                tooltipPosition="bottom">
                <i class="pi pi-plus" style="font-size: 21px;"></i>
            </button>
            <button class="fab-button primary" (click)="exportTable()" label="Exportar valores"
                pTooltip="Exportar Valores" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>
            <button class="fab-button primary" (click)="changeQueue()" label="Alterar Fila"
            pTooltip="Alterar Fila" tooltipPosition="left">
            <i class="pi pi-refresh"></i>
        </button>
            <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label for="boatName">{{'BOAT' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="boatFilter.boatName" name="boatName">
            </div>
            <div class="form-control">
                <label for="shipType">{{'TYPE' | translate}}</label>
                <p-dropdown [options]="shipTypeOptions" [(ngModel)]="boatFilter.shipType" name="shipType"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="shipType">{{'OWNER' | translate}}</label>
                <p-dropdown [options]="this.filteredCustomers" [filter]="true" [(ngModel)]="boatFilter.boatOwner" name="boatOwner"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="shipyardName">{{'SHIPYARD' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="boatFilter.shipyardName" name="shipyardName">
            </div>
            <div class="form-control">
                <label for="shipyardModelName">{{'MODEL' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="boatFilter.shipyardModelName" name="shipyardModelName">
            </div>
            <div class="form-control">
                <label for="tieRegister">{{'REGISTER' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="boatFilter.tieRegister" name="tieRegister">
            </div>
            <div class="form-control">
                <label for="dateStart">{{'EXPIRATION-START' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateStart" rPCalendarMask appendTo="body"
                    [(ngModel)]="boatFilter.expirationStart" [showIcon]="true" i18n [monthNavigator]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'">
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="dateEnd">{{'EXPIRATION-END' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateEnd" rPCalendarMask [(ngModel)]="boatFilter.expirationEnd"
                    appendTo="body" [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2019:2030'">
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="reviewed">{{'REVIEWED' | translate}}</label>
                <p-dropdown [(ngModel)]="boatFilter.reviewed" name="reviewed" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="active">{{'STATUS' | translate}}</label>
                <p-dropdown [(ngModel)]="boatFilter.active" name="active" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="vacancy">{{'Vagas' | translate}}</label>
                <p-multiSelect [defaultLabel]="'Vagas'" [options]="vacancies" [(ngModel)]="boatFilter.vacanciesList"
                    appendTo="body" selectedItemsLabel="{0} itens selecionados" name="vacancy">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label for="boatProfile">{{'BOAT_PROFILE' | translate}}</label>
                <p-dropdown [(ngModel)]="boatFilter.boatProfile" name="boatProfile" appendTo="body" [options]="boatProfiles"></p-dropdown>
            </div>
            <div *ngIf="isMovements"  class="form-control">
                <label for='movementGroup'>{{'MOVEMENT_GROUP' | translate}}</label>
                <p-multiSelect [defaultLabel]="'Grupo de Movimentação'" appendTo="body"[(ngModel)]="boatFilter.movementGroup"
                [options]="movementGroupFormOptions" selectedItemsLabel="{0} itens selecionados" name="movementGroup"></p-multiSelect>
            </div>
            <div class="form-control" *ngIf="isFranchise">
                <label for="franchise">{{'Grupo de franquia' | translate}}</label>
                <p-multiSelect [defaultLabel]="'Grupo de Franquia'" [options]="franchiseGroupOptions" [(ngModel)]="boatFilter.franchiseGroup"
                    appendTo="body" selectedItemsLabel="{0} itens selecionados" name="franchise">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label for="boatProfile">{{'INSURER_EXPIRATION' | translate}}</label>
                <p-dropdown [(ngModel)]="boatFilter.insuranceExpiration" name="insuranceExpiration" appendTo="body" [options]="insuranceExpirationOptions"></p-dropdown>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

    <div class="table">
        <p-table #tt [value]="boats" [paginator]="true" [rows]="boatFilter.size" [lazy]="true" autoLayout="true"
            [columns]="cols" [totalRecords]="totalRecords" (onLazyLoad)="onChangePage($event)" styleClass="easy-table"
            [alwaysShowPaginator]="false" [selection]="selectedBoats">
            <ng-template pTemplate="caption">
                <div class="p-caption">
                    <!-- <div class="export-buttons">
                      <!-- <button class="easymarine btn-csv-xls" type="button" (click)="exportTable()"
                          [pTooltip]="'Exporta todos os registros'" tooltipPosition="bottom">
                          Exportar Tabela
                      </button>
                      <button class="easymarine btn-csv-xls" label="Exportar CSV" (click)="exportTable()"
                            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
                            <span>Exportar CSV</span>
                            <i class="pi pi-file-excel"></i>
                        </button>

                    </div> -->
                  <div class="flex flex-column align-items-start">
                    <span class="total-registers">
                        {{ 'TOTAL-REGISTERS' | translate }}: {{boats.length}} {{'OF' | translate}} {{tt.totalRecords}}
                    </span>
                      <span *ngIf="marinaImplantation !== undefined && marinaImplantation !== null && marinaImplantation.totalBoatsActive">
                        Embarcações de Implantação: {{marinaImplantation.totalBoats}}
                    </span>
                  </div>
                  <div class="flex align-items-end">
                    <div class="pr-7">
                      <span class="total-registers">
                          {{ 'BOATS-WITH-VALUE' | translate }}: {{boatsWithValue}}
                      </span>
                    </div>

                    <div >
                      <span class="total-registers">
                          {{ 'BOATS-TOTAL-VALUE' | translate }}: {{boatsTotalValue | currency: 'BRL'}}
                      </span>
                    </div>
                  </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'name'">
                        {{ 'NAME' | translate }}
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th>{{ 'TYPE' | translate }}</th>
                    <th [pSortableColumn]="'shipyardName'">
                        {{ 'SHIPYARD' | translate }}
                        <p-sortIcon [field]="'shipyardName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'owners'">
                        {{ 'OWNERS' | translate }}
                        <p-sortIcon [field]="'owners'"></p-sortIcon>
                    </th>
                    <th>
                        Contato
                    </th>
                    <th [pSortableColumn]="'policyExpirationDate'">
                        {{ 'INSURER_EXPIRATION' | translate }}
                        <p-sortIcon [field]="'policyExpirationDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'shipyardModelName'">
                        {{ 'MODEL' | translate }}
                        <p-sortIcon [field]="'shipyardModelName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'tieExpiration'">
                        {{ 'EXPIRATION' | translate }}
                        <p-sortIcon [field]="'tieExpiration'"></p-sortIcon>
                    </th>
                    <th *ngIf="isMovements && false">
                      {{ 'QUEUEUP' | translate }}
                    </th>
                    <th [pSortableColumn]="'boatProfile'">
                        {{'BOAT_PROFILE' | translate}}
                        <p-sortIcon [field]="'boatProfile'"></p-sortIcon>
                    </th>
                    <th *ngIf="isMovements"  [pSortableColumn]="'movementGroup'">
                        {{'MOVEMENT_GROUP' | translate}}
                        <p-sortIcon [field]="'movementGroup'"></p-sortIcon>
                    </th>
                    <!-- <th [pSortableColumn]="'idMovementLocation'">
                        {{ 'LOCATION' | translate }}
                        <p-sortIcon [field]="'movementLocation.id'"></p-sortIcon>

                    </th> -->
                    <th *ngIf="isMovements" >
                        {{ 'LOCATION' | translate }}
                    </th>
                    <th *ngIf="isFranchise">
                        {{'Grupo de franquia' | translate}}
                    </th>
                    <th [pSortableColumn]="'codeVacancy'">
                        {{ 'VACANCY' | translate }}
                        <p-sortIcon [field]="'vacancy.code'"></p-sortIcon>
                    </th>
                    <th class="text-center" width="8%" [pSortableColumn]="'reviewed'">
                        {{ 'REVIEWED' | translate }}
                        <p-sortIcon [field]="'reviewed'"></p-sortIcon>
                    </th>
                    <th class="text-center" width="6%" [pSortableColumn]="'active'">
                        {{ 'STATUS' | translate }}
                        <p-sortIcon [field]="'active'"></p-sortIcon>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-boat>
                <tr [ngClass]="{'em-alert': isDocumentExpired(boat)}">
                    <td>{{boat.name}}</td>
                    <td>{{boat.shipTypeBoat}}</td>
                    <td>{{boat.shipyardName}}</td>
                    <td *ngIf="boat.qtdOwners == 1 || boat.qtdOwners == null">{{boat.owners}}</td>
                    <td *ngIf="boat.qtdOwners > 1" [pTooltip]="boat.owners">Diversos</td>

                    <td *ngIf="boat.qtdOwners == 1 || boat.qtdOwners == null">{{boat.contact}}</td>
                    <td *ngIf="boat.qtdOwners > 1" [pTooltip]="boat.contact">Diversos</td>
                    <td>{{boat.boatInsurerExpirationDate | date: 'dd/MM/yy'}}</td>
                    <td>{{boat.shipyardModelName}}</td>
                    <td>{{boat.tieExpiration | date: 'dd/MM/yy'}}</td>
                    <td *ngIf="isMovements && false">
                      <app-boat-movement-group
                        [params]="{name: boat?.movementGroup?.name,
                          urlIcon:  boat?.movementGroup?.urlIcon}">
                      </app-boat-movement-group>
                    </td>
                    <td>{{boat.boatProfile }}</td>
                    <td *ngIf="isMovements" >{{boat.movementGroup }}</td>
                    <td *ngIf="isMovements" >{{boat?.movementLocation?.name}}</td>
                    <td *ngIf="isFranchise">{{boat?.franchiseGroup?.name}}</td>
                    <td class="table-dropdown">
                        <p-dropdown [style]="{'width': '100%'}" [options]="getOptionsVacancies(boat)" appendTo="body"
                            [(ngModel)]="boat.vacancy" dataKey="id" [name]="boat + boats.indexOf(boat)"
                            (onChange)="changeVacancy(boat)">
                        </p-dropdown>
                    </td>
                    <td class="text-center">
                        <span href="javascript:;" [pTooltip]="boat.reviewed ? 'Sim' : 'Não'" class="badge badge-pill size-badge"
                         [ngClass]="{'success':boat.reviewed, 'danger': !boat.reviewed}" tooltipPosition="top"
                         (click)="updateReviewed(boat)">
                        </span>
                    </td>
                    <td class="text-center">
                        <a href="javascript:;" [pTooltip]="boat.active ? 'Desativar' : 'Ativar'"
                            class="badge badge-pill" [ngClass]="{'success':boat.active, 'danger': !boat.active}"
                            tooltipPosition="top" (click)="updateStatus(boat)">
                            {{ boat.active ? 'Ativo' : 'Inativo' }}
                        </a>
                    </td>
                    <td>
                        <div class="table-action" (click)="openBoatFormDialog(boat.id)" pTooltip="Editar"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td>
                        <div class="table-action" (click)="openBoatDeleteConfirmDialog(boat.id)" pTooltip="Excluir"
                            tooltipPosition="bottom">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
