import { MultiQueue } from "./multi-queue";

export class SlingTime {

    id: number;
    dayWeek: string;
    operationStart: any;
    operationEnd: any;
    slingDownStart: any;
    intervalStart: any;
    intervalEnd: any;
    slingDownEnd: any;
    slingReturnEnd: any;
    executionTime: number;
    inOperation: any;
    multiQueue: MultiQueue;

    hasInterval(): any {
        return this.intervalStart && this.intervalEnd;
    }
}