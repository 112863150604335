<div class="table">
    <p-table [value]="slingTimes" autoLayout="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Dia Semana</th>
                <th>Início</th>
                <th>Intervalo Início</th>
                <th>Intervalo Fim</th>
                <th>Fim</th>
                <th>Retorno</th>
                <th>Tempo Lingada</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-time>
            <tr>
                <td>
                    <p-checkbox [value]="true" [binary]="true"
                        [name]="'inOperation' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.inOperation"
                        [disabled]="isDayWeekDisabled(time.dayWeek)">
                    </p-checkbox>
                    {{ time.dayWeek | translate }}
                </td>
                <td>
                    <input pInputText type="time" tooltipPosition="bottom"
                        [ngClass]="{'danger': ((!time.slingDownStart || time.slingDownStart === '') && (time.inOperation))}"
                        pTooltip="Horário de início dos serviços da rampa"
                        [name]="'slingDownStart' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.slingDownStart" [disabled]="!time.inOperation" />
                </td>
                <td>
                    <input pInputText type="time" tooltipPosition="bottom"
                        pTooltip="Horário de início do intervalo dos serviços da rampa"
                        [name]="'intervalStart' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.intervalStart" [disabled]="!time.inOperation" />
                </td>
                <td>
                    <input pInputText type="time" tooltipPosition="bottom"
                        pTooltip="Horário de fim do intervalo dos serviços da rampa"
                        [name]="'intervalEnd' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.intervalEnd" [disabled]="!time.inOperation" />
                </td>
                <td>
                    <input pInputText type="time" tooltipPosition="bottom"
                        [ngClass]="{'danger': ((!time.slingDownEnd || time.slingDownEnd === '') && (time.inOperation))}"
                        pTooltip="Horário de fechamento dos serviços da rampa"
                        [name]="'slingDownEnd' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.slingDownEnd" [disabled]="!time.inOperation" />
                </td>
                <td>
                    <input pInputText type="time" tooltipPosition="bottom"
                        [ngClass]="{'danger': ((!time.slingReturnEnd || time.slingReturnEnd === '') && (time.inOperation))}"
                        pTooltip="Horário máximo de retorno para os barcos"
                        [name]="'slingReturnEnd' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.slingReturnEnd" [disabled]="!time.inOperation" />
                </td>
                <td>
                    <input pInputText type="number" tooltipPosition="bottom"
                        [ngClass]="{'danger': ((!time.executionTime || time.executionTime === '') && (time.inOperation))}"
                        pTooltip="Tempo entre horários de lingadas"
                        [name]="'executionTime' + slingTimes.indexOf(time)"
                        [(ngModel)]="time.executionTime" [disabled]="!time.inOperation" />
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>