<form (submit)="findBillets()" class="filters" appFilters>

    <!-- Filtros -->
    <div class="filter-row" *ngIf="contentLoaded">

        <div class="form-control" *ngIf="marinaCompanies?.length > 0">
            <label for="selectedCompany">{{'COMPANY' | translate}}</label>
            <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany" appendTo="body"
                [(ngModel)]="selectedCompany" (onChange)="changeCompany()">
            </p-dropdown>
        </div>

        <div class="form-control">
            <label for="minInvoiceNumber">{{'MIN-INVOICE-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.invoiceNumberStart" name="minInvoiceNumber">
        </div>

        <div class=" form-control">
            <label for="maxInvoiceNumber">{{'MAX-INVOICE-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.invoiceNumberEnd" name="maxInvoiceNumber">
        </div>

        <div class="form-control">
            <label for="selectedCustomer">{{'CUSTOMER' | translate}}</label>
            <p-dropdown [filter]="true" [options]="filteredCustomers" [(ngModel)]="filter.customerId" appendTo="body"
                name="selectedCustomer">
            </p-dropdown>
        </div>

        <div class="form-control">
            <label for="groups">{{'GROUPS' | translate}}</label>
            <p-multiSelect [defaultLabel]="'GROUPS' | translate" [options]="groups" [style]="{height: '30px'}" appendTo="body"
                [(ngModel)]="filter.groupIds" name="groups" selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>

        <div class="form-control">
            <label for="origin">{{'ORIGIN' | translate}}</label>
            <p-multiSelect [options]="origins" [(ngModel)]="filter.origin" name="origin" appendTo="body"
                selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>

        <div class="form-control">
          <label for="rangeEmissioDate">{{'EMISSION' | translate}}</label>
          <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeEmissioDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeDatesEmissao"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
        </div>

        <div class="form-control">
          <label for="rangeDueDate">{{'DUE' | translate}}</label>
          <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeDueDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeDatesVencimento"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
        </div>

        <div class="form-control">
          <label for="rangePaymentDate">{{'PAYMENT' | translate}}</label>
          <p-calendar
          dateFormat="dd/mm/yy"
          name="rangePaymentDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeDatesPagamento"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
        </div>


        <div class="form-control" *ngIf="slingConfig?.anticipation">
            <label for="startRepurchaseDate">{{'REPURCHASE-FROM' | translate}}</label>
            <p-calendar dateFormat="dd/mm/yy" name="startRepurchaseDate" [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.startRepurchaseDate" [showIcon]="true"
                i18n>
            </p-calendar>
        </div>

        <div class="form-control" *ngIf="slingConfig?.anticipation">
            <label for="endRepurchaseDate">{{'REPURCHASE-TO' | translate}}</label>
            <p-calendar dateFormat="dd/mm/yy" name="endRepurchaseDate" [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endRepurchaseDate" [showIcon]="true"
                i18n>
            </p-calendar>
        </div>

        <div class="form-control">
            <label for="repurchased">Pago</label>
            <p-dropdown [options]="yesNoList" [(ngModel)]="filter.paid" name="paid" [title]="'Pago'| translate" appendTo="body">
            </p-dropdown>
        </div>

        <div class="form-control">
            <label for="repurchased">Ativo</label>
            <p-dropdown [options]="yesNoList" [(ngModel)]="filter.active" name="active" [title]="'Ativo'| translate" appendTo="body">
            </p-dropdown>
        </div>

        <div class="form-control">
            <label for="repurchased">Nosso número</label>
            <input pInputText type="text" [(ngModel)]="filter.identifier" name="identifier"
                [placeholder]="'Nosso número' | translate" [title]="'Nosso número' | translate">
        </div>

        <div class="form-control">
            <label for="carteiraCobranca">Carteira de Cobrança</label>
            <p-dropdown [options]="billingPortifolios" [(ngModel)]="filter.idCarteiraCobranca" name="paid" appendTo="body"
                [title]="'Carteira de Cobrança'| translate" dataKey="idCarteiraCobranca">
            </p-dropdown>
        </div>

        <div class="form-control" *ngIf="yesNoList?.length > 0">
            <label for="repurchased">Cancelado banco</label>
            <p-dropdown [options]="yesNoList" [(ngModel)]="filter.bankCanceled" name="bankCanceled" appendTo="body"
                [title]="'Cancelado banco'| translate">
            </p-dropdown>
        </div>

        <div class="form-control" *ngIf="yesNoList?.length > 0">
            <label for="refused">Recusado</label>
            <p-dropdown [options]="yesNoList" [(ngModel)]="filter.refused" name="refused" appendTo="body"
                [title]="'Recusado'| translate">
            </p-dropdown>
        </div>
        <div class="form-control">
          <label for="rangeCancelDate">{{'CANCEL-FROM' | translate}}</label>
          <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeCancelDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeDates"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
        </div>

        <ng-container *ngIf="slingConfig?.anticipation">
            <div class="form-control" *ngIf="yesNoList?.length > 0">
                <label for="repurchased">Antecipado</label>
                <p-dropdown [options]="yesNoList" [(ngModel)]="filter.anticipated" name="anticipated" appendTo="body"
                    [title]="'Anticipado'| translate">
                </p-dropdown>
            </div>

            <div class="form-control" *ngIf="yesNoList?.length > 0">
                <label for="repurchased">Recomprado</label>
                <p-dropdown [options]="yesNoList" [(ngModel)]="filter.repurchased" name="repurchased" appendTo="body"
                    [title]="'Recomprado'| translate">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label for="repurchased">Lote de antecipação</label>
                <input pInputText type="text" [(ngModel)]="filter.anticipationLot" name="anticipationLot"
                    [placeholder]="'ANTICIPATION-LOT' | translate" [title]="'ANTICIPATION-LOT' | translate">
            </div>
        </ng-container>

        <div class="buttons">
            <div class="form-button">
                <button (click)="clear(true)" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
            </div>
            <div class="form-button">
                <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
            </div>
        </div>
    </div>

</form>
<div class="table">
    <p-table [value]="billetsFiltered" autoLayout="true" [alwaysShowPaginator]="true"
        [rows]="paginationFilter.size" [totalRecords]="totalRecords" [paginator]="true" [lazy]="true" #table
        [(selection)]="selectedBillets" [rowsPerPageOptions]="[10,20,30,50,100]" (onPage)="onChangePage($event)"
        [globalFilterFields]="['customer', 'invoiceNumber', 'account', 'wallet']"
        (onSort)="onSort($event)" styleClass="easy-table">
        <!-- Botões legenda topo -->
        <ng-template pTemplate="caption">
            <button class="easymarine danger" (click)="cancelBillets()" type="button"
                title="Cancelar os boletos gerados no sistema">Cancelar boletos</button>

            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText size="50" placeholder="Filtro por Fatura, Cliente, Carteira ou Conta"
                    [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
            </span>

            <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                {{billets.length}}
                {{'OF' | translate}}
                {{table.totalRecords}}
            </span>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th colspan="3">Boletos selecionados: {{selectedBillets?.length}}</th>
                <th colspan="7"></th>
                <th class="text-right">Total:</th>
                <th class="text-right">{{totalBillets | number:'1.2-2'}}</th>
                <th class="text-right">{{totalPaid | number:'1.2-2'}}</th>
                <th *ngIf="marinaCompanies?.length > 0"></th>
            </tr>
            <tr>
                <th class="text-center">
                    <input type="checkbox" class="primeCheckbox" (click)="selectAll()">
                </th>
                <th class="text-center" [pSortableColumn]="'invoiceNumber'">
                    Fatura
                    <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'marinaCompany'" *ngIf="marinaCompanies?.length > 0">
                    Empresa
                    <p-sortIcon [field]="'marinaCompany'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'sequence'">
                    Parcela
                    <p-sortIcon [field]="'sequence'"></p-sortIcon>
                </th>
                <th class="text-center">Boleto</th>
                <th class="text-center" [pSortableColumn]="'customer'">
                    Cliente
                    <p-sortIcon [field]="'customer'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'group'">
                    Grupo
                    <p-sortIcon [field]="'group'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'issueDate'">
                    Emissão
                    <p-sortIcon [field]="'issueDate'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'dueDate'">
                    Vencimento
                    <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'identifier'">
                    Nosso número
                    <p-sortIcon [field]="'identifier'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'idCarteiraCobranca'">
                    Conta
                    <p-sortIcon [field]="'idCarteiraCobranca'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'idCarteiraCobranca'">
                    Carteira
                    <p-sortIcon [field]="'idCarteiraCobranca'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'value'">
                    Valor
                    <p-sortIcon [field]="'value'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'valuePaid'">
                    Valor Pago
                    <p-sortIcon [field]="'valuePaid'"></p-sortIcon>
                </th>
                <th class="text-center">
                  Cancelado em:
                </th>
                <th class="text-center">
                    C. banco
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-billet>
            <tr [ngClass]="{'inactive': !billet.active, 'paid': billet.paid}">
                <td class="text-center"
                    [pTooltip]="billet.anticipated ? 'Boleto antecipado não pode ser cancelado.' : (billet.paid ? 'O boleto já está pago.' : (!billet.active ? 'O boleto já se encontra cancelado.' : ''))"
                    tooltipPosition="bottom">
                    <p-tableCheckbox [value]="billet" *ngIf="canCancel(billet)">
                    </p-tableCheckbox>
                </td>
                <td class="text-center link green" (click)="extract(billet.invoiceId)">
                    {{ billet.invoiceNumber}}
                </td>
                <td class="text-center" *ngIf="marinaCompanies?.length > 0"
                    [pTooltip]="billet.marinaCompany ? billet.marinaCompany : loggedMarina.companyName"
                    tooltipPosition="bottom">
                    {{ getCompanyIndex(billet.marinaCompanyId) }}
                </td>
                <td class="text-center">
                    {{ billet?.sequence }}
                </td>
                <td class="text-center" [ngClass]="{'anticipated' : billet?.anticipated}">
                    <ng-container  >
                        <a *ngIf="billet?.active" [href]="billet?.documentUrl" target="_blank">
                            <app-barcode-icon *ngIf="billet?.registered && !billet.paid" [styleBillet]="'registered'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                            <app-barcode-icon *ngIf="billet.paid" [styleBillet]="'paid'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                            <ng-container *ngIf="!billet?.registered && !billet.paid && billet.processed">
                                <app-barcode-icon *ngIf="billet.active && billet.valuePaid > 0" [styleBillet]="'paid'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                                <app-barcode-icon *ngIf="!billet.valuePaid && isToday(billet)" [styleBillet]="'waiting'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                                <app-barcode-icon *ngIf="billet.active && !billet.valuePaid && !isToday(billet) && !billet.registered && !billet.refused" [styleBillet]="'pending'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                                <app-barcode-icon *ngIf="billet.refused && billet.active" [styleBillet]="'refused'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                            </ng-container>
                        </a>
                        <ng-container *ngIf="!billet?.registered && !billet?.paid && !billet?.processed && billet?.active">
                            <app-barcode-icon *ngIf="!billet.valuePaid && isToday(billet)" [styleBillet]="'waiting'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="'Aguardando o arquivo de retorno'"></app-barcode-icon>
                            <app-barcode-icon *ngIf="billet.active && !billet.valuePaid && !isToday(billet) && !billet.registered && !billet.refused" [styleBillet]="'pending'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="'Aguardando o arquivo de retorno'"></app-barcode-icon>
                        </ng-container>
                        <app-barcode-icon *ngIf="!billet?.active && !billet.paid" [styleBillet]="'canceled'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="billet.bankCanceledDate ? 'Boleto cancelado em: ' + getDateFormatted(billet.bankCanceledDate) : 'Boleto cancelado'"></app-barcode-icon>
                    </ng-container>
                </td>             
                <td [pTooltip]="billet.emailCollectionArray ? billet.emailCollectionArray.join('\n') : ''"
                    tooltipPosition="bottom">
                    {{ billet?.customer }}
                </td>
                <td class="text-center">
                    {{ billet?.group ? billet?.group : 'Sem grupo' }}
                </td>
                <td class="text-center">
                    {{ billet?.issueDate | date:'dd/MM/yy'}}
                </td>
                <td class="text-center">
                    {{ billet?.dueDate | date:'dd/MM/yy' }}
                </td>
                <td class="text-center">
                    {{ billet?.identifier }}
                </td>
                <td class="text-center">
                    {{ billingPortfolio(billet).conta.nome }}
                </td>
                <td class="text-center">
                    {{ billingPortfolio(billet).nome }}
                </td>
                <td class="text-right">
                    {{ billet?.value | number:'1.2-2' }}
                </td>
                <td class="text-right" [pTooltip]="billet?.valuePaid > 0 ? getDateFormatted(billet.datePaid) : ''"
                    tooltipPosition="bottom">
                    {{ billet?.valuePaid | number:'1.2-2' }}
                </td>
                <td class="text-center">
                  {{ billet?.cancelDate   | date:'dd/MM/yy'}}
                <td class="text-center"
                    [pTooltip]="!billet.active ? (billet.bankCanceled ? 'Data cancelamento: ' + getDateFormatted(billet.bankCanceledDate) : 'Não cancelado no banco') : ''"
                    tooltipPosition="bottom">
                    <span (click)="cancelBilletBank(billet)" *ngIf="!billet.active">
                        <i class="pi pi-check" style="color: green" *ngIf="billet.bankCanceled"></i>
                        <i class="pi pi-times" style="color: red" *ngIf="!billet.active && !billet.bankCanceled"></i>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
