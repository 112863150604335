<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'Faturamento > Gerar faturas' | translate }}</span>
        <span style="float: right; color: red; margin-top: 5px; font-weight: bold;"
            *ngIf="invoice.qtyOrdersNotAttend > 1">
            Existem {{invoice.qtyOrdersNotAttend}} pedidos não atendidos
        </span>
        <span style="float: right; color: red; margin-top: 5px; font-weight: bold;"
            *ngIf="invoice.qtyOrdersNotAttend == 1">
            Existe {{invoice.qtyOrdersNotAttend}} pedido não atendido
        </span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>
    <form class="filters" (submit)="view()" appFilters>
        <div class="filter-row">
            <div class="form-control"
                pTooltip="Ao buscar pelo mês de referência, somente pedidos de origem mensalidades são considerados"
                tooltipPosition="bottom">
                <label>Buscar por: </label>
                <div class="filter-row">
                    <p-radioButton name="searchMethod" label="Data e Origem" [(ngModel)]="searchByReference"
                        [value]="false">
                    </p-radioButton>
                    &nbsp;&nbsp;
                    <p-radioButton name="searchMethod" label="Mês referência" [(ngModel)]="searchByReference"
                        [value]="true">
                    </p-radioButton>
                </div>
            </div>
        </div>
        <div class="filter-row" *ngIf="!searchByReference">
            <div class="form-control">
                <label>Pedidos até</label>
                <p-calendar dateFormat="dd/mm/yy" name="referenceDate" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.referenceDate" [showIcon]="true"
                    appendTo="body" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Origem</label>
                <p-multiSelect defaultLabel="Selecione..." [options]="orderOriginList" [style]="{height: '30px'}"
                    appendTo="body" [(ngModel)]="filter.orderOrigin" selectedItemsLabel="{0} itens selecionados"
                    name="orderOrigin">
                </p-multiSelect>
            </div>
            <app-filter-billing-company
              class="form-control" *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)"
              style="justify-content: space-between;"
              [slingConfig]="slingConfig"
              [filter]="filter"
              (filterChange)="changeCompany($event)">
            </app-filter-billing-company>
        </div>
        <div class="filter-row" *ngIf="searchByReference">
            <div class="form-control">
                <label>Data de referência inicial</label>
                <p-calendar dateFormat="dd/mm/yy" name="referenceStart" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.referenceStart"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Data de referência final</label>
                <p-calendar dateFormat="dd/mm/yy" name="referenceEnd" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.referenceEnd"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <app-filter-billing-company
              class="form-control" *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)"
              style="justify-content: space-between;"
              [slingConfig]="slingConfig"
              [filter]="filter"
              (filterChange)="changeCompany($event)">
            </app-filter-billing-company>
        </div>
        <div class="filter-row">
            <div class="form-control">
                <label>Grupo</label>
                <br>
                <p-multiSelect defaultLabel="Selecione..." [options]="groups" [style]="{height: '30px'}"
                    appendTo="body" [maxSelectedLabels]="1" [(ngModel)]="filter.groupIds" name="groupIds"
                    selectedItemsLabel="{0} itens selecionados"
                    (onPanelShow)="showGroupSelect()"
                    (onPanelHide)="hideGroupSelect()">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>Tipo Produto</label>
                <br>
                <p-multiSelect
                defaultLabel="Selecione..."
                [options]="productTypesList"
                appendTo="body"
                name="productType"
                [(ngModel)]="filter.productTypes"
                [style]="{height: '30px'}"
                [maxSelectedLabels]="1"
                selectedItemsLabel="{0} itens selecionados"
                (onPanelHide)="hideProductTypeListSelect()"
                (onPanelShow)="showProductTypeListSelect()"
                ></p-multiSelect>
            </div>
            <div class="form-control">
                <label>Produto(s)</label>
                <br>
                <p-multiSelect
                    defaultLabel="Selecione..."
                    appendTo="body"
                    name="productsList"
                    [style]="{height: '30px'}"
                    [maxSelectedLabels]="1"
                    selectedItemsLabel="{0} itens selecionados"
                    [options]="productList"
                    [(ngModel)]="filter.productListIds"
                >

                </p-multiSelect>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button class="easymarine primary" type="submit">Pré-Visualizar</button>
                </div>
                <div class="form-button">
                    <button class="easymarine success" (click)="dialogMonthlySummary()" type="button">Resumo</button>
                </div>
                <div class="form-button" [pTooltip]="hasOrderSelected() ? '' : 'Selecione os pedidos a serem faturados'"
                    tooltipPosition="top">
                    <button class="easymarine danger" [disabled]="!hasOrderSelected()" (click)="dialogGenerate()"
                        type="button">Gerar faturas</button>
                </div>
            </div>
        </div>
    </form>
    <div>
        <p-table [value]="invoicesTable" autoLayout="true" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <div class="table-header center">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText size="50" placeholder="Filtro por cliente e embarcação"
                            [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="2"></th>
                    <th *ngIf="slingConfig && hasFinancialMultipleByCustomerFilter(slingConfig)"></th>
                    <th class="text-right">
                        <fa-icon [icon]="'shopping-cart'" style="font-size: 18px"></fa-icon>&nbsp;
                        <span>{{totalSingleOrder | number: '1.2-2'}}</span>
                    </th>
                    <th class="text-right">
                        <fa-icon [icon]="'ship'" style="font-size: 18px;"></fa-icon>&nbsp;
                        <span>{{totalSling | number: '1.2-2'}}</span>
                    </th>
                    <th class="text-center">
                        <fa-icon [icon]="'university'" style="font-size: 18px;"></fa-icon>&nbsp;
                        <span>{{totalTuition | number: '1.2-2'}}</span>
                    </th>
                    <th *ngIf="isWorkshop" class="text-left">
                      <fa-icon [icon]="'tools'" style="font-size: 18px;"></fa-icon>&nbsp;
                      <span>{{totalWorkshop | number: '1.2-2'}}</span>
                  </th>
                    <th class="text-right">{{total | number: '1.2-2'}}</th>
                </tr>
                <tr>
                    <th class="text-center">
                        <p-checkbox [(ngModel)]="allSelected" binary="true" (onChange)="checkedCustomer(null)">
                        </p-checkbox>
                    </th>
                    <th [pSortableColumn]="'customerName'">
                        Cliente
                        <p-sortIcon [field]="'customerName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'marinaCompany.companyName'" *ngIf="slingConfig && hasFinancialMultipleByCustomerFilter(slingConfig)">
                        Empresa
                        <p-sortIcon [field]="'marinaCompany.companyName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'groupName'">
                        Grupo
                        <p-sortIcon [field]="'groupName'"></p-sortIcon>
                    </th>
                    <th>Origem</th>
                    <th [pSortableColumn]="'dueDate'">
                        Dia de Vencimento
                        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'hasQuota'">
                        Rateio
                        <p-sortIcon [field]="'hasQuota'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'total'">
                        Total da fatura
                        <p-sortIcon [field]="'totalCurrency'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice>
                <tr>
                    <td class="text-center">
                        <p-checkbox [(ngModel)]="invoice.selected" binary="true" (onChange)="checkedCustomer(invoice)">
                        </p-checkbox>
                    </td>
                    <td (click)="showOrders(invoice)" style="cursor:pointer; color:#2196F3">
                        <i class="pi"
                            [ngClass]="{'pi-angle-down' : !invoice.showOrders, 'pi-angle-up': invoice.showOrders}"></i>
                        {{ invoice.customerName }}
                    </td>
                    <td class="text-center" *ngIf="slingConfig && hasFinancialMultipleByCustomerFilter(slingConfig)"
                        [pTooltip]="invoice.marinaCompany ? invoice.marinaCompany.companyFederalName : loggedMarina.companyName"
                        tooltipPosition="bottom">
                        {{invoice.marinaCompany ? getCompanyIndex(invoice.marinaCompany) : 1}}
                    </td>
                    <td>{{invoice.groupName}}</td>
                    <td class="text-center">
                        <ng-container [ngSwitch]="getIconOrigin(invoice)">
                            <ng-container *ngSwitchCase="'MULTIPLE'">
                                <fa-icon [icon]="'chart-pie'" style="font-size: 21px;"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'DETACHED'">
                              <fa-icon [icon]="'shopping-cart'" style="font-size: 21px"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'SLING'">
                                <fa-icon [icon]="'ship'" style="font-size: 21px;"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'TUITION'">
                                <fa-icon [icon]="'university'" style="font-size: 21px;"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'WORKSHOP'">
                              <fa-icon [icon]="'tools'" style="font-size: 21px"></fa-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'LEGACY_IMPORT'">
                              <fa-icon [icon]="'save'" style="font-size: 21px"></fa-icon>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td class="text-center">{{invoice.dueDate | date: 'dd'}}</td>
                    <td [pTooltip]="getCustomersQuota(invoice)" tooltipPosition="bottom">{{invoice.hasQuota}}</td>
                    <td class="text-right colorAttend ">{{invoice.total | number: '1.2-2'}}</td>
                </tr>
                <tr [hidden]="!invoice.showOrders">
                    <td [attr.colspan]="(slingConfig && hasFinancialMultipleByCustomerFilter(slingConfig)) ? 8 : 7">
                        <p-table [value]="invoice.orders" autoLayout="true">
                            <ng-template pTemplate="header">
                <tr>
                    <th>Pedido</th>
                    <th>Data</th>
                    <th *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)">
                      Empresa
                    </th>
                    <th>Embarcação</th>
                    <th>Origem</th>
                    <th>Referência</th>
                    <th>Total</th>
                    <th>Rateio</th>
                    <th>Faturar</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr>
                    <td (click)="showItens(order)" style="cursor:pointer">
                        <p-checkbox [(ngModel)]="order.selected" binary="true" (onChange)="selectOrder(invoice)">
                        </p-checkbox>
                        <i class="pi"
                            [ngClass]="{'pi-angle-down' : !order.showItens, 'pi-angle-up': order.showItens}"></i>
                        {{order.orderNumber}}
                    </td>
                    <td>{{order.date | date: 'dd/MM/yyyy'}}</td>
                    <td *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)"
                      [pTooltip]="order.billingCompany ? order.billingCompany.companyFederalName : loggedMarina.companyName"
                      tooltipPosition="bottom">
                        {{order.billingCompany ? getCompanyIndex(order.billingCompany) : 1}}
                    </td>
                    <td>{{order.boatName}}</td>
                    <td class="text-center">
                        <div [ngSwitch]="order.origin">
                            <div *ngSwitchCase="'DETACHED'">
                              <fa-icon [icon]="'shopping-cart'" style="font-size: 21px"></fa-icon>
                            </div>
                            <div *ngSwitchCase="'SLING'">
                                <fa-icon [icon]="'ship'" style="font-size: 21px;"></fa-icon>
                            </div>
                            <div *ngSwitchCase="'TUITION'">
                                <fa-icon [icon]="'university'" style="font-size: 21px;"></fa-icon>
                            </div>
                        </div>
                    </td>
                    <td>{{(order.dateReferences ? order.dateReferences : order.date) | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-right">{{order.total | number: '1.2-2'}}</td>
                    <td class="text-right">{{order.quota | number: '1.0-0'}}%</td>
                    <td class="text-right">{{order.totalQuota | number: '1.2-2'}}</td>
                </tr>
                <tr [hidden]="!order.showItens">
                    <td colspan="9">
                        <p-table [value]="order.itens">
                            <ng-template pTemplate="header">
                <tr>
                    <th>Produto/Serviço</th>
                    <th>Quantidade</th>
                    <th>Valor</th>
                    <th>Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td class="border-product">{{item.productName}}</td>
                    <td class="border-product text-right">{{item.quantity | number: '1.2-2'}}</td>
                    <td class="border-product text-right">{{item.value | number: '1.2-2'}}</td>
                    <td class="border-product text-right">{{item.total | number: '1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
</p-fieldset>
