<form #f="ngForm" autocomplete="off" (submit)="saveSling(true,f)" class="dialog-form">
    <div class="form-group" *ngIf="searchByCustomer !== undefined">
        <label><strong>Selecionar por:</strong></label>
        <div class="form-radio">
            <p-radioButton inputId="optTrue" name="searchMethod" [(ngModel)]="searchByCustomer"
                (click)="!isASlingEdition && changeSearch()" [disabled]="isASlingEdition" [value]="true">
            </p-radioButton>
            <label for="optTrue">Cliente</label>
        </div>
        <div class="form-radio">
            <p-radioButton inputId="optFalse" name="searchMethod" [(ngModel)]="searchByCustomer"
                [disabled]="isASlingEdition" (click)="!isASlingEdition && changeSearch()" [value]="false">
            </p-radioButton>
            <label for="optFalse">Embarcação</label>
        </div>
    </div>
    <br>
    <div class="form-group basic">

        <!--Busca por Responsavel-->
        <div class="form-control" *ngIf="searchByCustomer">
            <label>{{ 'CUSTOMER' | translate }}</label>
            <p-dropdown placeholder="Selecione..." [disabled]="isASlingEdition" required [filter]="!isToten"
                [options]="customers" [(ngModel)]="selectedCustomerId" appendTo="body" name="selectedCustomerId"
                (onChange)=" changeSelectedCustomer()">
                <ng-template let-customer pTemplate="item">
                    <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{customer.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="form-control" *ngIf="searchByCustomer">
            <label>{{ 'BOAT' | translate }}</label>
            <p-dropdown placeholder="Selecione..." [disabled]="isASlingEdition" required [filter]="true" appendTo="body"
                [options]="boatsFilter" [(ngModel)]="selectedBoatId" name="selectedBoatId" emptyFilterMessage=""
                (onChange)="changeSelectedBoat()">
                <ng-template let-boat pTemplate="selectedItem">
                    <span [ngStyle]="{'color': boat?.disabled ? 'red' : '#222222' }">{{boat?.label}}</span>
                </ng-template>
                <ng-template let-boat pTemplate="item">
                    <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{boat.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>

        <!--busca por embarcação-->
        <div class="form-control" *ngIf="!searchByCustomer">
            <label>{{ 'BOAT' | translate }}</label>
            <p-dropdown placeholder="Selecione..." [disabled]="isASlingEdition" required [filter]="true" appendTo="body"
                [options]="boatsFilter" [(ngModel)]="selectedBoatId" name="selectedBoatId"
                (onChange)=" loadAssociatedCustomers()">
                <ng-template let-boat pTemplate="selectedItem">
                    <span [ngStyle]="{'color': boat?.disabled ? 'red' : '#222222' }">{{boat?.label}}</span>
                </ng-template>
                <ng-template let-boat pTemplate="item">
                    <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{boat.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="form-control" *ngIf="!searchByCustomer">
            <label>{{ 'CUSTOMER' | translate }}</label>
            <p-dropdown placeholder="Selecione..." [disabled]="isASlingEdition" required [filter]="!isToten"
                appendTo="body" [options]="customers" [(ngModel)]="selectedCustomerId" name="selectedCustomerId"
                emptyFilterMessage="" (onChange)=" selectCustomer()">
                <ng-template let-customer pTemplate="item">
                    <div>{{customer.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="form-control" *ngIf="enabledSailor()">
          <label>{{'SAILOR' | translate}}</label>
          <p-dropdown placeholder="Selecione..." [disable]="isASlingEdition" [filter]="!isToten"
          appendTo="body" [options]="sailors" [(ngModel)]="selectedSailorId" name="selectedSailorId"
          emptyFilterMessage="" (onChange)=" selectSailor()">
            <ng-template let-sailor pTemplate="item">
              <div>{{sailor.label}}</div>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="form-control">
            <label>{{ 'FEDERAL-ID.GENERAL' | translate }}</label>
            <input type="text" pInputText disabled [(ngModel)]="sling.navigationPlan.responsible.federalId"
                [ngModelOptions]="{standalone: true}">
        </div>
    </div>
    <br>
    <div class="form-group">
        <div class="form-control switch" *ngIf="slingConfig?.toConfirm">
            <label>À confirmar</label>
            <p-inputSwitch [(ngModel)]="sling.toConfirm" name="toConfirm" [disabled]="!isPendingDown()"></p-inputSwitch>
        </div>
        <div class="form-control switch">
            <label>Manual<span style="color: red;" *ngIf="hasClosed()">*</span></label>
            <p-inputSwitch [(ngModel)]="manualHour" name="manualHour" [disabled]="!isPendingDown()"></p-inputSwitch>
        </div>


        <div class="form-control forecast">
            <label for="requestDate">{{ 'CHOOSE-DATE' | translate }}</label>
            <div class="form-group">
                <button class="sling-success" type="button" (click)="chooseRequestDate('-')"
                    [disabled]="lessButtonRequestDate || !isPendingDown()">-</button>
                <button class="sling-success" type="button" (click)="chooseRequestDate('+')"
                    [disabled]="!isPendingDown()">+</button>
                <p-calendar dateFormat="dd/mm/y" name="requestDate" [readonlyInput]="true" [minDate]="startDate"
                    appendTo="body" [(ngModel)]="requestDate" [showIcon]="true" i18n [disabled]="!isPendingDown()"
                    (onSelect)="changeExecutionForecast($event)" required styleClass="forecast-date">
                </p-calendar>
                <div class="day-name">
                    <span>{{pt.dayNames[requestDate.getDay()]}}</span>
                </div>
            </div>
        </div>
        <div class="form-control">
            <label>Hr saída</label>
            <p-dropdown placeholder="Selecione..." [filter]="true" [options]="availablePeriods" appendTo="body"
                [(ngModel)]="sling.executionForecast" name="executionForecast" [required]="!manualHour"
                [disabled]="!isPendingDown()" *ngIf="!manualHour && availablePeriods" (onHide)="getAvailablePeriodsSlingUp()">
            </p-dropdown>
            <input pInputText type="time" [style]="{display: 'block'}" name="executionForecast"
                [disabled]="!isPendingDown()" [(ngModel)]="executionForecast" [required]="manualHour"
                *ngIf="manualHour">
        </div>
    </div>
    <div class="divider"></div>
    <p-fieldset>
        <p-header class="article-title">
            <span class="article-title">{{'NAVIGATION-PLAN' | translate}}</span>
        </p-header>
        <div class="form-group">
          <div class="form-control">
            <label>{{ getLabelPassengers() }}</label>
            <app-passengers
              [(ngModel)]="sling.navigationPlan.crewQty"
              [typePeople]="hasChild ? 'Adult' : 'Person'"
              name="crewQty"
              [totalPassengers]="loadPassengersNumber() - (sling.navigationPlan.childQty ? sling.navigationPlan.childQty : 0)">
            </app-passengers>
          </div>
          <div class="form-control" *ngIf="hasChild">
            <label>{{ 'CHILDREN' | translate }}</label>
            <app-passengers
              [(ngModel)]="sling.navigationPlan.childQty"
              [typePeople]="'Child'"
              name="childQty"
              [totalPassengers]="loadPassengersNumber() - (sling.navigationPlan.crewQty ? sling.navigationPlan.crewQty : 0)">
            </app-passengers>
          </div>
          <div class="form-control">
            <label style="display: flex; align-items: center; justify-content: space-between;">
              <span>{{ 'DESTINATION' | translate }}</span>
              <span (click)="showMultiplesDestination()" class="link">múltiplos destinos</span>
            </label>
            <div class="form-group">
              <input type="text" pInputText [(ngModel)]="sling.navigationPlan.destination"
                class="input-destination" [ngModelOptions]="{standalone: true}"
                [required]="slingConfig.navigationPlanRequiredWeb">
              <p-dropdown placeholder="Favoritos" [filter]="true" [options]="frequentFestinations"
                styleClass="destinations-dropdown" [(ngModel)]="sling.navigationPlan.destination"
                name="frequentFestinations" class="flex1" emptyFilterMessage="" dropdownIcon="pi pi-star"
                id="dropdown-icon-fav" appendTo="body">
                </p-dropdown>
            </div>
          </div>
            <div class="form-control">
                <label>{{ 'RETURN-DATE' | translate }}</label>
                <p-calendar dateFormat="dd/mm/yy" name="returnDate" [readonlyInput]="true" [minDate]="minReturnDate"
                    [(ngModel)]="returnDate" [showIcon]="true" i18n (onSelect)="getAvailablePeriodsSlingUp()" required
                    appendTo="body">
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{ 'RETURN-TIME' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="!isToten" [options]="availablePeriodsSlingUp"
                    [(ngModel)]="sling.navigationPlan.returnTime" [required]="!manualHour" appendTo="body" emptyFilterMessage=""
                    name="navigationPlan.returnTime" *ngIf="!manualHour">
                </p-dropdown>
                <input pInputText type="time" [style]="{display: 'block'}" name="navigationPlanReturnTime"
                    [(ngModel)]="navigationPlanReturnTime" [required]="manualHour" *ngIf="manualHour">
            </div>
        </div>

        <p-footer>
            <div class="form-group">
                <div class="form-control">
                    <div class="float-left" *ngIf="sling.id">
                        <p style="font-weight:600; padding-left: 20px">Data do pedido:
                            {{getRequestDate() | date: "dd/MM/yyyy HH:mm"}}</p>
                    </div>
                    <div class="float-left">
                        <p style="font-weight:600; padding-left: 20px; color: red" *ngIf="hasClosed()"> *Utilize o
                            horário manual caso necessário!</p>
                    </div>
                </div>
                <div class="buttons">
                    <button *ngIf="sling.slingStatus == 'PENDING_DOWN'" class="easymarine danger" type="button"
                        (click)="close()">{{ 'CANCEL' | translate }}</button>
                    <button [disabled]="disableFlag  || (!slingConfig?.hasFuelSupply
                        && !slingConfig?.hasConsumables && !slingConfig?.hasAccessories)" (click)="saveSlingData(false,f)"
                        class="easymarine" type="button">
                        {{ 'NEXT' | translate }}
                    </button>
                    <button [disabled]="disableFlag || !hasEdit(sling)" class="easymarine primary"
                        type="submit">
                        {{ 'SAVE' | translate }}
                    </button>
                </div>
            </div>
        </p-footer>
    </p-fieldset>
    <div class="message-day" [ngClass]="messageDayExecutionForecast.type === 'SpecialDay'? 'warn':'danger'"
        *ngIf="messageDayExecutionForecast">
        <fa-icon class="icon" [icon]="'exclamation-circle'"></fa-icon>
        <h5>
            <span *ngIf="!hasMessageDayInEqualsDays()"> {{ 'CHOOSE-DATE' | translate }} - </span>
            {{messageDayExecutionForecast.date | date: 'dd/MM/yy'}} - {{messageDayExecutionForecast.message}}
        </h5>

    </div>
    <div class="message-day" [ngClass]="messageDayReturnDate.type === 'SpecialDay'? 'warn':'danger'"
        *ngIf="showMessageDayReturnDate()">
        <fa-icon class="icon" [icon]="'exclamation-circle'"></fa-icon>
        <h5>
            <span *ngIf="!hasMessageDayInEqualsDays()"> {{ 'RETURN-DATE' | translate }} - </span>
            {{messageDayReturnDate.date | date: 'dd/MM/yy'}} - {{messageDayReturnDate.message}}
        </h5>
    </div>
</form>
