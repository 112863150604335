import {Component, OnInit} from '@angular/core';
import moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {Boat} from 'src/app/models';
import {BoatService} from 'src/app/services/boat.service';
import {SlingConfigService} from 'src/app/services/sling-config.service';
import {TuitionService} from 'src/app/services/tuition.service';
import {MessageUtil} from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import {OrderReferenceDateUtil} from '../../../../utils/order-reference-date.util';
import {Tuition} from '../../../../models/tuition';
import {InvoiceService} from '../../../../services/invoice.service';
import {MarinaOrderService} from '../../../../services/marina-order.service';
import {InvoiceGeneratedByTuitionComponent} from './invoice-generated-by-tuition/invoice-generated-by-tuition.component';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-tuition-range',
  templateUrl: './tuition-range.component.html',
  styleUrls: ['./tuition-range.component.scss']
})
export class TuitionRangeComponent implements OnInit {

  initialReferenceMonth: number;
  initialReferenceYear: number;
  finalReferenceMonth: number;
  finalReferenceYear: number;
  tuition: any;
  selectedTuitions: any[] = [];
  monthsReference: any[] = [{label: 'Janeiro', value: 0}, {label: 'Fevereiro', value: 1}, {label: 'Março', value: 2},
    {label: 'Abril', value: 3}, {label: 'Maio', value: 4}, {label: 'Junho', value: 5},
    {label: 'Julho', value: 6}, {label: 'Agosto', value: 7}, {label: 'Setembro', value: 8},
    {label: 'Outubro', value: 9}, {label: 'Novembro', value: 10}, {label: 'Dezembro', value: 11}];
  tuitions: any[];
  boatTuitions: any[];
  totalValue: number;
  totalDiscount: number;
  total: number;
  slingConfig: any;
  operationEnd: any;
  operationEndFormated: any;
  generatedTuitions: Tuition[];

  constructor(
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
    private tuitionService: TuitionService,
    private boatService: BoatService,
    private messageService: MessageUtil,
    public dialog: DialogService,
    private config: DynamicDialogConfig,
    private orderReferenceDateUtil: OrderReferenceDateUtil,
    private invoiceService: InvoiceService,
    private marinaOrderService: MarinaOrderService
  ) {
  }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.tuition = this.config.data.tuition;
    if (this.tuition) {
      this.findBoat();
    }
  }

  private loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        this.operationEnd = new Date(this.slingConfig.operationEnd);
        this.operationEndFormated = moment(this.operationEnd).locale('pt-br').format('LL');

      },
      (error) => null);
  }

  findBoat(): void {
    this.boatService.getById(this.tuition.boatId).subscribe((boat: Boat) => {
      const today = moment();
      const start = moment(boat.boatServices[0].contractStartDate, 'YYYY-MM-DD');
      if (today.isAfter(start)) {
        this.initialReferenceMonth = today.month();
        this.initialReferenceYear = today.year();
      } else {
        this.initialReferenceMonth = start.month();
        this.initialReferenceYear = start.year();
      }
      if (boat.boatServices[0].contractEndDate) {
        const end = moment(boat.boatServices[0].contractEndDate, 'YYYY-MM-DD');
        this.finalReferenceMonth = end.month();
        this.finalReferenceYear = end.year();
      }
    });
  }

  viewLaunch(): void {
    this.selectedTuitions = [];
    if (
      this.initialReferenceMonth >= 0 &&
      this.initialReferenceYear >= 0 &&
      this.finalReferenceMonth >= 0 &&
      this.finalReferenceYear >= 0
    ) {
      this.spinner.show();

      let referDate = new Date(Number(this.initialReferenceYear), Number(this.initialReferenceMonth), 1, 0, 0, 0, 0);
      const finalReferDate = new Date(Number(this.finalReferenceYear), Number(this.finalReferenceMonth), 1, 0, 0, 0, 0);
      this.boatTuitions = [];
      if (referDate > finalReferDate) {
        this.messageService.generateMessage('error', 'Data inválida', 'A data inicial deve ser inferior à data final');
        this.spinner.hide();
        return;
      }


      if (finalReferDate > this.operationEnd) {
        this.messageService.generateMessage(
          'error',
          'Data inválida',
          'A data final deve ser igual ou inferior à data final de operação da Marina: ' + this.operationEndFormated
        );
        this.spinner.hide();
        return;
      }


      while (referDate <= finalReferDate) {
        this.boatTuitions.push(
          {idServiceBoat: this.tuition.serviceBoatId, referenceDate: new Date(referDate)}
        );
        referDate = new Date(referDate.setMonth(referDate.getMonth() + 1));
      }
      this.tuitionService.preview(this.boatTuitions).subscribe(
        (tuitions) => {
          this.totalValue = 0;
          this.totalDiscount = 0;
          this.total = 0;
          this.tuitions = tuitions.map((tuition) => {
            const product = tuition.marinaOrder.orderItems[0].product;
            const service = tuition.marinaOrder.boat.boatServices.filter((x) => x.service.id === product.id);
            if (service.length > 0) {
              const referenceEnd = moment(tuition.dateReferences, 'YYYY-MM-DD').add(1, 'month').toDate();
              const referenceStart = moment(tuition.dateReferences, 'YYYY-MM-DD').toDate();
              if (
                new Date(this.tuition.contractStartDate) <= referenceEnd &&
                (!this.tuition.contractEndDate || (new Date(this.tuition.contractEndDate) >= referenceStart)
                )
              ) {
                tuition.value = service[0].value;
                tuition.discount = service[0].discount;
              } else {
                tuition.value = 0;
                tuition.discount = 0;
                tuition.marinaOrder.value = 0;
              }
              this.totalValue += tuition.value;
              this.totalDiscount += tuition.discount;
              tuition.marinaOrder.value = tuition.marinaOrder.orderItems.map((item) => {
                return item.itemValue - (item.discount ? item.discount : 0);
              }).reduce((a, b) => a + b, 0);
              this.total += tuition.marinaOrder.value;
            }
            return tuition;
          });
          this.spinner.hide();
        },
        (error) => {
          const exception = error.error.data.exception;
          this.messageService.generateMessage(exception.type, exception.title, exception.message);
          this.spinner.hide();
        }
      );
    } else {
      this.messageService.generateMessage('error', 'Campos obrigatórios', 'Preencha o mês e ano de início e fim do período.');
    }
  }

  generateTuition(): void {

    const boatTuitions = this.getBoatTuitions();
    this.spinner.show();

    if (StorageUtil.getMarinaId()===2)
        { Swal.fire({
                          title: "Fila de Processamento",
                          imageUrl: "assets/images/processoEasy.png",
                          imageWidth: 200,
                          imageHeight: 100,
                          text: "Sua solicitação foi recebida e está em processamento. Você pode acompanhar o processo no ícone a cima.",
                          showCloseButton: true,})}
    this.tuitionService.generateByBoat(boatTuitions).subscribe(
      () => {
        this.messageService.generateMessage('success', 'Sucesso', 'Mensalidades geradas com sucesso.');
        this.spinner.hide();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
        this.spinner.hide();
      }
    );
    this.viewLaunch();
    this.selectedTuitions = [];
  }

  calculateQuotaValue(value: number, quota: number): number {
    return (value * quota) / 100;
  }

  getPriceTypeName(priceType: string): string {
    switch (priceType) {
      case 'PerRangeLength':
        return 'Range';
      case 'FixedPerRange':
        return 'Fixo';
      case 'SimplePrice':
        return 'Simples';
      default:
        return '';
    }
  }

  getFontColor(tuition): string {
    if (tuition.marinaOrder.value === 0) {
      return 'green';
    }
    return '#2196F3';
  }

  hasContractEndDate(tuition: any): string {
    if (this.tuition.contractEndDate) {
      const referenceDate = moment(tuition.dateReferences, 'YYYY-MM-DD').toDate();
      const contractEndDate = new Date(this.tuition.contractEndDate);
      if (referenceDate.getMonth() === contractEndDate.getMonth() && referenceDate.getFullYear() === contractEndDate.getFullYear()) {
        return '#fddd33'; // amarelo
      } else if ((referenceDate.getMonth() === 11 && contractEndDate.getMonth() === 0) ||
        ((referenceDate.getMonth() + 1) === contractEndDate.getMonth() &&
          referenceDate.getFullYear() === contractEndDate.getFullYear())) {
        return '#7cf54a'; // verde
      } else if (referenceDate.getTime() > contractEndDate.getTime()) {
        return '#ea5b5b';
      }
    } else {
      return '';
    }
    return '';
  }

  isEnabled(tuition): boolean {
    return tuition.marinaOrder.orderStatus !== 'Ordered' && tuition.value > 0;
  }

  hasSelection(): any {
    const selection = this.selectedTuitions.filter(x => this.isEnabled(x));
    return selection?.length !== 0 ? selection : null;
  }

  dialogGenerateTuition(): void {
    if (this.hasSelection()) {
      Swal.fire({
        text: 'Deseja gerar os pedidos de mensalidades baseadas nos valores apresentados?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((isConfirm) => {
        if (isConfirm.value) {
          this.generateTuition();
        }
      });
    } else {
      this.messageService.generateMessage('warning', 'Atenção', 'Não há itens na seleção.');
    }
  }

  dialogGenerateInvoiceUnified(): void {
    this.orderReferenceDateUtil.selectDateForInvoices(this.slingConfig).then((datesSelection) => {
      if (datesSelection) {
        const dueDate = datesSelection.dueDate;
        const competenceDate = datesSelection.competenceDate;
        const referenceMonth = datesSelection.referenceMonth;
        const referenceYear = datesSelection.referenceYear;
        const maturityType = datesSelection.maturityType;
        this.spinner.show();
        const boatTuitions = this.getBoatTuitions();
        this.tuitionService.generateByBoat(boatTuitions).subscribe(
          (newTuitions) => {
            this.generatedTuitions = newTuitions;
            this.generateInvoice(competenceDate, dueDate, referenceMonth, referenceYear, maturityType);
          },
          (error) => {
            const exception = error.error.data.exception;
            this.messageService.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
          }
        );
      }
    });
  }

  async generateInvoice(competenceDate: Date, dueDate: Date, referenceMonth, referenceYear, maturityType): Promise<void> {
    let invoiceCustomerDTO = {
      referenceDate: new Date(),
      competenceDate,
      dueDate: maturityType === 'FIXED_DATE' ? dueDate : null,
      referenceMonth: referenceMonth + 1,
      referenceYear,
      customerIds: [],
      orderNumbers: [],
    };
    this.addOrdersId(invoiceCustomerDTO);
    invoiceCustomerDTO.customerIds = Array.from(new Set(invoiceCustomerDTO.customerIds));
    try {
      invoiceCustomerDTO = await this.checkQuotas(invoiceCustomerDTO);
    } catch (error) {
      const exception = error.error.data.exception;
      this.messageService.generateMessage(
        exception.type,
        exception.title,
        exception.message
      );
    }
    this.generateInvoices(invoiceCustomerDTO);
  }

  generateInvoices(invoiceCustomerDTO): void {
    this.spinner.show();
    this.invoiceService.generateInvoiceCustomers(invoiceCustomerDTO).subscribe(
      (data) => {
        this.messageService.generateMessage('success', 'Sucesso', 'Faturas geradas com sucesso.');
        this.viewLaunch();
        this.selectedTuitions = [];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
  }
  private getBoatTuitions(): any[]{
    const boatTuitions = [];
    this.selectedTuitions.forEach((t: any) => {
      if (this.isEnabled(t)) {
        boatTuitions.push(
          {
            idServiceBoat: this.tuition.serviceBoatId,
            referenceDate: moment(t.dateReferences, 'YYYY-MM-DD').toDate()
          }
        );
      }
    });
    return boatTuitions;
  }

  addOrdersId(invoiceCustomerDTO): void {
    this.generatedTuitions.forEach((tuition) => {
      invoiceCustomerDTO.orderNumbers.push(tuition.marinaOrder.orderNumber);
      if (tuition.marinaOrder.orderQuotas !== null && tuition.marinaOrder.orderQuotas.length > 0) {
        tuition.marinaOrder.orderQuotas.forEach((quota) => invoiceCustomerDTO.customerIds.push(quota.customer.id));
      }
    });
  }

  async checkQuotas(invoiceCustomerDTO): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.marinaOrderService.checkQuotas(invoiceCustomerDTO).subscribe(
        async (customers) => {
          if (customers.length > 0) {
            this.spinner.hide();
            Swal.fire({
              title: 'Pedidos com rateio',
              text: 'Existe(m) pedido(s) selecionado(s) com rateio de cliente em outra empresa/grupo.\nDeseja continuar?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true,
            }).then(async (result) => {
              if (result.value) {
                invoiceCustomerDTO.customerIds =
                  invoiceCustomerDTO.customerIds.concat(customers);
                resolve(invoiceCustomerDTO);
              } else {
                reject();
              }
            });
          } else {
            resolve(invoiceCustomerDTO);
          }
        },
        async (err) => {
          reject(err);
        }
      );
    });
  }

  async dialogGenerateInvoiceSeparately(): Promise<void> {
    const selectedTuitions = this.selectedTuitions.filter(x => this.isEnabled(x));
    const slingConfig = this.slingConfig;
    const tuition = this.tuition;
    this.dialog.open(InvoiceGeneratedByTuitionComponent, {
      width: '800px',
      height: '650px',
      header: 'Gerar Faturas Separadamente',
      data: {
        selectedTuitions,
        slingConfig,
        tuition
      }
    }).onClose.subscribe(
      (data) => {
        this.viewLaunch();
        this.selectedTuitions = [];
      });
  }
}
