<div class="dialog-form" >
  <div class="form-group">
  <div class="form-control">
    <label>{{ "Número Inicial" | translate }}</label>
    <p-inputNumber
      name="lot"
      [(ngModel)]="nStart"
      appendTo="body"
      [showButtons]="true"
      [minFractionDigits]="0"
      [maxFractionDigits]="0"
    ></p-inputNumber>
    <span style="font-size: smaller; font-weight: bold;">{{ "Ultimo Número " + (nLast === undefined ? "0" : nLast) }}</span>
  </div>
  <div class="form-control">
    <label>{{ "Número Final" | translate }}</label>
    <p-inputNumber
      name="block"
      [(ngModel)]="nEnd"
      appendTo="body"
      [showButtons]="true"
      [minFractionDigits]="0"
      [maxFractionDigits]="0"
    ></p-inputNumber>
  </div>
</div>

  <div class="buttons">
    <div class="form-button">
      <button (click)="clear()" class="easymarine danger" type="button">
        {{ "CANCEL" | translate }}
      </button>
    </div>
    <div class="form-button">
      <button class="easymarine primary" type="button" (click)="save()">
        {{ "Gerar Títulos" | translate }}
      </button>
    </div>
  </div>
</div>
