import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { Marina, OrderQuota, SlingConfig } from 'src/app/models';
import { Invoice } from 'src/app/models/invoice';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { StorageUtil } from 'src/app/utils/storage.util';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { NotaFiscalService } from '../../../../services/nota-fiscal.service';
import { GenerateDocumentResponse } from '../../../../models/dtos/generate-document-response';

@Component({
  selector: 'app-emitter-nf',
  templateUrl: './emitter-nf.component.html',
  styleUrls: ['./emitter-nf.component.scss'],
})
export class EmitterNfComponent implements OnInit {
  invoiceList: any;
  selectedinvoices: any;
  slingConfig: SlingConfig;
  cityTax: number;
  blockProduct: boolean = true;
  blockService: boolean = true;
  allService: boolean = true;
  allProduct: boolean = true;
  m1: boolean = true;
  m2: boolean = true;
  competencia: string = 'Data de Competência';

  invoiceListAbleToGenerateNFC: Invoice[];
  enableNFConsumidor = true;
  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private invoiceService: InvoiceService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private operationalConfigService: OperationalConfigService,
    private notaFiscalService: NotaFiscalService
  ) {}
  competencyDropdown: any[] = [
    { value: 0, label: 'Selecione Data de competência' },
    { value: 1, label: 'Data de emissâo da fatura' },
    { value: 2, label: 'Data de vencimento da fatura' },
    { value: 3, label: 'Data de competência da fatura' },
    { value: 4, label: 'Data de emissão da nota fiscal' },
  ];
  ngOnInit(): void {
    this.slingConfig = history.state.slingConfig;
    this.cityTax = this.slingConfig.cityTax;
    this.getInvoiceList(history.state.invoiceList);

    const marina: Marina = StorageUtil.getMarina();
    this.getConfig();
    this.blockService = !marina.enableInvoice;
    switch (this.slingConfig.competency) {
      case 0:
        this.competencia = 'Sem Competência selecionada';
        break;
      case 1:
        this.competencia = 'Data de emissâo da fatura';
        break;
      case 2:
        this.competencia = 'Data de vencimento da fatura';
        break;
      case 3:
        this.competencia = 'Data de competência da fatura';
        break;
      case 4:
        this.competencia = 'Data de emissão da nota fiscal';
        break;
    }
  }

  async getConfig() {
    this.allProduct = await this.operationalConfigService.hasNFProduct();
    this.blockProduct = await this.operationalConfigService.hasNFProduct();
    this.blockProduct = !this.blockProduct;
    this.enableNFConsumidor =
      await this.operationalConfigService.hasNFConsumer();
  }

  closeM1(): void {
    this.m1 = false;
  }

  closeM2(): void {
    this.m2 = false;
  }

  loadItens() {
    if (
      this.invoiceList === undefined ||
      this.slingConfig === undefined ||
      this.invoiceList.length === 0 ||
      this.slingConfig === null
    ) {
      this.router.navigate(['/app/billing/invoice']);
    }
  }

  getCompetence(inv: Invoice): string {
    switch (this.slingConfig.competency) {
      case 0:
        return '00/00/00';
      case 1:
        return moment(inv.issuedate).format('DD/MM/YY');
      case 2:
        return moment(inv.dueDate).format('DD/MM/YY');
      case 3:
        return moment(inv.competenceDate).format('DD/MM/YY');
      case 4:
        return moment(Date.now()).format('DD/MM/YY');
    }

    return '00/00/00';
  }

  calcTotal(invoice: any): number {
    if (+invoice.totalService === 0) {
      return 0;
    }
    if (this.slingConfig.discountInInvoiceDoc) {
      if (
        invoice.dueDateDiscount === null ||
        invoice.dueDateDiscount === undefined ||
        invoice.dueDateDiscount === 0
      ) {
        return invoice.totalService;
      }
      let duedateDiscount = invoice.dueDateDiscount;
      let totalDiscountPay = invoice.invoicePaids
        .filter((inv) => inv.canceled === false)
        .reduce((acc, inv) => acc + inv.discount, 0);
      if (
        duedateDiscount === null ||
        duedateDiscount === undefined ||
        duedateDiscount === 0
      ) {
        return invoice.totalService;
      }
      if (duedateDiscount <= totalDiscountPay) {
        return invoice.totalService - +duedateDiscount.toFixed(2);
      } else {
        return invoice.totalService - +totalDiscountPay.toFixed(2);
      }
    }
    return invoice.totalService;
  }

  getInvoiceList(invoices): void {
    let listIds: number[] = [];
    invoices.forEach((invoice) => {
      listIds.push(invoice.id);
    });
    this.spinner.show();
    this.invoiceService.getInvoiceList(listIds).subscribe(
      (data) => {
        this.invoiceList = data;

        if (
          this.invoiceList.length > 0 &&
          this.invoiceList[0].marinaCompany != null
        ) {
          this.cityTax = this.invoiceList[0].marinaCompany.cityTax;
        }

        for (let i = 0; i < this.invoiceList.length; i++) {
          this.checkNFsValid(this.invoiceList[i]);
          this.checkNFpValid(this.invoiceList[i]);
          // this.checkNFCAndAddTolist(this.invoiceList[i])
        }

        this.loadItens();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  freeToIssue(invoice): boolean {
    if (invoice.nfeNumber === null || invoice.nfeNumber === undefined) {
      return true;
    }
    if (invoice.invoiceLogs !== undefined && invoice.invoiceLogs.length !== 0) {
      const last = JSON.parse(
        invoice.invoiceLogs[invoice.invoiceLogs.length - 1].response
      );
      if (last.nfeStatus === 'Negada' || last.nfeStatus === 'Cancelada') {
        return true;
      }
    }
    return false;
  }

  checkNFsValid(invoice): void {
    let valid: boolean = false;
    let totalService: number = 0;
    let totalServiceTax: number = 0;
    if (this.freeToIssue(invoice)) {
      for (let i = 0; i < invoice.orders.length; i++) {
        let totalServiceT: number = 0;
        let totalServiceTaxT: number = 0;
        for (let j = 0; j < invoice.orders[i].orderItems.length; j++) {
          if (
            (invoice.orders[i].orderItems[j].product.issueNotaFiscal === true &&
              (invoice.orders[i].orderItems[j].product.productType ===
                'Service' ||
                invoice.orders[i].orderItems[j].product.productType ===
                  'PeriodicalService')) ||
            invoice.orders[i].orderItems[j].product.productType ===
              'PeriodicalServiceCustomer' ||
            invoice.orders[i].orderItems[j].product.productType ===
              'ServiceCustomer'
          ) {
            totalServiceT += invoice.orders[i].orderItems[j].itemValueTotal;
            totalServiceTaxT +=
              invoice.orders[i].orderItems[j].itemValueTotal *
              (this.cityTax / 100);
            valid = true;
          }
        }
        let orderQuota: OrderQuota = invoice.orders[i].orderQuotas.find(
          (quota) => quota.customer.id === invoice.customer.id
        );
        let cota: number = orderQuota.quota;
        if (cota != null && cota != undefined) {
          cota = cota / 100;
          totalService += totalServiceT * cota;
          totalServiceTax += totalServiceTaxT * cota;
        } else {
          totalService += totalServiceT;
          totalServiceTax += totalServiceTaxT;
        }
      }
    }
    invoice.emitNfs = valid;
    invoice.totalService = totalService.toFixed(2);
    invoice.totalServiceTax = totalServiceTax.toFixed(2);
  }

  checkNFpValid(invoice): void {
    let valid: boolean = false;
    let totalProduct: number = 0;
    let totalProductTax: number = 0;
    if (invoice.nfeNumber === null) {
      for (let i = 0; i < invoice.orders.length; i++) {
        for (let j = 0; j < invoice.orders[i].orderItems.length; j++) {
          if (
            invoice.orders[i].orderItems[j].product.issueNotaFiscal === true &&
            invoice.orders[i].orderItems[j].product.productType === 'Product'
          ) {
            totalProduct += invoice.orders[i].orderItems[j].itemValueTotal;
            totalProductTax +=
              invoice.orders[i].orderItems[j].itemValueTotal *
              ((invoice.orders[i].orderItems[j].product.icms +
                invoice.orders[i].orderItems[j].product.pis +
                invoice.orders[i].orderItems[j].product.cofins) /
                100);
            valid = true;
          }
        }
      }
    }
    invoice.emitNfp = valid;
    invoice.totalProduct = totalProduct;
    invoice.totalProductTax = totalProductTax;
  }
  checkNFCAndAddTolist(invoice: any) {
    let valid: boolean = false;
    let totalProduct: number = 0;
    let totalProductTax: number = 0;
    if (invoice.nfcNumber === null) {
      for (let i = 0; i < invoice.orders.length; i++) {
        for (let j = 0; j < invoice.orders[i].orderItems.length; j++) {
          if (
            invoice.orders[i].orderItems[j].product
              .issueNotaFiscalConsumidor === true
          ) {
            totalProduct += invoice.orders[i].orderItems[j].itemValueTotal;
            totalProductTax +=
              invoice.orders[i].orderItems[j].itemValueTotal *
              ((invoice.orders[i].orderItems[j].product.icms +
                invoice.orders[i].orderItems[j].product.pis +
                invoice.orders[i].orderItems[j].product.cofins) /
                100);
            valid = true;
          }
        }
      }
    }
    invoice.emitNfc = valid;
    invoice.totalProduct = totalProduct;
    invoice.totalProductTax = totalProductTax;
  }
  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Extrato da fatura - PDF',
    });
  }

  getLastPayment(invoicePaids: InvoicePaid[]): string {
    if (invoicePaids !== null && invoicePaids.length > 0) {
      const filter = invoicePaids.filter((payment) => !payment.canceled);
      if (filter.length > 0) {
        const lastPayment = filter[filter.length - 1];
        const lastDatePayment = moment(lastPayment.datePaid).format('DD/MM/YY');
        return `Último pgto. em ${lastDatePayment}`;
      }
      return '';
    }
  }

  closeForm(): void {
    this.router.navigate(['/app/billing/invoice'],{state:{filter:history.state.filter}});
  }

  addOrRemoveAllService() {
    if (this.allService) {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (
          this.invoiceList[i].orders.length !== 0 &&
          this.invoiceList[i].status !== 'CANCELED' &&
          (this.invoiceList[i].nfeNumber === null ||
            this.freeToIssue(this.invoiceList[i]) === true) &&
          this.invoiceList[i].totalService > 0
        ) {
          console.log(i, ', vai emitir nfs');
          this.invoiceList[i].emitNfs = true;
        }
      }
    } else {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (
          this.invoiceList[i].orders.length !== 0 &&
          this.invoiceList[i].status !== 'CANCELED' &&
          this.freeToIssue(this.invoiceList[i]) === true &&
          this.invoiceList[i].totalService > 0
        ) {
          console.log(i, ', vai emitir nfs');
          this.invoiceList[i].emitNfs = false;
        }
      }
    }
  }

  addOrRemoveAllProduct() {
    if (this.allProduct) {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (
          this.invoiceList[i].nfeNumberProduct === null &&
          this.invoiceList[i].orders.length !== 0 &&
          this.invoiceList[i].status !== 'CANCELED' &&
          this.invoiceList[i].totalProduct > 0 &&
          !this.blockProduct
        ) {
          console.log(i, ', vai emitir nfs');
          this.invoiceList[i].emitNfp = true;
        }
      }
    } else {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (
          this.invoiceList[i].orders.length !== 0 &&
          this.invoiceList[i].status !== 'CANCELED' &&
          this.invoiceList[i].totalProduct > 0 &&
          !this.blockProduct
        ) {
          console.log(i, ', vai emitir nfs');
          this.invoiceList[i].emitNfp = false;
        }
      }
    }
  }

  gerarNf() {
    Swal.fire({
      title: 'Geração de NF',
      text: `Confirma a geração das notas fiscais selecionadas?`,
      icon: 'warning',
      showCancelButton: true,
      backdrop: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
    }).then((result2) => {
      if (result2.value) {
        this.generateNfs();
        this.generateNfp();
        // this.generateNfc();
        this.spinner.hide();
        // this.closeForm();
      }
    });
  }

  generateNfs(): void {
    this.spinner.show();
    const selectedInvoices = this.invoiceList.filter(
      (inv) => inv.emitNfs && inv.totalService > 0
    );
    if (selectedInvoices.length === 0) {
      this.spinner.hide();
      return;
    }
    if (selectedInvoices.map((inv) => inv.id).join().length <= 0) {
      this.spinner.hide();
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Sem notas de Serviço selecionadas ou válidas;'
      );
      return;
    }
    console.log(selectedInvoices);
    this.invoiceService
      .generateDocuments(selectedInvoices.map((inv) => inv.id).join())
      .subscribe({
        next: (data) => {
          this.spinner.hide();

          if (data.rejected.length < 1) {
            this.messageUtil
              .generateMessage(
                'success',
                'SUMMARY.SUCCESS',
                selectedInvoices.length === 1
                  ? 'MSG.GENERATED-INVOICE-DOCUMENT'
                  : 'MSG.GENERATED-INVOICE-DOCUMENTS'
              )
              .then(() => {
                this.router.navigate(['/app/billing/invoice']);
              });
          } else {
            this.messageUtil
              .generateMessageParameter(
                'warning',
                'SUMMARY.WARNING',
                selectedInvoices.length === 1
                  ? 'MSG.REJECTED-GENERATE-INVOICE-DOCUMENT'
                  : 'MSG.REJECTED-GENERATE-INVOICE-DOCUMENTS',
                { value: data.rejected.join(', ') }
              )
              .then(() => {
                this.router.navigate(['/app/billing/invoice']);
              });
          }
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
    this.spinner.hide();
  }

  generateNfp(): void {
    console.log(this.blockProduct);
    if (this.blockProduct) {
      return;
    }
    this.spinner.show();

    const selectedInvoices = this.invoiceList.filter(
      (inv) => inv.emitNfp && inv.totalProduct > 0
    );
    if (selectedInvoices.length === 0) {
      this.spinner.hide();
      return;
    }
    if (selectedInvoices.map((inv) => inv.id).join().length <= 0) {
      this.spinner.hide();
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Sem notas de produtos selecionadas ou válidas;'
      );
      return;
    }
    this.invoiceService
      .generateDocumentsProduct(selectedInvoices.map((inv) => inv.id).join())
      .subscribe({
        next: (data) => {
          this.spinner.hide();
          this.processRejectedInvoices(data, selectedInvoices);
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
    this.spinner.hide();
  }

  private generateNfc() {
    if (!this.enableNFConsumidor) {
      return;
    }
    this.spinner.show();
    const selectedInvoices = this.invoiceList.filter(
      (inv) => inv.emitNfc && inv.totalProduct > 0
    );
    if (selectedInvoices.length === 0) {
      this.spinner.hide();
      return;
    }
    this.notaFiscalService
      .generateNFConsumidorList(selectedInvoices.map((inv) => inv.id))
      .subscribe({
        next: (data) => {
          this.processRejectedInvoices(data, selectedInvoices);
        },
        error: (err) => {
          this.spinner.hide();
          console.log(err);
          const exception = err?.error?.data?.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
  }
  private processRejectedInvoices(
    data: GenerateDocumentResponse,
    selectedInvoices
  ) {
    console.log('aaaaaaaaaaaaaaaaa');
    if (data.rejected.length < 1) {
      this.messageUtil
        .generateMessage(
          'success',
          'SUMMARY.SUCCESS',
          selectedInvoices.length === 1
            ? 'MSG.GENERATED-INVOICE-DOCUMENT'
            : 'MSG.GENERATED-INVOICE-DOCUMENTS'
        )
        .then(() => {
          this.router.navigate(['/app/billing/invoice']);
        });
    } else {
      this.messageUtil
        .generateMessageParameter(
          'warning',
          'SUMMARY.WARNING',
          selectedInvoices.length === 1
            ? 'MSG.REJECTED-GENERATE-INVOICE-DOCUMENT'
            : 'MSG.REJECTED-GENERATE-INVOICE-DOCUMENTS',
          { value: data.rejected.join(', ') }
        )
        .then(() => {
          this.router.navigate(['/app/billing/invoice']);
        });
    }
  }
}
