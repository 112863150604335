import { Token } from './../models/token';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Empresa } from '../models/finance/empresa';
import { Usuario } from '../models/finance/usuario';
import { Marina } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageUtil } from '../utils/storage.util';

@Injectable()
export class AuthenticationService {
    // tslint:disable-next-line: deprecation
    constructor(private http: HttpClient) {
    }

    login(body: { username: string, password: string }): Observable<any> {
        return this.http.post(
            environment.apiHost + '/user/login', body,
            { headers: { 'Content-Type': 'application/json', Client: 'EasyWeb' } }
        ).pipe(
            map((response: any) => {
                return {refreshToken: response.data.refreshToken, token: response.data.token };
            })
        );
    }


    refresh() {
        const token = StorageUtil.getToken();
        const refreshToken = StorageUtil.getRefreshToken();

        if (token && refreshToken) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Client: 'EasyWeb',
            }),
          };
          return this.http
            .post(
              environment.apiHost + '/user/refresh',
              { refreshToken },
              httpOptions
            )
            .pipe( 
                map((response: any) => {

                  const user = JSON.parse(JSON.stringify(response.data.user));
                  if (user && user.token) {
                    const newToken = response.data.user.token;
                    StorageUtil.saveToken(newToken);
                  }
                  
                })
            )
        }
        
    }


    

    getTokenFinanceiro(additionalCompany?: number): string {
        const user: Token = StorageUtil.getUser();
        const marina: Marina = StorageUtil.getMarina();

        const empresa: Empresa = {
            codigo: 1000 + marina.id,
            nome: marina.companyName,
            fantasia: marina.companyName,
            cnpj: marina.companyFederalId,
            telefone: marina.phone,
            endereco: marina.street,
            bairro: marina.district,
            cidade: marina.city,
            cep: marina.zipcode,
            email: marina.email,
            uf: marina.state,
            tipoSistema: 'PORTALPOSTAL'
        };

        let nomeDB = '';
        if (marina.operationalUnit && marina.operationalUnit.length > 0) {
            nomeDB = empresa.cnpj.replace(/[^a-zA-Z0-9]/g, '') + '_' + marina.operationalUnit ;
        } else {
            nomeDB = empresa.cnpj.replace(/[^a-zA-Z0-9]/g, '');
        }

        if (additionalCompany) {
            const marinaCompany = marina.marinaCompanies.filter((m) => m.id === additionalCompany);
            if (marinaCompany.length > 0) {
                empresa.nome = marinaCompany[0].companyName;
                empresa.fantasia = marinaCompany[0].companyFederalName;
                empresa.cnpj = marinaCompany[0].companyFederalId;
                if (marinaCompany[0].operationalUnit && marinaCompany[0].operationalUnit.length > 0) {
                    nomeDB = empresa.cnpj.replace(/[^a-zA-Z0-9]/g, '') + '_' + marinaCompany[0].operationalUnit;
                } else {
                    nomeDB = empresa.cnpj.replace(/[^a-zA-Z0-9]/g, '');
                }

            }
        }

        const usuario: Usuario = {
            codigo: user.userId,
            nome: user.firstName,
            login: user.username,
            perfil: '0',
            empresa,
            bancoDados: nomeDB
        };

        const base64 = btoa(encodeURIComponent(JSON.stringify(usuario)));
        return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
    }

  deactivateUser(body: any): Observable<any>{
      return this.http.post(environment.apiHost + '/user/unsubscribe', body).pipe(map((response: any) => response));
  }
}
