import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PanelConfig } from 'src/app/models';
import { PanelFunctionality } from 'src/app/models/enums';
import { RaspScreenOrientation } from 'src/app/models/enums/rasp-screen-orientation';
import { Raspberry } from 'src/app/models/raspberry';
import { PanelConfigService } from 'src/app/services/panel-config.service';
import { RaspberryService } from 'src/app/services/raspberry.service';
import { SlingMultiQueueService } from 'src/app/services/sling-multi-queue/sling-multi-queue.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-panel-config-dialog',
  templateUrl: './panel-config-dialog.component.html',
  styleUrls: ['./panel-config-dialog.component.scss']
})
export class PanelConfigDialogComponent implements OnInit {

  panelsConfig: PanelConfig[] = [new PanelConfig()];
  panelSelected = 0;
  panelFunctionalities: any = new Array();
  raspScreenOrientations: SelectItem[] = [
    { label: this.messageUtil.translateKey('PORTRAIT'), value: RaspScreenOrientation.PORTRAIT },
    { label: this.messageUtil.translateKey('PORTRAIT-INVERSE'), value: RaspScreenOrientation.PORTRAIT_INVERSE },
    { label: this.messageUtil.translateKey('LANDSCAPE'), value: RaspScreenOrientation.LANDSCAPE },
    { label: this.messageUtil.translateKey('LANDSCAPE-INVERSE'), value: RaspScreenOrientation.LANDSCAPE_INVERSE }
  ];
  loading = false;
  refreshTime: number;
  raspberries: any[];

  movementGroups: SelectItem[] = [];

  slingMultQueueOptions: SelectItem[] = [];
  raspberry: Raspberry;
  screenOrientation: RaspScreenOrientation = this.raspScreenOrientations[0].value;
  slingDownQueue = PanelFunctionality.SlingDownQueue;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private panelConfigService: PanelConfigService,
    private spinner: NgxSpinnerService,
    private raspberryService: RaspberryService,
    private messageUtil: MessageUtil,
    private slingMultiQueueService : SlingMultiQueueService
  ) {
    if (this.config.data && this.config.data.panelConfig) {
      this.panelsConfig = this.config.data.panelConfig;
      if (this.config.data.rasp) {
        this.raspberry = this.config.data.rasp;
        this.screenOrientation = this.config.data.rasp.screenOrientation;
      }
      if (this.config.data.id) {
        for (let i = 0; i < this.panelsConfig.length; i++) {
          if (this.panelsConfig[i].id === this.config.data.id) {
            this.panelSelected = i;
            break;
          }
        }
      }
    }
    if (this.config.data.movementGroups) {
      this.movementGroups = this.config.data.movementGroups;
    }
  }

  ngOnInit(): void {
    this.loadSlingMultQueue();
    this.getAllRaspberry();
    this.loadPanelFunctionality();
  }

  loadSlingMultQueue():void
  {
    this.slingMultiQueueService.getAllMarinaActive(true).subscribe(
      (data: any[]) => {
        this.slingMultQueueOptions = data.map((m) => ({ label: m.name, value: m.id }));
      },
      (error) => {
        this.spinner.hide();
        Swal.fire('Erro ao buscar configuração do Raspberry');
      }
    );
  }

  setMovementGroup($event) {
    console.log($event);
    let mg = this.movementGroups.find(mg => mg.value.name === $event.value);
    this.panelsConfig[this.panelSelected].movementGroupCode = mg.value.code;
    this.panelsConfig[this.panelSelected].movementGroupName = mg.value.name;
    console.log(this.panelsConfig[this.panelSelected]);
  }

  isDisabled(panelFunctionality): boolean {
    if (panelFunctionality !== null) {
      return panelFunctionality === 'Disabled';
    }
    return false;
  }

  async loadPanelFunctionality(): Promise<void> {
    this.panelFunctionalities = await this.panelConfigService.getPanelFunctionalities();
  }

  formValidations(form: NgForm): boolean {
    FormUtil.touchAndSendForm(form);
    if (form.valid && this.valideFuncionalities()) {
      return true;
    } else {
      Swal.fire('Alerta!', 'Por favor, corrija os campos obrigatórios', 'warning');
      return false;
    }
  }

  valideFuncionalities(): boolean {
    if (this.isMultPanel()) {
      for (const panel of this.panelsConfig) {
        if (!panel.panelFunctionality || !Object.values(PanelFunctionality).includes(panel.panelFunctionality)) {
          return false;
        }
        if (panel.observation === undefined || panel.observation === '') {
          return false;
        }
      }
    }
    return true;
  }

  configureLink(): void {
    const linkPanel = PanelConfigService.getLinkPanel().concat(this.raspberry.mac);
    this.panelsConfig.forEach(panel => panel.link = linkPanel);
  }

  configureRaspberry(): void {
    this.raspberry.screenOrientation = this.screenOrientation;
    this.panelsConfig.forEach(panel => panel.raspberry = this.raspberry);
  }

  configureCommonValues(): void {
    this.panelsConfig.forEach(panel => {
      panel.darkMode = this.panelsConfig[0].darkMode;
      panel.enableAnimation = this.panelsConfig[0].enableAnimation;
      panel.animationDelay = this.panelsConfig[0].animationDelay;
      panel.showInfo = this.panelsConfig[0].showInfo;
    });
  }

  save(): void {
    this.panelsConfig.forEach(panel => {

      if (panel.panelFunctionality !== 'MovementDownQueue') {
        panel.movementGroupCode = null;
        panel.movementGroupName = null;
      }
    });
    this.configureLink();
    this.configureRaspberry();
    this.spinner.show();
    let panels: PanelConfig[];
    if (this.screenOrientation === RaspScreenOrientation.PORTRAIT
      || this.screenOrientation === RaspScreenOrientation.PORTRAIT_INVERSE) {
      panels = [this.panelsConfig[0]];
    } else {
      this.configureCommonValues();
      panels = this.panelsConfig;
    }
    this.panelConfigService.saveByMac(this.screenOrientation, panels).subscribe(
      (res) => {
        Swal.fire('Sucesso!', 'Configuração salva com sucesso', 'success');
        this.dialogRef.close();
      },
      (error) => {
        this.spinner.hide();
        Swal.fire('Erro ao salvar!', error.error.message, 'error');
      },
      () => this.spinner.hide());
  }

  private getAllRaspberry(): void {
    this.raspberryService.getAllRaspberry().subscribe(
      (data: Raspberry[]) => {
        this.raspberries = data.map((r) => ({ label: r.mac, value: r }));
        if (this.panelsConfig[0].id) {
          this.raspberries.unshift({ label: this.raspberry.mac, value: this.raspberry });
        }
      },
      (error) => {
        this.spinner.hide();
        Swal.fire('Erro ao buscar configuração do Raspberry');
      }
    );
  }

  getTabNamePainel(index: number): string {
    return `${this.messageUtil.translateKey('PANEL')} ${index + 1}`;
  }

  isMultPanel(): boolean {
    return this.panelsConfig.length > 1 &&
      (this.screenOrientation === RaspScreenOrientation.LANDSCAPE
        || this.screenOrientation === RaspScreenOrientation.LANDSCAPE_INVERSE);
  }

  changeScreenOrientation(event: any): void {
    if (this.panelsConfig.length === 1 &&
      (event.value === RaspScreenOrientation.LANDSCAPE
        || event.value === RaspScreenOrientation.LANDSCAPE_INVERSE)) {
      while (this.panelsConfig.length < 3) {
        this.panelsConfig.push(new PanelConfig());
      }
    }
  }
}
