import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SlingConfig } from '../models';

@Injectable()
export class SlingConfigService {

    constructor(private httpClient: HttpClient) { }

    getSlingConfig(): Observable<SlingConfig[]> {
        return this.httpClient.get(environment.apiHost + '/secure/sling-config/').pipe(
            map((response: any) => response.data.slingConfig)
        );
    }

    getDaysDue(): Observable<string> {
        return this.httpClient.get(environment.apiHost + '/secure/sling-config/days-due')
            .pipe(map((response: any) => response.data.daysDue as string));
    }

    save(slingConfig: any): Observable<any> {
        return this.httpClient.post(environment.apiHost + '/secure/sling-config/', slingConfig).pipe(
            map((response: any) => response.data.slingConfig)
        );
    }

    getSlingConfigToday(): Observable<any> {
        return this.httpClient.get(environment.apiHost + '/secure/sling-config/today/').pipe(
            map((response: any) => response.data.slingConfig)
        );
    }

    updateMarinaCompaniesAccount(companyDtoList: any[]): Observable<any> {
        return this.httpClient.put(environment.apiHost + '/secure/marinas/companies', companyDtoList).pipe(
            map((response: any) => response.data.companies)
        );
    }

    findByMarinaId(marinaId: number): Observable<any> {
        return this.httpClient.get(`${environment.apiHost}/api-core/secure/sling-multi-queue/marina/${marinaId}`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
    

}
