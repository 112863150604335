import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MultiQueue } from 'src/app/models/multi-queue';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlingMultiQueueService {


  apiPath: string = environment.apiHost + '/api-core/secure/sling-multi-queue';

  constructor(private http: HttpClient) {}

  getAllMarinaActive(status:boolean): Observable<MultiQueue[]> {
    return this.http
      .get(this.apiPath + '/active/'+status, )
      .pipe(map((response: any) => response as MultiQueue[]));
  }

    changeQueue(boatsIds: number[], queue: any) {
    return this.http.post(
      this.apiPath + '/change-queue/'+queue,

        boatsIds,


    );
  }

}
