import { Marina } from './marina';
import { PanelFunctionality } from './enums';
import { Advertisement } from './advertisement';
import { Raspberry } from './raspberry';
import { MovementGroupPanelDto } from './movement/movement-group-panel-dto';

export class PanelConfig {
    id: number;
    name: string;
    link: string;
    marina: Marina;
    showWeather: boolean;
    panelFunctionality: PanelFunctionality;
    observation: string;
    advertisements: Advertisement[] = [];
    showTime: boolean;
    darkMode: boolean;
    raspberry: Raspberry;
    showInfo: boolean;
    enableAnimation: boolean;
    animationDelay: number;
    displayCanceled: boolean;
    autoPaginate: boolean;
    blinkLastMovNavigation: boolean;
    blinkLastMovNavigationTimer: number;
    page: number;
    paginationRefreshTime: number;
    movementGroupCode: string;
    movementGroupName: string;
    slingMultQueue?:number;
    constructor() {
        this.marina = new Marina();
        this.darkMode = false;
        this.showTime = false;
        this.showWeather = false;
        this.showInfo = false;
        this.enableAnimation = false;
        this.displayCanceled = false;
        this.autoPaginate = false;
        this.page = 1;
        this.paginationRefreshTime = 10;
        this.slingMultQueue=null;
    }
}
