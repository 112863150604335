import { OperationalConfigService } from './../../../../services/config/operational-config.service';
import { BoatSailorPermission } from './../../../../models/enums/boat-sailor-permission';
import { BoatSailor } from './../../../../models/boat-sailor';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Boat, Customer, CustomerBoat, MarinaOrder, OrderQuota, Sling, SlingConfig } from 'src/app/models';
import { MessageSlingDay } from 'src/app/models/dtos/message-sling-day';
import { BoatStatus, SlingStatus } from 'src/app/models/enums';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageSlingDayUtil } from 'src/app/utils/message-sling-day.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingUtil } from 'src/app/utils/sling.util';
import Swal from 'sweetalert2';
import { MultipleDestinationsComponent } from '../multiple-destinations/multiple-destinations.component';
import { BoatFilterListWithCustomerDTO } from 'src/app/models/dtos/boat-filter-list-with-customerDTO';
import { NavigationPlanService } from 'src/app/services/navigation-plan.service';
import { OptionPrintQuestionTotem } from 'src/app/models/enums/option-print-question-totem';
import { PrintType } from 'src/app/models/enums/print-type';
import { PrintingService } from 'src/app/services/printing-service';

@Component({
  selector: 'app-sling-data',
  templateUrl: './sling-data.component.html',
  styleUrls: ['./sling-data.component.scss']
})
export class SlingDataComponent implements OnInit {

  @Input() slingConfig: SlingConfig;
  @Input() sling: Sling;
  @Input() isASlingEdition: boolean;
  @Output() eventSling: EventEmitter<number> = new EventEmitter();
  @Output() eventClose = new EventEmitter();
  @Output() eventClean = new EventEmitter();

  private listBoat: BoatFilterListWithCustomerDTO[];
  selectedBoatId: number;
  selectedCustomerId: number;
  selectedSailorId: number;
  selectedCustomer: Customer = new Customer();
  selectedBoatSailor: BoatSailor = new BoatSailor();
  boatsFilter: SelectItem[];

  customers: any[] = [];
  sailors: any[] = [];
  allCustomers: any[] = [];
  loading = false;
  requestDate: Date = new Date();
  startDate: Date = new Date();
  minReturnDate: Date = new Date();
  returnDate: Date;
  executionForecast: string;
  navigationPlanReturnTime: string;
  manualHour: boolean;
  frequentFestinations = [];

  disableFlag = false;
  boats: Boat[];
  selectedBoat: Boat = new Boat();
  customersBoat: CustomerBoat[];
  availablePeriods: any[];
  availablePeriodsSlingUp: any[];
  marinaOrder: MarinaOrder;
  cellphoneMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  timeMask = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
  passengersNumber: any[] = new Array();
  lessButtonRequestDate = true;
  isToten: boolean;
  searchByCustomer: boolean;

  messageDayExecutionForecast: MessageSlingDay;
  messageDayReturnDate: MessageSlingDay;

  hasChild: boolean = false;
  totalPassagers: number = 0;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private boatService: BoatService,
    private slingService: SlingService,
    private messageUtil: MessageUtil,
    private messageSlingDayUtil: MessageSlingDayUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private dialog: DialogService,
    public slingUtil: SlingUtil,
    private operationalConfigService: OperationalConfigService,
    private printingService: PrintingService
  ) { }

  async ngOnInit(): Promise<void> {
    this.hasChild = await this.operationalConfigService.hasChildControl()
      .then((hasChild: boolean) => { return hasChild; });

    this.spinner.show();
    this.requestDate = this.sling.executionForecast ? new Date(this.sling.executionForecast) : new Date();
    this.searchByCustomer = this.slingConfig.searchByCustomer;
    this.setFrequentDestination();
    await Promise.all([
      this.loadCustomers(),
      this.loadBoats(),
      this.getAvailablePeriods(this.requestDate),
    ]);

    if (this.isASlingEdition) {
      this.initSlingEdition();
    } else {
      this.sling.navigationPlan.responsible = new Customer();
      this.changeSearch();
    }

    this.minReturnDate = new Date(this.requestDate.getTime());
    this.minReturnDate.setHours(0, 0, 0, 0);
    this.startDate.setHours(0, 0, 0, 0);
    this.spinner.hide();
   }


   hasEdit(sling: Sling): boolean {
    return sling && sling.slingStatus === 'PENDING_DOWN'  &&
      (
        this.slingConfig.editSlingInPreparation ===true ||
        sling.boatStatus === BoatStatus.WAITING_PREPARATION &&
        sling.marinaOrder.orderItems.filter((item) => item.qtySupplied === null || item.qtySupplied === undefined).length === 0
      );
  }

  initSlingEdition(): void {
    this.selectedBoatId = this.sling.boat.id;
    this.selectedCustomerId = this.sling.navigationPlan.responsible.id;
    this.selectedSailorId = this.sling.boatSailor?.sailor?.id;
    this.boatsFilter = this.listBoat ? this.listBoat.filter((boat) => boat.id === this.selectedBoatId).map((boat) => ({
      label: boat.name.concat(boat.maintenance ? ' (Em manutenção)' : ''),
      value: boat.id,
      disabled: boat.maintenance
    })) : [];
    this.selectedCustomer = this.allCustomers.find((obj) =>
      obj.id === this.selectedCustomerId);
    this.loadAssociatedCustomers();
    this.selectSailor();
  }

  getReturnHour(): void {
    const matchedOption = this.availablePeriodsSlingUp.find(
      (option) => option.label === this.navigationPlanReturnTime
    );

    if (matchedOption) {
      this.sling.navigationPlan.returnTime = matchedOption.value;
      this.navigationPlanReturnTime = matchedOption.value; // Atualiza também o estado vinculado
    }
  }

  async loadBoats(): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        this.boatService.getBoatFilterListWithCustomer().subscribe(
          (data) => {
            this.listBoat = data;
            resolve(data);
          },
          () => {
            this.boats = null;
            resolve(null);
          }
        );
      }
    );

  }

  selectCustomer(): void {
    if (this.searchByCustomer) {
      this.selectedCustomer = this.allCustomers.find((obj) =>
        obj.id === this.selectedCustomerId);
      this.filterBoat();
    } else {
      this.selectedCustomer = this.sling.boat.boatCustomers.find((obj) =>
        obj.customer.id === this.selectedCustomerId).customer;
    }
    this.sling.marinaOrder.customer = this.selectedCustomer;
    this.sling.navigationPlan.responsible = this.selectedCustomer;
    if (!this.isASlingEdition) {
      this.defineQuotaNewSling();
    }
    this.setOnBoardPhone();
  }

  selectSailor() {
    this.selectedBoatSailor = this.sling.boat.boatSailors.find(boatSailor => boatSailor.sailor.id === this.selectedSailorId);
    this.sling.boatSailor = this.selectedBoatSailor;
  }

  loadOptionsSailorByBoat() {
    this.sailors = [];
    const boatSailors = this.sling.boat.boatSailors;
    boatSailors.forEach(boatSailor => {
      if (boatSailor.permission.includes(BoatSailorPermission.SlingCreate)) {
        const sailor = { label: `${boatSailor.sailor.name} ${boatSailor.sailor.lastName}`, value: boatSailor.sailor.id };
        this.sailors.push(sailor);
      }
    });
  }

  loadOptionsCustomersByBoat(): void {
    this.customers = this.sling.boat.boatCustomers.map((obj) => ({
      label: obj.customer.name.concat((obj.type === 'Owner' ? ' (Proprietário)' : ' (Autorizado)')),
      value: obj.customer.id
    }));
  }

  async loadAssociatedCustomers(): Promise<void> {
    this.customers = [];
    if (this.boatsFilter.find((boat) => boat.value === this.selectedBoatId && boat.disabled) === undefined) {
      if (this.selectedBoatId) {
        this.sling.boat = await this.getBoat(this.selectedBoatId);
        if (this.slingConfig?.typeBlockCustomer?.valueOf() === 'boat') {
          // @ts-ignore
          if (this.sling.boat.boatCustomers.find(item => item.blocked)) {
            Swal.fire({
              title: 'Atenção',
              text: 'Embarcação possui bloqueio no financeiro.',
              icon: 'warning',
              reverseButtons: true
            });
          }
        }
        this.loadPassengersNumber();
        this.loadOptionsCustomersByBoat();

        if (this.customers.length === 1 && !this.isASlingEdition) {
          this.selectedCustomerId = this.customers[0].value;
          this.selectCustomer();
        }
      }
      this.loadOptionsSailorByBoat();
    }
    this.getAvailablePeriods(this.requestDate);
  }

  loadPassengersNumber() {
    return (this.sling.boat.crew ? this.sling.boat.crew : 0) + this.sling.boat.passengersDay;
  }

  setOnBoardPhone(): void {
    if (!this.searchByCustomer) {
      this.sling.navigationPlan.responsible = {
        ...this.sling.boat.boatCustomers
          .find((c) => c.customer.id === this.sling.navigationPlan.responsible.id).customer
      };
    } else {
      this.sling.navigationPlan.responsible = { ...this.allCustomers.find((c) => c.id === this.sling.navigationPlan.responsible.id) };
    }
    this.sling.navigationPlan.onBoardPhone = this.sling.navigationPlan.responsible.phone;
  }

  async getAvailablePeriods(date: any): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.minReturnDate = new Date();
      this.minReturnDate.setHours(0, 0, 0, 0);
      this.slingService.getAvailablePeriods(date.getTime(), this.sling?.boat?.slingQueueType?.id??0).subscribe({
        next: async (data) => {

          this.availablePeriods = data.map((hour) => ({ label: moment(new Date(hour)).format('HH:mm'), value: hour }));
          if (this.sling.id && this.sling.executionForecast &&
            (date).setHours(0, 0, 0, 0) === new Date(this.sling.executionForecast).setHours(0, 0, 0, 0)) {
            this.executionForecast = moment(new Date(this.sling.executionForecast)).format('HH:mm');
            this.availablePeriods
              .unshift(
                {
                  label: moment(new Date(this.sling.executionForecast)).format('HH:mm'),
                  value: this.sling.executionForecast
                }
              );
          }
          this.checkDayExecutionForecast();
          await this.getAvailablePeriodsSlingUp().then(() => {
            this.getReturnHour();
          });
          resolve();
        },
        error: async (error) => {
          this.spinner.hide();
          if (error.error.data.exception.message === 'NO-HOURS-AVAILABLE' && this.sling.id && this.sling.executionForecast) {
            this.availablePeriods = new Array();
            this.availablePeriods
              .push(
                {
                  label: moment(new Date(this.sling.executionForecast)).format('HH:mm'),
                  value: this.sling.executionForecast
                }
              );

          } else {
            this.availablePeriods = null;
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
          this.checkDayExecutionForecast();
          await this.getAvailablePeriodsSlingUp();
          resolve();
        }}
      );
    });
  }

  pushReturnTimeSling(): void {
    this.navigationPlanReturnTime = moment(new Date(this.returnDate)).format('HH:mm');
  }

  async changeExecutionForecast(date): Promise<void> {
    this.sling.executionForecast = null;
    this.sling.navigationPlan.returnTime = null;
    this.returnDate = null;
    this.availablePeriodsSlingUp = [];
    await this.getAvailablePeriods(date);
    this.getAvailablePeriodsSlingUp();
  }

  async getAvailablePeriodsSlingUp(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.spinner.show();

      if (!this.returnDate) {
        if (!this.sling.navigationPlan.returnTime) {
          this.returnDate = this.requestDate;
        } else {
          this.returnDate = new Date(this.sling.navigationPlan.returnTime);
        }
      }

      this.availablePeriodsSlingUp = null;
      if (this.returnDate) {
        this.slingService.getAvailablePeriodsSlingUP(this.returnDate.getTime(), this.sling?.boat?.slingQueueType?.id??0).subscribe({
          next: (data) => {
            const specialDay = this.slingConfig.operationSpecialDays.find(specialDay => this.messageSlingDayUtil.equalsDays(new Date(specialDay.date), this.returnDate));
            if (specialDay) {
              data = data.filter(hour => moment(hour).isSameOrAfter(moment(specialDay.operationStart)) && this.verifyReturnAfterExecutionInSomeDay(hour));
            }
            if (this.sling.executionForecast && this.requestDate == this.returnDate) data = data.filter(hour => new Date(hour).getTime() >= new Date(this.sling.executionForecast).getTime());
            this.availablePeriodsSlingUp = data.map((hour) => ({ label: moment(hour).format('HH:mm'), value: hour }));

            this.availablePeriodsSlingUp = data.map((hour) => ({ label: moment(new Date(hour)).format('HH:mm'), value: hour }));
            if (this.availablePeriodsSlingUp.length > 0 && !this.sling.id) {
              this.sling.navigationPlan.returnTime = this.availablePeriodsSlingUp[this.availablePeriodsSlingUp.length - 1].value;
              this.navigationPlanReturnTime = moment(new Date(
                this.availablePeriodsSlingUp[this.availablePeriodsSlingUp.length - 1].value
              )).format('HH:mm');


              } else {
              if (this.sling.navigationPlan.returnTime &&
                (this.returnDate).setHours(0, 0, 0, 0) === new Date(this.sling.navigationPlan.returnTime).setHours(0, 0, 0, 0)) {
                this.availablePeriodsSlingUp
                  .unshift(
                    {
                      label: moment(new Date(this.sling.navigationPlan.returnTime)).format('HH:mm'),
                      value: this.sling.navigationPlan.returnTime
                    }
                  );
              }
            }
            this.pushReturnTimeSling();
            this.spinner.hide();
            this.checkDayReturnDate();
            resolve();
          },
          error: async (error) => {
            this.spinner.hide();
            if (this.sling.id && this.returnDate) {
              this.availablePeriodsSlingUp = [];
              this.availablePeriodsSlingUp.push(
                {
                  label: moment(new Date(this.returnDate)).format('HH:mm'),
                  value: this.returnDate
                }
              );
              this.sling.navigationPlan.returnTime = new Date(this.sling.navigationPlan.returnTime);
              this.navigationPlanReturnTime = moment(new Date(this.returnDate)).format('HH:mm');
            } else {
              const exception = error.error.data.exception;
              if (!this.messageDayExecutionForecast || !this.messageSlingDayUtil.equalsDays(this.returnDate, this.messageDayExecutionForecast.date)) {
                this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
              }
              this.availablePeriodsSlingUp = [];
            }
            this.checkDayReturnDate();
            resolve();
          }
        });
      }
    });
  }

  verifyReturnAfterExecutionInSomeDay(hour: Date): boolean {
    if (!moment(this.sling.executionForecast).isValid() || !this.messageSlingDayUtil.equalsDays(moment(this.sling.executionForecast).toDate(), moment(hour).toDate())) {
      return true;
    }
    return moment(hour).isSameOrAfter(moment(this.sling.executionForecast));
  }

  private defineQuotaNewSling(): void {
    let idCustomer = null;
    const quotas = [];
    const customerBoat = this.sling.boat.boatCustomers
      .find((cb) => cb.customer.id === this.sling.marinaOrder.customer.id);

    if (!customerBoat) {
      return;
    }

    const quota: OrderQuota = new OrderQuota();
    quota.quota = 100;
    if (customerBoat.associatedOwner) {
      quota.customer = customerBoat.associatedOwner;
      idCustomer = customerBoat.associatedOwner.id;
    } else {
      quota.customer = customerBoat.customer;
      idCustomer = customerBoat.customer.id;
    }
    quotas.push(quota);
    this.sling.boat.boatCustomers.forEach((cb) => {
      if (!cb.associatedOwner && cb.customer.id !== idCustomer) {
        const q: OrderQuota = new OrderQuota();
        q.quota = 0;
        q.customer = cb.customer;
      }
    });
    this.sling.marinaOrder.orderQuotas = quotas;
  }

  continueSaveFuelData(closeDialog: boolean, form): void {
    if (!form.form.valid && this.slingUtil.hasCancel(this.sling)) {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'MSG.FIELD-REQUIRED');
      return;
    }

    if (closeDialog) {
      this.spinner.show();
      this.slingService.save(this.sling).subscribe({
        next: (data) => {
          this.toasty.success(this.messageUtil.translateKey('MSG.INSERT-SUCCESS'));
          this.loading = false;
          this.disableFlag = false;
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.Question) {
            Swal.fire({
              title: 'Atenção',
              text: 'Deseja imprimir o plano de navegação?',
              icon: 'warning',
              reverseButtons: true,
              showDenyButton: true,
              customClass: {
                confirmButton: ['new-easymarine', 'primary'],
                denyButton: ['new-easymarine', 'disabled']
              },
              confirmButtonText: 'SIM',
              denyButtonText: 'NÃO',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await this.printNavitagionPlan(data.id);
                this.close('reload');
              }
              if (result.isDenied || result.isDismissed) {
                this.close('reload');
              }
            });
          }
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.AutoPrint) {
            this.printNavitagionPlan(data.id);
            this.close('reload');
          }
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.NoAction) {
            this.close('reload');
          }
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          this.loading = false;
          this.disableFlag = false;
        },
        complete: () => this.spinner.hide()
      });
    } else {
      if (this.slingConfig.hasFuelSupply || this.slingConfig.hasConsumables) {
        this.eventSling.emit(this.slingConfig.hasFuelSupply ? 1 : 2);
      } else {
        this.eventSling.emit(3);
      }
    }
  }

  saveSling(closeDialog: boolean, form): void {
    if (this.sling.boat.franchiseUseInCurrentMonth>0 && this.sling.boat.franchiseGroup!=null &&
      this.sling.boat.franchiseUseInCurrentMonth>=this.sling.boat.franchiseGroup.franchise
    ) {
      if (this.sling.boatSailor != null){
        if (!this.sling.boatSailor.permission.includes(BoatSailorPermission.Franchise_use)){
          Swal.fire({
            title: 'Atenção',
            text: 'Este marinheiro não tem permissão para exceder a franquia..',
            icon: 'warning',
            reverseButtons: true
          });
          return;
        }
      }

    }
    if (this.sling.navigationPlan.crewQty == null || this.sling.navigationPlan.crewQty == undefined) {
      Swal.fire({
        title: 'Atenção',
        text: 'Passageiros não selecionados',
        icon: 'warning',
      });
      return;
    }
    if (this.sling.navigationPlan.destination == null || this.sling.navigationPlan.destination == undefined) {
      Swal.fire({
        title: 'Atenção',
        text: 'Destino não selecionado.',
        icon: 'warning',
      });
      return;
    }
    if (this.sling.navigationPlan.responsible.financialBlock) {
      Swal.fire({
        title: 'Atenção',
        text: 'Cliente possui bloqueio no financeiro. Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.saveSlingData(closeDialog, form);
        }
      });
    } else {
      this.saveSlingData(closeDialog, form);
    }
  }

  saveSlingData(closeDialog: boolean, form): void {
    if (this.manualHour) {
      this.requestDate.setHours(Number(this.executionForecast.substr(0, 2)));
      this.requestDate.setMinutes(Number(this.executionForecast.substr(3, 2)));
      this.sling.executionForecast = this.datePipe.transform(this.requestDate, 'yyyy-MM-ddTHH:mm');

      this.returnDate.setHours(Number(this.navigationPlanReturnTime.substr(0, 2)));
      this.returnDate.setMinutes(Number(this.navigationPlanReturnTime.substr(3, 2)));
      this.sling.navigationPlan.returnTime = this.datePipe.transform(this.returnDate, 'yyyy-MM-ddTHH:mm');
    }
    if (!this.sling.id) {
      this.slingService.checkBookingTime(this.sling).subscribe({
        next: (data) => {
          this.continueSaveFuelData(closeDialog, form);
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        },
      });
    } else {
      this.continueSaveFuelData(closeDialog, form);
    }
  }

  isAttemped(): boolean {
    return this.sling.marinaOrder.orderStatus !== 'Ordered' ||
      this.sling.slingStatus !== 'PENDING_DOWN';
  }

  placeHolderTimeDisponible(): string {
    if (!this.availablePeriods) {
      return 'Horário não disponível';
    }
    return 'Hora solicitada';
  }

  async chooseRequestDate(typeChoose): Promise<void> {
    const dayChoose = new Date(this.requestDate.getTime());
    dayChoose.setHours(0, 0, 1);
    if (typeChoose === '+') {
      this.lessButtonRequestDate = false;
      dayChoose.setDate(dayChoose.getDate() + 1);
      this.requestDate = new Date(dayChoose.getTime());
    } else {
      dayChoose.setDate(dayChoose.getDate() - 1);
      this.requestDate = new Date(dayChoose.getTime());
      const today = new Date();
      if (today.getDay() === this.requestDate.getDay() &&
        today.getMonth() === this.requestDate.getMonth() &&
        today.getFullYear() === this.requestDate.getFullYear()) {
        this.lessButtonRequestDate = true;
      }
    }
    this.changeExecutionForecast(dayChoose);
  }

  getRequestDate(): Date {
    if (this.sling?.marinaOrder?.orderDate) {
      return moment(this.sling.marinaOrder.orderDate).utc(true).toDate();
    }
    return null;
  }

  private setFrequentDestination(): void {
    if (this.slingConfig.frequentFestinations) {
      this.slingConfig.frequentFestinations.forEach(fd => this.frequentFestinations.push({ label: fd.name, value: fd.name }));
    }
  }

  changeSearch(): void {
    this.eventClean.emit();
    this.selectedCustomerId = null;
    this.selectedBoatId = null;

    this.clearSlingSailor();
    if (this.searchByCustomer) {
      this.boatsFilter = [];
      this.customers = this.allCustomers.map((c) => ({ label: c.name, value: c.id }));
    } else {
      this.customers = [];
      this.loadOptionsBoatsFilter();
    }
  }

  changeSelectedCustomer(): void {
    this.clearSlingSailor();
    this.selectCustomer();
    this.filterBoat();
    this.setOnBoardPhone();
  }

  async changeSelectedBoat(): Promise<void> {
    this.clearSlingSailor();
    this.sling.boat = await this.getBoat(this.selectedBoatId);
    if (this.sling.boat.franchiseUseInCurrentMonth>0 && this.sling.boat.franchiseGroup!=null &&
      this.sling.boat.franchiseUseInCurrentMonth>=this.sling.boat.franchiseGroup.franchise
    ) {
      Swal.fire({
        title: 'Atenção',
        text: 'Embarcação atingiu o limite de franquia do grupo.',
        icon: 'warning',
        reverseButtons: true
      });
    }
    this.loadPassengersNumber();
    if (!this.isASlingEdition && this.sling.boat && this.sling.marinaOrder.customer.id) {
      this.defineQuotaNewSling();
    }
    this.loadOptionsSailorByBoat();
    if (this.slingConfig?.typeBlockCustomer?.valueOf() === 'boat') {
      // @ts-ignore
      if (this.sling.boat.boatCustomers.find(item => item.blocked)) {
        Swal.fire({
          title: 'Atenção',
          text: 'Embarcação possui bloqueio no financeiro.',
          icon: 'warning',
          reverseButtons: true
        });
      }
    }
    this.getAvailablePeriods(this.requestDate);
  }

  getBoat(boatId: number): Promise<Boat> {
    this.spinner.show();
    return new Promise(resolve => {
      this.boatService.getById(boatId).subscribe(
        data => {
          this.spinner.hide();
          resolve(data);
        },
        error => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve(null);
        }
      );
    });
  }

  changeSelectedSailor(): void {
    this.sling.boatSailor = this.sling.boat.boatSailors.find((boatSailor) => boatSailor.sailor.id === this.selectedSailorId && boatSailor.active === true);
  }

  clearSlingSailor() {
    this.sling.boatSailor = null;
    this.selectedBoatSailor = null;
    this.selectedSailorId = null;
    this.sailors = [];
  }

  filterBoat(): void {
    const filtered = this.listBoat.filter((boat) => boat.customerIds.some((customerId) =>
      customerId === this.selectedCustomerId) && boat.active).map((boat) => ({
        label: boat.name.concat(boat.maintenance ? ' (Em manutenção)' : ''),
        value: boat.id,
        disabled: boat.maintenance
      }));
    this.boatsFilter = filtered.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    if (this.boatsFilter.length === 1) {
      this.selectedBoatId = this.boatsFilter[0].value;
      this.changeSelectedBoat();
    }
  }

  async loadCustomers(): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        this.customerService.getAllWithBoats().subscribe(
          async (data) => {
            this.allCustomers = data.filter((customer) => customer.active);
            resolve(data);
          },
          async (error) => {
            console.log(error);
            resolve(null);
          }
        );
      }
    );
  }

  loadOptionsBoatsFilter(): void {
    this.boatsFilter = this.listBoat ? this.listBoat.filter((boat) => boat.active).map((boat) => ({
      label: boat.name.concat(boat.maintenance ? ' (Em manutenção)' : ''),
      value: boat.id,
      disabled: boat.maintenance
    })) : [];
  }

  isPendingDown(): boolean {
    return this.sling.slingStatus === SlingStatus.PENDING_DOWN;
  }

  close(reload?): void {
    this.eventClose.emit(reload);
  }

  checkDayExecutionForecast() {
    if (!this.requestDate) {
      return;
    }
    const hasSpecialDay = this.messageSlingDayUtil.checkSpecialDayExecutionForecast(this.requestDate, this.slingConfig);
    const hasCloseDay = this.messageSlingDayUtil.checkCloseDayExecutionForecast(this.requestDate, this.slingConfig);
    const hasCloseDayWeek = this.messageSlingDayUtil.checkCloseDayWeekExecutionForecast(this.requestDate, this.slingConfig);
    if (hasCloseDay) {
      this.messageDayExecutionForecast = hasCloseDay;
    } else if (hasSpecialDay) {
      this.messageDayExecutionForecast = hasSpecialDay;
    } else if (hasCloseDayWeek) {
      this.messageDayExecutionForecast = hasCloseDayWeek;
    } else {
      this.messageDayExecutionForecast = undefined;
    }
  }

  checkDayReturnDate() {
    if (!this.returnDate) {
      return;
    }
    const hasSpecialDay = this.messageSlingDayUtil.checkSpecialDayReturnDate(this.returnDate, this.slingConfig);
    const hasCloseDay = this.messageSlingDayUtil.checkCloseDayReturnDate(this.returnDate, this.slingConfig);
    const hasCloseDayWeek = this.messageSlingDayUtil.checkCloseDayWeekReturnDate(this.returnDate, this.slingConfig);
    if (hasCloseDay) {
      this.messageDayReturnDate = hasCloseDay;
    } else if (hasSpecialDay) {
      this.messageDayReturnDate = hasSpecialDay;
    } else if (hasCloseDayWeek) {
      this.messageDayReturnDate = hasCloseDayWeek;
    } else {
      this.messageDayReturnDate = undefined;
    }
  }

  showMessageDayReturnDate(): boolean {
    if (!this.messageDayExecutionForecast) {
      return !!this.messageDayReturnDate;
    }
    return this.messageDayReturnDate && !this.hasMessageDayInEqualsDays();
  }

  hasMessageDayInEqualsDays() {
    if (this.messageDayExecutionForecast && this.messageDayReturnDate) {
      return this.messageSlingDayUtil.equalsDays(this.messageDayExecutionForecast.date, this.messageDayReturnDate.date);
    }
    return false;
  }

  hasClosed(): boolean {
    return (this.messageDayExecutionForecast && this.messageDayExecutionForecast.type !== 'SpecialDay')
      || (this.messageDayReturnDate && this.messageDayReturnDate.type !== 'SpecialDay');
  }

  async showMultiplesDestination() {
    this.dialog.open(MultipleDestinationsComponent, {
      width: '480px',
      height: '550px',
      header: 'Múltiplos destinos',
      data: {
        frequentFestinations: this.frequentFestinations,
        frequentFestination: this.sling.navigationPlan.destination
      }
    }).onClose.subscribe((destinations) => {
      if (destinations != undefined) {
        this.sling.navigationPlan.destination = destinations;
      }
    });
  }

  getLabelPassengers() {
    if (this.hasChild) {
      return this.messageUtil.translateKey('ADULTS');
    }
    return this.messageUtil.translateKey('SHIP-ATT.PASSENGERS');
  }

  enabledSailor(): boolean {
    return this.sling.boat.boatSailors && this.sling.boat.boatSailors.length > 0 && this.sailors.length > 0 && this.slingConfig.hasSailor;
  }

  printNavitagionPlan(id: number): void {
    this.spinner.show();
    this.printingService.generatePdfById(id, PrintType.SLING).subscribe((data) => {
      this.spinner.hide();
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }
}
