<div class="dialog-form">
  <form (ngSubmit)="formValidations(f) && save()" #f="ngForm" novalidate>
    <p-fieldset>
      <p-header class="article-title">
        <div class="article-title">{{'GENERAL-INFO' | translate}}</div>
      </p-header>
      <div class="form-group">
        <div class="form-control">
          <label for="raspberry">N° de série*</label>
          <p-dropdown name="raspberry" [options]="raspberries" [(ngModel)]="raspberry" required appendTo="body"
            placeholder="Selecione...">
          </p-dropdown>
        </div>
        <div class="form-control">
          <label for="raspScreenOrientation">{{ 'PANEL-ORIENTATION' | translate }}
            <fa-icon [icon]="'info-circle'" pTooltip="{{ 'PANEL-ORIENTATION-INFO' | translate }}" tooltipPosition="top"
              tooltipStyleClass="tooltip"></fa-icon>
          </label>
          <p-dropdown name="raspScreenOrientation" [options]="raspScreenOrientations" [(ngModel)]="screenOrientation"
            (onChange)="changeScreenOrientation($event)" appendTo="body" required>
          </p-dropdown>
        </div>
      </div>
      <br>
      <div class="form-group" *ngIf="!isMultPanel()">
        <div class="form-control">
          <label for="raspberry">{{ 'OBSERVATION' | translate }}*</label>
          <input type="text" pInputText [(ngModel)]="panelsConfig[0].observation" required name="observation">
        </div>
        <div class="form-control">
          <label for="panelFunctionality">{{ 'CONTENT' | translate }}*</label>
          <p-dropdown name="panelFunctionality" [options]="panelFunctionalities" appendTo="body"
            [(ngModel)]="panelsConfig[0].panelFunctionality" placeholder="Selecione..."
            [disabled]="isDisabled(panelsConfig[0]?.panelFunctionality)" required></p-dropdown>
        </div>

        <div class="form-control" *ngIf="panelsConfig[0].panelFunctionality === slingDownQueue">
          <label for="slingMultQueue">{{ 'Fila de Descida' | translate }}*</label>
          <p-dropdown name="slingMultQueue" [options]="slingMultQueueOptions" appendTo="body"
          [(ngModel)]="panelsConfig[0].slingMultQueue"
           placeholder="Selecione..."
            [disabled]="isDisabled(panelsConfig[0]?.panelFunctionality)" required></p-dropdown>
        </div>

        <div *ngIf="this.config.data.panelConfig[0].panelFunctionality === 'MovementDownQueue'" class="form-control">
          <label>{{ 'QUEUE' | translate }}</label>
          <p-dropdown name="movementGroups" [options]="movementGroups" [(ngModel)]="panelsConfig[0].movementGroupName"
            optionLabel="value.name" optionValue="value.name" (onChange)="setMovementGroup($event)" appendTo="body"
            placeholder="Selecione..." [disabled]="isDisabled(panelsConfig[0]?.movementGroupName)">
          </p-dropdown>
        </div>
      </div>
      <div class="form-group" *ngIf="isMultPanel()">
        <div class="form-control">
          <label for="darkMode">Modo escuro</label>
          <p-inputSwitch name="darkMode" [(ngModel)]="panelsConfig[0].darkMode">
          </p-inputSwitch>
        </div>
        <div class="form-control">
          <label for="showInfo">Exibir observação do painel</label>
          <p-inputSwitch name="showInfo" [(ngModel)]="panelsConfig[0].showInfo">
          </p-inputSwitch>
        </div>
        <div class="form-control">
          <label for="enableAnimation">Habilitar animação</label>
          <p-inputSwitch name="enableAnimation"
            (onChange)="panelsConfig[0].animationDelay = (panelsConfig[0].enableAnimation ? 10 : null)"
            [(ngModel)]="panelsConfig[0].enableAnimation">
          </p-inputSwitch>
        </div>
        <div class="form-control" *ngIf="panelsConfig[0].enableAnimation">
          <label for="enableAnimation">Intervalo (em segundos)</label>
          <p-inputNumber name="animationDelay" [(ngModel)]="panelsConfig[0].animationDelay" required>
          </p-inputNumber>
        </div>
      </div>
    </p-fieldset>
    <br>
    <p-tabView *ngIf="isMultPanel()">
      <p-tabPanel [header]="getTabNamePainel(i)" *ngFor="let panel of panelsConfig; let i = index"
        [selected]="i == panelSelected">
        <app-panel-config-form [movementGroups]="movementGroups" [panelConfig]="panel"
          [panelFunctionalities]="panelFunctionalities" [raspScreenOrientations]="raspScreenOrientations"
          [screenOrientation]="screenOrientation" [slingMultQueueOptions]="slingMultQueueOptions">
        </app-panel-config-form>
      </p-tabPanel>
    </p-tabView>
    <app-panel-config-form *ngIf="!isMultPanel()" [panelConfig]="panelsConfig[0]"
      [panelFunctionalities]="panelFunctionalities" [raspScreenOrientations]="raspScreenOrientations"
      [screenOrientation]="screenOrientation">
    </app-panel-config-form>
    <br>
    <div class="buttons">
      <div class="form-control">
        {{'REQUIRED-FIELDS' | translate}}
      </div>
      <div class="buttons">
        <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate
          }}</button>
        <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </form>
</div>
