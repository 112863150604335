<div class="dialog-form">
  <fieldset>
    <legend>Nova Fila</legend>
    <div class="form-group">
      <div class="form-control">
        <label for="type">Selecione a Nova fila</label>
        <p-dropdown
          [options]="listQueue"
          [(ngModel)]="queue"
          optionLabel="label"
          optionValue="value"
          placeholder="Selecione"
          required
        ></p-dropdown>
      </div>

      <div class="form-control" style="margin-top: 22px;">
        <button
          class="easymarine primary"
          style="width: 50%"
          (click)="changeQueue()"
        >
          Alterar
        </button>
      </div>
    </div>
  </fieldset>
  <br />
  <fieldset>
    <legend>Embarcações</legend>
    <div class="table">
      <p-table
        [value]="boats"
        autoLayout="true"
        #table
        [(selection)]="selectedBoats"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Nome</th>
            <th>Tipo</th>
            <th>Proprietarios</th>
            <th>Tipo de Fila</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-result>
          <tr>
            <td class="text-center">
              <p-tableCheckbox [value]="result"></p-tableCheckbox>
            </td>
            <td >
              {{ result?.name }}
            </td>
            <td >
              {{ result?.shipyardModel?.shipType.toUpperCase() | translate  }}

            </td>
            <td >
              {{ result?.boatOwners }}
            </td>

            <td >
              {{ result?.slingQueueType.name }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </fieldset>
</div>
