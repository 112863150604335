import { Component, HostListener, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CheckUserBraceletComponent } from 'src/app/components/sling/check-user-bracelet/check-user-bracelet.component';
import { SlingConfig } from 'src/app/models';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { LandExitComponent } from '../land-exit/land-exit.component';
import Swal from 'sweetalert2';
import { MultiQueue } from 'src/app/models/multi-queue';
import { SlingMultiQueueService } from 'src/app/services/sling-multi-queue/sling-multi-queue.service';


@Component({
  selector: 'app-sling-check',
  templateUrl: './sling-check.component.html',
  styleUrls: ['./sling-check.component.scss']
})
export class SlingCheckComponent implements OnInit {

  isMobile = false;
  slingConfig: SlingConfig;
  activeIndex = 0;
  slingConfigMultQueue: MultiQueue[] = [];

  @HostListener('window:resize', ['$event']) onScroll(): void {
    this.checkMobile();
    this.showHideSideNav();
  }

  constructor(
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private slingMultQueueService: SlingMultiQueueService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.slingConfig = await this.loadSlingConfig();
    this.checkMobile();
    this.loadSlingMultQueue();
  }

  onChangeSelectTab($event) {
    this.activeIndex = $event.index;
  }

  reload() {
    const index = this.activeIndex;
    this.activeIndex = 99;
    setTimeout(() => {
      this.activeIndex = index;
    }, 1);
  }

  loadSlingMultQueue():void
  {
    this.slingMultQueueService.getAllMarinaActive(true).subscribe(
      (data: any[]) => {
        this.slingConfigMultQueue = data;
        console.log(data);
      },
      (error) => {
        Swal.fire('Erro ao buscar lista de filas');
      }
    );
  }

  openModalUserInfo(): void {
    this.dialog.open(CheckUserBraceletComponent, {
      width: '400px',
      showHeader: false,
      dismissableMask: true
    });
  }

  checkMobile(): void {
    this.isMobile = window.innerWidth <= 576;
  }

  showHideSideNav(): void {
    BroadcastService.get('showHideSidenav').emit(false);
  }

  async loadSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(
      async (resolve) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            resolve(data[0]);
          },
          async (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            resolve(null);
          }
        );
      }
    );
  }

  enableSlingDown(): boolean {
    return this.activeIndex === 0;
  }

  enableSlingDownMult(i:number): boolean {
    return this.activeIndex ===  i;
  }

  enableSlingWater(): boolean {
    if (this.slingConfigMultQueue.length>1){
      return this.activeIndex === 1 + this.slingConfigMultQueue.length;
    }else{
        return this.activeIndex === 1;
    }
  }

  enableSlingUp(): boolean {
    if (this.slingConfigMultQueue.length>1)
    {return this.activeIndex === 1 + this.slingConfigMultQueue.length && !this.slingConfig?.queueControl
    || this.activeIndex === 2 + this.slingConfigMultQueue.length ;
    }else{
      return this.activeIndex === 1  && !this.slingConfig?.queueControl
    || this.activeIndex === 2
    }
  }

  openLandExitModal(): void {
    this.dialog.open(LandExitComponent, {
      width: '50%',
      height: '40vh',
      header: 'SAÍDA POR TERRA',
    });
  }

}
