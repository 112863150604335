<p-fieldset>
    <p-header class="article-title">
      <span class="article-title">{{
        "TITLE-REGISTER-REPORTS" | translate
      }}</span>
      <div class="header-buttons">
          <button
          class="fab-button primary"
          label="Exportar valores"
          pTooltip="Exportar Valores"
          tooltipPosition="left"
          (click)="buildPDF()"
        >
          <i class="pi pi-file"></i>
        </button>
      </div>
    </p-header>
    <form class="filters" style="min-width: 10px;min-height: 100px;">
        <div class="filter-row">
            <div class="form-control" style="min-width: 100px;min-height: 100px;">
                <label>{{'FIRST-NAME' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="filters.name" name="filters.name">
            </div>
            <div class="form-control" [(ngModel)]="filters.tpe" style="min-width: 10px;min-height: 10px;">
                <label>{{'TYPE' | translate}}</label>
                <p-dropdown [options]="typeOptions" [(ngModel)]="filters.type" name="filters.type"
                    appendTo="body">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label>{{'DAYS-BIRTHDAY' | translate}}</label>
                <p-dropdown [options]="daysForBirthday" [(ngModel)]="filters.daysForBirthday" name="filters.daysForBirthday"
                appendTo="body">
                </p-dropdown>
            </div>

            <div class="form-control" style="min-width: 10px;min-height: 10px;">
                <label>{{'AGE' | translate}}</label>
                <input pInputText type="number" max="99" [(ngModel)]="filters.age" name="filters.age">
            </div>
            
            <div class="form-control" style="min-width: 10px;min-height: 10px;">
                <label>{{'STATUS' | translate}}</label>
                <p-dropdown [options]="active" [(ngModel)]="filters.active" name="filters.active"
                appendTo="body">
                </p-dropdown>
            </div>
            
        </div>    
    </form>
    <div class="filters filter-grid">
        <div class="filter-row">
            <div class="buttons">
                <div class="form-button">
                    <button class="danger easymarine" (click)="clearFilters()" type="reset">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="primary easymarine" (click)="getBirthday()" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>   

    </div>
    <div class="table">
        <p-table [value]="birthdays" id="birthdaysTable" #shippingFiles [resizableColumns]="true" [autoLayout]="true"
        sortMode="multiple" [rows]="10" [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[10,20,30,50,100]">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center" [pSortableColumn]="'name'">{{'NAME' | translate}} <p-sortIcon [field]="'name'"></p-sortIcon></th>
                  
                    <th [pSortableColumn]="'birthday'">{{'DATE-BIRTH' | translate}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                    <th [pSortableColumn]="'age'">{{'AGE' | translate}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                    <th [pSortableColumn]="'type'">{{'TYPE' | translate}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                    <th [pSortableColumn]="'phone'">{{'PHONE' | translate}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                    <th [pSortableColumn]="'email'">{{'EMAIL' | translate}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                    <th [pSortableColumn]="'status'">{{'STATUS' | translate}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file let-index="rowIndex">
                <tr>
                    <td>
                        {{ file.name }}
                    </td>
                    <td>
                        {{ file.birthday | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                        {{ calcAge(file.birthday)}}
                    </td>
                    <td>
                        {{ dicType(file.type)}}
                    </td>
                    <td>
                        {{ file.phone }}
                    </td>
                    <td>
                        {{file.email }}
                    </td>
                    <td>
                        <span *ngIf="file.status" class="badge badge-pill success" style="cursor: default;">{{ 'ACTIVE' | translate }}</span>
                        <span *ngIf="!file.status"
                            class="badge badge-pill danger" style="cursor: default;">{{ 'INACTIVE' | translate }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>