import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BirthdayService } from 'src/app/services//birthday.service';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss'],
  providers: [BirthdayService],
})
export class BirthdayComponent {
    birthdays: any[] = new Array();
    filters: any = {};

    public typeOptions: Array<{ label: string, value: any }> = [
      { label: 'Cliente', value: 'CUSTOMER' },
      { label: 'Marinheiro', value: 'SAILOR' },
      { label: 'Todos', value: '' }
    ];

    public daysForBirthday: Array<{ label: string, value: number }> = [
      { label: '5 dias', value: 5 },
      { label: '10 dias', value: 10 },
      { label: '15 dias', value: 15 },
      { label: '30 dias', value: 30 }
    ];

    public active: Array<{ label: string, value: any }> = [
      { label: 'Ativo', value: 1 },
      { label: 'Inativo', value: 0 },
      { label: 'Ambos', value: '' }
    ];

    constructor(
      private spinner: NgxSpinnerService,
      private birthdayService: BirthdayService
    ){

    }

    ngOnInit():void{
      this.filters.daysForBirthday = 30;
      this.filters.active = 1;
      this.filters.type ='';
      this.getBirthday();
    }

    buildPDF():void{
      this.birthdayService.buildPDF();
    }

    getBirthday():void {
      if (!this.spinner) {
        this.spinner.show();
      }

      this.sanitizeFilters();
      this.birthdayService.findWithFilter(this.filters).subscribe(response=>{
        this.birthdays = response;
      })
    }

    orderByMonthAndDay(list:any):any{
      return list.sort((a, b) => (a.birthday.getMonth() * 100 + a.birthday.getDate()) - (b.birthday.getMonth() * 100 + b.birthday.getDate()));

    }

    sanitizeFilters():void{
      Object.keys(this.filters).forEach((item) => {
        if("" === item) item = undefined;
      });
    }

    clearFilters():void{
      Object.keys(this.filters).forEach((item) => {
        this.filters[item] = undefined;
      });
    }

    calcAge(birthDate: Date):any {
      const age = moment().diff(birthDate, 'years');
      return  Number.isNaN(age) ? '' :age;
    }

    dicType(typeText: String):String{
      switch(typeText){
        case "CUSTOMER":
          return "Cliente";
        break;
        case "SAILOR":
          return "Marinheiro";
          break;
        default:
          return "Fornecedor";

      }
    }

}
