import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { saveAs } from 'file-saver';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Customer, Marina, SlingConfig } from 'src/app/models';
import { CustomerFilter } from 'src/app/models/dtos/customer-filter';
import { CustomerTableDTO } from 'src/app/models/dtos/customerTableDTO';
import { FederalIdType } from 'src/app/models/federal-id-type';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerImportsDialogComponent } from './customer-imports-dialog/customer-imports-dialog.component';
import { WorkSheet } from 'xlsx';
import { CustomerType } from 'src/app/models/enums';
import moment from 'moment';
import { LicenceCustomerState } from 'src/app/models/enums/licence-customer-state';
import { CustomReportExportComponent } from '../../../components/extract-custom-report/custom-report-export.component';
import { CustomerProfile } from 'src/app/models/customer-profile';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { MarinaImplantationService } from '../../../services/marina-implantation.service';
import { MarinaImplantation } from '../../../models/marina-implantation';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { CustomerDependentComponent } from './customer-dependent/customer-dependent.component';
import { AccessGroupEnum } from "../../../models/dtos/access-controller/access-group-enum";
import { PrintType } from 'src/app/models/enums/print-type';
import { PrintingService } from 'src/app/services/printing-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  @ViewChild('tt') tt: Table;

  templateFormURI = '/assets/documents/formularioCadastroCliente.pdf';
  numberOfRows = 10;
  customers: Customer[] = [];
  customersTable: Customer[] = [];
  showInactive = true;
  globalFilter = '';
  groups: any[];
  customerFilter: CustomerFilter = new CustomerFilter();
  isWorkshop: Boolean;
  isYatchClub: Boolean;
  activateTuitionByCustomer: Boolean;
  customerType: string;
  totalRecords = 0;
  slingConfig: SlingConfig;
  loggedMarina: Marina = new Marina();
  marinaCompanies: Array<{ label: string, value: any }>;
  uploadXlsxFileInProgress = false;
  outFile: any;
  customersSuccess: any;
  selectedCustomers: any[] = [];
  exportTip = 'Para aplicação do filtro, clique antes em "Pesquisar"';
  cols = [];
  groupsFilter: { label: string; value: any; }[];
  licenceStateFilter: { label: string; value: any; }[] = [
    { label: 'Ok', value: LicenceCustomerState.Ok },
    { label: 'Expirado', value: LicenceCustomerState.Expired },
    { label: 'Sem-habilitação', value: LicenceCustomerState.Without }
  ];
  customerProfiles: Array<SelectItem>;
  marinaImplantation: MarinaImplantation;
  selectedCustomer: CustomerTableDTO;
  menuItems: MenuItem[] = [];

  constructor(
    public dialog: DialogService,
    private customerService: CustomerService,
    private groupService: GroupService,
    private printingService: PrintingService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toasty: ToastService,
    private slingConfigService: SlingConfigService,
    private operationalConfigService: OperationalConfigService,
    private marinaImplantationService: MarinaImplantationService,
  ) { }

  async ngOnInit(): Promise<void> {

    await Promise.all([this.getWorkshopConfig(), this.getOperationalConfig()]);
    this.getCustomerProfiles();

    this.loggedMarina = StorageUtil.getMarina();
    this.defineNameColumnsExport();
    if (this.loggedMarina.marinaCompanies && this.loggedMarina.marinaCompanies.length > 0) {
      this.marinaCompanies = this.loggedMarina.marinaCompanies.map((m) => ({ label: m.companyFederalName, value: m }));
      this.marinaCompanies.unshift({ label: this.loggedMarina.companyName, value: this.loggedMarina });
      this.marinaCompanies.unshift({ label: 'Todas', value: null });
    }
    this.loadSlingConfig();

    this.findAllGroups();

    const customerId = + this.route.snapshot.queryParamMap.get('customerId');
    if (customerId) {
      setTimeout(() => {
        this.openCustomerFormDialog(new CustomerTableDTO(customerId));
      }, 2000);
    }

    this.customerFilter.active = true;
    this.getMarinaImplantation();
    this.loadMenuItems();
  }
  async getOperationalConfig(): Promise<void> {
    this.isYatchClub = await this.operationalConfigService.isYatchClub();
    this.activateTuitionByCustomer = await this.operationalConfigService.hasCustomerTuition();
  }

  async getWorkshopConfig() {
    this.isWorkshop =
      await this.operationalConfigService.isWorkshop();
  }

  getCustomerProfiles() {
    this.customerProfiles = [
      { label: 'Todos', value: null },
      { label: 'Marina', value: CustomerProfile.Marine },
      this.isWorkshop ? { label: 'Oficina', value: CustomerProfile.Workshop } : null,
      this.isWorkshop ? { label: 'Oficina e Marina', value: CustomerProfile.MarineAndWorkshop } : null
    ];

  }
  formatMaskFederalId(change) {

    if (change.length <= 11) {
      let cpf = (FormatUtil.formatCPF(change));
      this.customerFilter.federalId = cpf;
    }
    else {
      let cnpj = (FormatUtil.formatCNPJ(change));
      this.customerFilter.federalId = cnpj;
    }

  }

  private loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        this.customerFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
        this.find();
      },
      (error) => null);
  }

  filterGlobal(): void {
    this.customersTable = this.customers.filter(
      (c) =>
        (FormatUtil.getNotAccents(c.name).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          c.name.toUpperCase().includes(this.globalFilter.toUpperCase())
        ) ||
        (FormatUtil.removeSpecialCharacters(c.federalId)
          .includes(this.globalFilter) || c.federalId.includes(this.globalFilter)) ||
        (c.email.includes(this.globalFilter)
        ) ||
        (FormatUtil.removeSpecialCharacters(c.phone)
          .includes(this.globalFilter) || c.phone.includes(this.globalFilter)) ||
        (c.group && c.group.name.toUpperCase().includes(this.globalFilter.toUpperCase())
        ) ||
        (c.commercialAddress &&
          (FormatUtil.getNotAccents(c.commercialAddress.city).toUpperCase()
            .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
            c.commercialAddress.city.toUpperCase().includes(this.globalFilter.toUpperCase()))
        ) ||
        (c.residentialAddress &&
          (FormatUtil.getNotAccents(c.residentialAddress.city).toUpperCase()
            .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
            c.residentialAddress.city.toUpperCase().includes(this.globalFilter.toUpperCase()))
        )
    );
  }

  async openCustomerFormDialog(c?: CustomerTableDTO): Promise<void> {
    const data = {
      userApp: null,
      customer: null,
      customerTitleProperty: null,
      marinaTitleName: null
    };
    if (c && c.id) {
      data.userApp = c.userApp;
      data.customer = await this.getCustomerById(c.id);
      data.customerTitleProperty = await this.getTitlePropertyByCustomerId(c.id);
      data.marinaTitleName = await this.getTitlePropertyByMarinaId();
    }
    this.dialog.open(CustomerFormComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('CUSTOMER-REGISTRATION')
    }).onClose.subscribe(
      (d: Customer) => {
        if (d) {
          this.find();
        }
      }
    );
  }

  openCustomerDeleteConfirmDialog(id: number): void {
    Swal.fire({
      title: this.messageUtil.translateKey('DELETE-CONFIRMATION'),
      text: this.messageUtil.translateKey('DELETE-CONFIRMATION-MESSAGE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',

      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.customerService.delete(id).subscribe(
          () => {
            Swal.fire('Cliente excluído!', 'O cliente foi removido do sistema.', 'success');
          },
          (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          },
          () => {
            let customers = this.customersTable.filter((c) => c.id === id);
            let copy = [...this.customersTable];
            if (customers.length > 0) {
              copy.splice(this.customersTable.indexOf(customers[0]), 1);
              this.customersTable = copy;
            }
            customers = this.customers.filter((c) => c.id === id);
            copy = [...this.customers];
            if (customers.length > 0) {
              copy.splice(this.customers.indexOf(customers[0]), 1);
              this.customers = copy;
            }
          }
        );
      }
    });
  }

  islicenseExpired(customer: CustomerTableDTO): boolean {
    if (!customer.expiration) {
      return true;
    }
    return new Date().getTime() >= new Date(customer.expiration).getTime();
  }

  updateStatus(customer: any): void {
    this.spinner.show();
    const newStatus = !customer.active;
    this.customerService.updateStatus(customer.id, newStatus).subscribe(
      (data) => {
        customer.active = newStatus;
        customer.activeCustomer = customer.active ? 'Ativo' : 'Inativo';
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Situação atualizada com sucesso');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

  updateReviewed(customer: any): void {
    this.spinner.show();
    const newReviewed = !customer.reviewed;
    this.customerService.updateReviewed(customer.id, newReviewed).subscribe(
      (data) => {
        customer.reviewed = newReviewed;
        customer.reviewedCustomer = customer.reviewed ? 'Sim' : 'Não';
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Revisão atualizada com sucesso');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }


  dowloadForm(): void {
    window.open(this.templateFormURI);
  }

  async findAllGroups(): Promise<void> {
    return new Promise<void>(
      (resolve, reject) => {
        this.spinner.show();
        this.groupService.findAllActive().subscribe(
          async (groups) => {
            this.groups = [{ label: 'Sem grupo', value: null }].concat(groups.map((g) => ({ label: g.name, value: g.id })));
            this.groupsFilter = [{ label: 'Sem grupo', value: 0 }]
              .concat(groups.map((g) => ({ label: g.name, value: g.id })));
          },
          async (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            reject();
          },
          async () => {
            resolve();
            this.spinner.hide();
          });
      }
    );
  }

  changeGroup(customer: CustomerTableDTO): void {
    this.customerService.updateGroup(customer.id, { id: customer.groupId }).subscribe(
      () => {
        this.toasty.success('Grupo alterado com sucesso.');
      },
      (error) => {
        const exception = error.error.data ? error.error.data.exception : error.error;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        this.find();
      }
    );
  }

  find(): void {
    this.spinner.show();
    this.setCustomerTypeFilter();
    this.customerService.findByMarinaIdWithPagination(this.customerFilter).subscribe(
      (data: any) => {
        this.totalRecords = data.totalElements;
        this.customersTable = data.content.map((customer) => {
          customer.userAppBool = customer.userApp ? 'Sim' : 'Não';
          customer.location = customer.federalIdType === FederalIdType.Physical || customer.federalIdType === FederalIdType.Foreigner ?
            ((customer.residentialCity || '') + ' - ' + (customer.residentialState || '')) :
            ((customer.commercialCity || '') + ' - ' + (customer.commercialState || ''));
          customer.customerType = this.checkIfCustomerIsOnlyAssociated(customer) ?
            'Aut.' :
            'Prop.' + (customer.associatedBoats > 0 ? ' / Aut.' : '');
          customer.reviewedCustomer = customer.reviewed ? 'Sim' : 'Não';
          customer.activeCustomer = customer.active ? 'Ativo' : 'Inativo';
          customer.companyName = customer.marinaCompanyId ?
            this.getCompanyName(customer.marinaCompanyId) : this.loggedMarina.companyName;
          return customer;
        });
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

  clear(): void {
    this.customerFilter = new CustomerFilter();
    this.customerFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
    this.find();
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      const page = event.first / event.rows;
      this.customerFilter.sort = event.sortField;
      this.customerFilter.page = page;
      this.customerFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.find();
    }
  }

  changeCompany(event): void {
    if (event.value && event.value.marinaCompanies) {
      this.customerFilter.marinaCompanyId = null;
      this.customerFilter.onlyMarina = true;
    } else if (event.value) {
      this.customerFilter.marinaCompanyId = event.value.id;
      this.customerFilter.onlyMarina = false;
    } else {
      this.customerFilter.marinaCompanyId = null;
      this.customerFilter.onlyMarina = false;
    }
  }

  getCompanyIndex(company: number): number {
    if (company) {
      const m = this.marinaCompanies.find((c) => c.value && !c.value.marinaCompanies && c.value.id === company);
      return this.marinaCompanies.indexOf(m);
    }
    return 1;
  }

  getCompanyName(company: number): string {
    if (company) {
      const c = this.marinaCompanies.find((m) => m.value && !m.value.marinaCompanies && m.value.id === company);
      return c.value.companyFederalName ? c.value.companyFederalName : '';
    }
    return this.loggedMarina.companyName;
  }

  openImportDialog(): void {
    this.dialog.open(CustomerImportsDialogComponent, {
      width: '60%',
      height: '60vh',
      dismissableMask: false,
      header: this.messageUtil.translateKey('CUSTOMER-IMPORTS')
    });
  }

  async exportTable(): Promise<void> {
    await this.findDataExport();
    const data = { table: this.selectedCustomers, type: 'CUSTOMER' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-COSTUMER-TABLE')
    });
  }

  async exportTableExcel(): Promise<void> {
    await this.findDataExport();
    setTimeout(() => {
      this.exportExcel();
    }, 500);
  }

  async exportExcel(): Promise<void> {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedCustomers);
      this.changeHeaderWorksheet(worksheet);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      const date = moment(new Date()).utc(true).format('DD-MM-YYYY');
      const marina = StorageUtil.getMarinaName();
      this.saveAsExcelFile(excelBuffer, `clientes_${date}_${marina}`);
    });
  }

  changeHeaderWorksheet(worksheet: WorkSheet): void {
    worksheet.A1.v = this.cols[0].header;
    worksheet.B1.v = this.cols[1].header;
    worksheet.C1.v = this.cols[2].header;
    worksheet.D1.v = this.cols[3].header;
    worksheet.E1.v = this.cols[4].header;
    worksheet.F1.v = this.cols[5].header;
    worksheet.G1.v = this.cols[6].header;
    worksheet.H1.v = this.cols[7].header;
    worksheet.I1.v = this.cols[8].header;
    worksheet.J1.v = this.cols[9].header;
    worksheet.K1.v = this.cols[10].header;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  }

  async findDataExport(): Promise<void> {
    return new Promise(async (resolve) => {
      this.spinner.show();
      const filter = { ...this.customerFilter };
      filter.size = 1000000;
      this.customerService.findByMarinaIdWithPagination(filter).subscribe(
        async (data: any) => {
          this.selectedCustomers = data.content.map((customer) => {
            return this.formatDataExport(customer);
          });
          resolve();
        },
        async (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        },
        () => this.spinner.hide()
      );
    });
  }

  formatDataExport(customer): any {
    customer.userAppBool = customer.userApp ? 'Sim' : 'Não';
    customer.location = customer.federalIdType === FederalIdType.Physical || customer.federalIdType === FederalIdType.Foreigner ?
      ((customer.residentialCity || '') + ' - ' + (customer.residentialState || '')) :
      ((customer.commercialCity || '') + ' - ' + (customer.commercialState || ''));
    customer.customerType = this.checkIfCustomerIsOnlyAssociated(customer) ?
      'Autorizado' :
      'Proprietário' + (customer.associatedBoats > 0 ? ' / Autorizado' : '');
    customer.reviewedCustomer = customer.reviewed ? 'Sim' : 'Não';
    customer.activeCustomer = customer.active ? 'Ativo' : 'Inativo';
    customer.companyName = customer.marinaCompanyId ?
      this.getCompanyName(customer.marinaCompanyId) : this.loggedMarina.companyName;
    const getGroupName = this.groups.filter(group => {
      if (group.value === customer.groupId) {
        return group;
      }
    });
    customer.groupName = getGroupName.length > 0 ? getGroupName[0].label : null;
    customer.customerProfile = customer.customerProfile == 'WORKSHOP' ? 'Oficina' : (customer.customerProfile == 'WORKSHOP_AND_MARINE' ? 'Oficina e Marina' : 'Marina');

    return {
      id: customer.id,
      userAppBool: customer.userAppBool,
      name: customer.name,
      companyName: customer.companyName,
      federalId: customer.federalId,
      email: customer.email,
      chargeEmail: customer.chargeEmail,
      birthday: customer.birthday,
      expiration: customer.expiration,
      gender: customer.gender,
      phone: customer.phone,
      groupId: customer.groupId,
      groupName: customer.groupName,
      location: customer.location,
      customerType: customer.customerType,
      reviewedCustomer: customer.reviewedCustomer,
      activeCustomer: customer.activeCustomer,
      residentialPhone: customer.residentialPhone,
      residentialZipCode: customer.residentialZipCode,
      residentialCountry: customer.residentialCountry,
      residentialDistrict: customer.residentialDistrict,
      residentialCity: customer.residentialCity,
      residentialState: customer.residentialState,
      residentialAddress: customer.residentialAddress,
      residentialNumber: customer.residentialNumber,
      residentialComplement: customer.residentialComplement,
      commercialPhone: customer.commercialPhone,
      commercialZipCode: customer.commercialZipCode,
      commercialCountry: customer.commercialCountry,
      commercialCity: customer.commercialCity,
      commercialState: customer.commercialState,
      commercialAddress: customer.commercialAddress,
      commercialDistrict: customer.commercialDistrict,
      commercialNumber: customer.commercialNumber,
      commercialComplement: customer.commercialComplement,
      boatNames: customer.boatNames,
      spouse: customer.spouse,
      maritalStatus: customer.maritalStatus,
      customerProfile: customer.customerProfile,
      billingDay: customer.billingDay,
      licenceExpiration: customer.licenceExpiration,
      titlePropertyName: customer.titlePropertyName
    };
  }

  async defineNameColumnsExport() {
    this.cols = [
      { field: 'userAppBool', header: await this.messageUtil.translateKeyAsync('USER-APP') },
      { field: 'name', header: await this.messageUtil.translateKeyAsync('NAME') },
      { field: 'companyName', header: await this.messageUtil.translateKeyAsync('COMPANY') },
      { field: 'federalId', header: await this.messageUtil.translateKeyAsync('FEDERAL-ID.GENERAL') },
      { field: 'email', header: await this.messageUtil.translateKeyAsync('PERSONAL-EMAIL') },
      { field: 'phone', header: await this.messageUtil.translateKeyAsync('CELLPHONE') },
      { field: 'groupName', header: await this.messageUtil.translateKeyAsync('GROUP') },
      { field: 'location', header: await this.messageUtil.translateKeyAsync('LOCATION') },
      { field: 'customerType', header: await this.messageUtil.translateKeyAsync('TYPE') },
      { field: 'reviewedCustomer', header: await this.messageUtil.translateKeyAsync('REVIEWED') },
      { field: 'activeCustomer', header: await this.messageUtil.translateKeyAsync('STATUS') }
    ];
  }

  async getTitlePropertyByMarinaId(): Promise<any>{
    return new Promise<any>(
      async (resolve) => {
        this.spinner.show();
        this.customerService.getTitlePropertiesByMarinaId().subscribe(
          async (titleProperty) => {
            this.spinner.hide();
            resolve(titleProperty);
          },
          async (err) => {
            const exception = err.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
            resolve(null);
          }
        );
      }
    );
  }

  async getTitlePropertyByCustomerId(id): Promise<any>{
    return new Promise<any>(
      async (resolve) => {
        this.spinner.show();
        this.customerService.getTitlePropertiesByCustomerId(id).subscribe(
          async (titleProperty) => {
            this.spinner.hide();
            resolve(titleProperty);
          },
          async (err) => {
            const exception = err.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
            resolve(null);
          }
        );
      }
    );
  }

  async getCustomerById(id): Promise<Customer> {
    return new Promise<Customer>(
      async (resolve) => {
        this.spinner.show();
        this.customerService.getById(id).subscribe(
          async (customer) => {
            this.spinner.hide();
            resolve(customer);
          },
          async (err) => {
            const exception = err.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
            resolve(null);
          }
        );
      }
    );
  }

  setCustomerTypeFilter(): void {
    if (this.customerType !== '') {
      if (this.customerType === 'associated') {
        this.customerFilter.onlyAssociated = true;
        this.customerFilter.type = CustomerType.User;
      }
      if (this.customerType === 'owner') {
        this.customerFilter.onlyAssociated = false;
        this.customerFilter.type = CustomerType.Owner;
      }
      if (this.customerType === 'ownAsso') {
        this.customerFilter.onlyAssociated = true;
        this.customerFilter.type = CustomerType.Owner;
      }
      if (this.customerType === 'noBoat') {
        this.customerFilter.onlyAssociated = false;
        this.customerFilter.type = CustomerType.User;
      }

    } else {
      this.customerFilter.onlyAssociated = null;
      this.customerFilter.type = null;
    }
  }

  private getMarinaImplantation() {
    this.marinaImplantationService.getMarinaImplantationByMarinaId().subscribe({
      next: (resp) => this.marinaImplantation = resp,
      error: (err) => console.log(err)
    })
  }

  changeSelectedItem(customer) {
    this.selectedCustomer = customer;
  }

  loadMenuItems() {
    this.menuItems = [{ label: 'Editar', icon: 'pi pi-pencil', iconClass: 'red-menu-icon', command: () => this.openCustomerFormDialog(this.selectedCustomer) }]
    if (this.isYatchClub) {
      this.menuItems.push({ label: 'Dependentes', icon: 'pi pi-users', iconClass: 'green-menu-icon', command: () => this.openDependentCustomer(this.selectedCustomer) })
    }
    if (this.activateTuitionByCustomer) {
      this.menuItems.push({ label: 'Serviços', icon: 'pi pi-wrench', iconClass: 'green-menu-icon', command: () => this.openServiceCustomer(this.selectedCustomer) })
    }
    this.menuItems.push({ label: 'Excluir', icon: 'pi pi-trash', iconClass: 'red-menu-icon', command: () => this.openCustomerDeleteConfirmDialog(this.selectedCustomer.id) },
      { label: 'Ficha de Cadastro', icon: 'pi pi-print', iconClass: 'red-menu-icon', command: () => this.printCustomerFile(this.selectedCustomer) })

  }

  private openServiceCustomer(selectedCustomer: CustomerTableDTO) {
    this.dialog.open(CustomerServiceComponent, {
      header: `Serviços do Cliente - ${selectedCustomer.name}`,
      styleClass: 'standard-dialog',
      height: '85vh',
      width: '88%',
      data: { selectedCustomer },
    });
  }
  private openDependentCustomer(selectedCustomer: any) {
    if (selectedCustomer.mainDependentCustomerID !== null) {
      this.toasty.warning(`O cliente ${selectedCustomer.name} é dependente de alguém e não pode ter dependentes`);
      return;
    }
    this.dialog.open(CustomerDependentComponent, {
      header: `Dependentes do Cliente - ${selectedCustomer.name}`,
      styleClass: 'standard-dialog',
      height: '70vh',
      width: '70%',
      data: { selectedCustomer },
    });
  }

  checkIfCustomerIsOnlyAssociated(customer: any) {
    return customer.type === AccessGroupEnum.NON_MEMBER;
  }

  printCustomerFile(customer: CustomerTableDTO): void {
    this.spinner.show();
    this.printingService.generatePdfById(customer.id, PrintType.CUSTOMER_FILES).subscribe({
      next: (data) => {
        console.log(data);
        this.spinner.hide();
        const url = window.URL.createObjectURL(data);
        window.open(url);
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error', 'Erro ao gerar arquivo', 'Houve um erro ao gerar o arquivo, tente novamente mais tarde.');
      }
    });
  }
}



