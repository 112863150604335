import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MarinaOrder } from '../models';
import { PaginationFilter } from '../models/pagination-filter';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { OrderIssCityTaxRequestDTO } from '../models/dtos/order-iss-city-tax-requestDTO';
import { FilterBillingCompanySelected } from '../components/filter/filter-billing-company/filter-billing-company.component';
import { UploadDanfeRequestDTO } from '../models/dtos/upload-danfe-request-dto';

@Injectable()
export class MarinaOrderService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<MarinaOrder[]> {
        return this.http.get(environment.apiHost + '/secure/orders/').pipe(map((response: any) => response.data.orders as MarinaOrder[]));
    }

    getById(id: number): Observable<MarinaOrder> {
        return this.http.get(environment.apiHost + '/secure/order/' + id).pipe(map((response: any) => response.data.order));
    }

    getByOrderNumber(orderNumber: number): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/order/orderNumber/' + orderNumber)
            .pipe(map((response: any) => response.data.order));
    }

    create(order: MarinaOrder): Observable<MarinaOrder> {
        return this.http.post(environment.apiHost + '/secure/order/', order).pipe(map((response: any) => response.data.order));
    }

    createSingleOrder(order: MarinaOrder): Observable<MarinaOrder> {
        return this.http.post(environment.apiHost + '/secure/singleorder/', order)
            .pipe(map((response: any) => response.data.order as MarinaOrder));

    }

    cancel(id: number): Observable<MarinaOrder> {
        return this.http.get(environment.apiHost + '/secure/order/cancel/' + id).pipe(map((response: any) => response.data.order));
    }

    getByFilter(filter): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/orders/filter/', filter).pipe(map((response: any) => response.data.orders));
    }

    getByIdInvoice(idInvoice): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/orders/byIdInvoice/' + idInvoice)
            .pipe(map((response: any) => response.data.orders));
    }

    getOrderMarina(filter): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/orders/order/', filter).pipe(map((response: any) => response.data.orders));
    }

    getOrderMarinaWithPagination(filter, paginationFilter: PaginationFilter): Observable<any> {
        let params = new HttpParams();

        if (!paginationFilter.sort || !paginationFilter.order) {
            paginationFilter.sort = 'orderNumber';
            paginationFilter.order = 'DESC';
        }

        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());

        return this.http.post(environment.apiHost + '/secure/orders/pagination/', filter, { params })
            .pipe(map((response: any) => response.data));
    }

    edit(order: any): Observable<any> {
        return this.http.put(environment.apiHost + '/secure/order/edit/', order).pipe(map((response: any) => response.data.order));
    }

    getDebits(customerId: any,
      filter: FilterBillingCompanySelected = undefined
      ): Observable<MarinaOrder[]> {
        if(filter != undefined && filter.filterBillingCompany ) {
          let params = new HttpParams();
          if (filter.billingCompany !== null) {
            params = params.set('billingCompanyId', filter.billingCompany.id.toString());
          }
          return this.http.get(environment.apiHost + '/secure/order/debits/' + customerId + '/billingCompany', { params }).pipe(map((response: any) => response.data.orders));
        } else {
          return this.http.get(environment.apiHost + '/secure/order/debits/' + customerId).pipe(map((response: any) => response.data.orders));
        }
    }

    getDebitsHasSlingToDate(requestDateMilis: number): Observable<MarinaOrder[]> {
        return this.http.get(
            environment.apiHost + '/secure/order/debits/daily-debits-has-sling-to-date/' + requestDateMilis
        ).pipe(map((response: any) => response.data.orders));
    }

    getDebitsCustomersToDate(requestDateMilis: number, customersIds: string): Observable<MarinaOrder[]> {
        return this.http.get(environment.apiHost +
            '/secure/order/debits/customers-to-date/' + requestDateMilis + '?customersIds=' + customersIds
        ).pipe(map((response: any) => response.data.orders));
    }

    checkQuotas(invoiceCustomerDTO): Observable<number[]> {
        return this.http.post(environment.apiHost + '/secure/order/check-quotas', invoiceCustomerDTO)
            .pipe(map((response: any) => response.data.customers as number[]));
    }

    getWhitheldIssTaxByOrder(cityTaxRequestDTO: OrderIssCityTaxRequestDTO): Observable<number> {
        return this.http.post(environment.apiHost + '/secure/order/whitheld-iss-tax', cityTaxRequestDTO).pipe(map((response: any) => response.data.whitheldIssTax));
    }


    uploadDanfe(uploadDanfe: UploadDanfeRequestDTO): Observable<any[]> {
            const formData: FormData = new FormData();
            for (const file of uploadDanfe.files) {
                formData.append('files', file);
            }
            if(uploadDanfe.dueDate){
                formData.append('dueDate', uploadDanfe.dueDate);
            }
            if(uploadDanfe.referenceMonth){
                formData.append('referenceMonth', uploadDanfe.referenceMonth);
            }
            if(uploadDanfe.referenceYear){
                formData.append('referenceYear', uploadDanfe.referenceYear);
            }

            let hd = new HttpHeaders();
            hd = hd.set('Content-Type', 'multipart/form-data');
            const options = {
                headers: hd
            };

            return this.http.post(
                environment.apiHost + '/secure/order/upload-danfe', formData, options).pipe(
                    map((response: any) => response.data.uploadStatus)
                );
        }
}
