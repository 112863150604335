import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import { SlingConfigFormComponent } from './sling-config-form/sling-config-form.component';
import { SlingTimesService } from 'src/app/services/sling-multi-queue/sling-times.service';
import { SlingMultiQueueService } from 'src/app/services/sling-multi-queue/sling-multi-queue.service';
import { forkJoin } from 'rxjs';
import { MultiQueue } from 'src/app/models/multi-queue';

@Component({
  selector: 'app-sling-config',
  templateUrl: './sling-config.component.html',
  styleUrls: ['./sling-config.component.scss']
})
export class SlingConfigComponent implements OnInit {

  private datePipe: DatePipe;
  configs: any[] = new Array();
  currentMarinaId: number;
  multiQueue: MultiQueue[];
  slingOperationTimes: any[];
  slingAppointmentTimes: any[];
  slingConfig: any;

  constructor(
    private slingConfigService: SlingConfigService,
    private dialog: DialogService,
    private slingTimesService: SlingTimesService,
    private slingMultiQueueService: SlingMultiQueueService,
  ) {
    this.currentMarinaId = StorageUtil.getMarina().id;
    this.datePipe = new DatePipe('pt-BR');
  }

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm(): void {
    forkJoin([
      this.slingConfigService.getSlingConfig(),
      this.slingTimesService.findOperationByMarinaId(this.currentMarinaId),
      this.slingTimesService.findAppointmentByMarinaId(this.currentMarinaId),
      this.slingMultiQueueService.getAllMarinaActive(true)
    ]).subscribe(([config, operation, appointment, multiQueue]) => {
      this.configs = config;
      const hasConfig = config.length !== 0;
      if (hasConfig) {
        this.slingConfig = config[0];
      } else {
        this.slingConfig = new SlingConfig();
        this.slingConfig.slingOperationTimes = operation;
        this.slingConfig.slingAppointmentTimes = appointment;
      }
      this.multiQueue = multiQueue;
    });
  }

  async slingConfigDialog(config?): Promise<void> {
    this.dialog.open(SlingConfigFormComponent, {
      width: '80%',
      dismissableMask: false,
      height: '80vh',
      data: {
        slingConfig: this.slingConfig,
        multiQueue: this.multiQueue,
        marinaCompanies: StorageUtil.getMarina().marinaCompanies
      },
      header: 'Configuração geral'
    }).onClose.subscribe(
      () => {
        this.loadForm();
      }
    );
  }

}
