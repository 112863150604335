import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { PanelConfig } from 'src/app/models';
import { Advertisement } from 'src/app/models/advertisement';
import { PanelFunctionality } from 'src/app/models/enums';
import { RaspScreenOrientation } from 'src/app/models/enums/rasp-screen-orientation';
import { PanelConfigService } from 'src/app/services/panel-config.service';
import { ConfigResizePanel } from 'src/app/utils/config-resize-panel';
import { FormUtil } from 'src/app/utils/form.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-panel-config-form',
  templateUrl: './panel-config-form.component.html',
  styleUrls: ['./panel-config-form.component.scss']
})
export class PanelConfigFormComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;

  @Input()
  panelConfig: PanelConfig;
  loading = false;
  imageConfig = { class1: '', width1: 0, class2: '', width2: 0, class3: '', width3: 0, class4: '', width4: 0 };
  configResizePanel = new ConfigResizePanel(this.imageConfig);
  advertiseAdvertisement: boolean;
  imageType: number;
  image1 = '';
  image2 = '';
  image3 = '';
  image4 = '';
  refreshTime: number;
  uploadFileInProgress = false;
  indexEditAdvertisement = -1;

  @Input()
  screenOrientation = RaspScreenOrientation.PORTRAIT;
  @Input()
  panelFunctionalities: any = new Array();
  @Input()
  raspScreenOrientations: SelectItem[];
  @Input()
  movementGroups: SelectItem[] = [];
  @Input()
  slingMultQueueOptions: SelectItem[] = [];

  slingDownQueue = PanelFunctionality.SlingDownQueue;
  constructor(
    private panelConfigService: PanelConfigService
  ) { }

  ngOnInit(): void {
    if (this.panelConfig.advertisements.length > 0) {
      this.advertiseAdvertisement = true;
    } else {
      this.advertiseAdvertisement = false;
    };
  }

  setMovementGroup($event) {
    let mg = this.movementGroups.find(mg => mg.value.name === $event.value);
    this.panelConfig.movementGroupCode = mg.value.code;
    this.panelConfig.movementGroupName = mg.value.name;
  }

  isDisabled(panelFunctionality): boolean {
    return panelFunctionality == 'Disabled';
  }

  changeAdvertiseAdvertisement(): void {
    if (!this.advertiseAdvertisement) {
      this.panelConfig.advertisements = [];
      this.changeAdvertisement();
    }
  }

  formValidations(form: NgForm): boolean {
    FormUtil.touchAndSendForm(form);
    if (this.advertiseAdvertisement && this.panelConfig.advertisements.length < 1) {
      Swal.fire('Alerta!', 'Insira pelo menos uma propaganda para divulgar.', 'warning');
      return false;
    }
    if (form.valid) {
      return true;
    } else {
      Swal.fire('Alerta!', 'Por favor, corrija os campos obrigatórios', 'warning');
      return false;
    }
  }

  changeAdvertisement(): void {
    this.image1 = '';
    this.image2 = '';
    this.image3 = '';
    this.image4 = '';
    this.configResizePanel.resizeImageConfig(Number(this.imageType));
  }

  removeImage(index: number): void {
    this.panelConfig.advertisements.splice(index, 1);
  }

  editAdvertisement(index: number): void {
    this.indexEditAdvertisement = index;
    this.image1 = this.panelConfig.advertisements[index].image1;
    this.image2 = this.panelConfig.advertisements[index].image2;
    this.image3 = this.panelConfig.advertisements[index].image3;
    this.image4 = this.panelConfig.advertisements[index].image4;
    this.imageType = this.panelConfig.advertisements[index].imageType;
    this.refreshTime = this.panelConfig.advertisements[index].refreshTime;
    this.imageType = this.panelConfig.advertisements[index].imageType;
    this.configResizePanel.resizeImageConfig(Number(this.imageType));
    // this.removeImage(index);
  }

  addImage(): void {
    const advertisement: Advertisement = new Advertisement(
      this.imageType,
      this.image1,
      this.image2,
      this.image3,
      this.image4,
      this.refreshTime
    );
    if (this.indexEditAdvertisement >= 0) {
      this.panelConfig.advertisements[this.indexEditAdvertisement] = advertisement;
    } else {
      this.panelConfig.advertisements.push(advertisement);
    }
    this.image1 = '';
    this.image2 = '';
    this.image3 = '';
    this.image4 = '';
    this.imageType = null;
    this.refreshTime = null;
    this.indexEditAdvertisement = -1;
  }

  getNameImage(urlImage: string): string {
    const indexInit = urlImage.indexOf('_EASY_');
    if (indexInit > 0) {
      return urlImage.slice(indexInit + 6, urlImage.length);
    }
    return urlImage;
  }

  isDisabledAddImage(): boolean {
    const imageType = Number(this.imageType);
    if (!imageType || !this.refreshTime || Number(this.refreshTime) < 5) {
      return true;
    }
    if (imageType === 1 && this.image1 === '') {
      return true;
    } else if ((imageType === 2 || imageType === 3 || imageType === 4) &&
      (this.image1 === '' || this.image2 === '')) {
      return true;
    } else if ((imageType === 5 || imageType === 6 || imageType === 7) &&
      (this.image1 === '' || this.image2 === '' || this.image3 === '')) {
      return true;
    } else if (imageType === 8 &&
      (this.image1 === '' || this.image2 === '' || this.image3 === '' || this.image4 === '')) {
      return true;
    }
    return false;
  }

  onBasicUpload(files, image): void {
    this.uploadFileInProgress = true;
    this.panelConfigService.uploadFile(files).subscribe(
      (result) => {
        this.uploadFileInProgress = false;
        this[image] = result.url;
        this.upload.clear();
      },
      (err) => {
        this.uploadFileInProgress = false;
        console.error(err);
      }
    );
  }

  isMultPanel(): boolean {
    return (this.screenOrientation === RaspScreenOrientation.LANDSCAPE
      || this.screenOrientation === RaspScreenOrientation.LANDSCAPE_INVERSE);
  }

}
