import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { DaySettingForQueueDto } from '../models/dtos/config/day-setting-for-queue-dto';
import { DialogService } from 'primeng/dynamicdialog';
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';

@Injectable()
export class BirthdayService {

    constructor(private http: HttpClient,
                private dialog: DialogService,
    ) {}

    buildPDF() {
        const element = document.getElementById('birthdaysTable');
        const opt = {
            margin:      0,
            filename:     'birthdayReport.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2, logging: true, useCORS: true },
            jsPDF:        { 
              unit: 'in', 
              format: 'a4', 
              orientation: 'landscape',
              compress: true
            }
          };
    
        html2pdf()
          .from(element)
          .set(opt)
          .save();
    }

    findWithFilter(filter:any):Observable<any>{
        if(undefined != filter.age){
            return this.findAgeFilter(filter);
        }
        if(undefined != filter.daysForBirthday){
            filter.startDate = undefined;
            filter.endDate = undefined;
        }
        return this.callBirthdayEndpoint(filter);
    }

    findAgeFilter(filter:any): Observable<any>{
        const birthday = new Date();
        const currentDate = new Date();
        birthday.setFullYear(currentDate.getFullYear() - filter.age);
        filter.endDate = birthday.toISOString();
        
        const startDate = new Date();
        startDate.setFullYear(birthday.getFullYear() - 1);
        filter.startDate = startDate.toISOString();
            
        return this.callBirthdayEndpoint(filter);
    }

    callBirthdayEndpoint(filter:any):Observable<any>{

        return this.http
            .post(environment.apiHost + '/api-core/secure/reports/birthday',filter)
            .pipe(map((response: any) => response));
    }
    
}