import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SecuritiesPropertyFilter } from 'src/app/models/dtos/securities-property/securities-property-filter';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { SecuritiesProperty } from 'src/app/models/securities-property/securities-property';
import { CustomerService } from 'src/app/services/customer.service';
import { SecuritiesPropertyService } from 'src/app/services/securities-property/securities-property.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SecuritiesPropertyCadLotComponent } from './securities-property-cad-lot/securities-property-cad-lot.component';
import { SecuritiesPropertyEditComponent } from './securities-property-edit/securities-property-edit.component';
import { SecuritiesPropertyHistComponent } from './securities-property-hist/securities-property-hist.component';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { SecuritiesPropertyInviteUseComponent } from './securities-property-invite-use/securities-property-invite-use.component';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-securities-property',
  templateUrl: './securities-property.component.html',
  styleUrls: ['./securities-property.component.scss'],
})
export class SecuritiesPropertyComponent implements OnInit {
  listSecuritiesProperty: SecuritiesProperty[] = [];
  listTitleProperties: SelectItem[] = [];
  selectedTitleProperty: any;
  customerList: SelectItem[] = [];
  filter: SecuritiesPropertyFilter = new SecuritiesPropertyFilter();
  
  pageable: PaginationFilter = new PaginationFilter();
  contentLoaded = false;
  totalRecords = 0;
  totalDocuments: any;
  numberOfRows = 100;
  menuItems: MenuItem[];
  cols = [];
  selectedItem: SecuritiesProperty;
  useInvites: boolean;

  registerToPrint: any[];


  constructor(
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private securitiesPropertyService: SecuritiesPropertyService,
    private customerService: CustomerService,
    private operationalConfigService: OperationalConfigService
  ) {}

  ngOnInit() {
    this.getCustomers();
    this.getSecuritirsByFilter();
    this.loadMenuItems();
    this.getTitleProperties();
  }

  async getOperationalConfig() {
    this.useInvites = await this.operationalConfigService.invitesControllerEnabled();
 }

  opensecuritiesForm(securitiesProperty?: SecuritiesProperty): void {
    this.dialog
      .open(SecuritiesPropertyCadLotComponent, {
        width: '750px',
        height: '300px',
        header: 'Criar Título de Propriedade',
        data: {
          securitiesProperty: securitiesProperty,
        },
      })
      .onClose.subscribe(() => {
        this.getSecuritirsByFilter();
      });
  }

  loadMenuItems() {
    this.menuItems = [
      {
        label: 'Editar',
        icon: 'pi pi-pencil',
        iconClass: 'green-menu-icon',
        command: () => this.opensecuritiesEdit(this.selectedItem),
      },

      {
        label: 'Histórico',
        icon: 'pi pi-book',
        iconClass: 'red-menu-icon',
        command: () => this.opensecuritiesHistoric(this.selectedItem),
      },


    ];
    if (this.useInvites) {
      this.menuItems.push({
        label: 'Uso de Convite',
        icon: 'pi pi-copy',
        iconClass: 'image-icon-menu',
        command: () => this.openImageHistoric(this.selectedItem),
      });
    }
  }

  changeSelectedItem(item): void {
    this.selectedItem = item;
  }

  opensecuritiesEdit(securitiesProperty?: SecuritiesProperty): void {
    this.dialog
      .open(SecuritiesPropertyEditComponent, {
        width: '1000px',
        height: '800px',
        header: 'Alteração de Propriedade',
        data: {
          securitiesProperty: securitiesProperty
        },
      })
      .onClose.subscribe(() => {
        this.getSecuritirsByFilter();
      });
  }

  opensecuritiesHistoric(securitiesProperty?: SecuritiesProperty): void {
    this.dialog
      .open(SecuritiesPropertyHistComponent, {
        width: '1000px',
        height: '1000px',
        header:
          'Histórico de Proprietarios do Título ' + securitiesProperty.numberId,
        data: {
          securitiesProperty: securitiesProperty
        },
      })
      .onClose.subscribe(() => {
        this.getSecuritirsByFilter();
      });
  }


  openImageHistoric(securitiesProperty?: SecuritiesProperty): void {
    this.dialog
      .open(SecuritiesPropertyInviteUseComponent, {
        width: '1000px',
        height: '1000px',
        header:
          'Lista de uso dos convites do Título  ' + securitiesProperty.numberId,
        data: {
          securitiesProperty: securitiesProperty,
        },
      })
      .onClose.subscribe(() => {
        this.getSecuritirsByFilter();
      });
  }


  clear(): void {
    this.filter = new SecuritiesPropertyFilter();
    this.getSecuritirsByFilter();
  }

  onChangePage(event: LazyLoadEvent): void {
    const page = event.first / event.rows;
    if (event.multiSortMeta) {
      this.pageable.sort = event.multiSortMeta[0].field;
      this.pageable.order = event.multiSortMeta[0].order === 1 ? 'ASC' : 'DESC';
    }
    this.pageable.page = page;
    this.pageable.size = event.rows;
    this.getSecuritirsByFilter();
  }

  getTitlePropertyNameById(id: any):string{
    let name = "";
       this.listTitleProperties.forEach(item=>{
        if( id != null && id == item.value){
          name = item.label
        }
      })

    return name;
  }

  getSecuritirsByFilter(): void {
    this.contentLoaded = false;
    this.securitiesPropertyService
      .getAllByFilter(this.filter, this.pageable)
      .subscribe({
        next: (data) => {
          this.listSecuritiesProperty = data.content;
          this.totalRecords = data.totalElements;
          this.totalDocuments = data.totalPages;
          this.contentLoaded = true;
          this.contentLoaded = true;
        },
        error: (error) => {
          this.contentLoaded = true;
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
  }

  getTitleProperties():void {
    this.customerService.getTitlePropertiesByMarinaId().subscribe({
      next: (data) => {
        this.listTitleProperties = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  async exportTable(): Promise<void> {
    await this.findDataExport();
    const data = { table: this.registerToPrint, type: 'PROPERTIES' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Dados dos Títulos - Exportar Dados',
    });
  }

  async findDataExport(): Promise<void> {
    return new Promise(async (resolve) => {
      let pag = { ...this.pageable };
      pag.size = 1000000;
      pag.page = 0;
      pag.sort = 'numberId';
      pag.order = 'ASC';
      this.securitiesPropertyService
        .getAllByFilter(this.filter, pag)
        .subscribe({
          next: (data) => {
            this.registerToPrint = data.content.map((propert) => {
              return this.formatDataExport(propert);
            });
            resolve();
          },
          error: (error) => {
            this.contentLoaded = true;
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(
              exception.type,
              exception.title,
              exception.message
            );
          },
        });
    });
  }

  formatDataExport(proper): any {
    proper.status = proper.active ? 'Ativo' : 'Inativo';

    return {
      status: proper.status,
      numberId: proper.numberId,
      customerId: proper.customerId,
      currentCustomerName: proper.currentCustomerName,
      lastAssociation: proper.lastAssociation,
      currentCustomerPhone: proper.currentCustomerPhone,
    };
  }
}
