import { MenuItemService } from './../../services/menu-item.service';
import { OperationalConfigService } from './../../services/config/operational-config.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceBankingBillet } from 'src/app/models';
import { DashboardPieModel } from 'src/app/models/dashboardPieModel';
import { StockReport } from 'src/app/models/stockReport';
import { BilletService } from 'src/app/services/billet.service';
import { BoatService } from 'src/app/services/boat.service';
import { ContractDocumentService } from 'src/app/services/contract-document.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MyAccountService } from 'src/app/services/my-account.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { DueExpireTuitionsComponent } from '../billing/tuitions/due-expire-tuitions/due-expire-tuitions.component';

interface dataChartResult {
  name: string;
  value: number;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})



export class DashboardComponent implements OnInit {

  
  showDashboard = true;

  qtyBoatMaintenance = 0;
  boatsMaintenance = 'Nenhum barco em manutenção';

  customerByAppAndWeb: DashboardPieModel[] = [];
  customerType: DashboardPieModel[] = [];
  documentCondition: DashboardPieModel[] = [];
  slingOrigin: DashboardPieModel[] = [];
  slingQuantity: DashboardPieModel[] = [];
  slingStatusToday: DashboardPieModel[] = [];
  stockReport: StockReport[] = [];
  boatInsurance: DashboardPieModel[] = [];
  insuranceCondition: DashboardPieModel[] = [];
  licenceCondition: DashboardPieModel[] = [];
  slingQuantityChart: any;
  days: number;
  statusToday: any;

  origins: any;
  doughnutOptions = {
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      cutout: 70,
    },
  };
  barOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      cutout: 70,
    },
  };
  quantityChart: any;
  customerApp: any;
  boatDocuments: any;
  boatInsurers: any;
  insuranceValidation: any;
  customerTypes: any;
  licenceConditions: any;
  isMovements: boolean;
  isSlings: boolean;
  stashNotifications: number;
  bankingBillet$: Observable<number>;
  refusedBillets$: Observable<InvoiceBankingBillet[]>;
  numberNeverWashOrEngineTurn$: Observable<number>;
  numberExpiredPeriodicalService$: Observable<number>;
  numberOfServicesToWinExpire$: Observable<number>;
  numberContractsInAnalysis$: Observable<number>;

  blueColor = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-blue-color').trim();
  blueLight3Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-blue-light-3-color').trim();
  blueLight2Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-blue-light-2-color').trim();
  blueLight1Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-blue-light-1-color').trim();
  blueLight0Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-blue-light-0-color').trim();
  
  grayColor = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-gray-color').trim();
  gray4Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-gray-4-color').trim();
  gray3Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-gray-3-color').trim();
  gray2Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-gray-2-color').trim();
  gray1Color = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-gray-1-color').trim();
  
  redColor = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-red-color').trim();
  orangeColor = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-orange-color').trim();
  yellowColor = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-yellow-color').trim();
  greenColor = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-green-color').trim();
  

  constructor(
    private myAccountService: MyAccountService,
    private billetService: BilletService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private contractDocumentService: ContractDocumentService,
    private boatService: BoatService,
    private operationalConfigService: OperationalConfigService,
    private menuItemService: MenuItemService,
    private dashboardService: DashboardService,
    private router: Router,
    private dialogService: DialogService,
  ) {
    this.findDashboards();
  }

  ngOnInit(): void {
    this.operationalConfigService.isMovements().then((movements: boolean) => {
      this.isMovements = movements;
      this.isSlings = !movements;
    });

    this.bankingBillet$ = this.getBankPendingBillets();
    this.refusedBillets$ = this.getRefusedBillets();
    this.numberNeverWashOrEngineTurn$ = this.getnumberNeverWashOrEngineTurn();
    this.numberExpiredPeriodicalService$ = this.getExpiredPeriodicalService();
    this.numberOfServicesToWinExpire$ = this.getToWinExpirePeriodicalService();
    this.numberContractsInAnalysis$ = this.getNumberContractsInAnalysis();
    this.getInvoiceDays();
  }

  redirectToSelectionFiltered(event, type) {
    switch (type) {
      case 'insuranceValidity':
        let stringRoute = '/app/forms/boat'
        switch (event.element.index) {
          case 0:
            this.router.navigate([stringRoute], { queryParams: { insuranceValidity: 'NO_INSURANCE' } });
            break;
          case 1:
            this.router.navigate([stringRoute], { queryParams: { insuranceValidity: 'EXPIRED' } });
            break;
          case 2:
            this.router.navigate([stringRoute], { queryParams: { insuranceValidity: 'VALID' } });
            break;
          default:
            break;
        }
        break;
    }
  }

  openModal() {
    const ref = this.dialogService.open(DueExpireTuitionsComponent, {
      header: 'Serviços a vencer nos próximos 15 dias.',
    });
  }

  getInvoiceDays() {
    const vencimento: any = StorageUtil.getLastPayment();
    if (!vencimento) {
      this.getLatestPayment().subscribe({
        next: (vencimento) => {
          if (vencimento) {
            this.calculateDays(vencimento);
          }
        }, error: (err) => {
          console.log(err);
        }
      });
    } else {
      this.calculateDays(vencimento);
    }
  }

  getMenuStructure(): void {
    this.menuItemService.getAllStructured().subscribe((data) => {
      data.filter((b) => b.label === 'DASHBOARD').length > 0
        ? (this.showDashboard = true)
        : (this.showDashboard = false);
    });
  }

  findDashboards(): void {
    this.spinner.show();
    this.dashboardService.customerByAppAndWeb().subscribe((data) => {
      this.customerByAppAndWeb = data;
      this.createSeriesCustomerByAppAndWebChart();
    });
    this.dashboardService.customerType().subscribe((data) => {
      this.customerType = data;
      this.createSeriesCustomerTypeChart();
    });
    this.dashboardService.documentCondition().subscribe((data) => {
      this.documentCondition = data;
      this.createSeriesDocumentsConditionChart();
    });
    this.dashboardService.slingOrigin().subscribe((data) => {
      this.slingOrigin = data;
      this.createSeriesSlingOriginChart();
    });
    this.dashboardService.slingQuantity().subscribe((data) => {
      this.slingQuantity = data;
      this.slingQuantityChart = this.createSeriesSlingQuantityChart();
    });
    this.dashboardService.slingStatusToday().subscribe((data) => {
      this.slingStatusToday = data;
      this.slingStatusToday.forEach((status) => {
        this.translateService
          .get(status.name.toUpperCase())
          .subscribe((result: string) => (status.name = result));
      });
      this.statusTodayCreateSeriesSlingChart();
    });
    this.dashboardService.stockReport().subscribe((data) => {
      this.stockReport = data;
      // this.slingQuantityChart = this.createSeriesSlingQuantityChart();
    });
    this.dashboardService.qtyBoatMaintenance().subscribe((data) => {
      if (data.length > 0) {
        this.boatsMaintenance = data.join('\n');
        this.qtyBoatMaintenance = data.length;
      }
      this.spinner.hide();
    });

    this.dashboardService.boatInsurance().subscribe({
      next: (data) => {
        this.boatInsurance = data;
        this.createBoatInsuranceChart();
      },
      error: (e) => {
        console.log(e);
      },
    })

    this.dashboardService.insuranceCondition().subscribe((data) => {
      this.insuranceCondition = data;
      this.createInsuranceConditionChart();
    });

    this.dashboardService.licenceCondition().subscribe((data) => {
      this.licenceCondition = data;
      this.createLicenceConditionChart();
    });
  }
  private processChartData(
    backendData: dataChartResult[],
    statusOrder: string[]
  ): dataChartResult[] {
    const processedData = statusOrder.map(status => ({
      name: status,
      value: 0
    }));
  
    backendData.forEach(item => {
      const index = statusOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });
  
    return processedData;
  }
  
  private generateChartData(
    processedData: dataChartResult[],
    colorMapping: { [key: string]: string }
  ) {
    const labelsWithPercent = this.addPercentageToLabels(processedData);
  
    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => colorMapping[item.name] || this.blueColor),
        }
      ]
    };
  }
  



  //Lingadas de hoje
  statusTodayCreateSeriesSlingChart(): any {
    const processedData = this. statusTodayProcessData(this.slingStatusToday);
    //console.log('lingadas de hoje', this.slingStatusToday)
    this.statusToday = this. statusTodayCreateChartData(processedData);
  }
  private readonly statusTodayOrder = [
    'Cancelado',
    'Pendente de subida',
    'Água',
    'Pendente de descida',
    'OK',
   ];

  private readonly  statusTodayColorMapping = {
    'OK': this.blueColor,      
    'Pendente de descida': this.blueLight3Color, 
    'Água': this.blueLight2Color,          
    'Pendente de subida': this.blueLight1Color, 
    'Cancelado': this.redColor,     
  };

   statusTodayProcessData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.statusTodayOrder.map(status => ({
      name: status,
      value: 0
    }));

    backendData.forEach(item => {
      const index = this.statusTodayOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabels(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);
    
    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

   statusTodayCreateChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabels(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this. statusTodayColorMapping[item.name]),
        }
      ]
    };
  }



  //Origens da lingada
  private readonly slingOriginOrder = [
    'EasyWeb',
    'App totem',
    'GestorApp',
    'MarinheiroApp',
    'App cliente',
  ];

  private readonly slingOriginColorMapping = {
    'App cliente': this.blueColor,
    'MarinheiroApp': this.blueLight3Color,
    'GestorApp': this.blueLight2Color,
    'App totem': this.blueLight1Color,
    'EasyWeb': this.blueLight0Color,
  };

  createSeriesSlingOriginChart(): any {
    const processedData = this.processSlingOriginData(this.slingOrigin);
    //console.log('Origens da lingada', this.slingOrigin)
    this.origins = this.createSlingOriginChartData(processedData);
  }

  private processSlingOriginData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.slingOriginOrder.map(status => ({
      name: status,
      value: 0
    }));

    backendData.forEach(item => {
      const index = this.slingOriginOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabelsSlingOrigin(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

  private createSlingOriginChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsSlingOrigin(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.slingOriginColorMapping[item.name]),
        }
      ]
    };
  }


  

  //Resumo de lingadas
  createSeriesSlingQuantityChart(): any {
    this.quantityChart = {
      labels: this.slingQuantity.map((s) => s.name),
      datasets: [
        {
          data: this.slingQuantity.map((s) => s.value),
          backgroundColor: [this.blueLight3Color],
        },
      ],
    };
  }

  //Cliente App
  private readonly customerByAppAndWebOrder = [
    'Sem App',
    'Com App',
  ];

  private readonly customerByAppAndWebColorMapping = {
    'Com App': this.blueColor,
    'Sem App': this.redColor,
  };

  createSeriesCustomerByAppAndWebChart(): any {
    const processedData = this.processCustomerByAppAndWebData(this.customerByAppAndWeb);
   //console.log('App cliente', this.customerByAppAndWeb)
    this.customerApp = this.createCustomerByAppAndWebChartData(processedData);
  }

  private processCustomerByAppAndWebData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.customerByAppAndWebOrder.map(status => ({
      name: status,
      value: 0
    }));

     backendData.forEach(item => {
      const index = this.customerByAppAndWebOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabelsCustomerByAppAndWeb(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

  private createCustomerByAppAndWebChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsCustomerByAppAndWeb(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.customerByAppAndWebColorMapping[item.name] || this.blueColor),
        }
      ]
    };
  }

  

  //Documentos das embarcações
  private readonly documentConditionOrder = [
    'Sem documento',
    'Expirado',
    'Expira em até 30 dias',
    'Ok',
  ];

  private readonly documentConditionColorMapping = {
    'Ok': this.blueColor,
    'Expira em até 30 dias': this.yellowColor,
    'Expirado': this.orangeColor,
    'Sem documento': this.redColor,
  };

  createSeriesDocumentsConditionChart(): any {
    const processedData = this.processDocumentsConditionData(this.documentCondition);
    //console.log('Documentos das embarcações', this.documentCondition)
    this.boatDocuments = this.createDocumentsConditionChartData(processedData);
  }

  private processDocumentsConditionData(backendData: dataChartResult[]): dataChartResult[] {
     const processedData = this.documentConditionOrder.map(status => ({
      name: status,
      value: 0
    }));

    backendData.forEach(item => {
      const index = this.documentConditionOrder.indexOf(item.name);
      if (item.name === 'Sem documento') {
        const index = this.boatInsuranceOrder.indexOf('Sem documento');
        if (index !== -1) {
          processedData[index].value += item.value;
        }
      }
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabelsDocumentsCondition(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

  private createDocumentsConditionChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsDocumentsCondition(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.documentConditionColorMapping[item.name]),
        }
      ]
    };
  }

  
  //Seguro das embarcações
  private readonly boatInsuranceOrder = [
    'Não verificado',
    'Não segurado',
    'Segurado',
  ];

  private readonly boatInsuranceColorMapping = {
    'Segurado': this.blueColor,
    'Não segurado': this.blueLight1Color,
    'Não verificado': this.redColor,
  };

  createBoatInsuranceChart(): any {
    
    const processedData = this.processBoatInsuranceData(this.boatInsurance);
    //console.log('Seguro das embarcações', this.boatInsurance)
    this.boatInsurers = this.createBoatInsuranceChartData(processedData);
  }

  private processBoatInsuranceData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.boatInsuranceOrder.map(status => ({
      name: status,
      value: 0
    }));

   backendData.forEach(item => {
      if (item.name === 'Sem seguro') {
        const index = this.boatInsuranceOrder.indexOf('Não segurado');
        if (index !== -1) {
          processedData[index].value += item.value;
        }
      } else if (item.name === '') {
        const index = this.boatInsuranceOrder.indexOf('Não verificado');
        if (index !== -1) {
          processedData[index].value += item.value;
        }
      } else {
        const index = this.boatInsuranceOrder.indexOf('Segurado');
        if (index !== -1) {
          processedData[index].value += item.value;
        }
      }
    });

    return processedData;
  }

  private addPercentageToLabelsBoatInsurance(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name || 'Indefinido'} (${percentage}%)`;
    });
  }

  private createBoatInsuranceChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsBoatInsurance(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.boatInsuranceColorMapping[item.name] || this.blueColor),
        }
      ]
    };
  }

  //vIgencias das apolices
  private readonly insuranceConditionOrder = [
    'Vencido',
    'Expira em 30 dias',
    'Sem Seguro',
    'Vigente',
  ];

  private readonly insuranceConditionColorMapping = {
    'Vigente': this.blueColor,
    'Sem Seguro': this.orangeColor,
    'Expira em 30 dias': this.yellowColor,
    'Vencido': this.redColor,
  };

  createInsuranceConditionChart(): any {
    const processedData = this.processInsuranceConditionData(this.insuranceCondition);
    //console.log('vIgencias das apolices', this.insuranceCondition)
    this.insuranceValidation = this.createInsuranceConditionChartData(processedData);
  }

  private processInsuranceConditionData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.insuranceConditionOrder.map(status => ({
      name: status,
      value: 0
    }));

    backendData.forEach(item => {
      const index = this.insuranceConditionOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabelsInsuranceCondition(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

  private createInsuranceConditionChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsInsuranceCondition(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.insuranceConditionColorMapping[item.name] || this.blueColor),
        }
      ]
    };
  }

  

  //Tipos de Cliente
  private readonly customerTypeOrder = [
    'Sem Barco',
    'Autorizado',
    'Proprietário',
  ];

  private readonly customerTypeColorMapping = {
    'Proprietário': this.blueColor,
    'Autorizado': this.blueLight3Color,
    'Sem Barco': this.blueLight2Color,
  };

  createSeriesCustomerTypeChart(): any {
    const processedData = this.processCustomerTypeData(this.customerType);
    //console.log('Tipos de Cliente', this.customerType)
    this.customerTypes = this.createCustomerTypeChartData(processedData);
  }

  private processCustomerTypeData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.customerTypeOrder.map(status => ({
      name: status,
      value: 0
    }));

    backendData.forEach(item => {
      if (item.name === 'Prop./Aut.' || item.name === 'Proprietário') {
        item.name = 'Proprietário';
      }

      const index = this.customerTypeOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabelsCustomerType(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

  private createCustomerTypeChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsCustomerType(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.customerTypeColorMapping[item.name] || this.blueColor),
        }
      ]
    };
  }


  //Habilitação dos clientes
  private readonly licenceConditionOrder = [
    'Sem habilitação',
    'Expirado',
    'Expira em até 30 dias',
    'Ok',
  ];

  private readonly licenceConditionColorMapping = {
    'Ok': this.blueColor,
    'Expira em até 30 dias': this.yellowColor,
    'Expirado': this.orangeColor,
    'Sem habilitação': this.redColor,
  };

  createLicenceConditionChart(): any {
    const processedData = this.processLicenceConditionData(this.licenceCondition);
    //console.log('Habilitação dos clientes', this.licenceCondition)
    this.licenceConditions = this.createLicenceConditionChartData(processedData);
  }

  private processLicenceConditionData(backendData: dataChartResult[]): dataChartResult[] {
    const processedData = this.licenceConditionOrder.map(status => ({
      name: status,
      value: 0
    }));

    backendData.forEach(item => {
      const index = this.licenceConditionOrder.indexOf(item.name);
      if (index !== -1) {
        processedData[index].value += item.value;
      }
    });

    return processedData;
  }

  private addPercentageToLabelsLicenceCondition(data: dataChartResult[]): string[] {
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return data.map(item => {
      const percentage = total > 0 ? ((item.value / total) * 100).toFixed(1) : '0';
      return `${item.name} (${percentage}%)`;
    });
  }

  private createLicenceConditionChartData(processedData: dataChartResult[]) {
    const labelsWithPercent = this.addPercentageToLabelsLicenceCondition(processedData);

    return {
      labels: labelsWithPercent,
      datasets: [
        {
          data: processedData.map(item => item.value),
          backgroundColor: processedData.map(item => this.licenceConditionColorMapping[item.name] || this.blueColor),
        }
      ]
    };
  }

  

  private addPercentageToLabel(array: any[]): string[] {
    const values = array.map((s) => s.value);
    const total = values.reduce((a, b) => a + b, 0);
    return array.map((s) => {
      const percentage =
        ((s.value / total) * 100).toFixed(2).replace('.', ',') + '%';
      return s.name + ' - ' + percentage;
    });
  }

  refresh(): void {
    this.customerByAppAndWeb = [];
    this.customerType = [];
    this.documentCondition = [];
    this.slingOrigin = [];
    this.slingQuantity = [];
    this.slingStatusToday = [];
    this.findDashboards();
  }

  getLatestPayment(): Observable<Date> {
    return new Observable<Date>((observer) => {
      this.myAccountService.getLatestPayment().subscribe({
        next: (vencimento) => {
          if (vencimento) {
            observer.next(vencimento);
          } else {
            observer.next(null);
          }
        }, error: (err) => {
          observer.error(err);
        }
      });
    });
  }

  calculateDays(vencimento): void {
    this.days = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .diff(moment(vencimento, 'YYYY-MM-DD'), 'days', false);
  }

  getBankPendingBillets(): Observable<number> {
    return new Observable<number>((observer) => {
      this.billetService.findBankPending().subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: (err) => {
          console.log(err);
          observer.error(null);
        }
      });
    });
  }

  getRandomColor(): string {
    return Math.floor(Math.random() * 16777215).toString(16);
  }

  getRefusedBillets(): Observable<InvoiceBankingBillet[]> {
    return new Observable<InvoiceBankingBillet[]>((observer) => {
      this.billetService.findRefusedNotCanceled().subscribe({
        next: (response) => {
          let billets = response;
          if (response.length == 0) {
            billets = null;
          }
          observer.next(billets);
        },
        error: (err) => {
          console.log(err);
          observer.error(null);
        }
      });
    });
  }

  getExpiredPeriodicalService(): Observable<number> {
    return new Observable<number>((observer) => {
      this.boatService.getExpiredPeriodicalService().subscribe({
        next: (data) => {
          if (data > 0) {
            observer.next(data);
          } else {
            observer.next(null)
          }
        },
        error: (error) => {
          console.log(error);
          observer.error(error);
        }
      });
    });
  }

  getToWinExpirePeriodicalService(): Observable<number> {
    return new Observable<number>((observer) => {
      this.boatService.getExpiringServices().subscribe({
        next: (services) => {
          const length = services.length;
          observer.next(length);
        },
        error: (error) => {
          console.log(error);
          observer.error(error);
        }
      });
    });
  }

  getNumberContractsInAnalysis(): Observable<number> {
    return new Observable<number>((observer) => {
      this.contractDocumentService.getNumberContractsInAnalysis().subscribe({
        next: (data) => {
          if (data > 0) {
            observer.next(data);
          } else {
            observer.next(null);
          }
        },
        error: (error) => {
          console.log(error);
          observer.error(error);
        }
      });
    });
  }

  getnumberNeverWashOrEngineTurn(): Observable<number> {
    return new Observable<number>((observer) => {
      this.boatService.getNumberNeverWashOrEngineTurn().subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: (err) => {
          console.log(err);
          observer.error(null);
        }
      });
    });
  }
}
