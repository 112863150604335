import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-sling-time-config',
  templateUrl: './sling-time-config.component.html',
  styleUrls: ['./sling-time-config.component.scss']
})
export class SlingTimeConfigComponent {
//   <app-sling-time-config [slingTimes]="slingConfig.slingTimes" [slingConfig]="slingConfig"
//   [slingTime]="slingConfig.slingTimes[0]" [index]="0">
// </app-sling-time-config>
// baseado no html acima, crie o componente sling-time-config

  @Input()
  slingTimes: any;
  @Input()
  queue: any;
  @Input()
  slingOperations: any;

  constructor() { }

  ngOnInit() {
    if (!this.slingTimes) {
      this.slingTimes = [];
    } else {
      this.slingTimes = this.slingTimes.filter((time) => time.slingMultiQueue.id === this.queue.id);
    }
  }

  isDayWeekDisabled(dayWeek: string) {
    const operation = this.slingOperations.find((operation) => {
        return operation.dayWeek === dayWeek;
    });

    return !operation.inOperation;
}
}
