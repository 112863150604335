import {ServiceCustomer} from '../ServiceCustomer';

export class ServiceCustomerDTO {
  id: number;
  serviceId: number;
  serviceName: string;
  customerId: number;
  executionPeriodicity: string;
  billingPeriodicity: string;
  value: number;
  discount: number;
  observation: string;
  dueDateDiscount: number;
  contractStartDate: Date;
  contractEndDate: Date;
  lastReadjustment: Date;
  originalFileName: string;
  filePath: string;
  active: boolean;
  useDiscountOnPercentage: boolean;
  discountPercentage: number;
  dueDateDiscountPercentage: number;
  beneficiaryName: string;
  beneficiaryId: number;
  vacancyId: number;
  vacancyCode: string;


  constructor() {
    this.id = null;
    this.serviceId = null;
    this.customerId = null;
    this.executionPeriodicity = null;
    this.billingPeriodicity = null;
    this.value = 0;
    this.discount = 0;
    this.observation = null;
    this.dueDateDiscount = 0;
    this.contractStartDate = null;
    this.contractEndDate = null;
    this.lastReadjustment = null;
    this.originalFileName = null;
    this.filePath = null;
    this.active = true;
    this.useDiscountOnPercentage = false;
    this.discountPercentage = 0;
    this.dueDateDiscountPercentage = 0;
    this.beneficiaryName = null;
    this.beneficiaryId = null;
    this.vacancyId = null;
    this.vacancyCode = null;
    }

  mapToCustomerDTO(serviceCustomer: ServiceCustomer, customerId: number) {
    this.id = serviceCustomer.id;
    this.serviceId = serviceCustomer.service.id;
    this.customerId = customerId;
    this.executionPeriodicity = serviceCustomer.executionPeriodicity;
    this.billingPeriodicity = serviceCustomer.billingPeriodicity;
    this.value = serviceCustomer.value;
    this.discount = serviceCustomer.discount;
    this.observation = serviceCustomer.observation;
    this.dueDateDiscount = serviceCustomer.dueDateDiscount;
    this.contractStartDate = serviceCustomer.contractStartDate;
    this.contractEndDate = serviceCustomer.contractEndDate;
    this.lastReadjustment = serviceCustomer.lastReadjustment;
    this.originalFileName = serviceCustomer.originalFileName;
    this.filePath = serviceCustomer.filePath;
    this.active = serviceCustomer.active;
    this.useDiscountOnPercentage = serviceCustomer.useDiscountOnPercentage;
    this.discountPercentage = serviceCustomer.discountPercentage;
    this.dueDateDiscountPercentage = serviceCustomer.dueDateDiscountPercentage;
    this.beneficiaryId = serviceCustomer.beneficiaryId;
    this.beneficiaryName = serviceCustomer.beneficiaryName;
    this.vacancyId = serviceCustomer.vacancyId;
    this.vacancyCode = serviceCustomer.vacancyCode;
  }
}

export class Beneficiary {
  id: number;
  name: string;

  constructor() {
    this.id = null;
    this.name = null;
  }
}