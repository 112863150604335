import { Injectable } from '@angular/core';
import moment from 'moment';
import { SlingConfig, SlingTime } from "../models";
import { MessageSlingDay } from "../models/dtos/message-sling-day";
import { DayWeek } from '../models/enums/day-week';
import { OperationCloseDay } from '../models/operation-close-day';
import { OperationSpecialDay } from '../models/operation-special-day';
import { MessageUtil } from './message.util';

@Injectable()
export class MessageSlingDayUtil {

    constructor(
        private messageUtil: MessageUtil,
    ) { }

    public checkCloseDayExecutionForecast(date: Date, slingConfig: SlingConfig): MessageSlingDay {
        const message = this.getMessageCloseDay(date, slingConfig);
        if (message) {
            return {
                message,
                date,
                type: 'CloseDay'
            };
        }
        return undefined;
    }

    public checkCloseDayReturnDate(date: Date, slingConfig: SlingConfig): MessageSlingDay {
        const message = this.getMessageCloseDay(date, slingConfig);
        if (message) {
            return {
                message,
                date,
                type: 'CloseDay'
            };
        }
        return undefined;
    }

    public checkSpecialDayExecutionForecast(date: Date, slingConfig: SlingConfig): MessageSlingDay {
        const message = this.getMessageSpecialDay(date, slingConfig);
        if (message) {
            return {
                message,
                date,
                type: 'SpecialDay'
            };
        }
        return undefined;
    }

    public checkSpecialDayReturnDate(date: Date, slingConfig: SlingConfig): MessageSlingDay {
        const message = this.getMessageSpecialDay(date, slingConfig);
        if (message) {
            return {
                message,
                date,
                type: 'SpecialDay'
            };
        }
        return undefined;
    }

    public checkCloseDayWeekExecutionForecast(date: Date, slingConfig: SlingConfig): MessageSlingDay {
        const message = this.getMessageCloseDayWeek(date, slingConfig);
        if (message) {
            return {
                message,
                date,
                type: 'CloseDayWeek'
            };
        }
        return undefined;
    }

    public checkCloseDayWeekReturnDate(date: Date, slingConfig: SlingConfig): MessageSlingDay {
        const message = this.getMessageCloseDayWeek(date, slingConfig);
        if (message) {
            return {
                message,
                date,
                type: 'CloseDayWeek'
            };
        }
        return undefined;
    }

    private getMessageCloseDay(date: Date, slingConfig: SlingConfig): string {
        const closeDay = slingConfig.operationCloseDays.find(closeDay => this.isCloseDay(closeDay, date));
        return closeDay ? closeDay.observation : undefined;
    }

    private isCloseDay(specialDay: OperationCloseDay, date: Date): boolean {
        return this.equalsDays(new Date(specialDay.date), date);
    }

    private getMessageSpecialDay(date: Date, slingConfig: SlingConfig): string {
        const specialDay = slingConfig.operationSpecialDays.find(specialDay => this.isSpecialDay(specialDay, date));
        return specialDay ? specialDay.observation : undefined;
    }

    private getMessageCloseDayWeek(date: Date, slingConfig: SlingConfig): string {
        const slingTime = slingConfig.slingAppointmentTimes.find(slingTime => this.isCloseDayWeek(slingTime, date));
        return slingTime?.inOperation ? undefined : "A marina não opera nesse dia: " + this.messageUtil.translateKey(slingTime.dayWeek);
    }

    private isSpecialDay(specialDay: OperationSpecialDay, date: Date): boolean {
        return this.equalsDays(new Date(specialDay.date), date);
    }

    private isCloseDayWeek(slingTime: SlingTime, date: Date): boolean {
        const day = this.getDayWeek(date.getDay());
        return slingTime.dayWeek === day;
    }

    public equalsDays(date: Date, date2: Date): boolean {
        return moment(date).format('DD/MM/YY') === moment(date2).format('DD/MM/YY');
    }

    public getDayWeek(dayWeek: number): string {
        switch (dayWeek) {
            case 0: {
                return DayWeek.SUNDAY
            }
            case 1: {
                return DayWeek.MONDAY
            }
            case 2: {
                return DayWeek.TUESDAY
            }
            case 3: {
                return DayWeek.WEDNESDAY
            }
            case 4: {
                return DayWeek.THURSDAY
            }
            case 5: {
                return DayWeek.FRIDAY
            }
            case 6: {
                return DayWeek.SATURDAY
            }

        }
    }
}