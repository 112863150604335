import { Marina } from './marina';
import { FrequentDestination } from './frequent-destination';
import { EmailCopy } from './email-copy';
import { OptionPrintQuestionTotem } from './enums/option-print-question-totem';
import { User } from './user';
import { OptionWashAndTurnEngine } from './enums/option-wash-and-turn-engine';
import { OperationSpecialDay } from './operation-special-day';
import { OperationCloseDay } from './operation-close-day';
import { FinancialMultipleCompaniesType } from './enums/finances/financial-multiple-companies-type';
import { TypeBlockCustomer } from './enums/type-block-customer';

export class SlingConfig {
  id: number;
  operationStart: any;
  operationEnd: any;
  slingOperationTimes: any[] = [];
  slingAppointmentTimes: any[] = [];
  frequentFestinations: FrequentDestination[] = new Array();
  emailsCopy: EmailCopy[] = new Array();
  users: User[] = new Array();

  numberSling: number;
  sendInvoiceViaWhatsapp: boolean;
  amountSkipAvaiablePeriods: number;
  hasFuelSupply = false;
  hasConsumables = false;
  hasAccessories = false;
  printReceipt = false;
  advertiseApp = false;
  sendEmailCopy = false;
  sharedVacancy = false;
  queueControl = false;
  preparationControl = false;
  slingDownOnPreparation = false;
  panelsVacancyDisplay = false;
  slingsNextDay = false;
  ownFuelSupply = false;
  finishOnWater = false;
  blockOverdueClients = false;
  showSlingOrigin = false;
  includeTotemNavigation = false;
  printFuelSupply = false;
  toConfirm = false;
  measuredFuel = false;
  consumablesApp = false;
  marineEngineTurnRun = false;
  marineBoatWash = false;

  timeBetweenWash: number;
  autoScheduleUpdateWash = false;
  washRunOption: OptionWashAndTurnEngine;
  hasWashBoatMaintenance = false;
  boatWashSailor = false;

  timeBetweenEngineRotation: number;
  autoScheduleUpdateEngineTurn = false;
  engineTurnRunOption: OptionWashAndTurnEngine;
  hasEngineTurnBoatMaintenance = false;
  engineTurnSailor = false;

  manageDeck = false;
  hasFractionalSupply = false;
  intervalBetweenAdvertising: number;
  earlyPayment = false;
  daysDue: any;
  numberOfRecordsPerPage: number;
  smsGeneralNotices: any;

  accountId: number;
  accountName: string;
  paymentMethodId: number;
  paymentMethodName: string;
  emailCopy: string;
  marina?: Marina;
  printNavigationPlan: OptionPrintQuestionTotem;
  printItemsDuringDelivery: OptionPrintQuestionTotem;
  radiusOfGyration: number;
  radiusOfPrepGyration: any;
  fuelTankFractions: string;
  engineTurnScheduleDays: number;
  boatWashScheduleDays: number;
  searchByCustomer = false;
  showBoatsOnMaintenance = false;
  requestPendingUpApp = false;

  addPhotoBasicCustomerApp = false;
  addPhotoBasicOperatorApp = false;
  addPhotoBasicTotemApp = false;

  useIndex = false;
  indexUsed: string;
  timezone: number;
  discountDirect: boolean;

  competency: number;
  competencyJuros: number;
  competencyDiscount: number;

  // Financeiro
  financialMultipleCompaniesType: FinancialMultipleCompaniesType;
  costCenterInStock = false;

  // Antecipação
  anticipation = false;
  anticipationTaxAccountPlan: number;
  anticipationIpiAccountPlan: number;
  anticipationCreditAccountPlan: number;
  anticipationRepurchaseAccountPlan: number;
  anticipationDebitAccountPlan: number;

  navigationPlanRequired = false;
  navigationPlanRequiredWeb = false;
  navigationPlanRequiredTotem = false;
  engineWashHistory = false;
  showHistoryInvoiceApp = false;
  queuePositionControl = false;
  notificationsApp = false;
  chargePartialService = false;
  historyBeginDate: Date;
  invoiceBeginDate: Date;
  idPlanoContaInterest: number;
  idPlanoContaDiscount: number;
  messageAdditionalNavigationPlan: string;
  hasContracts = false;
  dynamicCompetenceDate = false;
  generateInvoiceReceipt = false;
  additionalInfo = false;
  additionalInfoValue: number;
  hasSailor = false;
  hasPositionCheckSailor = true;
  periodicalServicePriceFree = false;

  daysToBlock: number;
  automaticBlock: boolean;
  cityTax: number;
  // Adicionar um valor default para todas as variáveis boolean ou not null
  operationSpecialDays: OperationSpecialDay[] = [];
  operationCloseDays: OperationCloseDay[] = [];

  typeBlockCustomer: TypeBlockCustomer;

  cst: string;
  pis: number;
  cofins: number;
  discountInInvoiceDoc: boolean;

  stockMovementSlingIn: number;
  stockMovementSlingOut: number;
  stockMovementDetachedOut: number;
  stockMovementDetachedIn: number;
  stockMovementAdjustmentIn: number;
  stockMovementAdjustmentOut: number;

  franchiseProductId: number;

  showAdvancedPayment: boolean = false;

  inviteControlYear: boolean;
  dateRecharge: string;
  lastDateRecharge: Date;
  inviteQtd: number;
  inviteMaxBuy: number;
  inviteDailyLimit: number;
  inviteServiceId: number;

  operationNature: string;
  interestInTaxnote: boolean;

  latitudePrevisao: string;
  longitudePrevisao: string;
  editSlingInPreparation: boolean;

  printNavigationPlanWeb: OptionPrintQuestionTotem = OptionPrintQuestionTotem.NoAction;
  printReceiptWeb: OptionPrintQuestionTotem = OptionPrintQuestionTotem.NoAction;
  printItemsOnDeliveryWeb: OptionPrintQuestionTotem = OptionPrintQuestionTotem.NoAction;

  constructor() {
    this.engineTurnScheduleDays = 0;
    this.boatWashScheduleDays = 0;
  }
}
