import moment from 'moment';
import extenso from 'extenso';
import { StorageUtil } from './storage.util';
import Swal from 'sweetalert2';

export class PrintOrderInvoice {

    private itemsMarinaOrder = '';
    private orderQuotas = '';

    constructor() { }

    printOrder(order): void {
        const orderDate = moment(order.date).utc(true).format('DD/MM/YYYY - HH:mm');
        const todayHour = moment().utc(true).format('DD/MM/YYYY - HH:mm');
        const text = '<BOLD><CENTER>COMANDA<br><br>' +
            '<LEFT><SMALL><BOLD>   Estabelecimento: <NORMAL><SMALL>' + order.marinaTradeName + '<br>' +
            '<LEFT><SMALL><BOLD>   CNPJ: <NORMAL><SMALL>' + order.marinaFederalId + '<br>' +
            '<LEFT><SMALL><BOLD>   Cliente: <NORMAL><SMALL>' + order.customers[0].name + '<br>' +
            '<LEFT><SMALL><BOLD>   CPF/CNPJ: <NORMAL><SMALL>' + order.customers[0].federalId + '<br>' +
            '<CENTER><SMALL><BOLD>Itens <br>' +
            this.itemsMarinaOrder +
            this.orderQuotas +
            '<LEFT><SMALL><BOLD>   Data Pedido: <NORMAL><SMALL>' + orderDate + '<br><br>' +
            '<UNDERLINE><BOLD><CENTER>                             .' + '<br>' +
            '<CENTER><SMALL><BOLD>Assinatura<br><br>' +
            '<SMALL>   Impresso: ' + todayHour + '<br>' +
            '<SMALL>   Usuáro: ' + StorageUtil.getUserName() + '<br><cut>';
        const textEncoded = encodeURI(text);
        window.location.href = 'intent://' + textEncoded + ' #Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;';
    }

    printInvoice(invoice): void {
        const todayHour = moment().utc(true).format('DD/MM/YYYY - HH:mm');
        let paymentDate = moment().utc(true).format('DD/MM/YYYY');
        if (invoice.paymentDate !== undefined && invoice.paymentDate != null) {
            invoice.paymentDate.setHours(13);
            paymentDate = moment(invoice.paymentDate).utc(true).format('DD/MM/YYYY');
        }
        const paidValue = invoice.totalPreviousPayment !== undefined ? 0 : invoice.paidValue;
        const balanceToPay = invoice.value - invoice.totalPaid - paidValue;
        const totalPreviousPayment = invoice.totalPreviousPayment !== undefined ?
            invoice.totalPreviousPayment : invoice.totalPaid;
        const previousDebitBalance = invoice.previousDebitBalance !== undefined ?
            invoice.previousDebitBalance : (invoice.value - totalPreviousPayment);
        Swal.fire('Imprimindo', 'Aguarde impressão do recibo da fatura.', 'success');
        const text = '<BOLD><CENTER>RECIBO<br><br>' +
            '<LEFT><SMALL><BOLD>   FATURA: <NORMAL><SMALL>' + invoice.invoiceNumber + '<br>' +
            '<LEFT><SMALL><BOLD>   Estabelecimento: <NORMAL><SMALL>' + invoice.marina.tradeName + '<br>' +
            '<LEFT><SMALL><BOLD>   CNPJ: <NORMAL><SMALL>' + invoice.marina.companyFederalId + '<br>' +
            '<LEFT><SMALL><BOLD>   Cliente: <NORMAL><SMALL>' + invoice.customer.name + '<br>' +
            '<LEFT><SMALL><BOLD>   CPF/CNPJ: <NORMAL><SMALL>' + invoice.customer.federalId + '<br>' +
            '<CENTER><SMALL><BOLD>Itens <br>' +
            this.itemsMarinaOrder +
            '<br><LEFT><SMALL><BOLD>   Total da Fatura: <NORMAL><SMALL>' + invoice.value.toLocaleString('pt-br',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '<br>' +
            '<LEFT><SMALL><BOLD>   Pgto(s) anterior(es): <NORMAL><SMALL>' + totalPreviousPayment.toLocaleString('pt-br',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '<br>' +
            '<LEFT><SMALL><BOLD>   Saldo devedor anterior(es): <NORMAL><SMALL>' + previousDebitBalance.toLocaleString('pt-br',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '<br><br>' +
            '<CENTER><SMALL><BOLD>Dados do pagamento<br>' +
            '<LEFT><SMALL><BOLD>   Valor: <SMALL>' + invoice.paidValue.toLocaleString('pt-br',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '<br>' +
            '<LEFT><SMALL><BOLD>   Valor extenso: <NORMAL><SMALL>' + this.getValorPorExtenso(invoice.paidValue) + '<br>' +
            '<LEFT><SMALL><BOLD>   Data: <NORMAL><SMALL>' + paymentDate + '<br>' +
            '<LEFT><SMALL><BOLD>   Forma: <NORMAL><SMALL>' + invoice.paymentMethodName + '<br><br>' +
            '<CENTER><SMALL><BOLD>Saldo devedor: <SMALL>' + balanceToPay.toLocaleString('pt-br',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '<br><br>' +
            '<SMALL>   Impresso: ' + todayHour + '<br>' +
            '<SMALL>   Usuáro: ' + StorageUtil.getUserName() + '<br><cut>';
        const textEncoded = encodeURI(text);
        window.location.href = 'intent://' + textEncoded + ' #Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;';
    }

    loadOrdersPrint(marinaOrders: any[]): void {
        let orderNumber = 0;
        marinaOrders.forEach((mo) => {
            if (orderNumber !== mo.orderNumber) {
                orderNumber = mo.orderNumber;
                this.createHeaderOrder(mo);
            } else {
                this.createOrderItem(mo);
            }
        });
    }

    loadOrderQuota(marinaOrder: any): void {
        this.orderQuotas += '<CENTER><SMALL><BOLD>Rateio<br>' +
            '<LEFT><SMALL><BOLD>   Cliente                            Rateio  Valor rateio';
        marinaOrder.orderQuotas.forEach((oq) => {
            const totalCustomer = (oq.quota / 100) * marinaOrder.value;
            const totalQuotaCustomer = this.addSpaceField(
                totalCustomer.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 12);
            const customerName = this.addSpaceField(oq.customer.name > 35 ? oq.customer.name.substring(0, 34) : oq.customer.name, 35);
            const customerQuota = this.addSpaceField(
                oq.quota.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%', 8);
            this.orderQuotas += '<br><SMALL><LEFT>   ' + customerName + customerQuota + totalQuotaCustomer;
        });
        this.orderQuotas += '<br>';
    }

    createHeaderOrder(marinaOrder): void {
        const dateOrder = moment(marinaOrder.date).utc(true).format('DD/MM/YY');
        this.itemsMarinaOrder += '<CENTER><SMALL><BOLD>Pedido: <NORMAL><SMALL>' + marinaOrder.orderNumber + ' - '
            + dateOrder + ' - Total: ' + marinaOrder.value.toLocaleString('pt-br',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '<br>' +
            '<LEFT><SMALL><BOLD>   Produto                       Qtd       Preço unit Vl total  ';
        if (marinaOrder.customerQuota !== undefined) {
            this.itemsMarinaOrder += 'Rateio  <br>';
        } else {
            this.itemsMarinaOrder += '<br>';
        }
        this.createOrderItem(marinaOrder);
    }

    createOrderItem(marinaOrder): void {
        marinaOrder.orderItens.forEach((oi) => {
            const productName = this.addSpaceField(oi.productName.length > 30 ? oi.productName.substring(0, 29) : oi.productName, 30);
            const quantity = this.addSpaceField(
                oi.quantity.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 10);
            const price = this.addSpaceField(
                oi.price.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 11);
            const total = this.addSpaceField(
                oi.total.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 10);
            this.itemsMarinaOrder += '<SMALL><LEFT>   ' + productName + quantity + price + total;
            if (marinaOrder.customerQuota !== undefined) {
                const totalCustomer = (marinaOrder.customerQuota / 100) * oi.total;
                const totalQuotaCustomer = this.addSpaceField(
                    totalCustomer.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 8)
                    .trim();
                this.itemsMarinaOrder += totalQuotaCustomer + '<br>';
            } else {
                this.itemsMarinaOrder += '<br>';
            }
        });
        this.itemsMarinaOrder = this.itemsMarinaOrder.replace(/LINE0([^LINE0]*)$/, 'DLINE0' + '$1');
    }

    addSpaceField(field: string, qty: number): string {
        while (field.length < qty) {
            field += ' ';
        }
        return field;
    }

    getValorPorExtenso(valuePaid: any): string {
      let valueFormated = '';

      if (Number.isInteger(valuePaid)) {
          valueFormated = extenso(valuePaid, { mode: "currency" });
      } else {
          valueFormated = extenso(valuePaid, { mode: "currency" })
              .replace('inteiros', 'reais')
              .replace('inteiro', 'real')
              .replace('centésimos', 'centavos')
              .replace('centésimo', 'centavo');
      }

      return valueFormated.charAt(0).toUpperCase() + valueFormated.slice(1);
  }
}
