import { SlingMultiQueueService } from 'src/app/services/sling-multi-queue/sling-multi-queue.service';
import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-boat-multqueue',
  templateUrl: './boat-multqueue.component.html',
  styleUrls: ['./boat-multqueue.component.scss'],
})
export class BoatMultqueueComponent implements OnInit {

  boats: any[] = [];
  selectedBoats: any[] = [];
  listQueue: SelectItem[] = [];
  queue: any;

  constructor(
    public dialogRef: DynamicDialogRef,
    private dialog: DialogService,
    private config: DynamicDialogConfig,
    private slingMultiQueueService: SlingMultiQueueService

  ) {}


  ngOnInit() {
    if (this.config.data.boats) {
      this.boats = this.config.data.boats;
      console.log(this.boats);
      this.loadQueue();
    }
  }

  loadQueue() {
    this.slingMultiQueueService.getAllMarinaActive(true).subscribe((res) => {
      this.listQueue = res.map((queue) => {
        return {
          label: queue.name,
          value: queue.id,
        };
      });
    });
  }

  changeQueue() {
    if (!this.queue) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Selecione uma fila para alterar',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    if (this.selectedBoats.length === 0) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Selecione ao menos um barco para alterar',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja realmente alterar a fila dos barcos selecionados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        let boatsIds:number[] = this.selectedBoats.map((boat) => boat.id);
        this.slingMultiQueueService
          .changeQueue( boatsIds, this.queue)
          .subscribe((res) => {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Fila alterada com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            });
            this.dialogRef.close(true);
          });
      }
    });

  }
}
