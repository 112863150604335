import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {MarinaOrder, OrderItem} from '../models';
import { ProductCategoryType } from '../models/enums/product-category-type';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { OrderItemApi2 } from '../models/dtos/marinaorder/orderItemApi2';

@Injectable()
export class OrderItemService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<OrderItem[]> {
        return this.http.get(environment.apiHost + '/secure/order-items/')
            .pipe(map((response: any) => response.data.orderItens as OrderItem[]));
    }

    getById(id: number): Observable<OrderItem> {
        return this.http.get(environment.apiHost + '/secure/order-item/' + id).pipe(map((response: any) => response.data.orderItem));
    }

    getOrderedForTodayByCategory(productCategoryType: ProductCategoryType): Observable<OrderItem[]> {
        return this.http.get
            (environment.apiHost + '/secure/order-items/today-ordered-category/' + productCategoryType
            ).pipe(map((response: any) => response.data.orderItems as OrderItem[]));
    }

    getByOrderAndCategory(marinaOrderId: number, productCategoryType: ProductCategoryType): Observable<OrderItem[]> {
        return this.http.get(
            environment.apiHost + '/secure/order-items/order-category/' + marinaOrderId + '/' + productCategoryType
        ).pipe(map((response: any) => response.data.orderItems as OrderItem[]));
    }

    create(order: OrderItem): Observable<OrderItem> {
        return this.http.post(environment.apiHost + '/secure/order-item/', order).pipe(map((response: any) => response.data.orderItem));
    }

    saveAll(orderItems: OrderItem[]): Observable<OrderItem[]> {
        return this.http.post(
            environment.apiHost + '/secure/order-item/save-all/', orderItems).pipe(map((response: any) => response.data.orderItems));
    }

    update(order: OrderItem): Observable<OrderItem> {
        return this.http.put(environment.apiHost + '/secure/order-item/', order).pipe(map((response: any) => response.data.order));
    }

    saveChecking(orderItems: OrderItem[]): Observable<OrderItem> {
        return this.http.put(environment.apiHost + '/secure/order-item/checking', orderItems)
            .pipe(map((response: any) => response.data.order));
    }

    saveCheckingFullTank(orderItems: OrderItem[]): Observable<MarinaOrder> {
        return this.http.put(environment.apiHost + '/secure/order-item/checking-full-tank', orderItems)
            .pipe(map((response: any) => response.data.order));
    }

    addItemInMarinaOrder(orderItems: OrderItemApi2): Observable<any> {
      return this.http.post(environment.apiHost + '/api-orders/secure/marina-order/add-item/'+orderItems.marinaOrderId, orderItems)
          .pipe(map((response: any) => response));
  }

    delete(id: number): Observable<OrderItem> {
        return this.http.delete(environment.apiHost + '/secure/order-item/' + id).pipe(map((response: any) => response.data.order));
    }
}
